// number-only.directive.ts
import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumberOnly]'
})
export class NumberOnlyDirective {
  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    const char = String.fromCharCode(event.which);
    if (!/^[0-9]*$/.test(char)) {
      event.preventDefault(); // Prevent non-numeric input
    }
  }

  @HostListener('input', ['$event']) onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = inputElement.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
  }
}
