<!-- submission-list.component.html -->
<div class="submission-list-container">
  <div class="header">
    <div class="row d-flex">
      <div class="stat-item">
        <div class="stat-label">Submission</div>
        <div class="stat-value">{{ totalSubmission }}</div>
      </div>
      <div class="stat-item">
        <div class="stat-label">Missing PUDO</div>
        <div class="stat-value">{{ errorSubmission }}</div>
      </div>
    </div>
    <button [disabled]='isDataSubmitted' class="submit-all-btn btn btn-success" (click)="submitAll()">Submit All</button>
  </div>

  <div class="dynamic-submission-table-container">
    <table class="dynamic-table">
      <thead>
        <tr>
          <th *ngFor="let header of tableHeaders" class="card-header bg-theme">{{ header }}</th>
          <th class="card-header bg-theme">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let submission of submissionList; let i = index" [ngClass]="{'error-row': submission.isError}">
          <td *ngFor="let header of tableHeaders">
            {{ getSubmissionValue(submission, header) }}
          </td>
          <td>
            <button [disabled]='isDataSubmitted' class="delete-btn btn btn-danger" (click)="openConfirmationDialog(i)">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="confirmation-dialog" *ngIf="showConfirmation">
    <div class="confirmation-content">
      <p>Are you sure you want to delete this submission?</p>
      <div class="confirmation-buttons">
        <button class="confirm-btn" (click)="deleteSubmission(submissionToDelete)">Yes</button>
        <button class="cancel-btn" (click)="closeConfirmationDialog()">No</button>
      </div>
    </div>
  </div>

</div>
