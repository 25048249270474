import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { CustomFormService } from 'src/app/users/service/custom-form/custom-form.service';

@Component({
  selector: 'app-workflow-status-modal',
  templateUrl: './workflow-status-modal.component.html',
  styleUrls: ['./workflow-status-modal.component.scss'],
})
export class WorkflowStatusModalComponent {
  workflow: any[] = [];
  @Input() editFormData: any;
  @Input() selectedWorkFlowData: any;
  clickedWorkFlowTitle: any;
  defaultStatusArray: any[] = [];
  isAdminTrue: boolean = false;
  workFlowLogTableData: {
    sortBy: string;
    sortWith: string;
    data: any[];
    page: number;
    total: number;
    limit: number;
    search: string;
    filter: { type: string };
  };

  @Output() closeModal = new EventEmitter<boolean>();
  @Output() submitColumnSettings = new EventEmitter<string>();

  showWorkFlowContent: boolean = false;
  loggedUserId = localStorage.getItem('loggedInUserId');

  workFlowSubmitPayload;

  constructor(
    public toastService: ToastrService,
    private customFromService: CustomFormService
  ) {}

  // @Output() closeModal = new EventEmitter<void>();

  ngOnInit(): void {
    this.workflow = this.editFormData.workflow;
    this.selectedWorkFlowData.staffId = this.selectedWorkFlowData['Staff Id'];
    this.selectedWorkFlowData.createdAt =
      this.selectedWorkFlowData['Submitted At'];
    this.loadLogs();
    this.workFlowLogTableData = {
      sortBy: 'createdBy',
      sortWith: 'desc',

      data: [],
      page: 1,
      limit: 10,
      search: '',
      total: 0,
      filter: {
        type: 'plan',
      },
    };
  }

  onShowWorkFlowContent(workflow) {
    this.defaultStatusArray = workflow?.workflowStatus;
    this.clickedWorkFlowTitle = workflow;
    this.isAdminTrue = workflow?.admin?.includes(this.loggedUserId);
    this.showWorkFlowContent = true;
  }

  isAllSelected(event, item, selectedIndex) {
    if (event.target.checked) {
      this.workFlowSubmitPayload = {
        workflowStatus: {
          fieldId: this.clickedWorkFlowTitle._id, // workflowId(top)
          fieldStatusId: item._id, // workflowStatus status Id
        },
        _id: this.selectedWorkFlowData._id,
      };
    } else {
      this.workFlowSubmitPayload = null;
    }
  }

  onCloseStatusFrom(flag = false) {
    this.showWorkFlowContent = false;
    this.closeModal.emit(flag);
  }

  loadLogs() {
    this.customFromService
      .getWorkFlowLogs(this.selectedWorkFlowData._id)
      .subscribe((data) => {
        this.workFlowLogTableData.data = data?.data;
        this.workFlowLogTableData.total = data?.recordsTotal;
      });
  }

  onSubmitColumnSettings(): void {

    const subscribe = this.customFromService
      .updateWorkflowStatus(this.workFlowSubmitPayload)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(({ data }) => {
        this.toastService.success(data?.message);
        this.onCloseStatusFrom(true);
      });
    // this.submitColumnSettings.emit(status);
  }

  // Add remaining methods and logic here
}
