import { Component, OnInit, QueryList, ViewChildren ,Output, EventEmitter} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subject, Subscription, of } from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  delay,
  finalize,
} from 'rxjs/operators';
import {
  SortableHeader,
  SortEvent,
} from 'src/app/shared/directive/sortable.directive';

import { CentralBuilderService } from 'src/app/users/service/central-builder/central-builder.service';
import { CustomFormService } from 'src/app/users/service/custom-form/custom-form.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'my-forms-overview',
  templateUrl: './my-forms-overview.component.html',
  styleUrls: ['./my-forms-overview.component.scss'],
})
export class MyFormsOverviewComponent implements OnInit {
  usersPTable;
  public isModuleView: boolean = false;
  selectedModule;
  questions;
  public userssearchInput = new Subject<KeyboardEvent>();
  public form: FormGroup;
  unsubcribe: any;
  isTableLoading: boolean = true;
  public fields: any[] = [];
  @Output() takeQuestion = new EventEmitter<string>();
  @Output() customFormID = new EventEmitter<string>();
  @Output() formType = new EventEmitter<string>();
  @Output() formTitle = new EventEmitter<string>();


  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  // companyName: string;
  /// bussiness unit over view table config
  onSortUsers({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.usersPTable.sortWith = column;
    this.usersPTable.sortBy = direction;
    this.loadUsers();
  }

  onPageChangeUsers(page: number) {
    this.usersPTable.page = page;
    this.loadUsers();
  }
  onLimitChangeUsers() {
    this.loadUsers();
  }

  private usersSearchSubscription: Subscription;

  answerForm = new FormGroup({
    typeOne: new FormControl('', Validators.required),
    typeThree: new FormControl('', Validators.required),
    typeFour: new FormControl('', Validators.required),
    typeSix: new FormControl('', Validators.required),
    typeTwo: new FormArray([]),
    typeFive: new FormArray([])

  });

  get f(){
    return this.answerForm.controls;
  }

  submit(){
  }
  /*onSubmitWorkFlow() {
    //this.isLoadingSubmit = true;
    const data = {
      ...this.answerForm.value,
    };
    const sub = this.customFormService
      .createForm(data)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        (data: any) => {
          if (data.success) {
            this.overViewEvent.emit(null);
            this.initForm();
            this.isLoadingSubmit = false;
            this.toastService.success('Form Created SuccessFully')
          }

        },
        (error: HttpErrorResponse) => {
          this.isLoadingSubmit = false;
          this.toastService.error('Please Fill all the Fields')
        }
      );
  }*/

  constructor(
    private MyformService: CustomFormService,
    public toastService: ToastrService,
    private centralBuilderService: CentralBuilderService
  ) {
    this.form = new FormGroup({
      fields: new FormControl(JSON.stringify(this.fields)),
    });
    this.unsubcribe = this.form.valueChanges.subscribe((update) => {

      this.fields = JSON.parse(update.fields);
    });
    // this.config.notFoundText = 'Custom not found';
    this.usersPTable = {
      sortWith: '_id',
      sortBy: 'desc',
      data: [],
      page: 1,
      limit: 10,
      search: '',
      total: 0,
      isLoading: true,
    };

    this.usersSearchSubscription = this.userssearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.usersPTable.page = 1;
        this.usersPTable.search = input;
        this.loadUsers();
      });
  }

  ngOnInit(): void {

    this.loadUsers();
  }
  onCheckChangeTypeTwo(event) {
    const formArray: FormArray = this.answerForm.get('typeTwo') as FormArray;

    /* Selected */
    if(event.target.checked){
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    }
    /* unselected */
    else{
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if(ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
  }
  onCheckChangeTypeFive(event) {
    const formArray: FormArray = this.answerForm.get('typeFive') as FormArray;

    /* Selected */
    if(event.target.checked){
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    }
    /* unselected */
    else{
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if(ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
  }
  loadUsers() {
    const {total,data,isLoading, ...rest} = this.usersPTable
    console.log('rest', rest)
    const subscribe = this.MyformService.getAllMyForms(rest)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { data } = response.data;
          this.usersPTable.data = data;
          this.usersPTable.total = data?.length;
          this.isTableLoading = false;
          this.usersPTable.isLoading= false;
        },
        (error) => {
          this.usersPTable.data = [];
          this.usersPTable.total = 0;
          this.usersPTable.isLoading= false;
          this.toastService.error('No list found');
        }
      );
  }
  getFields() {
    return this.fields;
  }
  getQuestionsFields(questions) {
    const data = questions.sort((a, b) => a.indexNum - b.indexNum);
    const mainQuestions = questions.filter(question=>!question.conditionalQuestions.length);
    // const modifiedData = data.map((rawProduct) => {
    //   return {
    //     disabled: true,
    //   ...rawProduct
    //   };
    // });
    // let parentQuestions =  data.filter((value ) => value.conditionalQuestions === []);
    this.fields =mainQuestions
    this.form = new FormGroup({});

    this.fields.forEach((x) => {
      if ([1, 2,3,4,5].includes(x.type)) {
        this.form.addControl(x.question, new FormGroup({}))
        x.options.forEach((o) => {
          // x.options.forEach(o=>{
            (this.form.get(x.question) as FormGroup)?.addControl(o._id,new FormControl(false))
          // })
        });
      } else {
        this.form.addControl(
          x.question,
          new FormControl(
            x.value || '',
            x.required ? Validators.required : null
          )
        );
      }
    })
  }
  getQuestions(moduleId: string,formId: string) {
    this.customFormID.emit(formId);
    this.takeQuestion.emit(moduleId);
    this.formType.emit("takeQuestion");
    // this.centralBuilderService
    //   .getSingleQuestions(moduleId)
    //   .subscribe(({ data }) => {
    //     this.isModuleView = true;
    //     this.selectedModule = data?.data || null;
    //     this.getQuestionsFields(data.data?.questions);
    //     //   this.questions = data.moduleData.questions.map(
    //     //     ({ _id, question,options,type,  required,value}) => ({ _id, question,options,type,required,value })
    //     //   );
    //     //  this.selectedModule = data.moduleData
    //   });
  }
  viewQuestions(formId,title){
    this.customFormID.emit(formId);
    this.formType.emit("viewQuestion");
    this.formTitle.emit(title);
  }
  onSubmitQuestions(customFormId: string) {

  }
  
  ngOnDestroy(): void {
    this.usersSearchSubscription && this.usersSearchSubscription.unsubscribe();
  }
}
