import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { build$ } from 'protractor/built/element';
import { Observable } from 'rxjs';
import { generateHttpParams, queryParam } from 'src/app/core/utils/helper';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SetupService {
  private baseUrl: string;
  private serviceModuleBaseUrl: string;
  private baseUrl2: string;

  private get Headers(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json' ,
      'Content-Type': 'application/json',
    });
  }
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
    this.serviceModuleBaseUrl = environment.serviceModuleBaseUrl;
  }

  getBuShiftSetUpData(data): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/business-unit/read/new`,
      { isWebsite: '1' },
      {
        headers: this.Headers,
      }
    );
  }

  getBussinesUnit(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/business-unit/?${queryParam(data)}`, {
      headers: this.Headers,
    });
  }

  getDepartments({ page, limit, search, sort }): Observable<any> {
    return this.http.get(`${this.baseUrl}/businessunit`, {
      headers: this.Headers,
      params: { page, limit, search, sortWith: sort.key, sortBy: sort.order },
    });
  }

  getSection({ page, limit, search, sort }, departmentId): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/business-unit/${departmentId}/section/`,
      {
        headers: this.Headers,
        params: { page, limit, search, sortWith: sort.key, sortBy: sort.order },
      }
    );
  }

  getSubsection({ page, limit, search, sort }, sectionId): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/business-unit/${sectionId}/sub-section/`,
      {
        headers: this.Headers,
        params: { page, limit, search, sortWith: sort.key, sortBy: sort.order },
      }
    );
  }

  getBusinessUnits({ page, limit, search, sortWith, sortBy }) {
    return this.http.get(`${this.baseUrl}/business-unit`, {
      headers: this.Headers,
      params: { page, limit, search, sortWith, sortBy },
    });
  }

  getBusinessUnit() {
    return this.http.get(`${this.baseUrl}/business-unit`, {
      headers: this.Headers,
    });
  }

  getBusinessUnitBUShift() {
    return this.http.get(`${this.baseUrl}/opsgroup/planbu`, {
      headers: this.Headers,
    });
  }

  getOPSGroupAndTeam(buId) {
    return this.http.get(`${this.baseUrl}/ballot/getballotcalender1/${buId}`, {
      headers: this.Headers,
    });
  }

  getSkillset(businessUnitId) {
    const data ={"businessUnitId":businessUnitId}
    return this.http.post(`${this.baseUrl}/businessunit/skillsetsandlocations`, data,{
      headers: this.Headers,
    });
  }

  getSkillSpeciality(data) {
    return this.http.post(`${this.baseUrl}/subskillset/read`, data,{
      headers: this.Headers,
    });
  }
  //repotinglocation/read

  getReportingLocation() {
    return this.http.post(`${this.baseUrl}/repotinglocation/read`, {
      headers: this.Headers,
    });
  }
//appointment/read

getAppointment() {
  return this.http.post(`${this.baseUrl}/appointment/read`, {
    headers: this.Headers,
  });
}

  getSkillSet() {
    return this.http.post(`${this.baseUrl}/skillset/read`, {
      headers: this.Headers,
    });
  }
  getSubSkillSet() {
    return this.http.post(`${this.baseUrl}/subskillset/read`, {
      headers: this.Headers,
    });
  }

  getRepoLocations(businessunit) {
    const data = {"businessUnitId":businessunit};
    return this.http.post(`${this.baseUrl}/businessunit/skillsetsandlocations`, data,{
      headers: this.Headers,
    });
  }

  getGeoRepoLocations(businessunit) {
    const data = {"businessUnitId":businessunit};
    return this.http.get(`${this.baseUrl}/businessunit/${businessunit}/geolocation`,{
      headers: this.Headers,
    });
  }

  getBuShiftDetails(query:any) {
    const data = {
      "isWebsite": "1"
    }
    let params = new HttpParams();
    if (query.search) {
      params = params.set('search', query.search);
    }
    return this.http.post(`${this.baseUrl}/businessunit/read/new`, data,{
      headers: this.Headers,
      params: params
    });
  }
  //  service setup get details
  getServiceSetupDetails(query: any) {
    const params = generateHttpParams(query);

    return this.http.get(`${this.serviceModuleBaseUrl}/api/serviceSetup/read`, {
      headers: this.Headers,
      params: params,
    });
  }

  getSingleServiceSetup(id, timezone) {
    return this.http.get(
      `${this.serviceModuleBaseUrl}/api/serviceSetup/readSingleService/${id}`,
      {
        headers: this.Headers,
        params: { timezone },
      }
    );
  }

  getServiceType(query: any){
    const params = generateHttpParams(query);
    return this.http.get(`${this.serviceModuleBaseUrl}/api/serviceSetup/serviceTypeNames`, {
      headers: this.Headers,
      params,
    });
  }
  
  getServicesAndWeeks(query: any, serviceType: string){
    const params = generateHttpParams(query);
    return this.http.post(
      `${this.serviceModuleBaseUrl}/api/serviceSetup/serviceNamesAndWeeks`,
      { serviceType },
      {
        headers: this.Headers,
        params
      }
    );
  }

  getServiceTemplates(query: any, serviceSetupId: string){
    const params = generateHttpParams(query);
    return this.http.post(
      `${this.serviceModuleBaseUrl}/api/planService/templateRead`,
      { serviceSetupId },
      {
        headers: this.Headers,
        params,
      }
    );
  }

  getAllServices(payload: any){
    return this.http.post(
      `${this.serviceModuleBaseUrl}/api/service/serviceRead`,
      payload,
      {
        headers: this.Headers,
      }
    );
  }

  createTemplate(payload) {
    return this.http.post(`${this.serviceModuleBaseUrl}/api/planService/templateCreate`, payload,{
      headers: this.Headers,
    });
  }

  updateTemplate(payload) {
    return this.http.post(`${this.serviceModuleBaseUrl}/api/planService/templateUpdate`, payload,{
      headers: this.Headers,
    });
  }

  deleteTemplate(templateId) {
    return this.http.post(`${this.serviceModuleBaseUrl}/api/planService/templateDelete/${templateId}`,{
      headers: this.Headers,
    });
  }

  getGeoLocations(id) {
    return this.http.get(`${this.baseUrl}/bu-shift/${id}/geoLocation`, {
      headers: this.Headers,
    });
  }

  getBuShiftName({ page, limit, search, sortWith, sortBy }) {
    return this.http.get(`${this.baseUrl}/businessunit/planBU/addedShiftSetup`, {
      headers: this.Headers,
      params: { page, limit, search, sortWith, sortBy },
    });
  }

  getAllBuShiftModule() {
    return this.http.get(`${this.baseUrl}/businessunit/planBU/addedShiftSetup`, {
      headers: this.Headers,
    });
  }

  getBuShiftEdit(id) {
    const payload = {
      "businessUnitId": id
  }
    return this.http.post(`${this.baseUrl}/businessunit/read/singlebu`,payload, {
      headers: this.Headers,
    });
  }
  //businessunit/setting/
  getBuShiftGeoLocation(id) {
    return this.http.get(`${this.baseUrl}/businessunit/setting/${id}`, {
      headers: this.Headers,
    });
  }

  getUserShift(data) {
    const vl ={draw:2};
    return this.http.get(
      `${this.baseUrl}/companyuser/editList?${queryParam(data)}`,
//`${this.baseUrl}/user-shiftsetup?${queryParam(data)}`,
      {
        headers: this.Headers,
      }
    );
  }

  getUserShiftEdit(id) {
    const data = {
      "userId": id
  }
    return this.http.post(`${this.baseUrl}/companyUser/read/single`,data, {
      headers: this.Headers,
    });
  }

  getBuSetting(id) {
    return this.http.get(`${this.baseUrl}/bu-shift/setting/${id}`, {
      headers: this.Headers,
    });
  }

  getAdmin() {
    return this.http.get(`${this.baseUrl}/scheme/admin/`, {
      headers: this.Headers,
    });
  }

  getAdminWithfilter() {
    return this.http.get(`${this.baseUrl}/leavegroup/bu/adminlist`, {
      headers: this.Headers,
    });
  }

  getScheme(id, data) {

    return this.http.get(`${this.baseUrl}/scheme/${id}`, {
      headers: this.Headers,
    });
  }

  getSingleScheme(id) {
    return this.http.get(`${this.baseUrl}/scheme/single/${id}`, {
      headers: this.Headers,
    });
  }

  deleteSingleScheme(data) {
    return this.http.post(`${this.baseUrl}/scheme/delete`, data, {
      headers: this.Headers,
    });
  }

  createSkillSet(name) {
    return this.http.post(`${this.baseUrl}/skillset/create`, name, {
      headers: this.Headers,
    });
  }

  createSpeciality(name) {
    return this.http.post(
      `${this.baseUrl}/subskillset/create`,
      { name: name.name, skillSetId: name.skillSetId._id,status:"1" },
      {
        headers: this.Headers,
      }
    );
  }

  createLocations(name) {
    return this.http.post(`${this.baseUrl}/repotinglocation/create`, name, {
      headers: this.Headers,
    });
  }

  updateLocations(payload){
    return this.http.post(`${this.baseUrl}/repotinglocation/update`, payload, {
      headers: this.Headers,
    });
  }

  saveBuSetup(data) {
    return this.http.post(`${this.baseUrl}/businessunit/updateBuShiftScheme`, data, {
      headers: this.Headers,
    });
  }

  updateUserShift(data) {
    return this.http.post(`${this.baseUrl}/companyuser/update`, data, {
      headers: this.Headers,
    });
  }

  updateScheme(data) {
    return this.http.post(`${this.baseUrl}/scheme/update`, data, {
      headers: this.Headers,
    });
  }

  createDepartment(department) {
    return this.http.post(
      `${this.baseUrl}/business-unit/department/`,
      department,
      {
        headers: this.Headers,
      }
    );
  }

  createSchemeShift(data) {
    return this.http.post(`${this.baseUrl}/scheme`, data, {
      headers: this.Headers,
    });
  }

  updateDepartment(department, deptID) {
    return this.http.put(
      `${this.baseUrl}/business-unit/department/${deptID}`,
      department,
      {
        headers: this.Headers,
      }
    );
  }
  deleteDepartment(deptID) {
    return this.http.delete(
      `${this.baseUrl}/business-unit/department/${deptID}`,
      {
        headers: this.Headers,
      }
    );
  }
  createSection(section, departmentId) {
    return this.http.post(
      `${this.baseUrl}/business-unit/${departmentId}/section/`,
      section,
      {
        headers: this.Headers,
      }
    );
  }
  updateSection(section, departmentId, sectionID) {
    return this.http.put(
      `${this.baseUrl}/business-unit/${departmentId}/section/${sectionID}`,
      section,
      {
        headers: this.Headers,
      }
    );
  }
  deleteSection(id, departmentId) {
    return this.http.delete(
      `${this.baseUrl}/business-unit/${departmentId}/section/${id}`,
      {
        headers: this.Headers,
      }
    );
  }
  createSubsection(subsection, sectionId) {
    return this.http.post(
      `${this.baseUrl}/business-unit/${sectionId}/sub-section/`,
      subsection,
      {
        headers: this.Headers,
      }
    );
  }
  updateSubsection(subsectionId, sectionId, data) {
    return this.http.put(
      `${this.baseUrl}/business-unit/${sectionId}/sub-section/${subsectionId}`,
      data,
      {
        headers: this.Headers,
      }
    );
  }
  deleteSubsection(subsectionId, sectionId) {
    return this.http.delete(
      `${this.baseUrl}/business-unit/${sectionId}/sub-section/${subsectionId}`,
      {
        headers: this.Headers,
      }
    );
  }

  updateSkillSet(data) {
    return this.http.post(`${this.baseUrl}/skillset/update`, data, {
      headers: this.Headers,
    });
  }

  deleteSkillSet(data) {
    return this.http.post(`${this.baseUrl}/skillset/delete`, data, {
      headers: this.Headers,
    });
  }

  updateSpeciality(data) {
    return this.http.post(`${this.baseUrl}/subskillset/update`, data, {
      headers: this.Headers,
    });
  }

  deleteSpeciality(data) {
    return this.http.post(`${this.baseUrl}/subskillset/delete`, data, {
      headers: this.Headers,
    });
  }

  schemeUserLog(data) {
    return this.http.post(`${this.baseUrl}/scheme/userlog`, data, {
      headers: this.Headers,
    });
  }
  getTierType(id) {
    return this.http.get(`${this.baseUrl}/ballot/setting/${id}`, {
      headers: this.Headers,
    });
  }
  // save service setup data
  saveServiceSetup(data) {
    return this.http.post(`${this.serviceModuleBaseUrl}/api/serviceSetup/create`, data, {
      headers: this.Headers,
    });
  }

  updateServiceSetup(id,data) {
    return this.http.post(`${this.serviceModuleBaseUrl}/api/serviceSetup/update/${id}`, data, {
      headers: this.Headers,
    });
  }

  getServiceLogs(payload: any) {
    return this.http.post(
      `${this.serviceModuleBaseUrl}/api/manageService/readServiceLogs`,
      payload,
      {
        headers: this.Headers,
      }
    );
  }

  cancelServiceBooking(payload: any){
    return this.http.post(`${this.serviceModuleBaseUrl}/api/service/adminCancelBookings`, payload, {
      headers: this.Headers,
    });
  }

  createServices (payload: any){
    return this.http.post(
      `${this.serviceModuleBaseUrl}/api/service/serviceCreate`,
      payload,
      {
        headers: this.Headers,
      }
    );
  }

  cancelService(serviceId: any){
    return this.http.post(
      `${this.serviceModuleBaseUrl}/api/service/cancelService`,
      { serviceId },
      {
        headers: this.Headers,
      }
    );
  }

  adjustService(payload: any){
    return this.http.post(
      `${this.serviceModuleBaseUrl}/api/service/adjust`,
      payload,
      {
        headers: this.Headers,
      }
    );
  }
}
