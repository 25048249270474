<div class="card mb-3" *ngIf="!isModuleView">
  <div class="card-header flexi-header p-4">
    <h3>MODULES</h3>
  </div>
  <div class="card-body">
    <div class="card table-card">
      <div class="card-body flexi-body">
        <div class="flex-column">
          <div class="d-flex justify-content-between mt-2 mb-3">
            <select
              class="custom-select"
              style="width: auto"
              name="pageSize"
              (change)="onLimitChangeModule()"
              (search)="modulesearchInput.next($event)"
              (keyup)="modulesearchInput.next($event)"
              [(ngModel)]="moduleTable.limit"
            >
             <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="30">30</option>
              <option [ngValue]="40">40</option>
              <option [ngValue]="50">50</option>
            </select>
            <div class="form-group form-inline mb-0">
              <span *ngIf="moduleTable.isLoading">Please wait...</span>
              <input
                class="form-control ml-2"
                type="search"
                name="searchTerm"
                (keyup)="modulesearchInput.next($event)"
                placeholder="Search here"
                [(ngModel)]="moduleTable.search.value"
              />
            </div>
          </div>
          <div class="table-responsive">
            <table class="table" *ngIf="!isTableLoading">
              <thead>
                <tr>
                  <th
                    scope="col"
                    sortable="Tier 1"
                    (sort)="onSortModule($event, 'moduleName',0)"
                  >
                    MODULE NAME
                  </th>
                  <th
                    scope="col"
                    sortable="Tier 2"
                    (sort)="onSortModule($event, 'updatedAt',1)"
                  >
                    LAST MODIFIED
                  </th>
                  <th
                    scope="col"
                    sortable="Tier 3"
                    (sort)="onSortModule($event, 'status',2)"
                  >
                    STATUS
                  </th>

                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of moduleTable.data">
                  <td>
                    {{ item.moduleName }}
                  </td>

                  <td>{{ item.updatedAt |date:'medium' }}</td>

                  <td>
                    <span
                      class="badge"
                      [ngClass]="
                        item.status == 1
                          ? 'badge-success'
                          : 'badge-warning'
                      "
                    >
                      {{
                        item.status === 1 ? "Published" : "Draft"
                      }}</span
                    >
                  </td>

                  <td>
                    <button
                      class="btn btn-submit p-2 m-1"
                      (click)="getQuestions(item._id)"
                    >
                      View
                    </button>
                    <button
                      class="btn btn-add p-2 m-1"
                      (click)="onEditModule(item._id)"
                    >
                      Edit
                    </button>
                    <button
                      class="btn btn-clear p-2 m-1"
                      *ngIf="item.status != 1"
                      (click)="moduleDelete(item._id)"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <app-skliton-loading-table [numberOfCols]="4" [isTableLoading]="isTableLoading"></app-skliton-loading-table>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <p>Total Records {{ moduleTable.total }}</p>
          <ngb-pagination
            [collectionSize]="moduleTable.total"
            [(page)]="moduleTable.page"
            [maxSize]="5"
            (pageChange)="onPageChangeModule($event)"
            [pageSize]="moduleTable.limit"
          >
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card" *ngIf="isModuleView">
  <div class="card-header flexi-header p-4">
    <h3 style="display: inline">
      {{ selectedModule?.moduleName | uppercase }}
    </h3>
    <button
      class="btn btn-back pull-right"
      (click)="isModuleView = false"
    >
      Back
    </button>
  </div>

  <div class="card-body" style="word-break: break-word; padding: 10px">
      <div
        class="box d-flex flex-column"
        [ngClass]="{ 'section-border': selectedSection === section._id }"
        *ngFor="let section of selectedModule?.sections; let i = index"
      >
        <div class="d-flex" *ngIf="!selectedModule.isDefault">
          <strong class="mr-1">{{ i + 1 }} </strong>
          <div
            class="box-heading ml-2 d-flex"
            style="word-break: break-word"
          >
            &emsp;
            <p>{{ section?.sectionName }}</p>
          </div>
        </div>
    <div
      class="box d-flex flex-column"
      *ngFor="let item of section?.questions; let i = index"
    >
      <div class="d-flex">
        <strong class="mr-1">{{ i + 1 }} </strong>
        <div class="box-heading" style="word-break: break-word">
          &emsp; <p [innerHTML]="item?.question | safeHtml"></p>
        </div>
      </div>
      <div class="box-body" *ngIf="item.type === 1">
        <div class="d-flex flex-wrap answers-options">
          <div class="form-group flex-fill" style="margin: 1rem 1rem; width: 44%">
            <textarea disabled class="form-control" rows="5"></textarea>
          </div>
        </div>
      </div>

      <div class="box-body" *ngIf="item.type === 2">
        <div class="d-flex flex-column answers-options">
          <div
            class="checkbox checkbox-danger checkbox-inline"
            *ngFor="let option of item.options"
          >
            <input type="checkbox" disabled />
            <label for="consolidated">{{ option.value }}</label>
          </div>
        </div>
      </div>

      <div class="box-body" *ngIf="item.type === 3">
        <div class="d-flex flex-column answers-options">
          <div
            class="radio radio-danger radio-inline"
            *ngFor="let item of item.options"
          >
            <input type="radio" disabled />
            <label for="consolidated"> {{ item.value }}</label>
          </div>
        </div>
      </div>

      <div class="box-body" *ngIf="item.type === 4">
        <div class=" d-flex flex-column answers-options">
          <div class="flex-child" *ngFor="let opt of item.options">
              <input type="radio" [value]="opt.value" disabled />
                <label for="consolidated"> {{ opt.value }}</label>
            </div>
          </div>
        </div>

      <div class="box-body" *ngIf="item.type === 5">
        <div
        class="checkbox checkbox-danger checkbox-inline"
        *ngFor="let option of item.options"
      >
        <input type="checkbox" disabled />
        <label for="consolidated">{{ option.value }}</label>
      </div>
      </div>

      <div class="box-body" *ngIf="item.type === 6">
        <div class="d-flex flex-wrap answers-options">
          <textarea disabled class="form-control"></textarea>
        </div>
      </div>
      <div class="box-body" *ngIf="item.type === 7">
        <div class="d-flex flex-wrap answers-options">
          <div class="form-group flex-fill" style="margin: 1rem 1rem; width: 44%"
            *ngFor="let item of item.profile">
            <label for="my-input">{{ item.questionName }}</label>
            <input id="my-input" class="form-control" disabled type="text" />
          </div>
        </div>
      </div>
      <div class="box-body" *ngIf="item.type === 8">
        <div class="d-flex flex-wrap answers-options">
          <div
            class="form-group flex-fill"
            style="margin: 1rem 1rem; width: 30%"
          >
            <textarea disabled class="form-control"></textarea>
          </div>
        </div>
      </div>
      <div class="box-body" *ngIf="item.type === 9">
        <div class="d-flex flex-wrap answers-options">
          <div
            class="form-group flex-fill"
            style="margin: 1rem 1rem; width: 44%"
          >
            <input type="number" disabled class="form-control" />
          </div>
        </div>
      </div>
      <div class="box-body" *ngIf="item.type === 10">
        <div *ngFor="let option of item?.dateTime">
          <div class="d-flex flex-wrap answers-options"*ngIf="item.dateTime?.length === 2 && option === 2">
            <div class="form-group flex-fill" style="margin: 1rem">
              <label for="my-input">Date</label>
              <input id="my-input" class="form-control" type="date" disabled />
            </div>
            <div class="form-group flex-fill" style="margin: 1rem">
              <label for="my-input">Hours</label>
              <select name="" id="" class="form-control" disabled></select>
            </div>
            <div class="form-group flex-fill" style="margin: 1rem">
              <label for="my-input">Minutes</label>
              <select name="" id="" class="form-control" disabled></select>
            </div>
            <div class="form-group flex-fill" style="margin: 1rem">
              <label for="my-input">Seconds</label>
              <select name="" id="" class="form-control" disabled></select>
            </div>
          </div>
          <div class="d-flex flex-wrap answers-options"*ngIf="item.dateTime?.length === 1 && option ===1">
            <div class="form-group flex-fill" style="margin: 1rem">
              <label for="my-input">Date</label>
              <input id="my-input" class="form-control" type="date" disabled />
            </div>
          </div>
          <div class="d-flex flex-wrap answers-options"*ngIf="option === 2 && item.dateTime?.length === 1">
            <div class="form-group flex-fill" style="margin: 1rem">
              <label for="my-input">Hours</label>
              <select name="" id="" class="form-control" disabled></select>
            </div>
            <div class="form-group flex-fill" style="margin: 1rem">
              <label for="my-input">Minutes</label>
              <select name="" id="" class="form-control" disabled></select>
            </div>
            <div class="form-group flex-fill" style="margin: 1rem">
              <label for="my-input">Seconds</label>
              <select name="" id="" class="form-control" disabled></select>
            </div>
          </div>
      </div>
      </div>
      <div class="box-body" *ngIf="item.type === 11">
        <select class="form-control" [attr.disabled]="true"></select>
      </div>
      <div class="box-body" *ngIf="item.type === 12">
        <div class="form-group">
          <input id="my-input" disabled class="form-control-file" type="file" />
        </div>
        Preffered formats are -
        <span *ngFor="let item of item?.options">
          {{ item.value }}<span *ngIf="item.length != 0"> </span>
        </span>
      </div>
      <div class="box-body" *ngIf="item.type === 13">
        <div class="d-flex">
          <ngb-rating [max]="item.value" [rate]="0"></ngb-rating>
        </div>
      </div>
      <div class="box-body" *ngIf="item.type === 14">
        <select class="form-control" [attr.disabled]="true"></select>
      </div>

      <div class="box-body" *ngIf="item.type === 15">
          <div *ngFor="let items of item.options">
            <div *ngIf ="items.imageSrc">
            <img [src]="items.imageSrc" width="250px" height="320px"  id="4" alt="Image Preview" crossorigin="anonymous"/>
            </div>
            <p>{{items.value}}</p>
            <br/>
          </div>
    </div>
    </div>
    <div
    *ngIf="section?.questions.length == 0"
    style="text-align: center"
  >
    <h4>No questions to show</h4>
  </div>
  </div>
</div>
