<div class="d-flex flex-column">
  <ul
    ngbNav
    #companyNav="ngbNav"
    [(activeId)]="activeId"
    class="nav-tabs rounded mb-3"
    (click)="onTabChange($event)"
  >
    <li [ngbNavItem]="1">
      <a ngbNavLink>OVERVIEW</a>
      <ng-template ngbNavContent>
        <app-service-setup-overview
          (changeActiveEdit)="onEditBtn($event)"
          (changeActiveView)="onViewBtn($event)"
        ></app-service-setup-overview>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink> SERVICE SETUP</a>
      <ng-template ngbNavContent>
        <div class="card">
          <h5 class="card-header bg-theme">
            {{ isHeading }}
          </h5>
          <div class="card-body">
            <form [formGroup]="serviceSetUpForm">
              <div class="card mb-3">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="font-weight-500">
                          Service Type
                          <span class="field-mandatory required-sign"
                            >*</span
                          ></label
                        >
                        <ng-select
                          class="custom"
                          [items]="serviceOption"
                          bindLabel="serviceTypeName"
                          bindValue="serviceTypeName"
                          formControlName="serviceType"
                          placeholder="Select Service Type"
                          appendTo="body"
                          [multiple]="false"
                          [searchable]="true"
                          [clearable]="false"
                          [loading]="serviceTypeLoader"
                          [loadingText]="'Loading more...'"
                          (scrollToEnd)="loadMoreServiceTypes($event)"
                          [closeOnSelect]="true"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="form-group">
                        <label class="font-weight-500"
                          >Enter Service Name
                          <span class="field-mandatory required-sign"
                            >*</span
                          ></label
                        >
                        <input
                          type="text"
                          placeholder="Enter Service Name"
                          formControlName="serviceName"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-2">
                      <div class="form-group">
                        <label
                          class="font-weight-500"
                          style="width: max-content"
                          >Add Weeks for service view<span
                            class="field-mandatory required-sign"
                            >*</span
                          ></label
                        >
                        <ng-select
                          [items]="viewWeeks"
                          class="custom"
                          placeholder="No Of Weeks"
                          formControlName="serviceViewWeeks"
                          appendTo="body"
                          [multiple]="false"
                          [searchable]="true"
                          [clearable]="true"
                          [closeOnSelect]="true"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div class="form-group m-0">
                        <label class="font-weight-500"
                          >StandBy Slot<span
                            class="field-mandatory required-sign"
                            >*</span
                          ></label
                        >
                        <div class="form-check form-check-inline ml-2">
                          <input
                            class="form-check-input"
                            type="radio"
                            [value]="true"
                            id="yesCheckin"
                            formControlName="standBySlot"
                            name="standBySlot"
                          />
                          <label class="form-check-label">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            [value]="false"
                            id="noCheckin"
                            formControlName="standBySlot"
                            name="standBySlot"
                          />
                          <label class="form-check-label">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <ng-container
                      *ngIf="serviceSetUpForm.get('standBySlot').value"
                    >
                      <div class="col-2">
                        <div class="form-group m-0">
                          <label class="font-weight-500">
                            Activation Time Limit
                          </label>
                          <ng-select
                            class="custom"
                            bindLabel="name"
                            placeholder="Select Number of Days"
                            appendTo="body"
                            [value]="true"
                            [multiple]="false"
                            [searchable]="true"
                            [clearable]="
                              serviceSetUpForm.get('StandByActivationTime')
                                .value
                                ? true
                                : false
                            "
                            formControlName="StandByActivationTime"
                          >
                            <ng-option
                              [value]="item"
                              *ngFor="let item of timeLimit"
                              >{{ item }}
                            </ng-option>
                          </ng-select>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>

              <div class="card mb-3">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div class="form-group m-0">
                        <label class="font-weight-500"
                          >Do you want to show ServiceEndTime on Manage
                          Page?<span class="field-mandatory required-sign"
                            >*</span
                          ></label
                        >
                        <div class="form-check form-check-inline ml-2">
                          <input
                            class="form-check-input"
                            type="radio"
                            [value]="true"
                            id="yesCheckin"
                            formControlName="showServiceEndTime"
                            name="showServiceEndTime"
                          />
                          <label class="form-check-label">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            [value]="false"
                            id="noCheckin"
                            formControlName="showServiceEndTime"
                            name="showServiceEndTime"
                          />
                          <label class="form-check-label">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mb-3">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div class="form-group m-0">
                        <label class="font-weight-500"
                          >Cancel Service<span
                            class="field-mandatory required-sign"
                            >*</span
                          ></label
                        >
                        <div class="form-check form-check-inline ml-2">
                          <input
                            class="form-check-input"
                            type="radio"
                            [value]="true"
                            id="yesCheckin"
                            formControlName="cancelService"
                            name="cancelService"
                          />
                          <label class="form-check-label">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            [value]="false"
                            id="noCheckin"
                            formControlName="cancelService"
                            name="cancelService"
                          />
                          <label class="form-check-label">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div class="form-group m-0">
                        <label class="font-weight-500"
                          >Cut-off Configuration for Booking Service<span
                            class="field-mandatory required-sign"
                            >*</span
                          ></label
                        >
                        <div class="form-check form-check-inline ml-2">
                          <input
                            class="form-check-input"
                            type="radio"
                            [value]="true"
                            id="yesCheckin"
                            formControlName="cutOffTimeRequired"
                            name="cutOffTimeRequired"
                          />
                          <label class="form-check-label">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            [value]="false"
                            id="noCheckin"
                            formControlName="cutOffTimeRequired"
                            name="cutOffTimeRequired"
                          />
                          <label class="form-check-label">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ng-container
                    *ngIf="serviceSetUpForm.get('cutOffTimeRequired').value"
                  >
                    <label
                      ><b
                        >Select time duration before service start time</b
                      ></label
                    >
                    <div class="form-check form-check-inline ml-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        [value]="true"
                        id="cutOffDurationBeforeStartTime"
                        formControlName="cutOffDurationBeforeStartTime"
                        name="cutOffDurationBeforeStartTime"
                      />
                      <label class="form-check-label">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        [value]="false"
                        id="cutOffDurationBeforeStartTime"
                        formControlName="cutOffDurationBeforeStartTime"
                        name="cutOffDurationBeforeStartTime"
                      />
                      <label class="form-check-label">No</label>
                    </div>
                    <div formGroupName="cutOffConfiguration">
                      <ng-container
                        *ngIf="
                          serviceSetUpForm.get('cutOffDurationBeforeStartTime')
                            .value
                        "
                      >
                        <div class="row">
                          <div class="col-2">
                            <div class="form-group">
                              <label class="font-weight-500"
                                >Days<span class="field-mandatory required-sign"
                                  >*</span
                                ></label
                              >
                              <input
                                type="text"
                                class="form-control"
                                formControlName="cutOffDays"
                                placeholder="Enter days"
                              />
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="form-group">
                              <label class="font-weight-500"
                                >Hours<span
                                  class="field-mandatory mandatory_Star"
                                  >*</span
                                ></label
                              ><br />
                              <ng-select
                                class="custom"
                                [items]="hourList"
                                bindLabel="name"
                                formControlName="cutOffHours"
                                appendTo="body"
                                [multiple]="false"
                                [searchable]="true"
                                [clearable]="true"
                                [closeOnSelect]="true"
                                placeholder="Select Hours"
                              >
                              </ng-select>
                            </div>
                          </div>
                          <div class="col-2">
                            <label class="font-weight-500"
                              >Minutes<span
                                class="field-mandatory mandatory_Star"
                                >*</span
                              ></label
                            ><br />
                            <ng-select
                              class="custom"
                              [items]="minList"
                              bindLabel="name"
                              formControlName="cutOffMinutes"
                              appendTo="body"
                              [multiple]="false"
                              [searchable]="true"
                              [clearable]="true"
                              [closeOnSelect]="true"
                              placeholder="Select Minutes"
                            >
                            </ng-select>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <ng-container
                      *ngIf="
                        !serviceSetUpForm.get('cutOffDurationBeforeStartTime')
                          .value
                      "
                    >
                      <div class="row mt-3">
                        <div class="col-2">
                          <div class="form-group">
                            <label
                              class="font-weight-500"
                              style="width: max-content"
                              >Select Fixed Cut-off Time
                              <span class="field-mandatory mandatory_Star"
                                >*</span
                              ></label
                            >
                            <ng-select
                              [items]="time"
                              class="custom"
                              bindLabel="name"
                              placeholder="Select Time"
                              formControlName="fixedCutOffTime"
                              appendTo="body"
                              [multiple]="false"
                              [searchable]="true"
                              [clearable]="true"
                              [closeOnSelect]="true"
                            >
                            </ng-select>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </form>
          </div>
          <div class="card-footer">
            <div *ngIf="isVisibleActionButton">
              <button
                class="btn btn-lg btn-success"
                type="submit"
                (click)="
                  activateDisable ? updateSetupDetails() : saveSetupDetails()
                "
                [disabled]="setupSaveLoader"
              >
                {{ activateDisable ? "Update" : "Submit" }}
                <span
                  *ngIf="setupSaveLoader"
                  class="spinner-border ml-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
              <button
                class="btn btn-lg btn-danger ml-2"
                type="button"
                (click)="cancel()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>
