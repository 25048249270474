import {
  Component, OnInit, SecurityContext, TemplateRef,
  ViewChild
} from '@angular/core';
import {
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import { Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { CentralBuilderService } from 'src/app/users/service/central-builder/central-builder.service';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/users/service/notification/notification.service';
import SignaturePad from 'signature_pad';
import { SignatureQuestionsComponent } from '../../../my-forms/signature-questions/signature-questions.component';
import { CustomFormService } from 'src/app/users/service/custom-form/custom-form.service';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-admin-question-form',
  templateUrl: './admin-question-form.component.html',
  styleUrls: ['./admin-question-form.component.scss']
})
export class AdminQuestionFormComponent implements OnInit {
  @ViewChild('adminQuestion', { static: true })
  adminQuestion: TemplateRef<any>;
  adminQuestionReference: any;
  @Input() questionId: string = null;
  @Input() customFormId: string = null;
  @Input() selectedWorkFlowID: string = null;
  @Input() manageFormId: string = null;
  @Input() workflowId: string = null;
  @Input() workflow: any;
  @Output() takeQuestion = new EventEmitter<boolean>();
  @Output() viewPage = new EventEmitter<string>();
  @Output() formTitleviewPage = new EventEmitter<string>();
  @Output() adminModalClosed = new EventEmitter<boolean>();
  @Output() formSubmitted = new EventEmitter<any>();
  showWorkFlowContent: boolean = false;
  questions: any = [];
  viewQuestions: any = [];
  subMittedAnswer: any = [];
  signaturePad: SignaturePad;
  notAnswered: boolean;
  time: NgbTimeStruct = { hour: 0o0, minute: 0o0, second: 0o0 };
  dateTime: NgbTimeStruct = { hour: 0o0, minute: 0o0, second: 0o0 };
  dataTimeOnly: NgbTimeStruct = { hour: 0o0, minute: 0o0, second: 0o0 };
  hourStep = 1;
  minuteStep = 1;
  secondStep = 30;
  secondsArray = ['AM', 'PM']
  hourArray = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  minuteArray = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
  hasParent: any = question => 0 === question?.conditionalQuestions?.length;
  meridian = true;
  user = { _id: null, name: null };
  items = [];
  public isModuleView: boolean = false;
  hasAdditionalQuestion: boolean;
  selectedModule;
  signatureImg: string;
  ratingType;
  favoriteSeason;
  pollingtype;
  selectedCars;
  imageURL;
  fileToUpload: File | null = null;
  dataTewelve;
  typeFourteenQuestion;
  questionDropDownToClear = null;
  questionIDFourteen;
  typeFiveAnswer: any;
  currentSelectedWorkFlow: any;
  imagePath = environment.imageUrl;
  signQuestionClicked: boolean;
  selectedModuleId: any;
  typeSixteenImageField: any[] = [];
  typeSixteenImageSRC: any;
  typeSixteenIndex: any;
  isPopupOpen: boolean = false;
  @ViewChild('myImage', { static: false }) myImage: ElementRef;
  constructor(private centralBuilderService: CentralBuilderService, private dialogRef: MatDialog, private toastService: ToastrService,
    private notificationService: NotificationService, public modelService: NgbModal, public customFormService: CustomFormService,
    private sanitizer : DomSanitizer) {
  }

  async ngOnInit(): Promise<any> {
    console.log('tttt', this.workflow)
    if (this.questionId) {
      this.adminQuestionReference = this.modelService.open(this.adminQuestion, {
        windowClass: 'modal-ui-fix right-align',
      });

      this.adminQuestionReference.result.then((data) => {
      },
        (error) => {
          this.adminModalClosed.emit(true)
        })
    }
  }

  onShowWorkFlowContent(item: any) {
    this.currentSelectedWorkFlow = item;
    this.showWorkFlowContent = true;
    this.hasAdditionalQuestion = !!item?.additionalModuleId?._id;
    if (!this.hasAdditionalQuestion) {
      this.goToChangeStatus()
    }
    else {
      this.selectedModuleId = item?.additionalModuleId?._id
      const value = this.getQuestions(item?.additionalModuleId?._id);
    }

  }
  getSubMittedAnswer() {

  }
  toggleMeridian() {
    this.meridian = !this.meridian;
  }

  multiSelect(value, question) {
    question.answer = value?.map(x => {
      if (this.typeFourteenQuestion?.find(qus => qus._id == x)) {
        return this.typeFourteenQuestion?.find(qus => qus._id == x)
      }
    });
  }

  close(modalRef) {
    modalRef.close();
  }

  drop(event: CdkDragDrop<string[]>, question: any) {
    moveItemInArray(question.images, event.previousIndex, event.currentIndex);
    this.typeFifteen(question);
  }
  typeFifteen(question) {
    let payload;
    payload = question.images.map((data) => {
      return { "value": data.value, "_id": data.option, correctAns: false }
    });
    question.images.map((data, i) => {
      if (data.src !== '')
        payload[i].imageSrc = data.src
    });
    question.answer = payload;

  }

  back() {
    this.takeQuestion.emit(false);
  }

  openDialog(event: MouseEvent, questionid) {
    const target = new ElementRef(event.currentTarget);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.backdropClass = 'hideFullScreen';
    const modalRef = this.dialogRef.open(SignatureQuestionsComponent, dialogConfig);
    modalRef.componentInstance.takeSignature.subscribe((signURL: any) => {
      this.signatureImg = signURL;
      questionid.answer = signURL;
      this.close(modalRef);
    });
  }

  setTypeFive(a, b, c, event) {
    const ind = this.viewQuestions[c].answer.findIndex((data) => {
      return data._id == b._id
    });
    if (ind >= 0) {
      this.viewQuestions[c].answer[ind].selected = event.target.checked;
    }
  }
  formatHoursTo12(date) {
    let hour: string | number = date.hour % 12 || 12;
    let minute;
    if (hour < 10)
      hour = '0' + hour;
    if (date.minute < 10)
      minute = '0' + date.minute;
    else
      minute = date.minute;
    let seconds;
    if (date.hour < 12)
      seconds = 'am';
    else
      seconds = 'pm'
    const time = hour + "-" + minute + "-" + seconds;
    return time;
  }

  isFormSubmitted() {
    this.formSubmitted.emit(this.currentSelectedWorkFlow);
  }
  goToChangeStatus() {
    this.takeQuestion.emit(true);
    this.modelService.dismissAll(this.adminQuestion);

    this.formSubmitted.emit(this.currentSelectedWorkFlow);
  }

  submit() {
    const answers = this.viewQuestions.reduce((prev, question, i) => {
      let answer = null,
        _id = question._id,
        type = question.type;
      switch (question.type) {
        case 1:
        case 6:
        case 8:
        case 9:
        case 14:
        case 12:
        case 13:
        case 16:
        case 5:
        case 15:
          answer = question.answer;
          break;
        case 2:
        case 3:
        case 11:
          answer = question?.options?.find(item => item.value == question.answer) || null;
          break;
        case 10:
          if (-1 !== question.dateTime.indexOf(1) && !!question.answer && !!question.answer.date) {
            answer = answer || {};
            answer["date"] = question.answer.date ? question.answer.date.split("-").reverse().join("-") : null;
          }
          if (
            -1 !== question.dateTime.indexOf(2) &&
            !!question.answer &&
            !!question.answer.time

          ) {
            answer = answer || {};
            if (question?.answer.time?.hour && question?.answer.time?.minute) {
              answer["time"] = this.convert24hourTo12HourFormat(question.answer.time?.hour + ':' + question.answer.time?.minute)
            }
          }
          if ((-1 !== question.dateTime.indexOf(1) && -1 !== question.dateTime.indexOf(2)) &&
            !(!!question.answer && !!question.answer.date && !!question.answer.time?.hour && !!question.answer.time?.minute)) {
            answer = false;
          }
          break;
        case 4:
          answer = question.options?.filter(v => v.answer);
          if (!answer.length) {
            answer = null;
          }
          break;
        default:
          answer = false;
          break;
      }
      return prev.concat({ _id, answer, type });
    }, []);
    if (answers?.some(v => v.type === 9 && !(!!v.answer))) {
      this.toastService.error('Please enter valid numeric values');
      return;
    }
    if (answers.some(v => !!!v.answer)) {
      this.toastService.error("Answer all questions");
      return;
    }
    let _questions = this.questions.map(v => v._id);
    const payload = {
      isAdminForm: true,
      customFormId: this.customFormId,
      answers: answers,
      manageFormId: this.manageFormId,
      moduleId: {
        moduleName: this.selectedModule?.moduleName,
        questions: this.viewQuestions,
        _id: this.selectedModuleId,
      },
      questions: _questions,
      workflowId: this.workflowId
    }
    answers.length > 0 ? this.centralBuilderService.editAnswersAdminOnly(payload).subscribe((data) => {
      this.toastService.success("Form Updated Successfully!!")
      this.viewPage.emit(this.customFormId);
      this.formTitleviewPage.emit(this.selectedModule.moduleName);
      //redirect to manage page
      this.takeQuestion.emit(true);
      this.modelService.dismissAll(this.adminQuestion);

      this.formSubmitted.emit(this.currentSelectedWorkFlow);
    }) : '';
  };
  downloadAttachMent(answer) {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.href = answer?.url;
    a.download = answer?.name;
    a.click();
    document.body.removeChild(a);
  }
  async getQuestions(moduleId: string) {
    let payload = {
      "customFormId": this.customFormId,
      "moduleId": moduleId,
      "moduleType": "customForms",
      "trackQns": true
    }
    this.notificationService
      .getModuleQuestions(payload)
      .subscribe(({ data }) => {

        this.isModuleView = true;
        this.selectedModule = data?.data || null;
        if (this.selectedModule?.questions?.length) {
          this.selectedModule.questions = this.selectedModule.questions?.filter(v => 7 !== v.type) || [];
        }
        this.questions = this.selectedModule.questions.filter(v => 7 !== v.type) || [];
        this.viewQuestions = this.questions.filter(x => 0 === x.conditionalQuestions.length) || [];
        let typeFourteen;
        typeFourteen= this.viewQuestions?.find(item=>item.type === 14)?._id || null;
        const answerPayload = {
          isAdminModule: true,
          customFormId: this.customFormId,
          manageFormId: this.manageFormId,
          workflowId: this.workflowId,
          moduleId: moduleId
        }
        this.notificationService.getManageFormAnswers(answerPayload).subscribe((data) => {
          this.notAnswered = data?.data?.data?.notAnswered ? true : false;
          this.subMittedAnswer = data?.data?.data[0]?.questionId;
          const answers = []
          this.viewQuestions?.map((item, index) => {
            if (item.type == 5) {
              item.options.map((data, index) => {
                answers[index] = {
                  "value": data.value,
                  "correctAns": false,
                  "imageSrc": "",
                  "_id": data?._id,
                  "selected": false
                }
              })
              item.answer = answers;
            }
            if (item.type === 10) {
              item.answer = {};
              item.answer['date'] = null;
              item.answer['time'] = null;
            }

          });
          if (!this.notAnswered) {
            this.viewQuestions?.map((item, index) => {
              const url = item?.answer;
              if (item.type === 10) {
                item.answer = { date: null, time: null };
                const time = this.subMittedAnswer[index]?.answer?.time?.split(':');
                item.answer.date = this.subMittedAnswer[index]?.answer.date ? this.subMittedAnswer[index]?.answer.date?.split("-").reverse().join("-") : null;
                item.answer.time = time ? { hour: parseInt(time[0]), minute: parseInt(time[1]), second: 0 } : null;
              }
              if (item.type == 8 || item.type == 9 || item.type == 1 || item.type == 13)
                item.answer = this.subMittedAnswer[index]?.answer
              if (item.type == 2 || item.type == 3) {
                const answer = this.subMittedAnswer[index]?.answer
                item.answer = typeof answer == 'object' ? answer?.value:answer

              }
              if (item.type == 11) {
                item.answer = this.subMittedAnswer[index]?.answer ? typeof this.subMittedAnswer[index]?.answer === 'object' ? this.subMittedAnswer[index]?.answer?.value : this.subMittedAnswer[index]?.answer : null;
              }
              if (item.type == 12) {
               item.answer = url
              }
              if (item.type == 6) {
                item.answer = `${this.subMittedAnswer[index]?.answer || ''}`.startsWith(`data:image/png;base64,`) ? this.subMittedAnswer[index]?.answer : `data:image/png;base64,${this.subMittedAnswer[index]?.answer}`
               }
              if (item.type == 5) {
                item.answer = this.subMittedAnswer[index]?.answer;
                /* don't remove this code now as it can be used in future*/
                //   this.subMittedAnswer[index]?.answer.map((data,index)=>{
                //  const ind =   item.options.findIndex((opt)=>{
                //      return opt?.value == data?.value
                //     })
                //     if(ind >= 0 )
                //     {
                //       answers[ind].selected = true
                //     }
                //   })
                //   item.answer = answers

              }
              if (item.type == 15) {
                item.answer = JSON.parse(JSON.stringify(this.subMittedAnswer[index]?.answer)) || [];
                this.subMittedAnswer[index]?.answer.map((final: any, index) => {
                  item['images'][index] = { "src": final?.imageSrc || "", "value": final?.value, "option": final._id };
                });

              }
              if (item.type == 4) {
                item.answer=this.subMittedAnswer[index]?.answer;
                this.selectPolling(item?._id, this.subMittedAnswer[index]?.answer[0])
              }
              if (item.type === 14) {
                typeFourteen = item._id;
                item.answer=this.subMittedAnswer[index]?.answer;
              }
              if (item.type == 16) {
                this.typeSixteenImageField = item.answer
              }
            })
          }
          else if (this.notAnswered) {
            this.viewQuestions?.map((item, index) => {
              if (item?.type == 15) {
                item?.options.map((final: any, index) => {
                  item['images'][index] = { "src": final?.imageSrc || "", "value": final?.value, "option": final._id };
                });
              }
              if (item?.type != 5 && item.type != 10) {
                this.viewQuestions[index]?.answer ? this.viewQuestions[index].answer = "" : ''

              }
            });
          }
        });
        this.getNominateUserType(typeFourteen);

      }
      );
    return true
  }

  fileUpload(event, item) {
    let typeList = item.options?.map(data => { return data.value.toLowerCase() }) || [];
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
      let fileType = file.name.split('.')
      let fileExe = fileType?.length ? fileType[fileType.length - 1].toLowerCase() : null;
      if (fileExe && typeList?.includes(fileExe)) {
        var useraddData = new FormData();
        useraddData.append("file", file);
        const fileUpload = this.customFormService
          .uploadFiles(useraddData)
          .pipe(
            finalize(() => {
              fileUpload.unsubscribe();
            })
          )
          .subscribe(
            (data: any) => {
              item.answer = { url: data?.filePath || null, fileName: file.name }
            },
            (error: HttpErrorResponse) => {
              this.toastService.error(error?.message || 'File upload error');
            }
          );
      }
      else {
        this.toastService.error("Only this format are accecpted", typeList?.toString());
      }
      // }
    } else {
      this.toastService.error("Only this format are accecpted", typeList?.toString());
    }


  }

  getNominateUserType(questionId) {
    let finalData = {
      questionId: questionId
    }
    this.centralBuilderService.getNominateUser(finalData).subscribe((data) => {
      this.setDropdown(data.data, questionId);
    });
  }

  setDropdown(data, questionId) {
    this.typeFourteenQuestion = data?.items || [];
    this.questionIDFourteen = questionId;
  }
  setChild(question, option, index?: number) {
    if (question.type == 11) {
      this.selectedModule.questions[index].answer = option;
      this.selectedModule.questions[index].isVisibleList = !this.selectedModule.questions[index].isVisibleList;
    }
    this.checkQuestions(question, option._id);
  };
  checkQuestions(question, a) {

    let q = question._id;
    const filteredQuestions = this.questions
      ?.filter(
        v =>
          !this.hasParent(v) &&
          v?.conditionalQuestions?.some(
            c => c.questionId === q && c.optionId === a
          )
      )
      ?.map(c => {
        c["cQuestion"] = q;
        c["cOption"] = a;
        return JSON.parse(JSON.stringify(c));
      });
    if (question.type == 5) {
      setTimeout(() => {
        if (!question.options.filter(option => option._id === a)[0].answer) {
          let allQuestionsadded = filteredQuestions.map(v => v._id);

          let questionsNeedToDelete = this.viewQuestions?.reduce((prev, curr, i) => {
            return allQuestionsadded.includes(curr._id) ? prev.concat(i) : prev;
          }, []);
          questionsNeedToDelete.sort((a, b) => b - a);
          questionsNeedToDelete.forEach(v => {
            this.viewQuestions.splice(v, 1);
          })
        } else {
          const index = this.viewQuestions?.findIndex(ques => ques._id == q) + 1;
          this.viewQuestions.splice(index, 0, ...filteredQuestions);
        }

        this.viewQuestions = this.viewQuestions?.reduce((prev, curr, i) => {
          const index = prev.findIndex(v => v._id === curr._id);
          return -1 === index ? prev.concat(curr) : prev;
        }, []);
      }, 100);
    } else {
      setTimeout(() => {

        this.viewQuestions = this.viewQuestions?.filter(ques =>
          "cQuestion" in ques ? ques.cQuestion !== q : true
        );
        const index = this.viewQuestions.findIndex(ques => ques._id == q) + 1;
        this.viewQuestions.splice(index, 0, ...filteredQuestions);

        this.viewQuestions = this.viewQuestions.reduce((prev, curr, i) => {
          const index = prev.findIndex(v => v._id === curr._id);
          return -1 === index ? prev.concat(curr) : prev;
        }, []);

      })

    }
  };

  selectPolling(questionId, option) {
    let question = this.questions.find(q => q._id.toString() === questionId.toString());
    if (!question.pollingSelectionCount || (1 === question.pollingSelectionCount)) {
      question.options?.forEach(opt => opt.answer = (option?._id?.toString() === opt?._id?.toString()));
    } else if ((question.options?.filter(op => op.answer)?.length === question?.pollingSelectionCount) && !option?.answer) {
      this.toastService.error('maxinum options reach for this question')
    } else {
      question.options.find(op => op?._id?.toString() === option?._id?.toString()).answer =
        !question.options?.find(op => op?._id?.toString() === option?._id?.toString())?.answer;
    }
  }
  convert24hourTo12HourFormat(time) {
    const time_part_array = time.split(":");
    let ampm = 'am';
    if (time_part_array[0] >= 12) {
      ampm = 'pm';
    }
    if (time_part_array[0] > 12) {
      time_part_array[0] = time_part_array[0] - 12;
    }
    const formatted_time = time_part_array[0] + ':' + time_part_array[1] + ':' + ampm;
    return formatted_time;
  }

  onImageClick(event: MouseEvent, viewQuestions, index) {
    if (index >= 0 && this.viewQuestions[index].answer != "")
      this.typeSixteenImageField = this.viewQuestions[index].answer;
    else
      this.typeSixteenImageField = [];
    const imageElement = event.target as HTMLImageElement;
    const imageWidth = imageElement.offsetWidth;
    const imageHeight = imageElement.offsetHeight;
    const offsetX = event.offsetX;
    const offsetY = event.offsetY;
    const percentageX = ((offsetX / imageWidth) * 100) - 3.3;
    const percentageY = ((offsetY / imageHeight) * 100) - 5.8;

    let field = {
      value: '',
      coordinates: {
        x: percentageX,
        y: percentageY,
        radious: 36,
        imgWidth: imageWidth,
        imgHeight: imageHeight,
      },

    };
    this.typeSixteenImageField.push(field)
    this.viewQuestions[index].answer = this.typeSixteenImageField;
  }
  openPopup(img: any, index) {
    this.typeSixteenImageField = this.viewQuestions[index].answer;
    this.typeSixteenImageSRC = img;
    this.typeSixteenIndex = index;
    this.isPopupOpen = true;
  }

  closePopup() {
    this.isPopupOpen = false;
  }
  conditionQuestionDelete(index) {
    this.typeSixteenImageField.splice(index, 1);
  }

  safeUrl(url: string): SafeUrl {
    const safeUrl: SafeUrl = this.sanitizer.sanitize(SecurityContext.URL, this.imagePath + '/' + url);
    return safeUrl ? this.sanitizer.bypassSecurityTrustUrl(safeUrl.toString()) : null;
  }

}
