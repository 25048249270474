<aside class="h-100 scrollbar">
  <ul>
    <!-- SYSTEM SETTINGS -->
    <li
      *ngIf="
        hasPrivileges(
          'externalLink',
          'businessUserSetup',
          'roleSetup',
          'userProfile',
          'integration'
        )
      "
      class="heading"
      (click)="collapse1.toggle()"
      [attr.aria-expanded]="!systemSettingCollapsed"
      aria-controls="collapseExample"
    >
      SYSTEM SETTINGS
      <i
        class="fa"
        [ngClass]="
          systemSettingCollapsed ? 'fa-angle-double-down' : 'fa-angle-double-up'
        "
        aria-hidden="true"
      ></i>
    </li>
    <div #collapse1="ngbCollapse" [(ngbCollapse)]="systemSettingCollapsed">
      <li
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="rolesArray?.externalLink"
      >
        <a class="" [routerLink]="['company-setup']" (click)="toggleMenu()"
          >COMPANY SETUP</a
        >
      </li>

      <li
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="rolesArray?.businessUserSetup"
      >
        <a class="" [routerLink]="['/users/bu-unit']" (click)="toggleMenu()"
          >BUSINESS UNIT</a
        >
      </li>

      <li
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="rolesArray?.roleSetup"
      >
        <a class="" [routerLink]="['user-roles']" (click)="toggleMenu()"
          >USER ROLES</a
        >
      </li>

      <li
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="rolesArray?.userProfile"
      >
        <a class="" [routerLink]="['custom-fields']" (click)="toggleMenu()"
          >CUSTOM FIELDS</a
        >
      </li>

      <li
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="rolesArray?.integration"
      >
        <a class="" [routerLink]="['integration-logs']" (click)="toggleMenu()"
          >INTEGRATION LOGS</a
        >
      </li>
    </div>

    <!-- ADMIN -->
    <li
      *ngIf="
        hasPrivileges(
          'viewUser',
          'createUser',
          'facialCreation',
          'createEditOPSGroup',
          'lockedAccount',
          'resetPassword'
        )
      "
      class="heading"
      (click)="collapse2.toggle()"
      [attr.aria-expanded]="!adminCollapsed"
      aria-controls="collapseExample"
    >
      ADMIN
      <i
        class="fa"
        [ngClass]="
          adminCollapsed ? 'fa-angle-double-down' : 'fa-angle-double-up'
        "
        aria-hidden="true"
      ></i>
    </li>
    <div #collapse2="ngbCollapse" [(ngbCollapse)]="adminCollapsed">
      <ng-container
        *ngIf="
          hasPrivileges(
            'viewUser',
            'createUser',
            'facialCreation',
            'createEditOPSGroup',
            'lockedAccount',
            'resetPassword'
          )
        "
      >
        <li>
          <i class="fa fa-user-circle ml-3 fa-lg" aria-hidden="true"></i
          ><span class="ml-3">USERS</span>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.viewUser"
        >
          <a class="" [routerLink]="['view-users']" (click)="toggleMenu()">
            VIEW USERS</a
          >
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.createUser"
        >
          <a class="" [routerLink]="['create-users']" (click)="toggleMenu()"
            >CREATE USERS</a
          >
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.facialCreation"
        >
          <a class="" [routerLink]="['facial-id']" (click)="toggleMenu('/users/facial-id')"
            >FACIAL ID</a
          >
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.createEditOPSGroup"
        >
          <a class="" [routerLink]="['ops-group']" (click)="toggleMenu()"
            >OPS GROUP</a
          >
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.lockedAccount"
        >
          <a class="" [routerLink]="['lock-account']" (click)="toggleMenu()"
            >LOCKED ACCOUNTS
          </a>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.resetPassword"
        >
          <a class="" [routerLink]="['rest-password']" (click)="toggleMenu()"
            >RESET PASSWORD
          </a>
        </li>
        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a class="" [routerLink]="['manage-transport']" (click)="toggleMenu('/users/manage-transport')"
            >TRANSPORT DATA MANAGE</a
          >
        </li>
      </ng-container>
    </div>

    <!-- GAMIFICATION -->
    <li
      *ngIf="hasPrivileges('challengesWeb', 'redemptionList')"
      class="heading"
      (click)="collapse12.toggle()"
      [attr.aria-expanded]="!gamificationCollapsed"
      aria-controls="collapseExample"
    >
      GAMIFICATION
      <i
        class="fa"
        [ngClass]="
          gamificationCollapsed ? 'fa-angle-double-down' : 'fa-angle-double-up'
        "
        aria-hidden="true"
      ></i>
    </li>
    <div #collapse12="ngbCollapse" [(ngbCollapse)]="gamificationCollapsed">
      <li
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="rolesArray?.challengesWeb"
      >
        <a class="" [routerLink]="['Challenges']" (click)="toggleMenu()"
          >CHALLENGES</a
        >
      </li>

      <li
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="rolesArray?.redemptionList"
      >
        <a class="" [routerLink]="['redemption-list']" (click)="toggleMenu()">
          REDEMPTION LIST</a
        >
      </li>
    </div>

    <!-- OPERATION -->
    <li
      *ngIf="
        hasPrivileges(
          'buShiftSetup',
          'userShiftSetup',
          'schemeSetup',
          'planShift',
          'adjustShift',
          'viewShift',
          'viewBooking',
          'timesheet',
          'reports',
          'manageServiceSetup',
          'managePlanService',
          'manageService',
          'viewService'
        )
      "
      class="heading"
      (click)="collapse3.toggle()"
      [attr.aria-expanded]="!operationCollapsed"
      aria-controls="collapseExample"
    >
      OPERATION
      <i
        class="fa"
        [ngClass]="
          communicationCollapsed ? 'fa-angle-double-down' : 'fa-angle-double-up'
        "
        aria-hidden="true"
      ></i>
    </li>
    <div #collapse3="ngbCollapse" [(ngbCollapse)]="operationCollapsed">
      <ng-container
        *ngIf="hasPrivileges('buShiftSetup', 'userShiftSetup', 'schemeSetup')"
      >
        <li>
          <i class="fa fa-cogs ml-3 fa-lg" aria-hidden="true"></i>
          <span class="ml-3">SETUP</span>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.buShiftSetup"
        >
          <a [routerLink]="['bu-shift-setup']" (click)="toggleMenu()" class="">
            BU SHIFT SETUP
          </a>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.userShiftSetup"
        >
          <a
            [routerLink]="['user-shift-setup']"
            (click)="toggleMenu()"
            class=""
          >
            USER SHIFT SETUP
          </a>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.schemeSetup"
        >
          <a [routerLink]="['shift-scheme']" (click)="toggleMenu()" class="">
            SHIFT SCHEME
          </a>
        </li>
      </ng-container>

      <ng-container
        *ngIf="
          hasPrivileges(
            'planShift',
            'adjustShift',
            'viewShift',
            'viewBooking',
            'timesheet'
          )
        "
      >
        <li>
          <i class="fa fa-clock-o ml-3 fa-lg" aria-hidden="true"></i>
          <span class="ml-3">SHIFT SCHEDULER</span>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.planShift"
        >
          <a [routerLink]="['plan-shift']" (click)="toggleMenu()" class="">
            PLAN SHIFT
          </a>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.adjustShift"
        >
          <a [routerLink]="['manage-shift']" (click)="toggleMenu()" class="">
            MANAGE SHIFT
          </a>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.viewShift"
        >
          <a [routerLink]="['view-shift']" (click)="toggleMenu()" class="">
            VIEW SHIFT
          </a>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.viewBooking"
        >
          <a [routerLink]="['view-booking']" (click)="toggleMenu()" class="">
            VIEW BOOKING
          </a>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.timesheet"
        >
          <a [routerLink]="['timesheet']" (click)="toggleMenu()" class="">
            TIMESHEET
          </a>
        </li>
      </ng-container>

      <ng-container *ngIf="rolesArray?.reports">
        <li>
          <i class="fa fa-user-circle-o ml-3 fa-lg" aria-hidden="true"></i>
          <span class="ml-3">REPORT</span>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a
            [routerLink]="['list-of-shift-released']"
            (click)="toggleMenu()"
            class=""
          >
            LIST OF SHIFTS RELEASED
          </a>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a [routerLink]="['list-of-booking']" (click)="toggleMenu()" class="">
            LIST OF BOOKING
          </a>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a
            [routerLink]="['list-of-cancellation']"
            (click)="toggleMenu()"
            class=""
          >
            LIST OF CANCELLATION
          </a>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a [routerLink]="['list-of-users']" (click)="toggleMenu()" class="">
            LIST OF USERS
          </a>
        </li>
      </ng-container>

      <ng-container
        *ngIf="
          hasPrivileges(
            'manageServiceSetup',
            'managePlanService',
            'manageService',
            'viewService'
          )
        "
      >
        <li>
          <i class="fa fa-wrench ml-3 fa-lg" aria-hidden="true"></i>
          <span class="ml-3">SERVICES</span>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.manageServiceSetup"
        >
          <a [routerLink]="['service-setup']" (click)="toggleMenu()" class="">
            SERVICE SETUP
          </a>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.managePlanService"
        >
          <a [routerLink]="['plan-service']" (click)="toggleMenu()" class="">
            PLAN SERVICE
          </a>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.manageService || rolesArray?.viewService"
        >
          <a [routerLink]="['manage-service']" (click)="toggleMenu()" class="">
            MANAGE SERVICE
          </a>
        </li>
      </ng-container>
    </div>

    <!-- HUMAN RESOURCE -->
    <li
      *ngIf="
        hasPrivileges(
          'leaveSchemeLeaveType',
          'leaveSchemeLeaveGrouping',
          'createEditBallot',
          'leavePlannerDashboardView'
        )
      "
      class="heading"
      (click)="collapse7.toggle()"
      [attr.aria-expanded]="!humanResourceCollapsed"
      aria-controls="collapseExample"
    >
      HUMAN RESOURCE
      <i
        class="fa"
        [ngClass]="
          humanResourceCollapsed ? 'fa-angle-double-down' : 'fa-angle-double-up'
        "
        aria-hidden="true"
      ></i>
    </li>
    <div #collapse7="ngbCollapse" [(ngbCollapse)]="humanResourceCollapsed">
      <li
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="
          rolesArray?.leaveSchemeLeaveType ||
          rolesArray?.leaveSchemeLeaveGrouping
        "
      >
        <a class="" [routerLink]="['leave-scheme']" (click)="toggleMenu()">
          LEAVE SCHEME
        </a>
      </li>

      <li
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="rolesArray?.createEditBallot"
      >
        <a class="" [routerLink]="['leave-balloting']" (click)="toggleMenu()">
          LEAVE BALLOTING
        </a>
      </li>

      <li
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="rolesArray?.leavePlannerDashboardView"
      >
        <a class="" [routerLink]="['leave-planner']" (click)="toggleMenu()">
          LEAVE PLANNER
        </a>
      </li>
    </div>

    <!-- COMMUNICATION -->
    <li
      *ngIf="
        hasPrivileges(
          'channelSetup',
          'newsAndEvents',
          'manageNews',
          'manageEvents',
          'manageWall',
          'viewNotification',
          'inputNotification'
        )
      "
      class="heading"
      (click)="collapse5.toggle()"
      [attr.aria-expanded]="!communicationCollapsed"
      aria-controls="collapseExample"
    >
      COMMUNICATION
      <i
        class="fa"
        [ngClass]="
          communicationCollapsed ? 'fa-angle-double-down' : 'fa-angle-double-up'
        "
        aria-hidden="true"
      ></i>
    </li>
    <div #collapse5="ngbCollapse" [(ngbCollapse)]="communicationCollapsed">
      <ng-container
        *ngIf="
          hasPrivileges(
            'channelSetup',
            'newsAndEvents',
            'manageNews',
            'manageEvents'
          )
        "
      >
        <li>
          <i class="fa fa-newspaper-o ml-3 fa-lg" aria-hidden="true"></i
          ><span class="ml-3">NEWS AND EVENTS</span>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.channelSetup"
        >
          <a class="" [routerLink]="['channel-setup']" (click)="toggleMenu()">
            CHANNEL SETUP
          </a>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.newsAndEvents"
        >
          <a
            class=""
            [routerLink]="['my-news-and-events']"
            (click)="toggleMenu()"
          >
            MY NEWS AND EVENTS
          </a>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.manageNews"
        >
          <a class="" [routerLink]="['manage-news']" (click)="toggleMenu()">
            MANAGE NEWS
          </a>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.manageEvents"
        >
          <a class="" [routerLink]="['manage-events']" (click)="toggleMenu()">
            MANAGE EVENTS
          </a>
        </li>
      </ng-container>

      <ng-container *ngIf="rolesArray?.manageWall">
        <li>
          <i class="fa fa-th-large ml-3 fa-lg" aria-hidden="true"></i>
          <span class="ml-3">BOARDS</span>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.manageWall"
        >
          <a class="" [routerLink]="['board-setup']" (click)="toggleMenu()">
            BOARD SETUP
          </a>
        </li>
      </ng-container>

      <ng-container
        *ngIf="rolesArray?.viewNotification || rolesArray?.inputNotification"
      >
        <li>
          <i class="fa fa-bell ml-3 fa-lg" aria-hidden="true"></i
          ><span class="ml-3">NOTIFICATIONS</span>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.viewNotification"
        >
          <a
            class=""
            [routerLink]="['my-notifications']"
            (click)="toggleMenu()"
          >
            MY NOTIFICATIONS
          </a>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.inputNotification"
        >
          <a
            class=""
            [routerLink]="['manage-notification']"
            (click)="toggleMenu()"
          >
            MANAGE NOTIFICATIONS
          </a>
        </li>

        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="rolesArray?.inputNotification"
        >
          <a
            class=""
            [routerLink]="['custom-notification']"
            (click)="toggleMenu()"
          >
            CUSTOM NOTIFICATION
          </a>
        </li>
      </ng-container>
    </div>

    <!-- CENTRAL BUILDER -->
    <li
      *ngIf="
        hasPrivileges(
          'centralBuilder',
          'setUpForm',
          'exportManageFormOnly',
          'myForm'
        )
      "
      class="heading"
      (click)="collapse6.toggle()"
      [attr.aria-expanded]="!centralBuilderCollapsed"
    >
      CENTRAL BUILDER
      <i
        class="fa"
        [ngClass]="
          centralBuilderCollapsed
            ? 'fa-angle-double-down'
            : 'fa-angle-double-up'
        "
        aria-hidden="true"
      ></i>
    </li>
    <div #collapse6="ngbCollapse" [(ngbCollapse)]="centralBuilderCollapsed">
      <li
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="rolesArray?.centralBuilder"
      >
        <a class="" [routerLink]="['question-builder']" (click)="toggleMenu()">
          QUESTION BUILDER</a
        >
      </li>

      <li
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="rolesArray?.setUpForm || rolesArray?.formSupervisor || rolesArray?.manageForm || rolesArray?.exportManageFormOnly"
      >
        <a class="" [routerLink]="['forms']" (click)="toggleMenu()"> FORMS </a>
      </li>

      <li
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="rolesArray?.myForm"
      >
        <a class="" [routerLink]="['my-forms']" (click)="toggleMenu()">
          MY FORMS
        </a>
      </li>
    </div>
  </ul>
</aside>
