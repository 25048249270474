<div class="card"  >
  <div *ngIf="isLoader"
  style="position: fixed;top: 0;right: 0;bottom: 0;left: 0;z-index: 1050;background-color: rgba(0, 0, 0, 0.5);overflow: auto;">
  <div class="pageLoading" style="text-align: center;color: white;font-size: 700%;">
    <div class="innerDiv" style=" position: absolute; top: 50%; left: 50%;">
      <i class="fa fa-spinner fa-spin" style="float: none;"></i>
    </div>
  </div>
</div>
<div class="card-header flexi-header my-2 ps-4">
  <h3> {{formName}} </h3>
</div>
  <div class="card-header flexi-header p-4">
    <h3> FORM LOGS </h3>
    <button class="btn btn-warning pull-right" style="color: ivory" (click)="goToManage()">
      Back
    </button>
  </div>
  <div class="card-body">
    <div class="card table-card">
      <div class="card-body flexi-body">
        <div class="flex-column">
          <div class="d-flex justify-content-between mt-2 mb-3">
            <select
              class="custom-select"
              style="width: auto"
              name="pageSize"
              (change)="onLimitChangeUsers()"

              [(ngModel)]="usersPTable.limit"
            >
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="30">30</option>
              <option [ngValue]="40">40</option>
              <option [ngValue]="50">50</option>
            </select>
            <div style="width: 40%;flex-direction: row-reverse;" class="form-group form-inline mb-0">
              <input
              style="width: 70%;"
                class="form-control ml-2"
                type="search"
                name="searchTerm"
                (search)="userssearchInput.next($event)"
                (keyup)="userssearchInput.next($event)"
                placeholder="Search here"
                [(ngModel)]="usersPTable.search"
              />
            </div>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th
                    scope="col"
                    sortable="createdAt"
                    (sort)="onSortUsers($event)"
                  >
                    Date/Time Submitted
                  </th>
                  <th
                    scope="col"
                    sortable="staffId"
                    (sort)="onSortUsers($event)"
                  >
                    By Staff Id
                  </th>
                  <th
                    scope="col"
                    sortable="totalSuccess"
                    (sort)="onSortUsers($event)"
                  >
                    Submitted Count
                  </th>

                  <th
                    scope="col"
                    sortable="totalFailed"
                    (sort)="onSortUsers($event)"
                  >
                     Failed Count
                  </th>

                  <th scope="col">Error File Path</th>
                </tr>
              </thead>
              <tbody *ngIf="!isTableLoading">
                <tr *ngFor="let item of usersPTable.data">
                  <td>
                    {{ item.createdAt | date:'medium' }}
                  </td>

                  <td>{{ item.staffId }}</td>

                  <td>{{ item.totalFailed+item.totalSuccess }}</td>
                  <td>{{ item.totalFailed?item.totalFailed:'-' }}</td>


                  <td (click)='downloadLogFile(item)' *ngIf='item.totalFailed' ><span class="link-style">Download</span></td>
                  <td *ngIf='!item.totalFailed'></td>


                </tr>
              </tbody>
            </table>
            <ngx-skeleton-loader
            *ngIf="isTableLoading"
            count="6"
            [theme]="{
              width: 'calc(100% - 20px)',
              'border-radius': '6px',
              height: '44px',
              margin: '10px',
              display: 'block'
            }"
          ></ngx-skeleton-loader>
          </div>
          <!-- <app-skliton-loading-table [numberOfCols]="5" [isTableLoading]="isTableLoading"></app-skliton-loading-table> -->

        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <p>Total Records {{ usersPTable.total }}</p>
          <ngb-pagination
            [collectionSize]="usersPTable.total"
            [(page)]="usersPTable.page"
            [maxSize]="5"
            (pageChange)="onPageChangeUsers($event)"
            [pageSize]="usersPTable.limit"
          >
          </ngb-pagination>

        </div>
      </div>
    </div>
  </div>
</div>
