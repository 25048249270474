<div class="card" *ngIf="isModuleView">
  <div class="card-header flexi-header pt-4">
    <h3 style="display: inline">
      {{ selectedModule?.moduleName | uppercase }}
    </h3>
    <button class="btn btn-warning pull-right" style="color: ivory" (click)="back()">
      Back
    </button>
  </div>

  <div class="card-header">
    <div *ngIf='selectedModule.welComeAttachement' class="card-body centered-image">
      <img [src]="validateURLImage(selectedModule.welComeAttachement)" crossorigin="anonymous" width="300px"
        height="300px" class="image" id="4" alt="Image Preview" />
    </div>
    <h3 style="text-align: center">
      checked {{ selectedModule?.welComeMessage }}
    </h3>
  </div>
  <div class="card-body" style="word-break: break-word; padding: 10px; height: max-content">
    <!-- [formGroup]="answerForm" -->
    <!-- <form> -->
    <div class="box d-flex flex-column">
      <div class="card-header flexi-header pt-4">
        <h3 style="display: inline">
          <strong class="mr-1">1 </strong> &emsp;
          {{firstSection}}
        </h3>
      </div>
      <div class="box d-flex flex-column" *ngFor="let item of viewQuestions; let i = index">
        <div class="topHead d-flex">
          <strong class="mr-1">{{ i + 1 }}&nbsp;
            <span *ngIf="item.required" class="required">*</span>
          </strong>
          <div class="box-heading" style="word-break: break-word">
            &emsp;
            <p [innerHTML]="item?.question | safeHtml"></p>
          </div>
        </div>
        <div class="box-body" *ngIf="item.type === 1">
          <textarea matInput rows="5" cols="100" [(ngModel)]="item.answer"
            maxlength="{{ !!item?.maxlength ? item?.maxlength : 500 }}" [disabled]="isFormSubmitted"></textarea>
          <br />
          <div *ngIf="isFormSubmitted && item?.explanation">
            <span>Answer Explanation:</span>
            <p [innerHTML]="item?.explanation | safeHtml"></p>
          </div>
        </div>
        <div *ngIf="item.type === 2">
          <div class="form-group answers-options" [ngClass]="
                item.optionsView == 0
                  ? 'image-container-vertically'
                  : 'image-container-horizontally'
              ">
            <div class="box" *ngFor="let option of item.options">
              <div class="card-body" *ngIf="!!option.imageSrc">
                <img [src]="option?.imageSrc" crossorigin="anonymous" class="image-size" id="4" alt="Image Preview" />
              </div>
              <input type="radio" id="{{ option._id }}" name="{{ item._id }}" [value]="option" [(ngModel)]="item.answer"
                (change)="setChild(item, option)" class="card-header-flexi" [disabled]="isFormSubmitted" />
              <label for="consolidated">{{ option.value }}</label>
            </div>
          </div>
          <br />
          <div *ngIf="isFormSubmitted && item?.explanation">
            <span>Answer Explanation:</span>
            <p [innerHTML]="item?.explanation | safeHtml"></p>
          </div>
        </div>
        <div class="" *ngIf="item.type === 3">
          <div [ngClass]="
                item.optionsView == 0
                  ? 'image-container-vertically'
                  : 'image-container-horizontally'
              ">
            <div class="box" *ngFor="let option of item.options">
              <div class="card-body" *ngIf="!!option.imageSrc">
                <img [src]="option?.imageSrc" class="image-size" crossorigin="anonymous" alt="Image Preview" />
              </div>
              <input type="radio" id="{{ option._id }}" name="{{ item._id }}" [value]="option" [(ngModel)]="item.answer"
                (change)="setChild(item, option)" class="card-header-flexi" [disabled]="isFormSubmitted" />
              <label for="{{ option._id }}">{{ option.value }}</label>
            </div>
            <br />
            <div *ngIf="isFormSubmitted && item?.explanation">
              <span>Answer Explanation:</span>
              <p [innerHTML]="item?.explanation | safeHtml"></p>
            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="item.type === 4">
          <div [ngClass]="
                item.optionsView == 0
                  ? 'image-container-vertically'
                  : 'image-container-horizontally'
              " style="overflow: auto">
            <div class="question-radio-inline box" *ngFor="let option of item.options; let i = index">
              <label for="{{ option._id }}" class="card" style="border-radius: 10px">
                <img *ngIf="!!option.imageSrc" class="image-size" crossorigin="anonymous" src="{{ option.imageSrc }}"
                  alt="" style="border-radius: 10px 10px 0px 0px" (click)="item.selected = i" />
                <div class="card-container" [ngStyle]="{ width: option.imageSrc ? '' : '150px' }" style="
                      background-color: #6eb96e;
                      display: flex;
                      justify-content: space-between;
                      border-radius: 0px 0px 10px 10px;
                      padding: 5px;
                    " (click)="selectPolling(item._id, option)">
                  <h4><b>Select</b></h4>
                  <img crossorigin="anonymous" style="
                        width: 12%;
                        min-width: 30px;
                        height: 1%;
                        margin: 3px;
                      " src="{{
                        './assets/images/' +
                          (option.answer ? 'like1' : 'like0') +
                          '.png'
                      }}" alt="" height="10px" />
                </div>
              </label>
              <h4 style="text-align: center" [ngClass]="{ pollingSelect: item.selected === i }"
                (click)="item.selected = i">
                <b>{{ option.value }}</b>
              </h4>
              <span class="text-success" *ngIf="!!option.percentage">{{ option.percentage }} %</span>
            </div>
          </div>
          <br />
          <div *ngIf="isFormSubmitted && item?.explanation">
            <span>Answer Explanation:</span>
            <p [innerHTML]="item?.explanation | safeHtml"></p>
          </div>
        </div>

        <div class="" *ngIf="item.type === 5">
          <div class="form-group answers-options" [ngClass]="
                item.optionsView == 0
                  ? 'image-container-vertically'
                  : 'image-container-horizontally'
              ">
            <div class="box" *ngFor="let option of item.options">
              <div *ngIf="option.imageSrc" class="card-body">
                <img [src]="option.imageSrc" class="image-size" id="4" alt="Image Preview" />
              </div>
              <input type="checkbox" [(ngModel)]="option.answer" name="{{ option._id }}" id="{{ option._id }}"
                (change)="setChild(item, option)" [disabled]="isFormSubmitted" />
              <label for="consolidated">{{ option.value }}</label>
            </div>
          </div>
          <br />
          <div *ngIf="isFormSubmitted && item?.explanation">
            <span>Answer Explanation:</span>
            <p [innerHTML]="item?.explanation | safeHtml"></p>
          </div>
        </div>

        <div class="box-body" *ngIf="item.type === 6">
          <div>
            <div *ngIf="item.answer" class="signature-div">
              <img [src]="item.answer" class="signature-img" />
            </div>
            <button mat-button class="mat-sign-button" (click)="openDialog(item)" [disabled]="isFormSubmitted">
              Signature
            </button>
            <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="home"></mat-icon>
          </div>
          <br />
          <div *ngIf="isFormSubmitted && item?.explanation">
            <span>Answer Explanation:</span>
            <p [innerHTML]="item?.explanation | safeHtml"></p>
          </div>
        </div>

        <div class="box-body" *ngIf="item.type === 8">
          <div class="d-flex flex-wrap answers-options">
            <div class="box form-group flex-fill" style="margin: 1rem 1rem; width: 44%">
              <textarea  *ngIf="!item.isNumeric" class="form-control" rows="5" [(ngModel)]="item.answer" [ngModelOptions]="{standalone: true}"
              maxlength="{{item?.maxlength}}" [disabled]="isFormSubmitted">
              </textarea>
              <textarea class="form-control" rows="5" *ngIf="!!item.isNumeric" [(ngModel)]="item.answer" [ngModelOptions]="{standalone: true}"
                maxlength="{{item?.maxlength}}" minlength="{{item?.minlength}}" [disabled]="isFormSubmitted" appNumberOnly>
              </textarea>
            </div>
          </div>
          <br />
          <div *ngIf="isFormSubmitted && item?.explanation">
            <span>Answer Explanation:</span>
            <p [innerHTML]="item?.explanation | safeHtml"></p>
          </div>
        </div>

        <div class="box-body" *ngIf="item.type === 9">
          <div class="d-flex flex-wrap answers-options">
            <div class="box form-group flex-fill" style="margin: 1rem 1rem; width: 44%">
              <input type="number" class="form-control" [(ngModel)]="item.answer" [disabled]="isFormSubmitted" />
            </div>
          </div>
          <br />
          <div *ngIf="isFormSubmitted && item?.explanation">
            <span>Answer Explanation:</span>
            <p [innerHTML]="item?.explanation | safeHtml"></p>
          </div>
        </div>

        <div class="box-body" *ngIf="item.type === 10">
          <div *ngFor="let option of item.dateTime">
            <div *ngIf="item.dateTime?.length === 2 && option === 2" class="box">
              <div class="d-flex flex-wrap answers-options">
                <div class="form-group flex-fill" style="margin: 1rem">
                  <label for="my-input">Date</label>
                  <input id="my-input" class="form-control" type="date" [(ngModel)]="item.answer['date']"
                    [disabled]="isFormSubmitted" />
                </div>
              </div>
              <div style="margin-left: 10px" class="box">
                <label>Time</label>
                <ngb-timepicker [(ngModel)]="item.answer['time']" [meridian]="true" [hourStep]="hourStep"
                  [minuteStep]="minuteStep"></ngb-timepicker>
              </div>
              <br />
              <div *ngIf="isFormSubmitted && item?.explanation">
                <span>Answer Explanation:</span>
                <p [innerHTML]="item?.explanation | safeHtml"></p>
              </div>
            </div>
            <div class="box form-group" *ngIf="item.dateTime?.length === 1 && option === 1">
              <div class="d-flex flex-wrap answers-options" *ngIf="option === 1">
                <div class="form-group flex-fill" style="margin: 1rem">
                  <label for="my-input">Date</label>
                  <input id="my-input" class="form-control" type="date" [(ngModel)]="item.answer['date']"
                    [disabled]="isFormSubmitted" />
                </div>
              </div>
              <br />
              <div *ngIf="isFormSubmitted && item?.explanation">
                <span>Answer Explanation:</span>
                <p [innerHTML]="item?.explanation | safeHtml"></p>
              </div>
            </div>
            <div style="margin-left: 10px" class="box" *ngIf="option === 2 && item.dateTime?.length === 1">
              <div style="margin-left: 10px" class="box">
                <label>Time</label>
                <ngb-timepicker [(ngModel)]="item.answer['time']" [meridian]="true" [hourStep]="hourStep"
                  [minuteStep]="minuteStep"></ngb-timepicker>
              </div>
              <br />
              <div *ngIf="isFormSubmitted && item?.explanation">
                <span>Answer Explanation:</span>
                <p [innerHTML]="item?.explanation | safeHtml"></p>
              </div>
            </div>
          </div>
          <br />
        </div>

        <div class="box-body" *ngIf="item.type === 11">
          <div class="box form-group ng-select-img-container">
            <ng-select #mySelect class="custom" placeholder="Select Options" bindLabel="value" appendTo="body"
              [multiple]="false" [searchable]="true" [clearable]="false" [(ngModel)]="item.answer"
              [readonly]="isFormSubmitted" (change)="setChild(item, item.answer, i)">
              <ng-option [value]="choice" *ngFor="let choice of item.options">
                <img src="{{ choice.imageSrc }}" crossorigin="anonymous" class="dropdown-img" *ngIf="choice.imageSrc" />
                &nbsp;{{ choice.value }}
              </ng-option>
            </ng-select>
          </div>
          <br />
          <div *ngIf="isFormSubmitted && item?.explanation">
            <span>Answer Explanation:</span>
            <p [innerHTML]="item?.explanation | safeHtml"></p>
          </div>
        </div>

        <div class="box-body" *ngIf="item.type === 12">
          <div class="box form-group">
            <input id="my-input" class="form-control-file" type="file" (change)="fileUpload($event, item)"
              [disabled]="isFormSubmitted" />
          </div>
          Preffered formats are -
          <span *ngFor="let fileType of item?.options; let index = index">
            {{ fileType.value
            }}<span *ngIf="item.options?.length != index + 1">, </span>
          </span>
          <br />
          <div *ngIf="isFormSubmitted && item?.explanation">
            <span>Answer Explanation:</span>
            <p [innerHTML]="item?.explanation | safeHtml"></p>
          </div>
        </div>

        <div class="box-body" *ngIf="item.type === 13">
          <div class="d-flex">
            <ngb-rating [max]="item.value" [(rate)]="item.answer" [readonly]="isFormSubmitted"></ngb-rating>
          </div>
          <br />
          <div *ngIf="isFormSubmitted && item?.explanation">
            <span>Answer Explanation:</span>
            <p [innerHTML]="item?.explanation | safeHtml"></p>
          </div>
        </div>

        <div class="box-body" *ngIf="item.type === 14">
          <div class="row">
            <div class="col-md-6">
              <ng-select class="custom" bindLabel="name" (change)="multiSelect($event, item)" placeholder="Select Users"
                [multiple]="true" [searchable]="true" [(ngModel)]="user" [clearable]="true"
                [disabled]="isFormSubmitted">
                <ng-option [value]="choice._id" *ngFor="let choice of typeFourteenQuestion">
                  {{ choice.name }}
                </ng-option>
              </ng-select>
            </div>
          </div>
          <br />
          <div *ngIf="isFormSubmitted && item?.explanation">
            <span>Answer Explanation:</span>
            <p [innerHTML]="item?.explanation | safeHtml"></p>
          </div>
        </div>

        <div class="box-body" *ngIf="item.type === 15">
          <div cdkDropList (cdkDropListDropped)="drop($event, item)">
            <div *ngFor="let imagObj of item.images" cdkDrag [cdkDragDisabled]="isFormSubmitted">
              <div class="img-thumbnail">
                <div *ngIf="imagObj.src">
                  <img [src]="imagObj.src" id="4" alt="Image Preview" class="sequence-image" />
                </div>
                <p>{{ imagObj.value }}</p>
              </div>

              <br />
            </div>
          </div>
          <br />
          <div *ngIf="isFormSubmitted && item?.explanation">
            <span>Answer Explanation:</span>
            <p [innerHTML]="item?.explanation | safeHtml"></p>
          </div>
        </div>

        <div class="box-body" *ngIf="item.type === 21">
          <div class="d-flex"></div>
          <br />
          <div *ngIf="isFormSubmitted && item?.explanation">
            <span>Answer Explanation:</span>
            <p [innerHTML]="item?.explanation | safeHtml"></p>
          </div>
        </div>

        <div class="box-body" *ngIf="item.type === 22">
          <div class="d-flex"></div>
          <br />
          <div *ngIf="isFormSubmitted && item?.explanation">
            <span>Answer Explanation:</span>
            <p [innerHTML]="item?.explanation | safeHtml"></p>
          </div>
        </div>

        <div class="box-body-16" *ngIf="item.type === 16">
          <div [ngStyle]="{ position: isPopupOpen ? 'sticky' : 'relative' }">
            <div class="full-icon" (click)="openPopup(item.imageSrc)" ngbTooltip="Expand" placement="bottom">
              <i class="fa fa-expand" aria-hidden="true"></i>
            </div>
            <img #myImage [src]="item.imageSrc" width="100%" alt="Image Preview"
              (click)="onImageClick($event, viewQuestions, i)" />
            <div *ngFor="let item of typeSixteenImageField; let j = index" [style.left.%]="item?.coordinates?.x"
              [style.top.%]="item?.coordinates?.y" class="circle">
              <span class="text">{{ j + 1 }}</span>
            </div>
            -
          </div>

          <br />
          <div *ngIf="isFormSubmitted && item?.explanation">
            <span>Answer Explanation:</span>
            <p [innerHTML]="item?.explanation | safeHtml"></p>
          </div>

          <div class="row imgSrc" *ngIf="typeSixteenImageField.length > 0">
            <strong class="pb-3">Spot descriptions</strong>
            <div class="table">
              <table class="table">
                <thead>
                  <tr>
                    <th class="w-20">SNO</th>
                    <th>DESCRIPTIONS</th>
                    <th>&ensp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="
                        let field of typeSixteenImageField;
                        let index = index
                      ">
                    <td class="srNo">
                      {{ index + 1 }}
                    </td>
                    <td>
                      <input type="text" class="form-control" [(ngModel)]="field.value" [disabled]="isFormSubmitted" />
                    </td>
                    <td *ngIf="typeSixteenImageField.length >= 1" class="cancel-button">
                      <img class="image" alt="" (click)="conditionQuestionDelete(index)"
                        src="./assets/images/cancel.png" height="20px" crossorigin="anonymous" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div *ngIf="viewQuestions.length == 0" style="text-align: center">
            No questions to show
          </div>
        </div>
      </div>
    </div>
    <div *ngFor="let section of sectionList; let j = index">
      <div lass="box d-flex flex-column" *ngIf="j !== 0">
        <div class="card-header flexi-header pt-4">
          <h3 style="display: inline">
            <strong class="mr-1">{{ j + 1 }} </strong> &emsp; {{ section?.sectionName
            }}
          </h3>
        </div>
        <div class="box d-flex flex-column" *ngFor="let item of section?.questions let i = index">
          <div class="topHead d-flex">
            <strong class="mr-1">{{ i + 1 }}&nbsp;
              <span *ngIf="item.required" class="required">*</span>
            </strong>
            <div class="box-heading" style="word-break: break-word">
              &emsp;
              <p [innerHTML]="item?.question | safeHtml"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-left: 400px; margin-top: 20px">
      <h4>
        {{ selectedModule?.Message }}
      </h4>
    </div>
    <button *ngIf="!isFormSubmitted" type="submit" class="btn-submit" (click)="submit()">
      submit
    </button>
    <button *ngIf="isFormSubmitted" type="submit" class="btn-submit" (click)="closePage()">
      Close
    </button>
    <!-- </form> -->
  </div>
</div>
<ng-container *ngIf="isPopupOpen">
  <div class="popup-overlay zoom-popup-overlay">
    <div class="popup zoom-image-popup">
      <div class="full-icon" (click)="closePopup()" ngbTooltip="Exit fullscreen" placement="bottom">
        <i class="fa fa-compress" aria-hidden="true"></i>
      </div>
      <img #myImage [src]="typeSixteenImageSRC" alt="Image Preview" (click)="onImageClick($event)"
        crossorigin="anonymous" />
      <div *ngFor="let item of typeSixteenImageField; let i = index" [style.left.%]="item?.x" [style.top.%]="item?.y"
        class="circlePopup">
        <span class="text">{{ i + 1 }}</span>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #ScoreAbleModal let-c="close" let-d="dismiss">
  <div>
    <div class="modal-content">
      <div class="modal-body" style="padding: 0px">
        <div>
          <div class="card w-60">
            <div class="card-header flexi-header">
              <h3>Score Details</h3>
            </div>
            <div class="card-body">
              <div>
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="home"></mat-icon>

                <h5>
                  Thanks you...The form you submitted have scoring enabled
                </h5>
                <br />
                <h5>Your score:{{ userScore?.userScore }}</h5>
                <br />
                <h5>Maximum score:{{ userScore?.totalScore }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn pull-left" (click)="modalClose()">
          Close
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!--<div class="card" *ngIf="isModuleView">
    <div class="card-header flexi-header p-4">
      <h3 style="display: inline">
        {{ selectedModule?.moduleName | uppercase }}
      </h3>
      <button
        class="btn btn-back pull-right"
        (click)="isModuleView = false"
      >
        Back
      </button>
    </div>

    <div class="card-body" style="word-break: break-word; padding: 10px">
      <div
        class="box d-flex flex-column"
        *ngFor="let item of viewQuestions; let i = index"
      >
        <div class="d-flex">
          <strong class="mr-1">{{ i + 1 }} </strong>
          <div class="box-heading" style="word-break: break-word">
            &emsp; <p [innerHTML]="item?.question | safeHtml"></p>
          </div>
        </div>
        <div class="box-body" *ngIf="item.type === 1">
          <div class="boxx">
            <div class="box" *ngFor="let option of item.options">
              <div *ngIf="option.imageSrc">
                <div class="card-body">
                  <img [src]="option.imageSrc" width="250px" height="320px" id="4" alt="Image Preview" />
                </div>
              </div>
              <input type="radio" name="typeOne" formControlName ="typeOne"[value]="option.value" />
              <label for="consolidated">{{ option.value }}</label>
            </div>
          </div>
        </div>



        <div class="box-body" *ngIf="item.type === 2">
          <div class="d-flex flex-column answers-options">
            <div
              class="checkbox checkbox-danger checkbox-inline"
              *ngFor="let option of item.options"
            >
            <div *ngIf="option.imageSrc">
              <div class="card-body">
                <img [src]="option.imageSrc" width="250px" height="320px" id="4" alt="Image Preview" />
              </div>
            </div>
            <input type="checkbox"  [value]="option.value" (change)="onCheckChangeTypeTwo($event)"/>
            <label for="consolidated">{{ option.value }}</label>
            </div>
          </div>
        </div>
        <div class="box-body" *ngIf="item.type === 3">
          <div class="boxx">
            <div class="box" *ngFor="let item of item.options">
              <div *ngIf="item.imageSrc">
                <div class="card-body">
                  <img [src]="item.imageSrc" width="250px" height="320px"  id="4" alt="Image Preview" />
                </div>
              </div>
              <input type="radio"  name="typeThree" formControlName ="typeThree"[value]="item.value" />
              <label for="consolidated"> {{ item.value }}</label>
            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="item.type === 4">
          <div class=" form-group d-flex flex-column answers-options">
            <div class="parent">
            <div class="flex-child" *ngFor="let item of item.options">
                  <div class="box"  *ngIf="item.imageSrc">
                    <img [src]="item.imageSrc" width="200px" height="250px"  id="4" alt="Image Preview" />
                  </div>
                <input type="radio"  name="typeFour" formControlName ="typeFour"[value]="item.value" />
                  <label for="consolidated"> {{ item.value }}</label>
              </div>
            </div>
            </div>
          </div>

        <div class="box-body" *ngIf="item.type === 5">
          <div class=" form-group d-flex flex-column answers-options">
            <div class="box" *ngFor="let option of item.options">
              <div *ngIf="option.imageSrc">
                <div class="card-body">
                  <img [src]="option.imageSrc" width="250px" height="320px" id="4" alt="Image Preview" />
                </div>
              </div>
              <input type="checkbox"  [value]="option.value" (change)="onCheckChangeTypeFive($event)"/>
                <label for="consolidated">{{ option.value }}</label>
              </div>
            </div>
          </div>

        <div class="box-body" *ngIf="item.type === 6">
          <div class="d-flex flex-wrap answers-options">
            <div
              class="form-group flex-fill"
              style="margin: 1rem 1rem; width: 44%"
              *ngFor="let item of item.profile"
            >
              <label for="my-input">{{ item.questionName }}</label>
              <input id="my-input" class="form-control" disabled type="text" />
            </div>
          </div>
        </div>
        <div class="box-body" *ngIf="item.type === 7">
          <div class="d-flex flex-wrap answers-options">
            <div
              class="form-group flex-fill"
              style="margin: 1rem 1rem; width: 44%"
            >
              <textarea disabled class="form-control" rows="3"></textarea>
            </div>
          </div>
        </div>
        <div class="box-body" *ngIf="item.type === 8">
          <div class="d-flex flex-wrap answers-options">
            <div
              class="form-group flex-fill"
              style="margin: 1rem 1rem; width: 44%"
            >
              <textarea disabled class="form-control" rows="5"></textarea>
            </div>
          </div>
        </div>
        <div class="box-body" *ngIf="item.type === 9">
          <div class="d-flex flex-wrap answers-options">
            <div
              class="form-group flex-fill"
              style="margin: 1rem 1rem; width: 44%"
            >
              <input type="number" disabled class="form-control" />
            </div>
          </div>
        </div>
        <div class="box-body" *ngIf="item.type === 10">
          <div class="form-group">
            <div class="d-flex flex-wrap answers-options">
              <div class="form-group flex-fill" style="margin: 1rem">
                <label for="my-input">Date</label>
                <input id="my-input" class="form-control" type="date" disabled />
              </div>
              <div class="form-group flex-fill" style="margin: 1rem">
                <label for="my-input">Hours</label>
                <select name="" id="" class="form-control" disabled></select>
              </div>
              <div class="form-group flex-fill" style="margin: 1rem">
                <label for="my-input">Miniutes</label>
                <select name="" id="" class="form-control" disabled></select>
              </div>
              <div class="form-group flex-fill" style="margin: 1rem">
                <label for="my-input">Seconds</label>
                <select name="" id="" class="form-control" disabled></select>
              </div>
            </div>

          </div>
        </div>
        <div class="box-body" *ngIf="item.type === 11">
          <select class="form-control" [attr.disabled]="true"></select>
        </div>
        <div class="box-body" *ngIf="item.type === 12">
          <div class="form-group">
            <input id="my-input" disabled class="form-control-file" type="file" />
          </div>
          Preffered formats are -
          <span *ngFor="let item of item?.options">
            {{ item.value }}<span *ngIf="item.length != 0"> </span>
          </span>
        </div>
        <div class="box-body" *ngIf="item.type === 13">
          <div class="d-flex">
            <ngb-rating [max]="item.value" [rate]="0"></ngb-rating>

          </div>
        </div>
        <div class="box-body" *ngIf="item.type === 14">
          <select class="form-control" [attr.disabled]="true"></select>
        </div>
        <div class="box-body" *ngIf="item.type === 15">


          <div class="row" ng-model="item.options">
            <div *ngFor="let option of item.options">
              <div class="thumbnail">
                <img
                  ng-if="option.imageSrc"
                  [src]="option.imageSrc"
                  style="height: 150px"
                />
                <div class="caption">
                  <p>{{ option.value }}</p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div
          *ngIf="viewQuestions.length == 0"
          style="text-align: center"
        >
          No questions to show
        </div>
      </div>
    </div>
  </div>-->
