import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'stats-display',
  templateUrl: './stats-display.html',
  styleUrls: ['./stats-display.scss'],
})
export class StatsDisplayComponent implements OnInit {
  @Input() totalSubmissions: number = 0;
  @Input() errorCount: number = 0;
  @Input() statuses: { _id: string, count: number }[] = [];
  @Input() workflow = [];

  private toastService: ToastrService

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalSubmissions) {
      this.totalSubmissions = changes.totalSubmissions.currentValue;
    }
    if (changes.errorCount) {
      this.errorCount = changes.errorCount.currentValue;
    }

    if (changes.statuses) {
      this.statuses = changes.statuses.currentValue;
    }
    if(changes.workflow){
      this.workflow =  changes.workflow.currentValue;
    }
  }

  getWorkflowStatusCount(fieldName) {
    return this.statuses?.find(item => item._id == fieldName)?.count || 0;
  }
}
