import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { SKELETON_LOADER_THEME } from 'src/app/constants';
import { getGmtOffSet } from 'src/app/core/utils/helper';
import { SetupService } from 'src/app/users/service/operations/setup.service';

@Component({
  selector: 'app-plan-service-logs',
  templateUrl: './plan-service-logs.component.html',
  styleUrls: ['./plan-service-logs.component.scss'],
})
export class PlanServiceLogsComponent implements OnInit {
  @Input() serviceType: any;
  gmtOffset: string;
  logsLoader = false;
  logsData: any[] = [];
  logDetail: any;
  serviceDetail: any;
  isOverview = true;
  showServiceDetails: boolean = false;
  currentWeekStart: moment.Moment;
  currentWeekEnd: moment.Moment;
  formattedWeekRange: string;
  skeletonLoaderTheme = SKELETON_LOADER_THEME;
  selectedService: any;

  constructor(
    private setupService: SetupService,
    private toastrService: ToastrService
  ) {
    this.initializeWeekRange();
  }

  ngOnInit(): void {
    this.gmtOffset = getGmtOffSet();
    this.getLogs();
  }

  private initializeWeekRange() {
    this.currentWeekStart = moment().utc().startOf('isoWeek');
    this.currentWeekEnd = moment().utc().endOf('isoWeek');
    this.updateFormattedWeekRange();
  }

  private updateFormattedWeekRange() {
    this.formattedWeekRange = `${this.currentWeekStart.format(
      'DD'
    )} to ${this.currentWeekEnd.format('DD MMM YYYY')}`;
  }

  private getLogs() {
    this.logsLoader = true;
    const payload = {
      weekRangeStartAt: this.currentWeekStart.toISOString(),
      weekRangeEndAt: this.currentWeekEnd.toISOString(),
      status: [0],
    };

    this.setupService
      .getServiceLogs(payload)
      .pipe(
        finalize(() => {
          this.logsLoader = false;
        })
      )
      .subscribe(
        (response: any) => {
          this.logsData = response.data?.data || [];
        },
        (error) => {
          this.toastrService.error(
            error?.error?.error?.message || 'Failed to load service logs'
          );
        }
      );
  }

  changeOverview(value: boolean, item: any) {
    this.logDetail = item;
    const service = this.logDetail?.services?.[0];
    const dayDetails = service?.days?.[0] || {};

    this.serviceDetail = {
      ...service,
      day: dayDetails?.day || '',
    };

    this.selectedService = dayDetails;
    this.showServiceDetails = !value;
    this.isOverview = value;
  }

  changeWeek(direction: 'Prev' | 'Nxt') {
    const increment = direction === 'Prev' ? -1 : 1;
    this.currentWeekStart.add(increment, 'week');
    this.currentWeekEnd.add(increment, 'week');
    this.updateFormattedWeekRange();
    this.getLogs();
  }

  changeShiftDetailsView(value: boolean, item: any, day: any) {
    this.serviceDetail = {
      ...item,
      day: day?.day ?? '',
    };
    this.showServiceDetails = value;
  }
}
