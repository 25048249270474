import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild ,Output ,EventEmitter} from '@angular/core';
import { QuestionModalComponent } from './question-modal/question-modal.component';
import { CentralBuilderService } from 'src/app/users/service/central-builder/central-builder.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-question-builder',
  templateUrl: './question-builder.component.html',
  styleUrls: ['./question-builder.component.scss']
})
export class QuestionBuilderComponent implements OnInit, AfterContentChecked {
  activeId = 1
  isEdited = false
  moduleID: string
  @ViewChild('createModule', { static: true })
  createModule: TemplateRef<any>;
  createModuleForm: FormGroup
  @Output() editModuleEvent = new EventEmitter<string>();
  constructor(private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private ref: ChangeDetectorRef,
    private centralBuilderService: CentralBuilderService,
    private toastService: ToastrService
  ) { }
  ngOnInit(): void {
    this.createModuleFormInit()
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  overViewEvent(value) {
    this.moduleID = value
    this.isEdited = false
    this.activeId = 1
  }

  EditModule(value) {
    this.moduleID = value
    this.isEdited = true
    this.activeId = 3
  }

  createModuleFormInit() {
    this.createModuleForm = this.formBuilder.group({
      moduleName: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9_]+.*$')]],
      isDefault: [true, Validators.required],
      // status: 0,
      // questions:  [[], []],
      // viewCount: 0,
    })
  }

  createModulePopup() {
    this.modalService.open(this.createModule);
  }

  // enableDefaultSection(val){
  //   console.log(val);
  //   this.createModuleForm.patchValue({
  //     isDefaultSection: val,
  //   });
  // }

  onCreateModule() {
    if (!this.createModuleForm.valid) {
      return
    }

    this.centralBuilderService.createModules(this.createModuleForm.value).subscribe((res) => {
        this.isEdited = true;
        this.createModuleFormInit();
        this.modalService.dismissAll();
        this.activeId = 3;
        this.moduleID = res.message.moduleId._id;
       this.onEditModule(this.moduleID);       
    },
    (error) => {
      this.toastService.error(error?.error?.message);
    }
    )
  }
  
  onEditModule(id: string) {
    this.editModuleEvent.emit(id)
  }
  closePopup() {
    this.modalService.dismissAll()
    this.activeId = 1
  }
}
