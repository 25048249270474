export const environment = {
  production: true,
  baseUrl: 'https://api.net-roc.com',
  imageUrl: 'https://api.net-roc.com',
  // webBaseUrl: 'https://api.net-roc.com',
  // baseUrl: 'https://admin.net-roc.com',
  webBaseUrl: 'https://www.net-roc.com',
  formServiceBaseUrl: 'https://stage-api-form.net-roc.com',
  serviceModuleBaseUrl: 'https://stage-api-service.net-roc.com',
  salt: '488EC1D327BE388E7126D67D3F22D',
  froalaEditorKey:'gVG3C-8D2D2A4D5C4E4A1vd1EAJLQCVLUVBa1NXNRSSATEXD-13C3B2E2E2E3C1A2C7E2A2=='
};