import * as moment from 'moment';
export const LOADING_MESSAGE = 'Please wait, Data is Processing ...';
export const EMAIL_PATTERN =
  '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';

export const DEFAULT_SERVICE_VIEW_WEEKS = 10;
export const DEFAULT_MIN_LIST = [0, 15, 30, 45];
export const DEFAULT_HOUR_LIST = Array.from({ length: 24 }, (_, i) => i + 1);
export const VIEW_WEEKS = Array.from({ length: 10 }, (_, i) => i + 1);

export const TIME_SLOTS = Array.from({ length: 96 }, (_, i) =>
  moment({ hour: Math.floor(i / 4), minute: (i % 4) * 15 }).format('HH:mm')
);

export const DAYS_OF_WEEK = [
  { index: 0, name: 'M' },
  { index: 1, name: 'T' },
  { index: 2, name: 'W' },
  { index: 3, name: 'T' },
  { index: 4, name: 'F' },
  { index: 5, name: 'S' },
  { index: 6, name: 'S' },
];

export const SKELETON_LOADER_THEME = {
  width: 'calc(100% - 20px)',
  'border-radius': '6px',
  height: '44px',
  margin: '10px',
  display: 'block',
};
