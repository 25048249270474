<div class="d-flex flex-column">
  <ul ngbNav [(activeId)]="activeId" #companyNav="ngbNav" class="nav-tabs rounded mb-3">
    <li [ngbNavItem]="1">
      <a ngbNavLink>OVERVIEW</a>
      <ng-template ngbNavContent>
        <my-forms-overview (takeQuestion)="question($event)" (customFormID)="customForm($event)" (formType)="formType($event)" (formTitle)="title($event)"></my-forms-overview>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a *ngIf="viewStatus === true" ngbNavLink>VIEW</a>
      <ng-template ngbNavContent>
        <div *ngIf="formName ===  'viewQuestion'">
          <app-view [customFormId]="customFormId"  (viewQuestion)="overview($event)" [formTitle]="formTitle"></app-view>
        </div>
        <div *ngIf="formName ===  'takeQuestion'">
        <app-my-forms-view [questionId]="questionId" [customFormId]="customFormId" (takeQuestion)="overview($event)" (viewPage)="viewPage($event)" (formTitleviewPage)="formTitleviewPage($event)" ></app-my-forms-view>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>



