import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-plan-service',
  templateUrl: './plan-service.component.html',
  styleUrls: ['./plan-service.component.scss'],
})
export class PlanServiceComponent implements OnInit, OnDestroy {
  activeId = 1;
  serviceTypeList: any[] = [];
  serviceType: any = null;
  isView = false;
  serviceTypeLoader = false;
  private routeSub: Subscription;
  pagination = {
    page: 1,
    limit: 10,
    sort: '{"createdAt": -1}',
  };

  @ViewChild('sidemenu') sidemenu!: ElementRef;

  constructor(
    private setupService: SetupService,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private toastrService: ToastrService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
  }

  ngOnInit(): void {
    this.route.data.subscribe((data: any) => {
      this.isView = data.isView;
    });
    this.getServiceType();
  }

  ngAfterViewInit() {
    if (!this.isView) {
      setTimeout(() => {
        this.renderer.addClass(this.sidemenu.nativeElement, 'animate');
      }, 50);
    }
  }

  getServiceType(): void {
    this.serviceTypeLoader = true;
    const subscribe = this.setupService
      .getServiceType(this.pagination)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
          this.serviceTypeLoader = false;
        })
      )
      .subscribe(
        (response: any) => {
          const { data, totalRecords } = response.data;
          this.serviceTypeList = [...this.serviceTypeList, ...data];
        },
        (error) => {
          const errorMessage =
            error?.error?.error?.message || 'Failed to load service types';
          this.toastrService.error(errorMessage);
        }
      );
  }

  loadMoreServiceTypes(): void {
    this.pagination.page++;
    this.getServiceType();
  }

  viewClicked(serviceType: any): void {
    if (serviceType) {
      this.serviceType = serviceType;
      this.isView = true;
    } else {
      this.toastrService.warning('Please select a Service Type', 'Warning');
    }
  }

  cancelSelection(): void {
    this.serviceType = null;
  }

  ngOnDestroy(): void {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }
}
