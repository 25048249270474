import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import { formatGMTOffset, getGmtOffSet } from 'src/app/core/utils/helper';
import { DAYS_OF_WEEK } from 'src/app/constants';

@Component({
  selector: 'app-manage-service-tab',
  templateUrl: './manage-service-tab.component.html',
  styleUrls: ['./manage-service-tab.component.scss'],
})
export class ManageServiceTabComponent implements OnInit {
  @Output() ChangeTabId = new EventEmitter<number>();
  @Output() daySelected = new EventEmitter<any>();

  @Input() serviceRequest: any;
  @Input() selectedServiceName: any;
  @Input() serviceTypeInfo: any;
  @Input() isLoading: boolean = false;
  @Input() refreshNum: any;

  daysOfWeek = DAYS_OF_WEEK;

  weekDates = [];
  selectedDay: any;
  allServices: any;
  dayServices: any;
  serviceDetailsForm: FormGroup;
  selectedService: any;
  isCreatingForm: boolean;
  hasManageAccess: boolean = false;
  displayConfirmation: boolean = false;
  confirmationBoxType: string = '';
  confirmationMessage: string = '';
  wantToCancelBooking: any = null;
  adjustServicebtn: boolean = false;

  gmtOffset: string;

  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private setupService: SetupService
  ) {}

  ngOnInit(): void {
    this.gmtOffset = formatGMTOffset(getGmtOffSet());
    this.hasManageAccess =
      JSON.parse(localStorage.getItem('privilegeFlags') || '{}')
        ?.manageService || false;
    this.selectedDay = this.daysOfWeek[0];
    this.fetchAllServices();
    this.initServiceDetailsForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.refreshNum && !changes.refreshNum.firstChange) {
      this.handleRefresh();
    }
  }

  handleRefresh(): void {
    this.weekDates = [];
    this.allServices = [];
    this.dayServices = null;
    this.serviceDetailsForm.reset();
    this.fetchAllServices();
  }

  // Initialize service details form
  initServiceDetailsForm() {
    this.serviceDetailsForm = this.fb.group({
      serviceName: [''],
      date: [''],
      startTime: [''],
      endTime: [''],
      createdBy: [''],
      confirmSlot: [0],
      standBySlot: [0],
      bookedCount: [0],
      bookedUsers: [''],
      adjust: [0],
    });
  }

  // Fetch all services
  fetchAllServices() {
    this.isLoading = true;
    this.serviceRequest.timezone = this.gmtOffset;
    const subscription = this.setupService
      .getAllServices(this.serviceRequest)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          subscription.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.allServices = response.data;
          this.createWeekDates();
          this.onDaySelect(this.selectedDay);
        },
        (error) => {
          const errorMessage =
            error?.error?.message || 'Failed to load services';
          this.toastrService.error(errorMessage);
        }
      );
  }

  // Create week dates
  createWeekDates() {
    const baseDate = moment(this.serviceRequest.weekRangeStartAt);
    for (let i = 0; i < 7; i++) {
      this.weekDates.push(baseDate.format('YYYY-MM-DD'));
      baseDate.add(1, 'days');
    }
  }

  // Handle day selection
  onDaySelect(day) {
    this.daySelected.emit(day);
    this.selectedDay = day;
    this.updateDayServices();
  }

  // Update day services based on selected day
  updateDayServices() {
    const selectedDate = this.weekDates[this.selectedDay.index];
    this.dayServices = this.allServices.list.hasOwnProperty(selectedDate)
      ? this.allServices.list[selectedDate]
      : [];
    if (this.dayServices.length > 0) {
      this.onServiceSelect(this.dayServices[0]);
    } else {
      this.serviceDetailsForm.reset();
    }
  }

  // Handle service selection
  onServiceSelect(service) {
    this.selectedService = service;
    const serviceStartMoment = moment(service.serviceStartDateTime);
    this.serviceDetailsForm.patchValue({
      serviceName: this.selectedServiceName.serviceName,
      date: serviceStartMoment.format('DD MMM YYYY'),
      startTime: serviceStartMoment.format('HH:mm'),
      endTime: service.serviceEndDateTime
        ? moment(service.serviceEndDateTime).format('HH:mm')
        : '',
      createdBy: `${service.createdBy.name} (${service.createdBy.staffId})`,
      confirmSlot: service.confirmSlot,
      standBySlot: service.standBySlot,
      bookedCount: this.activeBookedUsersCount(service.bookedUsers),
      bookedUsers: service.bookedUsers,
      adjust: null,
    });
    this.adjustServicebtn = false;
  }

  private activeBookedUsersCount(bookedUsers: any): number {
    return bookedUsers.filter(
      (user: any) => user.status !== 3 && user.status !== 4
    ).length;
  }

  openConfirmationDialog(message: string, type: string, item?: any): void {
    this.confirmationBoxType = type;
    this.confirmationMessage = message;
    if (type === 'BOOKING')
      this.wantToCancelBooking = {
        userId: item.userId,
        serviceId: this.selectedService._id,
      };
    this.displayConfirmation = true;
  }

  cancelRequest(): void {
    if (this.confirmationBoxType === 'BOOKING' && this.wantToCancelBooking) {
      this.cancelServiceBooking();
    } else if (
      this.confirmationBoxType === 'SERVICE' &&
      this.selectedService._id
    ) {
      this.cancelService();
    }
  }

  private cancelServiceBooking(): void {
    this.setupService
      .cancelServiceBooking(this.wantToCancelBooking)
      .pipe(finalize(() => (this.displayConfirmation = false)))
      .subscribe(
        (response: any) => {
          this.ChangeTabId.emit(2);
          this.toastrService.success(
            response?.message || 'Booking Canceled Successfully'
          );
        },
        (error) => {
          this.toastrService.error(
            error?.error?.message || 'Failed to cancel this service booking'
          );
        }
      );
  }

  private cancelService(): void {
    this.setupService
      .cancelService(this.selectedService._id)
      .pipe(finalize(() => (this.displayConfirmation = false)))
      .subscribe(
        (response: any) => {
          this.ChangeTabId.emit(2);
          this.toastrService.success(
            response?.message || 'Service Canceled Successfully'
          );
        },
        (error) => {
          this.toastrService.error(
            error?.error?.message || 'Failed to cancel this service'
          );
        }
      );
  }

  adjustService(serviceConfirmedCount: number) {
    this.setupService
      .adjustService({
        serviceId: this.selectedService._id,
        serviceConfirmedCount,
      })
      .pipe(finalize(() => (this.displayConfirmation = false)))
      .subscribe(
        (response: any) => {
          this.ChangeTabId.emit(2);
          const successMessage =
            response?.message || 'Service successfully adjusted.';
          this.toastrService.success(successMessage, 'Success');
        },
        (error) => {
          const errorMessage =
            error?.error?.message ||
            'An error occurred while adjusting the service. Please try again later.';
          this.toastrService.error(errorMessage, 'Adjustment Failed');
        }
      );
  }

  isServiceStarted(serviceStartTime: Date): boolean {
    const currentTime = new Date();
    return currentTime >= new Date(serviceStartTime);
  }
}
