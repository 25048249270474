import { HttpErrorResponse } from '@angular/common/http';
import { Input } from '@angular/core';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { CompanyService } from 'src/app/users/service/companyservice/company.service';
import {
  COMMON_MESSAGES,
  generateImageUrl,
  getAssignedUsers,
} from 'src/app/core/utils/helper';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import { CentralBuilderService } from 'src/app/users/service/central-builder/central-builder.service';
import { CustomFormService } from 'src/app/users/service/custom-form/custom-form.service';
import { environment } from 'src/environments/environment';
import { MatStepper } from '@angular/material/stepper';
import { NotificationService } from 'src/app/users/service/notification/notification.service';
@Component({
  selector: 'app-create-edit-forms',
  templateUrl: './create-edit-forms.component.html',
  styleUrls: ['./create-edit-forms.component.scss'],
})
export class CreateEditFormsComponent implements OnInit {
  step = 1;
  createForm: FormGroup;
  userDetailsForm: FormGroup;
  workFlowForm: FormGroup;
  workflowstatusForm: FormGroup;
  planBuUnitDropDownLoading: boolean = false;
  apppoinmentDropDownLoading: boolean = false;
  CustomFieldDropDownLoading: boolean = false;
  buDropDownToClear = null;
  questionDropDownToClear = null;
  apoinemntDropDownToClear = null;
  customFeildDropDownToClear = null;
  questionsDropDownToClear = null;
  answersDropDownToClear = null;
  isNextBtnActive: boolean = false;
  workFlowAdminDropDownToClear = null;
  WorkFlowAddFieldDropDownToClear = null;
  authorDropDownToClear = null;
  userDetailsEditIndex: number;
  isUserDetailsEdited: boolean = false;
  workflowEditIndex: number;
  isWorkflowEdited: boolean = false;
  isformEdited: boolean = false;
  scores;
  userDetails: any[] = [];
  workFlow: any[] = [];
  userDropDown: any[] = [];
  workFlowDropDown: any[] = [];
  isLoadingSubmit: boolean = false;
  isLoadingUpdate: boolean = false;
  workFlowQuestion: any;
  workFlowAnswer: any;
  workflowResponse: any[] = [];
  addedCustomfield: any;
  isDefaultWorkflow: boolean = false;

  planBuUnitDropDown: any[];
  appoinmentDropDown: any[];
  fieldNeedsToUpdate: any[];
  questionaarie = [];
  refreshModules = [];
  moduleTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
    total: number;
    isLoading: boolean;
  };

  questions = [];
  answers = [];
  @Input() formId: string = null;
  @Input() editFormData: any = null;
  @Output() overViewEvent = new EventEmitter<string>();
  userPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
  };
  authorLoading: boolean;
  bussinesUnitplanPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    limit: number;
    search: string;
    filter: { type: string };
  };
  appoinmentPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    total: number;
    limit: number;
    search: string;
  };
  CustomFieldPTable: {
    sortWith: string;
    sortBy: string;
    data: any[];
    page: number;
    total: number;
    limit: number;
    search: string;
  };
  UpdateduserDetails: any;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourFormGroup: FormGroup;
  fiveFormGroup: FormGroup;
  questionare: string;
  loggedInUserId: string = '';
  baseUrl = environment.imageUrl;
  servicesList = [{ serviceTypeName: 'Normal', _id: '1' }];
  servicesSubTypeList = [];
  formType = null;
  formTypeId = null;
  formSubTypeId = null;

  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private toastService: ToastrService,
    private setupService: SetupService,
    private customFormService: CustomFormService,
    private centralBuilderService: CentralBuilderService,
    private notificationService: NotificationService,
    private tostService: ToastrService
  ) {
    this.initForm();
    this.inituserDetailsFrom();
    this.initWorkFlowFrom();
    this.initWorkFlowStatusForm();
    this.getServicesDetails();
    this.moduleTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,
      limit: 10,
      search: '',
      total: 0,
      isLoading: true,
    };

    this.userPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,

      limit: 10,

      search: '',
    };
    this.bussinesUnitplanPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,

      limit: 8,

      search: '',
      filter: {
        type: 'plan',
      },
    };
    this.appoinmentPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,

      search: '',
    };
    this.CustomFieldPTable = {
      sortWith: 'createdBy',
      sortBy: 'desc',
      data: [],
      page: 1,
      total: 0,
      limit: 10,

      search: '',
    };
  }

  ngOnInit(): void {
    this.loggedInUserId = localStorage.getItem('loggedInUserId');
    this.firstFormGroup = this.formBuilder.group({
      firstCtrl: ['', Validators.required],
    });
    this.secondFormGroup = this.formBuilder.group({
      secondCtrl: ['', Validators.required],
    });
    this.thirdFormGroup = this.formBuilder.group({
      secondCtrl: ['', Validators.required],
    });
    this.fourFormGroup = this.formBuilder.group({
      firstCtrl: ['', Validators.required],
    });
    this.fiveFormGroup = this.formBuilder.group({
      secondCtrl: ['', Validators.required],
    });

    this.loadModules();
    this.getAdminList();
    // this.formId && this.getFormForEdit(this.formId);
  }
  ngOnChanges() {
    if (this.editFormData) {
      this.createForm.get('isDeployed').setValue(this.editFormData.isDeployed);
      this.patchFormData(this.editFormData);
      this.formTypeId = this.editFormData.formTypeId || '1';
      this.formSubTypeId = this.editFormData.formSubTypeId;
    }
  }
  getUserBasedOnBuUnits(data) {
    // this.userDropDown = data;
  }

  getAdminList() {
    this.userDropDown = [];
    const subscribe = this.setupService
      .getAdminWithfilter()
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          // const { count, data } = response.data;
          this.userDropDown = response.data || [];
          if (this.userDropDown && this.userDropDown.length > 0) {
            const Control = this.workFlowAdminControl;

            const defaultSelectedData: any = this.userDropDown?.find(
              (x) => x._id == this.loggedInUserId
            );
            let dataIndex = Control.value.findIndex(
              (item) => item._id == defaultSelectedData._id
            );
            if (dataIndex <= -1 && defaultSelectedData) {
              let field = this.formBuilder.group({
                name: [
                  defaultSelectedData?.name,
                  Validators.compose([Validators.required]),
                ],
                _id: [
                  defaultSelectedData?._id,
                  Validators.compose([Validators.required]),
                ],
              });
              Control.push(field);
            }
          }
        },
        (error) => {
          this.userDropDown = [];
        }
      );
  }
  async patchFormData(data: any) {
    this.userDetails = data.assignUsers ? data.assignUsers : [];

    this.UpdateduserDetails = data.assignUsers
      ? await getAssignedUsers(data.assignUsers)
      : [];

    this.workFlow = data.workflow || [];
    this.createForm.patchValue({
      title: data.title,
      isDeployed: data.isDeployed,
      formLogo: data.formLogo,
      description: data.description,
      viewOnly: data.viewOnly,
      userStatusVisibility: false,
      quickNavEnabled: data.quickNavEnabled,
      _id: data._id,
      moduleId: {
        string: data.moduleId.moduleName,
        _id: data.moduleId._id,
      },
      status: data.status,
      formStatus: data.formStatus,
      formDisplayType: data.formDisplayType,
      timeZone: -330,
      isMultipleSubmit: data.isMultipleSubmit,
      isCreateIndent: data.isCreateIndent,
      formTypeId: data.formTypeId || '1',
      formSubTypeId: data.formSubTypeId,
    });
  }
  getFormForEdit(id: string) {
    this.isformEdited = true;

    const sub = this.notificationService
      .getModuleQuestions(id)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        async ({ data }: any) => {
          this.userDetails = data.assignUsers ? data.assignUsers : [];
          this.UpdateduserDetails = data.assignUsers
            ? await getAssignedUsers(data.assignUsers)
            : [];

          this.workFlow = data.workflow;
          this.createForm.patchValue({
            title: data.title,
            isDeployed: data.isDeployed,
            formLogo: data.formLogo,
            description: data.description,
            viewOnly: data.viewOnly,
            userStatusVisibility: false,
            quickNavEnabled: data.quickNavEnabled,
            moduleId: {
              string: data.moduleId.moduleName,
              _id: data.moduleId._id,
            },
            formStatus: [],
            formDisplayType: data.formDisplayType,
            timeZone: -330,
            isMultipleSubmit: data.isMultipleSubmit,
      isCreateIndent: data.isCreateIndent,
      formTypeId: data.formTypeId || '1',
      formSubTypeId: data.formSubTypeId,
          });
        },
        (error) => {
          this.toastService.error('No list found');
        }
      );
  }

  goForward(stepper: MatStepper) {
    if (!this.createForm.valid) {
      this.toastService.error('Please Fill all the form fields');
    } else {
      stepper.next();
      this.getQuestions(this.createForm.get('moduleId._id').value);
    }
  }

  chooseFormType(value) {
    this.createForm.patchValue({
      isDeployed: value,
    });
  }
  initForm() {
    this.createForm = this.formBuilder.group({
      title: ['', Validators.required],
      isDeployed: [null, Validators.required],
      formLogo: ['', Validators.required],
      description: ['', Validators.required],
      viewOnly: false,
      userStatusVisibility: false,
      quickNavEnabled: false,
      moduleId: this.formBuilder.group({
        string: ['', Validators.compose([Validators.required])],
        _id: ['', Validators.compose([Validators.required])],
      }),
      status: 1,
      formStatus: this.formBuilder.array([]),
      formDisplayType: null,
      timeZone: -330,
      _id: [null],
      isMultipleSubmit: false,
      isCreateIndent: false,
      formTypeId: '',
      formSubTypeId: '',

    });
  }

  getServicesDetails() {
    const sub = this.customFormService
      .getServicesList()
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        (data: any) => {
          this.servicesList = [...this.servicesList, ...data.data];
          if(this.editFormData){
          this.addServiceType(this.editFormData.formTypeId || '1');
          }
        },
        (error: HttpErrorResponse) => {
          this.isLoadingSubmit = false;
          this.toastService.error(error?.message);
        }
      );
  }

  addServiceType($event) {
    if ($event === '1') {
      this.servicesSubTypeList = [];
      this.formSubTypeId = null;
      this.formTypeId = '1';
    } else {
      const result: any = this.servicesList.filter(
        (services) => services._id === $event
      );
      this.servicesSubTypeList = result[0].serviceName;
    }
  }

  inituserDetailsFrom() {
    return (this.userDetailsForm = this.formBuilder.group({
      businessUnits: this.formBuilder.array([], [Validators.required]),
      buFilterType: [1, Validators.required],
      appointments: this.formBuilder.array([]),
      subSkillSets: this.formBuilder.array([]),
      user: this.formBuilder.array([]),
      allBuToken: '',
      allBuTokenStaffId: '',
      customField: this.formBuilder.array([]),
    }));
  }
  initWorkFlowFrom() {
    return (this.workFlowForm = this.formBuilder.group({
      title: ['', Validators.required],
      type: [1, Validators.required],
      status: 1,
      additionalModuleId: this.formBuilder.group({
        string: null,
        _id: null,
      }),
      question: null,
      workflow: null,
      answer: null,
      workFlowAnswer: null,
      questionResponse: this.formBuilder.array([]),
      workflowResponse: this.formBuilder.array([]),
      userStatusVisibility: [false, Validators.required],
      workflowStatus: this.formBuilder.array([]),
      admin: this.formBuilder.array([]),
      tempId: null,
      _id: null,
    }));
  }

  initWorkFlowStatusForm() {
    this.workflowstatusForm = this.formBuilder.group({
      field: ['', Validators.required],
      tempId: [Date.now(), Validators.required],
      isDefault: [false, Validators.required],
      color: ['', Validators.required],
    });
  }
  ondeleteImage() {
    this.createForm.patchValue({
      formLogo: '',
    });
  }

  showCreateButton() {
    this.isNextBtnActive = true;
  }

  hideCreateButton() {
    this.isNextBtnActive = false;
  }

  loadModules() {
    const { data, total, isLoading, ...moduleTbale } = this.moduleTable;
    const subscribe = this.centralBuilderService
      .getAllNonLinedModules()
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          this.questionaarie = response.data?.moduleList || [];
          this.refreshModules = response.data?.moduleList || [];
        },
        (error) => {
          this.toastService.error('No list found');
        }
      );
  }
  iconUpload(event) {
    if (event.target.files && event.target.files.length) {
      let file: File = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const fileUpload = this.companyService
          .uploadFile(file, true)
          .pipe(
            finalize(() => {
              fileUpload.unsubscribe();
            })
          )
          .subscribe(
            (data: any) => {
              this.createForm.patchValue({
                formLogo: `${data.filePath}`,
              });
              this.tostService.success('Form Logo Uploaded Successfully');
            },
            (error: HttpErrorResponse) => {
              this.toastService.error(error.error.message);
            }
          );
      } else {
        this.tostService.error(COMMON_MESSAGES.UPLOAD_ONLY_IMAGE);
        event.target.value = '';
        file = null;
      }
    }
  }

  addQuestionaari(value) {
    if (value) {
      var index = this.questionaarie.indexOf(value);
      this.questionaarie.splice(index, 1);

      this.createForm.patchValue({
        moduleId: {
          string: value.moduleName,
          _id: value._id,
        },
      });
      this.questionare = value.moduleName;
      this.questionDropDownToClear = [];
    }
  }

  addAdditionalModuleId(value) {
    if (value) {
      this.workFlowForm.patchValue({
        additionalModuleId: {
          string: value.moduleName,
          _id: value._id,
        },
      });
    }
  }

  deleteQuestionaari() {
    this.loadModules();
    this.questionDropDownToClear = null;

    this.createForm.patchValue({
      moduleId: {
        string: null,
        _id: null,
      },
    });
  }
  deleteAditionalModule() {
    this.loadModules();
    this.WorkFlowAddFieldDropDownToClear = null;

    this.workFlowForm.patchValue({
      additionalModuleId: {
        string: null,
        _id: null,
      },
    });
  }

  get workFlowStatusControl(): FormArray {
    return this.workFlowForm.get('workflowStatus') as FormArray;
  }
  get questionResponseControl(): FormArray {
    return this.workFlowForm.get('questionResponse') as FormArray;
  }
  get workflowResponseControl(): FormArray {
    return this.workFlowForm.get('workflowResponse') as FormArray;
  }

  async adddWorkFlowStatus() {
    const Control = this.workFlowStatusControl;
    const index = await Control.value.findIndex(
      (x) => x.field === this.workflowstatusForm.get('field').value
    );

    if (index > -1) {
      this.toastService.error(
        `${this.workflowstatusForm.get('field').value} is already added`
      );
    } else {
      if (!this.workflowstatusForm.valid) {
        this.toastService.error('Please select color');
      }

      let field = this.formBuilder.group({
        field: [
          this.workflowstatusForm.get('field').value,
          Validators.compose([Validators.required]),
        ],
        tempId: [
          this.workflowstatusForm.get('tempId').value,
          Validators.compose([Validators.required]),
        ],
        isDefault: [
          this.workflowstatusForm.get('isDefault').value,
          Validators.compose([Validators.required]),
        ],
        color: [
          this.workflowstatusForm.get('color').value,
          Validators.compose([Validators.required]),
        ],
      });
      Control.push(field);
      this.initWorkFlowStatusForm();
    }
  }

  deleteQuestions(index) {
    const Control = this.workFlowStatusControl;

    Control.removeAt(index);
  }

  deleteWorkFlowStatus(index) {
    this.workflowResponse.splice(index, 1);
  }

  get workFlowAdminControl(): FormArray {
    return this.workFlowForm.get('admin') as FormArray;
  }

  async addWorkFlowAdmin(value) {
    if (value) {
      const Control = this.workFlowAdminControl;
      const index = await Control.value.findIndex((x) => x._id === value._id);

      if (index > -1) {
        this.toastService.error(`${value.name} is already added`);
        this.workFlowAdminDropDownToClear = [];
      } else {
        let field = this.formBuilder.group({
          name: [value.name, Validators.compose([Validators.required])],
          _id: [value._id, Validators.compose([Validators.required])],
        });
        Control.push(field);

        this.workFlowAdminDropDownToClear = [];
      }
    }
  }

  defaultSelectedAdmin() {
    setTimeout(() => {
      const Control = this.workFlowAdminControl;
      Control?.clear();
      const defaultSelectedData: any = this.userDropDown?.find(
        (x) => x._id == this.loggedInUserId
      );
      let dataIndex = Control.value.findIndex(
        (item) => item._id == defaultSelectedData._id
      );
      if (dataIndex <= -1 && defaultSelectedData) {
        let field = this.formBuilder.group({
          name: [
            defaultSelectedData?.name,
            Validators.compose([Validators.required]),
          ],
          _id: [
            defaultSelectedData?._id,
            Validators.compose([Validators.required]),
          ],
        });
        Control.push(field);
      }
    }, 500);
  }

  deleteWorkFlowAdmin(index) {
    const Control = this.workFlowAdminControl;
    Control.removeAt(index);
  }
  addWorkFlow() {
    if (this.workFlowForm.valid) {
      this.workFlowForm.value.tempId = Date.now();
      this.workFlow.push(this.workFlowForm.value);
      this.initWorkFlowFrom();
      this.initWorkFlowStatusForm();
      this.defaultSelectedAdmin();
      this.WorkFlowAddFieldDropDownToClear = null;
    }
  }

  ClearWorkFlowForm() {
    this.initWorkFlowFrom();
    this.initWorkFlowStatusForm();
    this.workFlow = [];
    this.defaultSelectedAdmin();
    this.WorkFlowAddFieldDropDownToClear = null;
  }

  editWorkflow(workFlow, index) {
    const WorkFlowStatus = this.workFlowStatusControl;
    const WorkFlowAdmin = this.workFlowAdminControl;
    const QuestionRespose = this.questionResponseControl;
    const WorkflowRespose = this.workflowResponseControl;
    this.workflowEditIndex = index;
    this.isWorkflowEdited = true;
    this.workFlowForm.patchValue({
      title: workFlow.title,
      type: workFlow.type,
      status: workFlow.status,
      additionalModuleId: {
        string: workFlow.additionalModuleId
          ? workFlow.additionalModuleId.moduleName
          : null,
        _id: workFlow.additionalModuleId
          ? workFlow.additionalModuleId._id
          : null,
      },
      userStatusVisibility: workFlow.userStatusVisibility,

      tempId: workFlow.tempId,
      _id: workFlow._id,
    });
    WorkFlowAdmin?.clear();
    workFlow.admin.forEach((element) => {
      let field = this.formBuilder.group({
        name: [element.name, Validators.compose([Validators.required])],
        _id: [element._id, Validators.compose([Validators.required])],
      });
      WorkFlowAdmin.push(field);
    });
    workFlow.workflowResponse.forEach((element) => {
      const workFlowData = this.workFlow.find(
        (id) => id._id === element.workflowId
      );
      const workflowResponseData = workFlowData.workflowStatus.find(
        (id) => id._id === element.statusId
      );
      let field = this.formBuilder.group({
        question: [
          workFlowData.title,
          Validators.compose([Validators.required]),
        ],
        statusId: [element.statusId, Validators.compose([Validators.required])],
        workFlowAnswer: [
          workflowResponseData.field,
          Validators.compose([Validators.required]),
        ],
        workflowId: [
          element.workflowId,
          Validators.compose([Validators.required]),
        ],
        _id: [element._id, Validators.compose([Validators.required])],
      });
      WorkflowRespose.push(field);
    });
    workFlow.questionResponse.forEach((element) => {
      const questionDetails = this.questions.find(
        (id: any) => id._id === element.questionId
      );
      const answerDetails = questionDetails.options?.find(
        (id: any) => id.value === element.answerId
      );
      let field = this.formBuilder.group({
        question: [
          questionDetails.question,
          Validators.compose([Validators.required]),
        ],
        questionId: [
          questionDetails._id,
          Validators.compose([Validators.required]),
        ],
        answer: [
          answerDetails.value,
          Validators.compose([Validators.required]),
        ],
        answerId: [
          answerDetails._id,
          Validators.compose([Validators.required]),
        ],
      });
      QuestionRespose.push(field);
    });
    workFlow.workflowStatus.forEach((element) => {
      let field = this.formBuilder.group({
        field: [element.field, Validators.compose([Validators.required])],
        isDefault: [
          element.isDefault,
          Validators.compose([Validators.required]),
        ],
        color: [element?.color, Validators.compose([Validators.required])],
        _id: [element?._id, Validators.compose([Validators.required])],
      });
      WorkFlowStatus.push(field);
    });
  }

  deleteWorkFlow(index) {
    this.workFlow.splice(index, 1);
  }

  updateWorkFlow() {
    this.workFlow[this.workflowEditIndex] = this.workFlowForm.value;
    this.initWorkFlowFrom();
    this.initWorkFlowStatusForm();
    this.isWorkflowEdited = false;
    this.workflowEditIndex = null;
    this.defaultSelectedAdmin();
    this.WorkFlowAddFieldDropDownToClear = null;
  }
  onWorkFlowSubmit() {
    const Output = [];
    this.workFlow.forEach((element) => {
      let questionResponse = element.questionResponse.map(
        ({ questionId, answer }) => ({ questionId, answerId: answer })
      );

      const responseData = element.workflowResponse;
      responseData.map((ele: any) => {
        ele.workflowId = ele.workflowId;
        ele.statusId = ele.statusId ? ele.statusId : ele.tempId;
        if (ele._id) {
          ele._id = ele._id;
        } else {
          delete ele._id;
        }
        delete ele.tempId;
        delete ele.workFlowAnswer;
        delete ele.question;
      });
      let workflowResponse = responseData;
      let admin = element.admin.map((item) => item._id);
      const { question, answer, workflow, workFlowAnswer, ...rest } = element;
      let _id = element._id ? element._id : null;
      let additionalModuleId = element.additionalModuleId
        ? element.additionalModuleId._id
        : null;

      let obj = {
        ...rest,
        _id,
        questionResponse,
        workflowResponse,
        additionalModuleId,
        admin,
      };

      Output.push(obj);
    });

    return Output;
  }

  goToNext() {
    if (!this.createForm.valid) {
      this.toastService.error('Please Fill all the form fields');
    }
  }

  editQuestions(questionDetails, index) {
    const Control = this.questionResponseControl;
    Control.removeAt(index);
    const questionnaireDetails = this.questions.find(
      (id) => id._id === questionDetails.questionId
    );
    this.getContionalAnswers(questionnaireDetails);
    this.workFlowForm.controls.question.setValue(questionnaireDetails);
    this.workFlowForm.controls.answer.setValue(
      this.answers.find((id) => id._id === questionDetails.answerId)
    );
  }

  onSubmitWorkFlow() {
    this.isLoadingSubmit = true;
    const data = {
      ...this.createForm.value,
      moduleId: this.createForm.get('moduleId._id').value,
      assignUsers: this.UpdateduserDetails || [],
      workflow: this.onWorkFlowSubmit() || [],
      formTypeId: this.formTypeId || '1',
      formSubTypeId: this.formSubTypeId,
    };
    const sub = this.customFormService
      .createForm(data)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        (data: any) => {
          this.overViewEvent.emit(null);
          this.initForm();
          this.isLoadingSubmit = false;
          this.toastService.success(data?.data?.message);
        },
        (error: HttpErrorResponse) => {
          this.isLoadingSubmit = false;
          this.toastService.error(error?.message);
        }
      );
  }
  onUpdateWorkFlow() {
    this.isLoadingUpdate = true;
    const data = {
      ...this.createForm.value,
      _id: this.formId,
      moduleId: this.createForm.get('moduleId._id').value,
      assignUsers: this.UpdateduserDetails,
      workflow: this.onWorkFlowSubmit(),
    };
    const sub = this.customFormService
      .createForm(data)
      .pipe(
        finalize(() => {
          sub.unsubscribe();
        })
      )
      .subscribe(
        (data: any) => {
          if (data.success) {
            this.overViewEvent.emit(null);
            this.initForm();
            this.isLoadingUpdate = false;
            this.toastService.success('Form Updated SuccessFully');
          }
        },
        (error: HttpErrorResponse) => {
          this.isLoadingUpdate = false;
        }
      );
  }

  getQuestions(moduleId: string) {
    this.centralBuilderService.getSingleQuestions(moduleId).subscribe((res) => {
      const newData = res.data.data.sections
        .flatMap((section) => section.questions)
        .filter((element) => element.options && element.options.length > 0)
        .map((element) => ({
          _id: element._id,
          question: element.question.replace(/<[^>]*>/g, ''), // Remove HTML tags directly here
          options: element.options,
        }));

      // Assign the processed questions to this.questions
      this.questions = newData;
    });
  }

  getContionalAnswers(question) {
    this.workFlowForm.patchValue({
      answer: null,
    });

    this.questions.forEach((ele) => {
      if (ele._id === question._id) {
        this.answers = ele.options;
        return;
      }
    });
  }

  getContionalWorkflowID(workflowResponse) {
    if (this.workFlowDropDown.includes(workflowResponse.workflowStatus)) {
      this.toastService.error('Workflow already added');
    } else {
      this.workFlowDropDown = workflowResponse.workflowStatus;
    }
  }

  getContionalWorkflowAnswer(workflowResponse) {
    this.workFlowAnswer = workflowResponse.field;
  }

  formsQuestionDelete(index) {
    const Control = this.questionResponseControl;

    Control.removeAt(index);
  }

  formsWorkflowDelete(index) {
    const Control = this.workflowResponseControl;

    Control.removeAt(index);
  }

  async showworkflowResponse() {
    if (this.workFlowForm.value.workflow === null) {
      this.toastService.error(`Please add the workflow`);
    } else {
      const { workflow, workFlowAnswer } = this.workFlowForm.value;
      const Control = this.workflowResponseControl;
      const index = await Control.value.findIndex(
        (x) => x.tempId === workflow._id
      );

      if (index > -1) {
        this.workFlowForm.patchValue({
          workFlowAnswer: null,
        });
        this.toastService.error(`${workflow.title} is already added`);
      } else {
        let field = this.formBuilder.group({
          question: [workflow.title, Validators.compose([Validators.required])],
          tempId: [
            workFlowAnswer.tempId,
            Validators.compose([Validators.required]),
          ],
          workFlowAnswer: [
            workFlowAnswer.field,
            Validators.compose([Validators.required]),
          ],
          workflowId: [
            workflow.tempId,
            Validators.compose([Validators.required]),
          ],
        });

        Control.push(field);
        this.workFlowForm.patchValue({
          workflow: null,
          workFlowAnswer: null,
        });
      }
    }
  }

  async addQuestionAndAnswer() {
    const { question, answer } = this.workFlowForm.value;

    const Control = this.questionResponseControl;
    const index = await Control.value.findIndex(
      (x) => x.questionId === question._id
    );

    if (index > -1) {
      this.workFlowForm.patchValue({
        answer: null,
      });
      this.toastService.error(`${question.question} is already added`);
    } else {
      let field = this.formBuilder.group({
        question: [
          question.question,
          Validators.compose([Validators.required]),
        ],
        questionId: [question._id, Validators.compose([Validators.required])],
        answer: [answer.value, Validators.compose([Validators.required])],
        answerId: [answer._id, Validators.compose([Validators.required])],
      });
      Control.push(field);
      this.workFlowForm.patchValue({
        question: null,
        answer: null,
      });
    }
  }

  updateDUserDetails(userDetails) {
    this.UpdateduserDetails = userDetails;
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  onCancelEdit() {
    this.initForm();
    this.userDetails.length = 0;
  }

  validateURLImage(str) {
    return generateImageUrl(str);
  }
}
