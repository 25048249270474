<div
  #sidemenu
  id="sidemenu"
  class="p-3 d-none d-sm-block pt-5 sidemenu"
  *ngIf="!isView"
>
  <aside>
    <div class="form-group">
      <label class="d-block text-center">SELECT SERVICE TYPE</label>
      <ng-select
        [items]="serviceTypeList"
        bindLabel="serviceTypeName"
        class="custom"
        placeholder="Select/Search Service type"
        appendTo="body"
        [searchable]="true"
        [clearable]="true"
        [closeOnSelect]="true"
        [loading]="serviceTypeLoader"
        (clear)="clearDropdown(1)"
        [(ngModel)]="serviceType"
        (change)="onSelectServiceType($event)"
        (scrollToEnd)="loadMoreServiceTypes()"
      >
      </ng-select>
    </div>
    <div class="form-group">
      <label class="d-block text-center">SELECT SERVICE NAME</label>
      <ng-select
        [items]="serviceNameList"
        [loading]="servicesNameLoader"
        loadingText="Please wait data is loading..."
        bindLabel="serviceName"
        class="custom"
        placeholder="Select Service Name"
        [loading]="servicesNameLoder"
        appendTo="body"
        [searchable]="true"
        [clearable]="true"
        [closeOnSelect]="true"
        (clear)="clearDropdown(2)"
        [(ngModel)]="serviceName"
        (change)="onSelectServiceName($event)"
        (scrollToEnd)="loadMoreServiceNames()"
      >
      </ng-select>
    </div>
    <div class="form-group">
      <label class="d-block text-center">SELECT WEEK</label>
      <ng-select
        [items]="weeksArr"
        [loading]="weekLoader"
        loadingText="Please wait data is loading..."
        bindLabel="label"
        class="custom"
        placeholder="Select Week"
        appendTo="body"
        [searchable]="true"
        [clearable]="true"
        [closeOnSelect]="true"
        (clear)="clearDropdown(3)"
        [(ngModel)]="selectedWeek"
        [loading]="weekLoader"
        (change)="onSelectWeek($event)"
      >
        <ng-container *ngFor="let item of weeksArr">
          <ng-option [value]="item.label">{{ item.label }}</ng-option>
        </ng-container>
      </ng-select>
    </div>
    <div class="row">
      <div class="col pr-md-3">
        <button class="btn btn-danger btn-block" (click)="clearDropdown(0)">
          Cancel
        </button>
      </div>
      <div class="col pl-md-0">
        <button class="btn btn-info btn-block" (click)="switchToManage()">
          View
        </button>
      </div>
    </div>
  </aside>
</div>
<div class="d-flex flex-column" *ngIf="isView">
  <ul
    ngbNav
    #companyNav="ngbNav"
    [(activeId)]="activeId"
    class="nav-tabs rounded mb-3"
  >
    <li [ngbNavItem]="1">
      <a ngbNavLink (click)="switchToManage()">MANAGE</a>
      <ng-template ngbNavContent>
        <div
          *ngIf="isManageTabActive && serviceName?.showServiceEndTime"
          class="top-header bg-white rounded mb-3"
        >
          <div>
            <div>Planned Service Hrs</div>
            <div class="hrs-text red-text">
              {{
                this.topData == undefined
                  ? "0.00"
                  : (this.topData.totalHours.need | number : "1.2-2")
              }}
            </div>
          </div>
          <div>
            <div>Planned Service Staff</div>
            <div class="hrs-text red-text">
              {{
                this.topData == undefined
                  ? "0.00"
                  : (this.topData.numberOfShifts.need | number : "1.2-2")
              }}
            </div>
          </div>
          <div>
            <div class="red-text">Booked Service Hrs</div>
            <div class="hrs-text red-text">
              {{
                this.topData == undefined
                  ? "0.00"
                  : (this.topData.totalHours.booked | number : "1.2-2")
              }}
            </div>
          </div>
          <div>
            <div class="red-text">Booked Service staff</div>
            <div class="hrs-text red-text">
              {{
                this.topData == undefined
                  ? "0.00"
                  : (this.topData.numberOfShifts.booked | number : "1.2-2")
              }}
            </div>
          </div>
        </div>

        <app-manage-service-tab
          *ngIf="isManageTabActive"
          [serviceRequest]="serviceRequest"
          [selectedServiceName]="serviceName"
          [serviceTypeInfo]="serviceType"
          [refreshNum]="refreshNum"
          [isLoader]="isLoader"
          (ChangeTabId)="changeActiveTab($event)"
          (day)="headerHrsStaff($event)"
        ></app-manage-service-tab>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>LOGS</a>
      <ng-template ngbNavContent>
        <app-manage-service-logs
          [refreshNum]="refreshNum"
          [serviceRequest]="serviceRequest"
          [selectedServiceName]="serviceName"
        ></app-manage-service-logs>
      </ng-template>
    </li>
    <li [ngbNavItem]="3" class="align-self-center flex-grow-1 nav-item pr-2">
      <button
        class="btn btn-primary float-right"
        (click)="refreshPage(activeId)"
      >
        REFRESH
      </button>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>
