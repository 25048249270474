import { Component, OnInit ,Input, TemplateRef, ViewChild, Output, EventEmitter} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CustomFormService } from 'src/app/users/service/custom-form/custom-form.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {
  @Input() customFormId: string = null;
  @Input() formTitle: string = null;
  @Output() viewQuestion = new EventEmitter<string>();
  @ViewChild('submitSection', { static: true })
  submitSection: TemplateRef<any>;
  usersPTable;
  followUP;
  sectionList;
  selectedResponseId;
  mainTitle;
  constructor(private customFormService: CustomFormService, private modalService: NgbModal,  private toastService: ToastrService, ) { }

  ngOnInit(): void {
     this.customFormService.getSubmittedForms(this.customFormId).subscribe((data)=>{
      this.usersPTable = data?.data;
      this.mainTitle = data?.data[0]?.formTitle || "";
     })
  }
  assignValue(value){
    value.data.map((val)=>{
      this.followUP = val?.showFormWorkflowStatus ? val?.showFormWorkflowStatus[0]?.fieldName : val?.showFormStatus[0]?.fieldName;
    })
    this.usersPTable = value.data;
    console.log(this.usersPTable);

  }

  back() {
    this.viewQuestion.emit(null);
  }

  takeAction(){

  }

  getSubmittedQuestions(id) {
    this.customFormService.getsubmittedFormData(id).subscribe((data) => {
      this.customFormId = data?.customFormId;
      this.sectionList = data.sections || [];
    })
  }

  openPopupForSubmit(event) {
    this.selectedResponseId = event?.responseId;
    this.getSubmittedQuestions(event?.responseId);
    this.modalService.open(this.submitSection, {
      windowClass: 'modal-ui-fix right-align',
    });
  }

  markAsAttendance(questionId) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let payload = {
      questionId: questionId,
      responseId: this.selectedResponseId,
      timeZone: timeZone
    }
    this.customFormService.markAsAttendnace(payload).subscribe((data)=>{
      if (data) {
        if (!!this.selectedResponseId) {
          this.toastService.success('Submitted successfully!');

        this. getSubmittedQuestions(this.selectedResponseId);
        this.customFormService.getSubmittedForms(this.customFormId).subscribe((data)=>{
          this.usersPTable = data?.data;
         })
        } 
       }
      this.usersPTable = data?.data;

     }, (err) => {
      console.log(err);
      this.toastService.error(err?.error?.error?.message);

     })
  }

}
