import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { data } from 'jquery';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createQueryString } from 'src/app/core/utils/helper';
import { environment } from 'src/environments/environment';
import { queryParam } from '../../../core/utils/helper';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private baseUrl: string;
  private profileData: any;
  public userProfileImage$ = new BehaviorSubject<string>("");
  private get Headers(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }
  getUsersBasedOnBussinesUnit(data): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/companyuser/readUserByBU`, data,{
        headers: this.Headers,

        // params: { page, limit, filter:JSON.stringify(filter), sortWith: sort.key, sortBy: sort.order },
      })
      .pipe(map((res) => res as any));
  }

  getAllUsers(data): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/user/?${queryParam(data)}`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  getAllViewUsers(data) {
    return this.http
      .get(`${this.baseUrl}/companyuser/editList?${queryParam(data)}`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }


  getLockedUser(params): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/companyUser/lockedAccounts?${queryParam(params)}`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }



  getOneUser(data): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/companyuser/read`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  updateUser(data) {
    return this.http.post(`${this.baseUrl}/companyuser/update`, data, {
      headers: this.Headers,
    });
  }

  getAppoinments(data) {
    return this.http
      .get(`${this.baseUrl}/appointment/?${queryParam(data)}`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  getAppoinmentsWithoutPagination(data) {
    return this.http
      .post(`${this.baseUrl}/appointment/fromuser?search=&`,data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  getSkillSet(data) {
    return this.http
      .get(`${this.baseUrl}/skillset/?${queryParam(data)}`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  getAssignLeave() {
    return this.http
      .get(`${this.baseUrl}/leaveGroup/get`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  getTransportBu() {
    return this.http
      .get(`${this.baseUrl}/subsection/transportbu`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  createAppoinment(data) {
    return this.http
      .post(`${this.baseUrl}/appointment/create`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  getUserbasedOnUserType(staffId) {
    return this.http
      .get(`${this.baseUrl}/resetPass/getUserData/${staffId}`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  getUserProfile() {
    return this.http
      .get(`${this.baseUrl}/user/read/`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  setUserProfileData(data: any) {
    this.profileData = data;
  }
  getuserProfileData() {
    return this.profileData;
  }

  getRestPassword(data) {
    return this.http
      .get(`${this.baseUrl}/resetPass/getResetPasswordLog?${queryParam(data)}`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  updateResetPassword(data) {
    return this.http
      .post(`${this.baseUrl}/resetPass/updatePassword`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  createUser(data) {
    return this.http
      .post(`${this.baseUrl}/companyuser/create`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }


  updateAppoinment(data) {
    return this.http
      .post(`${this.baseUrl}/appointment/update`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  updateUserProfile(data) {
    return this.http
      .put(`${this.baseUrl}/user/profile/`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }
  unLockUser(data) {
    return this.http
      .post(`${this.baseUrl}/companyUser/active`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  createBulkUser(fileToUpload) {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(`${this.baseUrl}/companyUser/uploadBulkUsers`, formData)
      .pipe(map((res) => res as any))
  }

  getUserData(data) {
    return this.http
      .get(
        `${this.baseUrl}/notification/unReadNotifications/?${queryParam(
          data
        )}`,
        {
          headers: this.Headers,
        }
      )
      .pipe(map((res) => res as any));
  }

  deleteAppoinment(data) {
    return this.http
      .post(`${this.baseUrl}/appointment/delete`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  createLeaveType(data) {
    return this.http
      .post(`${this.baseUrl}/leavetype/create`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  getLeaveType() {
    return this.http
      .get(`${this.baseUrl}/leaveType/get`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  updateLeaveType(updatePayload) {
    return this.http
      .post(`${this.baseUrl}/leaveType/update`, updatePayload, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  deleteLeaveType(leaveId) {
    return this.http
      .post(`${this.baseUrl}/leaveType/delete`, leaveId, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  getLeaveGroup() {
    return this.http
      .get(`${this.baseUrl}/leaveGroup/get`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  createLeaveGroup(data) {
    return this.http
      .post(`${this.baseUrl}/leaveGroup/create`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  updateLeaveGroup(updateRequest) {
    return this.http
      .post(`${this.baseUrl}/leaveGroup/update`, updateRequest, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  deleteLeaveGroup(deleteRequest) {
    return this.http
      .post(`${this.baseUrl}/leaveGroup/delete`, deleteRequest, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  createFacialId(data) {
    return this.http
      .post(`${this.baseUrl}/facial/create`, data, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  getFacialList(id: String) {
    return this.http
      .get(`${this.baseUrl}/facial/list/${id}`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  getUserImage(id: String) {
    return this.http
      .get(`${this.baseUrl}/timesheet/userimage/${id}`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  //method to set profile image observable
  setProfileImage(val : string){
    this.userProfileImage$.next(val);
  }

  getPrivilege() {
    return this.http
      .get(`${this.baseUrl}/companyuser/checkWithRole?getPriv=true`, {
        headers: this.Headers,
      })
      .pipe(map((res) => res as any));
  }

  getQuestionDetails(data): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/external/readExternalData`, data, {
      })
      .pipe(map((res) => res as any));
  }


}
