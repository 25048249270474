import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomFormService } from 'src/app/users/service/custom-form/custom-form.service';
import { ToastrService } from 'ngx-toastr';
import {
  SortableHeader,
  SortEvent,
} from 'src/app/shared/directive/sortable.directive';
import { transportTableParam } from 'src/app/core/model/table-param.model';
import { of, Subject, Subscription } from 'rxjs';
import { TableService } from 'src/app/core/service/table.service';
import { StorageService } from 'src/app/core/service/storage.service';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  finalize,
  map,
  mergeMap,
} from 'rxjs/operators';
import * as Papa from 'papaparse';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manage-transport',
  templateUrl: './manage-transport.component.html',
  styleUrls: ['./manage-transport.component.scss'],
})
export class ManageTransportComponent implements OnInit {
  @ViewChild('sidemenu') sidemenu: ElementRef;
  manageFormLogTable;
  transportFieldForm: FormGroup;
  importDataForm: FormGroup;
  @Output() back = new EventEmitter<any>();
  @ViewChild('AddDataModal', { static: true })
  AddDataModal: TemplateRef<any>;
  @ViewChild('importData', { static: true })
  importData: TemplateRef<any>;
  @Input() manageId: string = null;
  @Input() editFormData: any = null;
  @Input() overViewData: any = null;
  isView: boolean;
  formSubmissionListData = [];
  totalSubmissions: number = 0;
  errorCount: number = 0;
  statuses: any;
  customFormId: string = null;
  childComponentData: any = null;
  serviceId: string = null;
  buId: any = null;
  status: string = null;
  searchObj: any = null;
  isCreateIndent: boolean = false;
  hasWorkflow: boolean = false;
  firstRow: any = null;
  showCreateIndent: boolean = false;
  showLogs: boolean = false;
  formData: any = null;
  isFieldEdited: boolean = false;
  isLoader: boolean = false;
  buOption: any;
  currentPage = 1;
  privilegeFlags;
  isTableLoading: boolean = false;
  selectedID = null;
  fileToUpload: File | null = null;
  usersPTable;
  public userssearchInput = new Subject<KeyboardEvent>();
  tableQueryParams = transportTableParam;
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  onSortUsers({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.usersPTable.sort = { key: column, order: direction as any };
    this.loadFormLog();
  }

  private usersSearchSubscription: Subscription;
  headerKey = [];
  dataSet = [];
  routeSub

  constructor(
    private customformService: CustomFormService,
    public toastService: ToastrService,
    private formBuilder: FormBuilder,
    public modelService: NgbModal,
    private renderer: Renderer2,
    private customFromService: CustomFormService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,

  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.onSameUrlNavigation = 'reload';
    this.routeSub = this.route.data.subscribe((val) => {
      this.isView = val.isView;
    });
    this.transportFieldForm = this.formBuilder.group({});
    this.usersPTable = {
      sortBy: 'asc',
      sortWith: 'createdBy',

      data: [],
      page: 1,
      limit: 10,
      search: '',
    };

    this.usersSearchSubscription = this.userssearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.tableQueryParams.search = input;
        this.loadFormLog();
      });
  }

  async ngOnInit(): Promise<void> {
    this.childComponentData = this.editFormData;
    this.customFormId = this.manageId;
    this.isCreateIndent = this.editFormData?.isCreateIndent;
    this.hasWorkflow = this.editFormData?.hasWorkflow;
    const data = localStorage.getItem('privilegeFlags');
    this.buOption = [
      { key: 'Postal Codes', value: 'postalcode', type: 1 },
      { key: 'Waiting Time', value: 'waitingtime', type: 2 },
      {
        key: 'Airport To Home Dataset',
        value: 'airporttohomedataset',
        type: 3,
      },
      {
        key: 'Home To Airport Dataset',
        value: 'hometoairportdataset',
        type: 4,
      },
    ];
    this.privilegeFlags = JSON.parse(data);
    this.tableQueryParams.page = 1;
    this.tableQueryParams.search = '';
    this.loadFormLog();
    this.importDataForm = this.formBuilder.group({
      csv: [''],
    });
  }

  addDataField(type, value) {
    this.transportFieldForm = this.formBuilder.group({});
    const emptyValuesData = Object.fromEntries(
      Object.keys(this.usersPTable.data[0]).map((key) => [key, ''])
    );
    const objectData = type === 1 ? value : emptyValuesData;
    const { _id, status, ...remainingData } = objectData;
    let formData = remainingData;
    Object.keys(formData).forEach((key) => {
      this.transportFieldForm.addControl(
        key,
        this.formBuilder.control(formData[key])
      );
    });
    this.isFieldEdited = type === 0 ? false : true;
    if (type === 1) {
      this.selectedID = value?._id;
    }
    this.modelService.open(this.AddDataModal, {
      windowClass: 'modal-ui-fix right-align',
    });
  }

  onPageChangeUsers(page: number) {
    this.tableQueryParams.page = page;
    this.loadFormLog();
  }
  onLimitChangeUsers() {
    this.tableQueryParams.page = 1;
    this.loadFormLog();
  }

  openImportData() {
    this.modelService.open(this.importData, {
      ariaLabelledBy: 'modal-basic-title',
    });
  }

  checkForEmptyOrMissingValues(data: any[]): any[] {
    const invalidRows = data.filter((row, index) => {
      return Object.values(row).some(
        (value) => value === null && value === '' && value === undefined
      );
    });

    return invalidRows;
  }

  clickOnCancel() {
    this.location.back();
  }

  // Convert CSV to JSON using PapaParse
  importDataFromCSV(csvText: string) {
    const parsedData = Papa.parse(csvText, {
      header: true,
      skipEmptyLines: true,
    });
    return parsedData.data;
  }

  importDatafile(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  async uploadImportMasterData() {
    if (!this.fileToUpload) {
      alert('Please select a file first!');
      return;
    }

    const csvText = await this.fileToUpload.text();
    const csvtoJSON = this.importDataFromCSV(csvText);


    const emptyValueRow = this.checkForEmptyOrMissingValues(csvtoJSON);

    if (emptyValueRow.length && this.buId.type !== 3 && this.buId.type !== 4) {
      alert(
        'Import file is not having proper records, please verify and try again'
      );
      return;
    }
    if (
      this.buId?.value === 'postalcode' &&
      this.validatePostalCodes(csvtoJSON)
    ) {
      this.toastService.error(
        'Some record may have invalid Postal Code',
        'Error'
      );
      return true;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'After importing the data, the previous data will be removed.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
    this.customformService
    .importCSVMasterData(csvtoJSON, this.buId?.value)
    .subscribe((res: { message }) => {
      this.toastService.success(res.message);
      this.modelService.dismissAll();
      this.loadFormLog();
    });
      }
    });

  }

  isValidPostalCode(code) {
    return /^[0-9]{6}$/.test(code);
  }

  validatePostalCodes(array) {
    const invalidEntry = array.find(
      (item) => !this.isValidPostalCode(item.postalCode)
    );
    return invalidEntry;
  }

  downloadSampleCSV() {
    let defaultSample: any[] = [
      this.usersPTable.data[0]
    ]
    defaultSample.forEach(item => delete item._id);
    const jsonData = defaultSample;
    const csvRows = [];

    const headers = Object.keys(jsonData[0]);
    csvRows.push(headers.join(','));

    for (const row of jsonData) {
      const values = headers.map((header) =>
        JSON.stringify(row[header], (key, value) =>
          value === null ? '' : value
        )
      );
      csvRows.push(values.join(','));
    }
    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute(
      'download',
      `${
        this.buId?.type === 1
          ? 'postalCode_sample.csv'
          : 'waitingTime_sample.csv'
      }`
    );
    a.click();
    window.URL.revokeObjectURL(url);
  }

  onSubmitDataField() {
    const hasEmptyValue = Object.values(this.transportFieldForm?.value).some(
      (value) => value === ''
    );

    if (hasEmptyValue) {
      this.toastService.error('Please enter all the field data');
    } else {
      if (!this.isFieldEdited) {
        this.customformService
          .createTransportMasterData(
            this.transportFieldForm?.value,
            this.buId?.value
          )
          .subscribe(
            (data) => {
              this.toastService.success('Data saved successfully');
              this.modelService.dismissAll();
              this.loadFormLog();
            },
            (error) => {
              this.toastService.error(error?.error?.error?.message);
            }
          );
      } else {
        if (!this.selectedID) return;
        this.customformService
          .updateTransportMasterData(
            this.transportFieldForm?.value,
            this.selectedID,
            this.buId?.value
          )
          .subscribe(
            (data) => {
              this.toastService.success('Data updated successfully');
              this.modelService.dismissAll();
              this.loadFormLog();
            },
            (error) => {
              this.toastService.error(error?.error?.error?.message);
            }
          );
      }
    }
  }

  loadFormLog() {
    if (!this.buId) return;
    this.isLoader = true;
    this.tableQueryParams.limit = this.usersPTable.limit;
    const subscribe = this.customformService
      .getAdminTransportMasterData(this.buId?.value, this.tableQueryParams)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          const { count, data } = response.data;
          this.usersPTable.data = response.data;
          if(this.usersPTable.data.length > 0){
            this.headerKey = Object.keys(this.usersPTable.data[0]);
            this.headerKey = this.headerKey.filter((item) => item !== 'id' && item !== '_id');
          }
          this.usersPTable.total = response.total;
          this.isLoader = false;
        },
        (error) => {
          this.usersPTable.data = [];
          this.usersPTable.total = 0;
          this.toastService.error('No list found');
          this.isLoader = false;
        }
      );
  }

  ngOnDestroy(): void {
    this.usersSearchSubscription && this.usersSearchSubscription.unsubscribe();
  }

  refresh() {
    this.loadFormLog();
  }

  viewClicked() {
    if (this.buId) {
      this.isView = true;
      this.loadFormLog();
    } else {
      this.toastService.warning('Please Select required field', 'Error');
    }
  }

  exportmasterData() {
    this.isLoader = true;
    this.customFromService
      .exportTransportMasterData(this.buId?.value)
      .subscribe(
        (res) => {
          if (!res?.result.length)
            this.toastService.error('No data found!', 'Error');
          this.exportToCsv(`admin_${this.buId?.value}.csv`, res?.result);
          this.isLoader = false;
        },
        (error) => {
          this.isLoader = false;
        }
      );
  }

  exportToCsv(filename: string, rows: any[]) {
    const csvData = this.convertToCsv(rows);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  private convertToCsv(rows: any[]): string {
    if (rows.length === 0) return '';
    const headers = Object.keys(rows[0]);
    const csvContent = rows.map((row) => {
      return headers.map((header) => `"${row[header]}"`).join(',');
    });

    return [
      headers.map((header) => `"${header}"`).join(','),
      ...csvContent,
    ].join('\n');
  }

  checkLogs() {
    this.showLogs = true;
  }

  ngAfterViewInit() {
    if (!this.isView) {
      setTimeout(() => {
        this.renderer.addClass(this.sidemenu.nativeElement, 'animate');
      }, 50);
    }
  }
}
