<div>
  <div class="modal-content">
    <div class="modal-body" style="padding: 0px; min-height: 100vh">
      <div>
        <div class="card w-100">
          <div class="card-header flexi-header">
            <h3>CHANGE WORKFLOW STATUS</h3>
          </div>
          <div *ngIf="!showWorkFlowContent && workflow.length > 0">
            <div *ngFor="let item of workflow; let i=index">
              <button style="margin: 2px; margin-top: 20px; margin-right: 4px;" class="btn-block btn btn-success btn-lg"
                (click)="onShowWorkFlowContent(item)">
                <span *ngIf="workflow !== undefined && workflow !== null">{{item?.title}}</span>
              </button>
            </div>

          </div>
          <div class="card-body" *ngIf="showWorkFlowContent">
            <div class="row">
              <div class="col-md-12">

                <div class="row" style="margin-left: 4px;">
                  <label for="exampleInputEmail1" class="font-weight-bold">Form Name</label>
                  <input type="text" class="form-control" name="name" aria-describedby="nameHelp"
                    placeholder="Enter company name" value="{{editFormData?.title}}" maxlength="20" disabled />
                </div>
                <div class="row" style="margin-top: 5px; margin-left: 4px;">
                  <label for="exampleInputEmail1" class="font-weight-bold">Submitted User</label>
                  <input type="text" class="form-control" name="name" aria-describedby="nameHelp"
                    value="{{selectedWorkFlowData?.staffId}}" maxlength="20" disabled />
                </div>
                <div class="row" style="margin-top: 5px; margin-left: 4px;">
                  <label for="exampleInputEmail1" class="font-weight-bold">Submitted At</label>
                  <input type="text" class="form-control" name="name" aria-describedby="nameHelp"
                    value="{{selectedWorkFlowData?.createdAt |date:'medium'}}" maxlength="20" disabled />
                </div>

                <div class="row" style="margin-top: 5px; margin-left: 4px;">
                  <label for="exampleInputEmail1" class="font-weight-bold">WorkFlow Name</label>
                  <input type="text" class="form-control" name="name" aria-describedby="nameHelp"
                    value="{{clickedWorkFlowTitle?.title}}" maxlength="20" disabled />
                </div>

                 <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" sortable="Tier 1">STATUS</th>
                        <th scope="col" sortable="Tier 2">
                          ADDED
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr *ngFor="let ques of defaultStatusArray; let index = index">
                        <td>
                          <span class="text-items status-workflow-class" [style.background]="ques.color">
                            {{ques?.field}}</span>

                        </td>

                        <td>
                          <input [disabled]="!isAdminTrue" type="checkbox" [checked]="ques?.status == true"
                            [ngModel]="selectedCheckBoxIndex === index" (change)="isAllSelected($event,ques,index)" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="d-flex justify-content-between">
                      <button class="btn btn-clear btn-block mr-1" (click)="onCloseStatusFrom()">
                        Close
                      </button>
                      <button [disabled]="!isAdminTrue || !workFlowSubmitPayload" style="margin: 2px;" class="btn btn-add btn-block"
                        (click)="onSubmitColumnSettings()">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>


                <div class="row mt-2">
                  <div class="card-header flexi-header w-100">
                    <h3>FORM STATUS LOG</h3>
                  </div>
                  <div class="d-flex justify-content-between mt-2 mb-3">
                    <select class="custom-select" style="width: auto" name="pageSize" (change)="onLimitChangeWorkFlow()"
                      [(ngModel)]="workFlowLogTableData.limit">
                      <option [ngValue]="10">10</option>
                      <option [ngValue]="20">20</option>
                      <option [ngValue]="30">30</option>
                      <option [ngValue]="40">40</option>
                      <option [ngValue]="50">50</option>
                    </select>

                  </div>
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" sortable="Tier 1">STATUS</th>
                          <th scope="col" sortable="Tier 2">
                            STAFF ID
                          </th>
                          <th scope="col" sortable="Tier 2">
                            UPDATED BY
                          </th>
                          <th scope="col" sortable="Tier 2">
                            UPDATED AT
                          </th>
                        </tr>
                      </thead>
                      <tbody *ngIf="workFlowLogTableData?.data && workFlowLogTableData.data.length >0">

                        <tr *ngFor="let ques of workFlowLogTableData.data">
                          <td>
                            <span class="text-items" style="word-break: break-all" [style.background]="ques.color">
                              {{ques?.changeMessage}}</span>
                          </td>
                          <td>
                            {{ques?.user?.staffId}}
                          </td>
                          <td>
                            {{ques?.user?.name}}
                          </td>
                          <td>
                            {{ques?.createdAt |date:"medium"}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <br>

                <div class="card-footer">
                  <div class="d-flex justify-content-between">
                    <p>Total records {{ workFlowLogTableData.total }}</p>
                    <ngb-pagination [collectionSize]="workFlowLogTableData.total" [(page)]="workFlowLogTableData.page"
                      [maxSize]="5" (pageChange)="onPageChangeWorkFlow($event)" [pageSize]="workFlowLogTableData.limit">
                    </ngb-pagination>
                  </div>
                </div>
                <br>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
