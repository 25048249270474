import { isNumber } from '@ng-bootstrap/ng-bootstrap/util/util';
import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild , TemplateRef} from '@angular/core';
import { CentralBuilderService } from 'src/app/users/service/central-builder/central-builder.service';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { SignatureQuestionsComponent } from '../signature-questions/signature-questions.component';
import { NgbTimeStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomFormService } from 'src/app/users/service/custom-form/custom-form.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-my-forms-view',
  templateUrl: './my-forms-view.component.html',
  styleUrls: ['./my-forms-view.component.scss'],

})
export class MyFormsViewComponent implements OnInit {
  @Input() questionId: string = null;
  @Input() customFormId: string = null;
  @Output() takeQuestion = new EventEmitter<string>();
  @Output() viewPage = new EventEmitter<string>();
  @Output() formTitleviewPage = new EventEmitter<string>();
  questions: any = [];
  userScore:any;
  viewQuestions: any = [];
  @ViewChild('ScoreAbleModal', { static: true })
  ScoreAbleModal: TemplateRef<any>;
  time: NgbTimeStruct = { hour: 0o0, minute: 0o0, second: 0o0 };
  dateTime: NgbTimeStruct = { hour: 0o0, minute: 0o0, second: 0o0 };
  dataTimeOnly: NgbTimeStruct = { hour: 0o0, minute: 0o0, second: 0o0 };
  hourStep = 1;
  minuteStep = 1;
  secondStep = 30;
  secondsArray = ['AM', 'PM']
  hourArray = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  minuteArray = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
  hasParent: any = question => 0 === question?.conditionalQuestions?.length;
  meridian = true;
  user = { _id: null, name: null };
  items = [];
  images = [];
  public isModuleView: boolean = false;
  isPopupOpen : boolean = false;
  typeSixteenImageSRC:any;
  selectedModule;
  signatureImg: string;
  ratingType;
  favoriteSeason;
  pollingtype;
  selectedCars;
  imageURL;
  fileToUpload: File | null = null;
  dataTewelve;
  typeFourteenQuestion;
  questionDropDownToClear = null;
  isFormSubmitted:boolean;
  questionIDFourteen;
  sectionList;
  firstSection: string;
  imagePath = environment.imageUrl;
  baseUrl = environment.imageUrl;
  typeSixteenImageField:any[] = [];
  @ViewChild('myImage', { static: false }) myImage: ElementRef;
  constructor(private centralBuilderService: CentralBuilderService, private dialogRef: MatDialog, private toastService: ToastrService,
    private customFormService: CustomFormService, public modelService: NgbModal,private datePipe:DatePipe) {
  }

  ngOnInit(): void {
    // this.questionId is moduleId
    console.log('questionId', this.questionId,this.customFormId, this.takeQuestion )
    this.questionId && this.getQuestions(this.questionId);

  }
  toggleMeridian() {
    this.meridian = !this.meridian;
  }

  multiSelect(value, question) {
    question.answer = value?.map(x => {
      if (this.typeFourteenQuestion?.find(qus => qus._id == x)) {
        return this.typeFourteenQuestion?.find(qus => qus._id == x)
      }
    });
  }



  openDialog(questionid) {
    const modalRef = this.dialogRef.open(SignatureQuestionsComponent, {
      height: '500px',
      width: '600px',
    });
    modalRef.componentInstance.takeSignature.subscribe((signURL: any) => {
      this.signatureImg = signURL;
      questionid.answer = signURL;
      this.close(modalRef);
    });
  }

  close(modalRef) {
    modalRef.close();
  }

  drop(event: CdkDragDrop<string[]>, question: any) {
    moveItemInArray(question.images, event.previousIndex, event.currentIndex);
    this.typeFifteen(question);
  }

  back() {
    this.takeQuestion.emit(null);
  }
  closePage(){
    this.takeQuestion.emit(null);
  }
  formatHoursTo12(date) {
    let hour: string | number = date.hour % 12 || 12;
    let minute;
    if (hour < 10)
      hour = '0' + hour;
    if (date.minute < 10)
      minute = '0' + date.minute;
    else
      minute = date.minute;
    let seconds;
    if (date.hour < 12)
      seconds = 'am';
    else
      seconds = 'pm'
    const time = hour + "-" + minute + "-" + seconds;
    return time;
  }



  submit() {
    const mandatoryQuestion = this.viewQuestions.filter((data)=>{
      if(data?.required)
      return data?._id
    });
    const answers = this.viewQuestions.reduce((prev, question, i) => {
      let answer = null,
        _id = question._id,
        type = question.type,
        required = question?.required,
        sectionId = question?.sectionId,
        questionId = question._id,
        options = null || question?.options,
        maxLength = question?.maxlength || 0,
        isNumeric = question?.isNumeric

      switch (question.type) {
        case 1:
        case 2:
        case 3:
        case 6:
        case 8:
        case 11:
        case 14:
        case 12:
        case 13:
        case 16:
        case 15:
          case 21:
            case 22:
          answer = question.answer;
          break;
        case 9:
          answer = question.answer?.toString();
          break;
        case 10:
          if (-1 !== question.dateTime.indexOf(1) && !!question.answer && !!question.answer.date) {
            answer = answer || {};
           answer["date"] = this.datePipe.transform(new Date(question.answer.date),'dd-MM-yyyy');
          }

          if (
            -1 !== question.dateTime.indexOf(2) &&
            !!question.answer &&
            !!question.answer.time

          ) {
            answer = answer || {};
            if (question?.answer.time?.hour && question?.answer.time?.minute) {
              answer["time"] = this.convert24hourTo12HourFormat(question.answer.time?.hour + ':' + question.answer.time?.minute)
            }
          }
          if ((-1 !== question.dateTime.indexOf(1) && -1 !== question.dateTime.indexOf(2)) &&
            !(!!question.answer && !!question.answer.date && !!question.answer.time?.hour && !!question.answer.time?.minute)) {
            answer = false;
          }
          break;
        case 5:
        case 4:
          answer = question.options?.filter(v => v.answer);
          if (!answer.length) {
            answer = null;
          }
          break;
        default:
          answer = false;
          break;
      }
      return prev.concat({ _id, answer, type, required, sectionId, questionId, options, maxLength, isNumeric });
    }, []);
    if (answers?.some(v => v.type === 9 && !(!!v.answer))) {

      this.toastService.error('Please enter valid numeric values');
      return;
    }

    if (answers.some(v => mandatoryQuestion.findIndex(el => el._id == v._id) > -1 && !!!v.answer)) {
      this.toastService.error("Answer all mandatory questions");
      return;
    }
    let maxLengthData = false
    answers.forEach(element => {
      if (element?.type === 8 && !!element?.isNumeric) {
        if (element?.maxLength > element?.answer?.length) {
          maxLengthData = true;
          this.toastService.error(`Minimum length ${element?.maxLength} is required to submit answer `);
        
        }
      }
    });

    if(!!maxLengthData) return;
    let _questions = this.questions.map(v => v._id);
    const payload = {
      answers: answers,
      customFormId: this.customFormId,
      questions: _questions
    }
    const answerList = answers?.map((val) => ({
              sectionId: val?.sectionId,
              questionId: val.questionId,
              option: typeof val?.answer !== 'object' ? null : val?.answer?._id,
              value: typeof val?.answer !== 'object' ? val?.answer : val?.answer?.value,
              type: val?.type
    }) )
    const mainPayload = {
        customFormId: this.customFormId,
        answers: answerList,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }

    answers.length > 0 ? this.customFormService.submitForm(mainPayload).subscribe((data) => {
      this.toastService.success("Form Submitted Successfully");
      this.isFormSubmitted = true;
      this.showScreableModal(data)
    },
    (error) => {
      this.toastService.error(error?.error?.error?.message);
    }
  ) : '';

  };
showScreableModal(data:any){
  if(data?.data?.score){
    this.userScore = data?.data?.score;
    this.modelService.open(this.ScoreAbleModal, {
      ariaLabelledBy: 'modal-basic-title'
    });
  }
}

modalClose(){
  this.modelService.dismissAll(this.ScoreAbleModal)
}
  getQuestions(moduleId: string) {
    this.customFormService
      .getQuestionList(this.customFormId, moduleId, 'submit')
      .subscribe(({ data }) => {
        // pending
        console.log('data?.data', data)
        this.isModuleView = true;
        this.selectedModule = data || null;
        this.sectionList = this.selectedModule.sections || [];
        let sectionList = this.selectedModule.sections;
        this.firstSection = sectionList[0]?.sectionName;

        for(let i=0; i< sectionList.length; i++){
          const section = sectionList[0];
          sectionList[0].questions = section.questions.filter(v => 7 !== v.type) || [];
          this.viewQuestions= sectionList[0].questions.filter(x => 0 === x.conditionalQuestions.length) || [];
        }
            let typeFourteen = null;
        this.viewQuestions?.map(item => {
          item.answer = null;
          if (item.type === 10) {
            item.answer = {};
              item.answer['date'] = null;
              item.answer['time'] = null;
          }
          if (item.type === 14) {
            typeFourteen = item._id;
          }
          if (item.type === 15) {
            item?.options.map((final: any, index) => {
              item['images'][index] = { "src": final.imageSrc, "value": final.value, "option": final._id };
            })
          }
        })
        typeFourteen && this.getNominateUserType(typeFourteen);
        this.selectedModule.sections = sectionList;
        this.questions = sectionList
      });
  }


  getNominateUserType(questionId) {
    let finalData = {
      questionId: questionId
    }
    this.centralBuilderService.getNominateUser(finalData).subscribe((data) => {
      this.setDropdown(data.data, questionId);
    });
  }

  setDropdown(data, questionId) {
    this.typeFourteenQuestion = data?.items || [];
    this.questionIDFourteen = questionId;
  }

  fileUpload(event, question) {
    let typeList = question.options?.map(data => { return data.value.toLowerCase() }) || [];
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
      let fileType = file.name.split('.')
      let fileExe = fileType?.length ? fileType[fileType.length - 1].toLowerCase() : null;
      if (fileExe && typeList?.includes(fileExe)) {
        var useraddData = new FormData();
        useraddData.append("file", file);
        const fileUpload = this.customFormService
          .uploadFiles(useraddData)
          .pipe(
            finalize(() => {
              fileUpload.unsubscribe();
            })
          )
          .subscribe(
            (data: any) => {
              question.answer = { url: data?.filePath || null, fileName: file.name }
            },
            (error: HttpErrorResponse) => {
              this.toastService.error(error?.message || 'File upload error');
            }
          );
      }
      else {
        this.toastService.error("Only this format are accecpted", typeList?.toString());
      }
      // }
    } else {
      this.toastService.error("Only this format are accecpted", typeList?.toString());
    }


  }

  toggleDropdown() {
    //add dropdwn frun
  }


  typeFifteen(question) {
    let payload;
    payload = question.images.map((data) => {
      return { "value": data.value, "_id": data.option, correctAns: false }
    });
    question.images.map((data, i) => {
      if (data.src !== '')
        payload[i].imageSrc = data.src
    });
    question.answer = payload;
  }

  setChild(question, answer, index?:number) {
   if (question.type == 11) {
      this.viewQuestions[index].answer = answer;
      this.viewQuestions[index].isVisibleList = !this.viewQuestions[index].isVisibleList;
    }
    console.log(question, answer);
    this.checkQuestions(question, answer._id);
  };

  checkQuestions(question, a) {

    let q = question._id;
    const filteredData = this.questions
      ?.filter(
        v =>
          !this.hasParent(v) &&
          v?.conditionalQuestions?.some(
            c => c.questionId?._id === q && c.optionId?._id === a
          )
      );
    const filteredQuestions =  filteredData?.map(c => {
        c["cQuestion"] = q;
        c["cOption"] = a;
        return JSON.parse(JSON.stringify(c));
      });
    if (question.type == 5) {
      setTimeout(() => {
        if (!question.options.filter(option => option._id === a)[0].answer) {
          let allQuestionsadded = filteredQuestions.map(v => v._id);

          let questionsNeedToDelete = this.viewQuestions?.reduce((prev, curr, i) => {
            return allQuestionsadded.includes(curr._id) ? prev.concat(i) : prev;
          }, []);
          questionsNeedToDelete.sort((a, b) => b - a);
          questionsNeedToDelete.forEach(v => {
            this.viewQuestions.splice(v, 1);
          })
        } else {
          const index = this.viewQuestions?.findIndex(ques => ques._id == q) + 1;
          this.viewQuestions.splice(index, 0, ...filteredQuestions);
        }

        this.viewQuestions = this.viewQuestions?.reduce((prev, curr, i) => {
          const index = prev.findIndex(v => v._id === curr._id);
          return -1 === index ? prev.concat(curr) : prev;
        }, []);
      }, 100);
    } else {
      setTimeout(() => {

        this.viewQuestions = this.viewQuestions?.filter(ques =>
          "cQuestion" in ques ? ques.cQuestion !== q : true
        );
        const index = this.viewQuestions.findIndex(ques => ques._id == q) + 1;
        this.viewQuestions.splice(index, 0, ...filteredQuestions);

        this.viewQuestions = this.viewQuestions.reduce((prev, curr, i) => {
          const index = prev.findIndex(v => v._id === curr._id);
          return -1 === index ? prev.concat(curr) : prev;
        }, []);

      })

    }
  };

  selectPolling(questionId, option) {
    let question = this.questions.find(q => q._id.toString() === questionId.toString());
    if (!question.pollingSelectionCount || (1 === question.pollingSelectionCount)) {
      question.options.forEach(opt => opt.answer = (option._id.toString() === opt._id.toString()));
    } else if ((question.options.filter(op => op.answer).length === question.pollingSelectionCount) && !option.answer) {
      this.toastService.error('maxinum options reach for this question')
      } else {
      question.options.find(op => op._id.toString() === option._id.toString()).answer =
        !question.options?.find(op => op._id.toString() === option._id.toString()).answer;
    }
  }
  convert24hourTo12HourFormat(time) {
    const time_part_array = time.split(":");
    let ampm = 'am';
    if (time_part_array[0] >= 12) {
      ampm = 'pm';
    }
    if (time_part_array[0] > 12) {
      time_part_array[0] = time_part_array[0] - 12;
    }
    const formatted_time = time_part_array[0] + ':' + time_part_array[1] + ':' + ampm;
    return formatted_time;
  }

  onImageClick(event: MouseEvent, viewQuestions, index) {
    const imageElement = event.target as HTMLImageElement;
    const imageWidth = imageElement.offsetWidth;
    const imageHeight = imageElement.offsetHeight;
    const offsetX = event.offsetX;
    const offsetY = event.offsetY;
    const percentageX = ((offsetX / imageWidth) * 100)-3.3;
    const percentageY = ((offsetY / imageHeight) * 100)-5.8;

      let field= {
      value: '',
      coordinates:{
        x: percentageX,
        y: percentageY,
        radious: 36,
        imgWidth: imageWidth,
        imgHeight: imageHeight,
      },

    };
    this.typeSixteenImageField.push(field)
    this.viewQuestions[index].answer = this.typeSixteenImageField;
  }
  onDeleteDescription(index) {
  }
  openPopup(img:any) {
    this.typeSixteenImageSRC = img;
    this.isPopupOpen = true;
  }

  closePopup() {
    this.isPopupOpen = false;
  }
  conditionQuestionDelete(index) {
    this.typeSixteenImageField.splice(index,1);
  }

  validateURLImage(str) {
    if(str){
    var img_Path = str;
    if (img_Path.indexOf("https://") == 0) {
      var new_url = new URL(str);
     var pathName = (new_url.pathname.startsWith('//')) ? `/${new_url.pathname.split("//")[1]}` : new_url.pathname;
      return `${this.baseUrl}${pathName}`;
    }
    if (str.startsWith('/')) return this.baseUrl + str;
    return `${this.baseUrl}/${str}`;
   }
  }
}
