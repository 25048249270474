import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CustomFormService } from 'src/app/users/service/custom-form/custom-form.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'indent-draft-list',
  templateUrl: './indent-draft-list.html',
  styleUrls: ['./indent-draft-list.scss'],
})
export class IndentDraftListComponent implements OnInit {
  @Input() submissionList: any = [];
  @Input() customFormId: any = [];
  totalSubmission: number = 0;
  errorSubmission: number = 0;
  showCreateIndent: boolean = false;
  tableHeaders: string[] = [];
  showConfirmation = false;
  submissionToDelete: number | null = null;
  isDataSubmitted = false;
  @Output() onSave = new EventEmitter<any>();

  constructor(
    private customFormService: CustomFormService,
    private toastService: ToastrService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.submissionList) {
      this.submissionList = changes.submissionList.currentValue;
      this.totalSubmission = this.submissionList.length;
      this.errorSubmission = this.submissionList.filter(
        (submission) => submission.isError
      ).length;
      this.generateTableHeaders();
    }
  }

  submitAll() {
    // Handle submission logic

    const payload = this.submissionList.map((submission) => {
      const questionAnswer = submission.finalAnswers.filter(
        (answer: any) => answer.isQuestion
      );
      const staffInfo = submission.finalAnswers.find(
        (answer: any) => answer.title === 'Staff Id'
      );
      return {
        staffId: staffInfo ? staffInfo.value : '',
        answers: questionAnswer,
        isError: submission.isError,
      };
    });

    this.customFormService
      .multipleBooking({
        bookingList: payload,
        customFormId: this.customFormId,
      })
      .subscribe(
        ({ success, totalFailed, totalSuccess }) => {
          if (success) {
            Swal.fire({
              title: 'Data submitted successfully!',
              text: `Success Count: ${totalSuccess},  Error Count: ${totalFailed}`,
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: `Close`,
            }).then((result) => {
              // this.submissionList = [];
              this.isDataSubmitted = true;
              this.onSave.emit({ isDataSubmitted: this.isDataSubmitted });
            });
          } else {
            this.toastService.error('Something went wrong');
          }
        },
        (error) => {
          this.toastService.error(
            error?.error?.error?.message || 'Something went wrong'
          );
        }
      );
  }

  generateTableHeaders(): void {
    const headersSet = new Set<string>();

    this.submissionList.forEach((submission) => {
      submission.finalAnswers.forEach((answer) => {
        headersSet.add(answer.title.replace(/<[^>]+>/g, '').trim());
      });
    });

    this.tableHeaders = Array.from(headersSet);
  }

  getSubmissionValue(submission: any, header: string): string {
    const answer = submission.finalAnswers.find(
      (ans) => ans.title.replace(/<[^>]+>/g, '').trim() === header
    );
    return answer ? answer.value : 'N/A';
  }

  goBack() {
    this.showCreateIndent = false;
  }

  openConfirmationDialog(index: number): void {
    this.submissionToDelete = index;
    this.showConfirmation = true;
  }

  closeConfirmationDialog(): void {
    this.showConfirmation = false;
    this.submissionToDelete = null;
  }

  deleteSubmission(index: number | null): void {
    if (index !== null) {
      this.submissionList.splice(index, 1);
    }
    this.closeConfirmationDialog();
  }
}
