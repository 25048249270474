import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  NgbModal,
  ModalDismissReasons,
  NgbDateStruct,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { LeavePlannerService } from 'src/app/users/service/human-resources/leave-planner.service';
import Swal from 'sweetalert2';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
      : '';
  }
}

@Component({
  selector: 'manage-ballot',
  templateUrl: './manage-ballot.component.html',
  styleUrls: ['./manage-ballot.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class ManageBallotComponent implements OnInit {
  @Input() ballot;
  @Output() ballotRound = new EventEmitter<any>();
  @Output() ballotAuto = new EventEmitter<any>();
  @Output() redirectToOverview = new EventEmitter<any>();
  @Output() back = new EventEmitter<any>();
  ballotResultForm: FormGroup;
  active = 1;
  ballotList: any;
  ballotAll: Object;
  slotsArr = [];
  allOps: any[];
  closeResult = '';
  time = [];
  resultReleaseIn;
  extendBallotForm: FormGroup;
  viewLeaveBallot: FormGroup;
  applicationStartDate;
  allOpsTemp: any[];
  parentBallotId: any;
  autoBallot: Object;
  extendId: any;
  ballotRoundDropdown: any;
  detailView: any;
  mobileViewDetail: Object;
  selectedUser: any;
  daySlot: boolean;
  consolidate: Object = {};
  ballotRoundCount: any;
  detailViewTemp: any;
  teamOps: any;
  resultDisabled: boolean;
  summaryLoader: boolean;
  mobileViewLoader: boolean;
  mobileView: boolean = false;
  detailViewLoader: boolean;
  loader: boolean=false;
  conductAutoAssignDisabled=false;
  constructor(
    private leaveService: LeavePlannerService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    public toastService: ToastrService
  ) {}
  searchQuery: string = '';
  detailsViewTemp : any;
  isInitLoad: boolean = true;

  ngOnInit(): void {
    this.selectTime();
    this.ballotResultForm = this.fb.group({
      ballotName: null,
      ballotGrp: null,
      ballotTeam: null,
    });

    this.viewLeaveBallot = this.fb.group({
      ballotName: null,
      ballotGrp: null,
      ballotTeam: null,
    });

    this.getBallotList();
  }

  autoAssign() {
    Swal.fire({
      title: 'Are you sure?',
      html: '<p></p><b></b>',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      cancelButtonText: `No`,
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        const p = Swal.getHtmlContainer().querySelector('p');
        p.textContent = 'You want to Auto-Assign?';
      },
      preConfirm: () => {
        const b = Swal.getHtmlContainer().querySelector('b');
        b.textContent = 'Please wait Loading...';
        return new Promise((resolve, reject) => {
          this.leaveService.getAutoAssign(this.ballotList[0]._id).subscribe(
            (res) => {
              this.autoBallot = res;
              this.ballotAuto.emit(this.autoBallot);
              resolve('success');
            },
            () => {
              reject('Failed');
            }
          );
        });
      },
    });
  }

  getDetails() {
    this.detailViewLoader = true;
    const ballotDetailView = this.leaveService
      .getballotdetailsByUsers(this.parentBallotId)
      .pipe(
        finalize(() => {
          ballotDetailView.unsubscribe();
          this.detailViewLoader = false;
        })
      )
      .subscribe((res: { userlist }) => {
        if (this.ballotRoundCount.slice(-1) != 'l') {
          const detailRound = res.userlist.filter((val) => {
            if (val.ballotRound == this.ballotRoundCount.slice(-1)) {
              return true;
            }
          });

          this.detailView = detailRound;
        } else {
          this.detailView = res.userlist;
        }

        this.detailViewTemp = this.detailView;
        this.detailsViewTemp = this.detailView;
      });
  }

  getMobileViewDetail(item) {
    this.selectedUser = item;
    const mobileViewPayload = {
      id: item.ballotId,
      userId: item.userId,
      opsGroup: item.Ops?.opsGroupName,
    };
    this.mobileViewLoader = true;
    this.mobileView = true;
    const userDetail = this.leaveService
      .getMobileView(mobileViewPayload)
      .pipe(
        finalize(() => {
          this.mobileViewLoader = false;
          userDetail.unsubscribe();
        })
      )
      .subscribe((res: { data }) => {
        this.mobileViewDetail = res.data;
      });
  }

  getdate2(d) {
    return moment(d, 'YYYY-MM-DD').format('DD-MM-YYYY');
  }

  getBallotList() {
    this.summaryLoader = true;
    this.loader=true;
    this.leaveService
      .manageBallot(this.ballot._id)
      .subscribe((res: { data }) => {

        this.parentBallotId = res.data?.parent;
        this.ballotList = res.data.BallotList;

        this.ballotRoundDropdown = [...this.ballotList];
        this.resultReleaseDisable();

        const bv = { ...this.ballotRoundDropdown[0] };

        this.ballotRoundDropdown.unshift(bv);
        this.ballotRoundDropdown[0].ballotName =
          this.ballotRoundDropdown[0].ballotName + ' - All Rounds';
        this.ballotRoundDropdown[0]._id = 'allRound';
        const bbn = this.ballotRoundDropdown[0].ballotName;
        const roundIndex = this.ballotRoundDropdown.findIndex((v, i) => {
          if (v._id == this.ballot._id) {
            return true;
          }
        });

        this.ballotResultForm
          .get('ballotName')
          .patchValue(this.ballotRoundDropdown[roundIndex]._id);

        let allb = [];
        this.ballotList.forEach((v) => {
          allb.push(v._id);
        });

        const allP = { selectedBallotId: allb };

        const consolidateConst = this.leaveService
          .getConsolidatedResult(allP)
          .pipe(
            finalize(() => {
              consolidateConst.unsubscribe();
            })
          )
          .subscribe((res) => {
            this.consolidate['finalTotalQuota'] = res['finalTotalQuota'];
            this.consolidate['finalTotalSuccess'] = res['finalTotalSuccess'];
          });

        this.viewDetail();
        this.isInitLoad = false;
      });
  }

  resultReleaseDisable() {
    const ballot = this.ballotList[0];
    if (ballot.isAutoAssign) {
      if (ballot.isConduct == true && ballot.isResultRelease == true) {
        this.resultDisabled = true;
      } else if (ballot.isConduct == true && ballot.isResultRelease == false) {
        this.resultDisabled = false;
      } else if (ballot.isConduct == false && ballot.isResultRelease == false) {
        this.resultDisabled = true;
      }
    }
  }

  extend(content, item) {
    this.extendId = item._id;
    this.extendBallotForm = this.fb.group({
      applicationCloseDate: '',
      applicationCloseTime: null,
      resultReleaseDate: null,
      resultReleaseTime: null,
      timeZone: moment().format('[GMT]ZZ'),
    });

    this.applicationStartDate = item.applicationOpenDateTime;
    item &&
      this.extendBallotForm.patchValue({
        // resultReleaseDate: item.resultDate,
        applicationCloseDate: this.momToNgb(item.applicationCloseDateTime),
        applicationCloseTime: moment(item.applicationCloseDateTime).format(
          'HH:mm'
        ),
      });

    if (item.resultRelease == 2) {
      this.resultReleaseIn = 'manual';
    } else {
      this.resultReleaseIn = 'auto';
    }

    if (this.resultReleaseIn == 'auto') {
      this.extendBallotForm.patchValue({
        resultReleaseDate: this.momToNgb(item.resultDate),
        resultReleaseTime: moment(item.resultDate).format('HH:mm'),
      });
    }

    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  momToNgb(d) {
    let md = moment(d);
    const dd = {
      year: md.year(),
      month: md.month() + 1,
      day: md.date(),
    };
    return dd;
  }

  extendSubmit() {
    this.modalService.dismissAll();
    this.loader = true;
    const controls = this.extendBallotForm.controls;

    const payload = {
      applicationCloseDate: this.nbgToMom(
        controls.applicationCloseDate?.value
      ).format('MM-DD-YYYY'),
      applicationCloseTime: controls.applicationCloseTime?.value,
      resultRelease: this.resultReleaseIn === 'auto' ? 1 : 2,
      timeZone: moment().format('[GMT]ZZ'),
      ...(this.resultReleaseIn === 'auto' && {
        resultReleaseDate: this.nbgToMom(
          controls.resultReleaseDate?.value
        ).format('MM-DD-YYYY'),
        resultReleaseTime: controls.resultReleaseTime?.value,
      }),
    };

    this.leaveService
      .extendBallot(this.extendId, payload)
      .pipe(
        finalize(() => {
          this.loader = false;
        })
      )
      .subscribe({
        next: () => {
          this.toastService.success('Ballot Extended Successfully', 'Success');
          this.redirectToOverview.emit();
        },
        error: (err) => {
          this.toastService.error('Failed to extend the ballot', 'Error');
        },
      });
  }
  
  nbgToMom(d) {
    const Nd = moment().set({
      year: d.year,
      month: d.month - 1,
      date: d.day,
    });
    return Nd;
  }

  resultRelease(i) {
    Swal.fire({
      text: 'Are you sure you want to release result now?',
      footer:
        '<p class="text-danger">Please Note: This action is irreversible</p>',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.loader = true;
        const payload = { selectedBallotId: i };
        this.leaveService
          .resultRelease(payload)
          .subscribe((res) => {
            this.toastService.success(
              'Result Released Successfully',
              'Successfull'
            );
            this.redirectToOverview.emit();
          })
          .add(() => {
            this.loader = false;
          });
      }
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getTeam() {
    if (this.ballotResultForm.controls.ballotGrp.value.opsTeam.length) {
      this.teamOps = this.ballotResultForm.controls.ballotGrp.value.opsTeam;
    } else {
      this.teamOps = [];
      this.ballotResultForm.get('ballotTeam').patchValue([]);
    }
  }

  filterSummary() {
    if (this.allOpsTemp) {
      this.allOps = this.allOpsTemp;
    }

    if (this.active == 2) {
      this.detailView = this.detailViewTemp;
      const grpOps = this.ballotResultForm.controls.ballotGrp.value;
      const teamOps = this.ballotResultForm.controls.ballotTeam.value;
      if (grpOps) {
        const grpFilter = this.detailView.filter((val) => {
          if (val.Ops?._id == grpOps.opsGroup?.opsId) {
            return true;
          }
        });

        this.detailView = grpFilter;
        const teamFilter = this.detailView;

        if (teamOps) {
          const team = this.detailView.filter((val) => {
            if (val.Team?._id == teamOps._id) {
              return true;
            }
          });

          this.detailView = team;
        } else {
          this.detailView = grpFilter;
        }
      }
      this.detailsViewTemp = this.detailView;
    }

    if (this.ballotResultForm.controls.ballotTeam.value) {
      const filterOps = this.allOps.filter((v) => {
        if (
          v.OpsTeamId == this.ballotResultForm.controls.ballotTeam.value._id
        ) {
          return true;
        }
      });
      this.allOps = filterOps;
    } else if (this.ballotResultForm.controls.ballotGrp.value) {
      const gg = this.ballotResultForm.controls.ballotGrp.value.opsTeam[0]?._id;
      let vg;
      this.allOps.find((vv) => {
        if (vv.OpsTeamId == gg) {
          vg = vv.OpsGrpId;
        }
      });
      const filterOpsGrp = this.allOps.filter((v) => {
        if (v.OpsGrpId == vg) {
          return true;
        }
      });
      this.allOps = filterOpsGrp;
    }
    this.filterDetailView();
  }

  clearFilter() {
    this.detailsViewTemp = this.detailViewTemp;
    this.filterDetailView();
    this.ballotResultForm.patchValue({
      ballotGrp: null,
      ballotTeam: null,
    });
    this.allOps = this.allOpsTemp;
  }

  viewDetail() {
    const ballotId = {
      selectedBallotId: this.ballotResultForm.get('ballotName').value,
    };

    const ballRoundId = this.ballotResultForm.get('ballotName').value;

    const bR = this.ballotRoundDropdown.find((val) => val._id == ballRoundId);

    if (bR._id != 'allRound') {
      this.ballotRoundCount = bR.ballotRound;
    } else {
      this.ballotRoundCount = 'all';
    }

    if (!this.isInitLoad) {
      this.getDetails();
    }

    this.summaryLoader = true;
    this.loader=true;
    if (this.ballotResultForm.get('ballotName').value != 'allRound') {
      const viewballotsummary = this.leaveService
        .viewBallotRoundDetail(ballotId)
        .pipe(
          finalize(() => {
            viewballotsummary.unsubscribe();
            this.summaryLoader = false;
            this.loader=false;
          })
        )
        .subscribe((res: { data }) => {
          this.consolidate['totalTeamUnassign'] = res['totalTeamUnassign'];
          this.generateslots(res);
        });
    } else {
      let allb = [];
      this.ballotList.forEach((v) => {
        allb.push(v._id);
      });

      const allP = { selectedBallotId: allb };

      const allBallot = this.leaveService
        .allBallotDetail(allP)
        .pipe(
          finalize(() => {
            allBallot.unsubscribe();
            this.summaryLoader = false;
            this.loader=false;
          })
        )
        .subscribe((res) => {
          this.generateslots(res);
        });
    }
  }

  generateslots(res) {
    this.ballotAll = res;
    this.allOps = [];
    this.slotsArr = [];

    //to find week or day ballot by checking difference between 2 start dates in arr
    const startDateArr = this.ballotAll['data'][0].arr; //collection of startdate of every slots
    const slot1StartDate = moment(
      startDateArr[0].startDate.slice(0, 10),
      'MM-DD-YYYY'
    );
    const slot2StartDate = moment(
      startDateArr[1].startDate.slice(0, 10),
      'MM-DD-YYYY'
    );
    const lastSlotStartDate = moment(
      startDateArr[startDateArr.length - 1].startDate.slice(0, 10),
      'MM-DD-YYYY'
    );

    if (
      slot2StartDate.format('MM-DD-YYYY') ==
      moment(slot1StartDate).add(1, 'days').format('MM-DD-YYYY')
    ) {
      this.daySlot = true;
    } else {
      this.daySlot = false;
    }

    //create day or week slotsArr
    if (this.daySlot) {
      const daydiff = lastSlotStartDate.diff(slot1StartDate, 'days');
      this.slotsArr = [];
      for (var i = 0; i <= daydiff; i++) {
        this.slotsArr.push([moment(slot1StartDate).add(i, 'days')]);
      }
    } else {
      this.ballotAll['data'][0].arr.forEach((v) => {
        const sd = moment(v.startDate, 'MM-DD-YYYY HH:mm:ss');
        const startOfWeek = sd.clone().startOf('isoWeek');
        const endOfWeek = sd.clone().endOf('isoWeek');
        this.slotsArr.push([startOfWeek, endOfWeek]);
      });
    }

    let ballotSlot;
    let slotSortArr;

    this.ballotAll['data'].forEach((val, index) => {
      ballotSlot = this.ballotAll['data'][index].weekRangeSlot;
      let keyy = Object.keys(ballotSlot);

      slotSortArr = [];
      keyy.forEach((v) => {
        slotSortArr.push([v, ballotSlot[v]]);
      });

      let team = {
        OpsGrp: val.opsGroup.value,
        OpsGrpId: val.opsGroup.opsId,
        OpsTeam: 'Tier 1',
        OpsTeamId: '',
        balanceQuota: val.opsGroup.balanceQuota,
        applied: val.opsGroup.applied,
        quota: val.opsGroup.quota,
        successful: val.opsGroup.successful,
        unassignBalanace: val.opsGroup.unassignBalanace,
        slots: {},
      };

      const slotGrpVal = [];
      let pk = [];
      slotSortArr.forEach((vv) => {
        if (vv[0].endsWith('A')) {
          let ck = vv[0].slice(0, -1);
          pk.push([ck, vv[1].value]);
        }
      });

      pk.sort((a, b) => {
        let aa = parseInt(a[0]);
        let bb = parseInt(b[0]);
        if (aa < bb) {
          return -1;
        }
        if (aa > bb) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      pk.forEach((sv) => slotGrpVal.push(sv[1]));

      team['slots'] = slotGrpVal;
      this.allOps.push(team);

      if (val.opsTeam) {
        val.opsTeam.forEach((vv, opsTeamIndex) => {
          team = {
            OpsGrp: '',
            OpsGrpId: val.opsGroup.opsId,
            OpsTeam: vv.name,
            OpsTeamId: vv._id,
            balanceQuota: vv.balanceQuota,
            applied: vv.applied,
            quota: vv.quota,
            successful: vv.successful,
            unassignBalanace: vv.unassignBalanace,
            slots: {},
          };

          const slotTeam = [];
          let pTeamm = [];

          this.slotsArr.forEach((vs, slotsIndex) => {
            let matchKeyValue = slotSortArr.find((slotValue) => {
              if (
                slotValue[0] ==
                'OG' + slotsIndex + 'OT' + opsTeamIndex.toString()
              ) {
                return true;
              }
            });
            pTeamm.push(matchKeyValue);
          });

          pTeamm.forEach((sv) => slotTeam.push(sv[1].value));

          team['slots'] = slotTeam;
          this.allOps.push(team);
          this.allOpsTemp = this.allOps;
        });
      }
    });

  }

  conductAuto(item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Conduct this?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {


        const conductdata = {};
        conductdata['ballotId'] = this.ballot._id;
        conductdata['userFrom'] = this.ballot.userFrom;
        conductdata['data'] = this.ballot.eligibleStaffsForAuto;
        this.loader = true;
        this.conductAutoAssignDisabled=true;
        this.leaveService
          .conductAutoBallot(conductdata)
          .subscribe((res: { success: boolean; message: string }) => {
            if (res.success) {
              this.loader = false;
              this.toastService.success(res.message, 'Successfull');
             this.back.emit(1);
            } else {
              this.loader = false;
              this.toastService.error(res.message, 'Failed');
            }
          });
      }
    });
  }

  reBallot() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Re-Ballot this',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.loader=true;
        this.leaveService
          .getReballot(this.ballotList[0]._id)
          .subscribe((res: { data }) => {
            this.loader=false;
            this.ballotRound.emit(res.data);
          },(err)=>{
            this.loader=false;
          });
      }
    });
  }

  cancelManageBallot() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to cancel this?',
      footer:
        '<p class="text-danger">Please Note: This action is irreversible</p>',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.loader = true;
        const ballLen = this.ballotList.length;
        this.leaveService
          .updateCancelBallot(this.ballotList[ballLen - 1]._id)
          .subscribe(
            (res: { status; message }) => {
              if (res.status) {
                this.toastService.success(res.message, 'Successfull');
              } else {
                this.toastService.error(res.message, 'Failed');
              }
              this.back.emit(1);
            },
            () => {
              this.toastService.error('Somthing went wrong', 'Error');
            }
          ).add(() => {
            this.loader = false;
          });
      }
    });
  }

  exportBallotData() {
    this.loader=true;
    let id = this.ballotResultForm.value.ballotName === 'allRound'? this.parentBallotId:this.ballotResultForm.value.ballotName;
    this.leaveService.getExportBallotData(id).subscribe((res) => {
      var a = document.createElement('a');
      a.href = 'data:attachment/csv,' + encodeURI(res['csv']);
      a.target = '_blank';
      a.download = "Ballots.csv";
      document.body.appendChild(a);
      a.click();
      this.loader=false;
      this.toastService.success(
        "success",
        "Ballots exported succesfully"
      );
    }, () => {
      this.loader=false;
      this.toastService.error('Something went wrong', 'Failed');
    });

  }

  selectTime() {
    this.time = [];
    for (let hour = 0; hour < 24; hour++) {
      this.time.push(moment({ hour }).format('HH:mm'));
      this.time.push(moment({ hour, minute: 15 }).format('HH:mm'));
      this.time.push(moment({ hour, minute: 30 }).format('HH:mm'));
      this.time.push(moment({ hour, minute: 45 }).format('HH:mm'));
    }
  }

  filterDetailView() {
    if (!this.searchQuery.trim()) {
      this.detailView = this.detailsViewTemp;
      return;
    }
    const searchQueryLowerCase = this.searchQuery.toLowerCase();
    this.detailView = this.detailsViewTemp.filter(item =>
        item.user.name.toLowerCase().includes(searchQueryLowerCase) ||
        item.user.staffId.toLowerCase().includes(searchQueryLowerCase)
      );
  }
}
