import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectDropdownComponent } from './multi-select/muli-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterPipe } from './pipes/filter.pipe';
import { SingleSelectDropdownComponent } from './single-select/single-select.component';
import { ButtonsComponent } from './buttons/buttons.component';
import { FieldBuilderComponent } from './dynamic-fields/field-builder/field-builder';
import { CheckBoxComponent } from './dynamic-fields/fields/checkbox';
import { DropDownComponent } from './dynamic-fields/fields/dropdown';
// import { FileComponent } from './dynamic-fields/fields/file';
import { RadioComponent } from './dynamic-fields/fields/radio';
import { TextBoxComponent } from './dynamic-fields/fields/text-box';

import { SklitonLoadingTableComponent } from './skliton-loading-table/skliton-loading-table.component';
import { TableTdLoop } from './skliton-loading-table/loop.directive';
import { SafeHtmlPipe } from './pipes/safe.pipe';
import { FileComponent } from './dynamic-fields/fields/file';
import { DynamicFormBuilderComponent } from './dynamic-fields/dynamic-form.component';
import { TextAreaComponent } from './dynamic-fields/fields/text-area';
import { DynamicQuestionBuilderComponent } from './dynamic-questions/dynamic-questions';

import { QuestionBuilder } from './dynamic-questions/field-builder/question-builder';
import { DateTimePickerComponent } from './angular-date-time-picker/date-time-picker.html/date-time-picker.html.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CheckBoxsComponent } from './dynamic-questions/fields/checkboxs';
import { DropDownsComponent } from './dynamic-questions/fields/dropdowns';
import { FilesComponent } from './dynamic-questions/fields/files';
import { RadiosComponent } from './dynamic-questions/fields/radios';
import { TextAreasComponent } from './dynamic-questions/fields/text-areas';
import { TextBoxsComponent } from './dynamic-questions/fields/text-boxs';
import { MaterialModule } from './angular.material';
import { SortableHeader } from './directive/sortable.directive';
import { AssignUsersComponent } from './assign-users/assign-users.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BootstrapAlertComponent } from './bootstrap-alert/bootstrap-alert.component';
import { EventPublishstatusPipe } from './pipes/event-publishstatus.pipe';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { LoadingComponent } from './loading/loading.component';
import { NumberOnlyDirective } from './directive/numberOnly.directive';

// import { NgSelectModule } from '@ng-select/ng-seng servlect';

@NgModule({
  declarations: [
    SortableHeader,
    MultiSelectDropdownComponent,
    FilterPipe,
    SingleSelectDropdownComponent,
    ButtonsComponent,
    FieldBuilderComponent,
    TextBoxComponent,
    DropDownComponent,
    CheckBoxComponent,
    FileComponent,
    TextBoxsComponent,
    DropDownsComponent,
    CheckBoxsComponent,
    FilesComponent,
    TableTdLoop,
    RadioComponent,
    RadiosComponent,
    SafeHtmlPipe,
    TextAreaComponent,
    TextAreasComponent,
    DateTimePickerComponent,
    DynamicFormBuilderComponent,
    SklitonLoadingTableComponent,
    DynamicQuestionBuilderComponent,
    QuestionBuilder,
    AssignUsersComponent,
    BootstrapAlertComponent,
    EventPublishstatusPipe,
    LoadingComponent,
    NumberOnlyDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    FontAwesomeModule,
    MaterialModule,
    NgSelectModule,
    AngularMultiSelectModule,
  ],
  exports: [
    CommonModule,
    SortableHeader,
    MultiSelectDropdownComponent,
    SingleSelectDropdownComponent,
    ButtonsComponent,
    SklitonLoadingTableComponent,
    DynamicFormBuilderComponent,
    TableTdLoop,
    SafeHtmlPipe,
    MaterialModule,
    DateTimePickerComponent,
    DynamicQuestionBuilderComponent,
    AssignUsersComponent,
    BootstrapAlertComponent,
    FilterPipe,
    EventPublishstatusPipe,
    AngularMultiSelectModule,
    LoadingComponent,
    NumberOnlyDirective
  ],
  providers: [],
})
export class SharedModule {}
