<div class="card m-3" style="min-height: 80vh">
  <div class="card-header flexi-header pt-4">
    <ng-container
      *ngFor="let item of questionTypes"
      style="display: flex; flex-direction: row; justify-content: space-between"
    >
      <div [ngSwitch]="item.type">
        <h3 *ngIf="value.type === item.type">{{ item.name }}</h3>
        <button
          *ngIf="value.type === item.type"
          type="button"
          class="btn btn-large close-icon"
          aria-label="Close"
          (click)="cancelPopup()"
        >
          <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i>
        </button>
      </div>
    </ng-container>
  </div>

  <div class="card-body" [ngSwitch]="value.type">
    <ngb-alert
      class="mt-4"
      #selfClosingAlert
      *ngIf="successMessage"
      type="danger"
      (closed)="successMessage = ''"
      >{{ successMessage }}
    </ngb-alert>
    <div *ngSwitchCase="2">
      <form [formGroup]="createQuestionForm">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold" for="exampleInputEmail1"
                >Question Title<span class="field-mandatory">*</span></label
              >
              <div [froalaEditor]="options" formControlName="question"></div>
            </div>
          </div>
        </div>
        <div class="row d-flex px-4 pb-4">
          <button class="btn btn-info btn-sm" (click)="openIconPicker()">
            Choose Icon
          </button>
          <div class="pl-4" *ngIf="selectedIcon">
            <b>Selected Icon:</b>
            <i
              [class]="'fa ' + selectedIcon"
              style="
                width: 40px;
                height: 40px;
                font-size: x-large;
                padding-left: 10px;
              "
            ></i>
            {{ selectedIcon }}
          </div>
          <div *ngIf="selectedIcon">
            <div class="image-with-delete-icon" style="width: 100px; height: 50px">
              <i
                class="fa fa-close"
                title="Delete"
                for="4"
                (click)="onDeleteIcon(index)"
              ></i>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="value.questionslength > 0">
          <div class="col-md-12">
            <div class="form-group pr-0">
              <input
                type="checkbox"
                (change)="getConditionalQuestions()"
                class="checkbox checkbox-danger"
                formControlName="isConditional"
              />
              Make this question conditional, based on another selected question
              and answer
            </div>
          </div>
        </div>
      </form>
      <form [formGroup]="conditionalQuestionForm">
        <div
          class="row mt-2"
          *ngIf="createQuestionForm.get('isConditional').value"
        >
          <div class="col-md-12">
            <label class="font-weight-bold">Question Title</label>
            <ng-select
              (change)="getContionalAnswers($event)"
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="questionId"
            >
              <ng-option
                [value]="item"
                *ngFor="let item of conditionalQuestions"
              >
                <p [innerHTML]="item.question | safeHtml"></p>
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-12 mt-2" *ngIf="conditionalAnswers.length != 0">
            <label class="font-weight-bold">Answers</label>
            <ng-select
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="optionId"
            >
              <ng-option [value]="item" *ngFor="let item of conditionalAnswers">
                {{ item.value }}
              </ng-option>
            </ng-select>
            <div class="row mt-2">
              <div class="col-md-6">
                <button
                  class="btn btn-add"
                  (click)="
                    addConditionalQuestion(this.conditionalQuestionForm.value)
                  "
                >
                  {{ isEdit ? "Update" : "Add" }}
                </button>
                <button
                  class="btn btn-clear"
                  (click)="onClearConditionalQuestion()"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <form [formGroup]="createQuestionForm">
        <table class="table table-borderless mt-3">
          <tbody>
            <tr
              formArrayName="conditionalQuestions"
              *ngFor="
                let field of conditionalQuestionsControl.controls;
                let index = index
              "
            >
              <div class="questions-container mt-2">
                <td>
                  <div>
                    Question -
                    <p
                      class="questions"
                      innerHtml="{{ field.get('question').value }}"
                    ></p>
                  </div>
                  <span
                    class="text-items"
                    style="word-break: break-all"
                    [formGroupName]="index"
                  >
                    <p>Answer - {{ field.get("option").value }}</p>
                  </span>
                </td>
                <td class="cancel-button">
                  <img
                    class="ml-1"
                    alt=""
                    (click)="conditionQuestionDelete(index)"
                    src="./assets/images/cancel.png"
                    width="20px"
                    height="20px"
                    crossorigin="anonymous"
                  />
                </td>
              </div>
            </tr>
          </tbody>
        </table>
        <div class="row mt-2">
          <label class="font-weight-bold col-md-3"
            >Option View<span class="field-mandatory">*</span></label
          >
          <div class="form-group col-md-3 pr-0">
            <input type="radio" [value]="0" formControlName="optionsView" />
            <label for="std-row" class="radio-inline ml-1">Vertical </label>
          </div>
          <div class="form-group col-md-3 pr-0">
            <input type="radio" [value]="1" formControlName="optionsView" />
            <label for="custom-row" class="radio-inline ml-1">Horizontal</label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="d-flex justify-content-between">
              <div class="form-group">
                <label for="" class="font-weight-bold"
                  >Answer Options<span class="field-mandatory">*</span></label
                >
              </div>
              <button
                class="btn p-2 m-1 btn-add pull-right"
                (click)="addOptions()"
              >
                Add
              </button>
            </div>
          </div>
          <div class="table">
            <table class="table">
              <thead>
                <tr>
                  <th>ANSWER CHOICES<span class="field-mandatory">*</span></th>
                  <th>CORRECT ANSWER</th>
                  <th>IMAGE</th>
                  <th>&ensp;</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  formArrayName="options"
                  *ngFor="
                    let field of OptionControl.controls;
                    let index = index
                  "
                >
                  <ng-container [formGroupName]="index">
                    <td class="w-50">
                      <input
                        type="text"
                        class="form-control"
                        formControlName="value"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="m-2 w-50"
                        (change)="onClickCorrectAnswer($event, index)"
                        [checked]="field.get('correctAns').value ? true : ''"
                        formControlName="correctAns"
                      />
                    </td>
                    <td>
                      <div
                        class="custom-file mt-0 pt-4 pb-4"
                        style="width: 67px"
                        *ngIf="!field.get('imageSrc').value"
                      >
                        <input
                          type="file"
                          style="
                            position: absolute;
                            z-index: 9;
                            visibility: visible;
                            left: 0;
                            top: 0;
                            text-align: center;
                            width: 80%;
                            opacity: 0;
                          "
                          (change)="BannerUpload($event, index)"
                          required
                          id="b"
                        />
                        <label class="custom-file-label" for="b">
                          Upload Image
                        </label>
                      </div>
                      <div *ngIf="field.get('imageSrc').value">
                        <div class="image-with-delete-icon width-180">
                          <i
                            class="fa fa-close"
                            title="Delete"
                            for="4"
                            (click)="onDeleteImage(index)"
                          ></i>
                          <img
                            [src]="field.get('imageSrc').value"
                            width="200px"
                            height="200px"
                            id="4"
                            alt="Image Preview"
                            crossorigin="anonymous"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        *ngIf="OptionControl.controls.length >= 2"
                        class="m-3"
                      >
                        <img
                          (click)="onDeleteOptions(index)"
                          src="./assets/images/cancel.png"
                          width="20px"
                          height="20px"
                          crossorigin="anonymous"
                        />
                      </div>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group pr-0">
              <p class="font-weight-bold" for="exampleInputEmail1">
                Advanced Options
              </p>
              <input
                type="checkbox"
                class="checkbox checkbox-danger"
                formControlName="editable"
              />
              Post Answer Explanation
            </div>
          </div>
          <div class="col-md-4">
            <label class="font-weight-bold">Order</label>
            <select
              class="dropdown-select w-50 form-control"
              formControlName="indexNum"
            >
              <option [value]="null" disabled>Select Order</option>

              <option *ngFor="let cnt of order" [ngValue]="cnt">
                {{ cnt }}
              </option>
            </select>
          </div>
        </div>
        <div class="row" *ngIf="createQuestionForm.get('editable').value">
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold" for="exampleInputEmail1"
                >Question Title</label
              >
              <div [froalaEditor]="options" formControlName="explanation"></div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-4">
            <button class="btn btn-clear" (click)="cancelPopup()">
              Cancel
            </button>
            <button
              class="btn btn-submit"
              [disabled]="isLoading"
              (click)="onCreateQuestion()"
            >
              <i
                class="fa"
                [ngClass]="{ 'fa-spin fa-spinner': isLoading, '': !isLoading }"
              ></i>
              {{ isEdit ? "Update" : "Add" }}
            </button>
          </div>
        </div>
      </form>
    </div>

    <div *ngSwitchCase="5">
      <form [formGroup]="createQuestionForm">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold" for="exampleInputEmail1"
                >Question Title<span class="field-mandatory">*</span></label
              >
              <div [froalaEditor]="options" formControlName="question"></div>
            </div>
          </div>
        </div>
        <div class="row d-flex px-4 pb-4">
          <button class="btn btn-info" (click)="openIconPicker()">
            Choose Icon
          </button>
          <div class="pl-4" *ngIf="selectedIcon">
            <b>Selected Icon:</b>
            <i
              [class]="'fa ' + selectedIcon"
              style="
                width: 40px;
                height: 40px;
                font-size: x-large;
                padding-left: 10px;
              "
            ></i>
            {{ selectedIcon }}
          </div>
          <div *ngIf="selectedIcon">
            <div class="image-with-delete-icon" style="width: 100px; height: 50px">
              <i
                class="fa fa-close"
                title="Delete"
                for="4"
                (click)="onDeleteIcon(index)"
              ></i>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="value.questionslength > 0">
          <div class="col-md-12">
            <div class="form-group pr-0">
              <input
                type="checkbox"
                (change)="getConditionalQuestions()"
                class="checkbox checkbox-danger"
                formControlName="isConditional"
              />
              Make this question conditional, based on another selected question
              and answer
            </div>
          </div>
        </div>
      </form>
      <form [formGroup]="conditionalQuestionForm">
        <div
          class="row mt-2"
          *ngIf="createQuestionForm.get('isConditional').value"
        >
          <div class="col-md-12">
            <label class="font-weight-bold">Question Title</label>
            <ng-select
              (change)="getContionalAnswers($event)"
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="questionId"
            >
              <ng-option
                [value]="item"
                *ngFor="let item of conditionalQuestions"
              >
                <p [innerHTML]="item.question | safeHtml"></p>
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-12" *ngIf="conditionalAnswers.length != 0">
            <label class="font-weight-bold">Answers</label>
            <ng-select
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="optionId"
            >
              <ng-option [value]="item" *ngFor="let item of conditionalAnswers">
                {{ item.value }}
              </ng-option>
            </ng-select>

            <div class="row mt-2">
              <div class="col-md-6">
                <button
                  class="btn btn-add"
                  (click)="
                    addConditionalQuestion(this.conditionalQuestionForm.value)
                  "
                >
                  {{ isEdit ? "Update" : "Add" }}
                </button>
                <button
                  class="btn btn-clear"
                  (click)="onClearConditionalQuestion()"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <form [formGroup]="createQuestionForm">
        <table class="table table-borderless mt-3">
          <tbody>
            <tr
              formArrayName="conditionalQuestions"
              *ngFor="
                let field of conditionalQuestionsControl.controls;
                let index = index
              "
            >
              <div class="questions-container">
                <td>
                  <div>
                    Question -
                    <p
                      class="questions"
                      innerHtml="{{ field.get('question').value }}"
                    ></p>
                  </div>
                  <span
                    class="text-items"
                    style="word-break: break-all"
                    [formGroupName]="index"
                  >
                    <p>Answer - {{ field.get("option").value }}</p>
                  </span>
                </td>
                <td class="cancel-button">
                  <img
                    class="ml-1"
                    alt=""
                    (click)="conditionQuestionDelete(index)"
                    src="./assets/images/cancel.png"
                    width="20px"
                    height="20px"
                    crossorigin="anonymous"
                  />
                </td>
              </div>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <label class="font-weight-bold col-md-3"
            >Option View<span class="field-mandatory">*</span></label
          >

          <div class="form-group col-md-3 pr-0">
            <input type="radio" [value]="0" formControlName="optionsView" />
            <label for="std-row" class="radio-inline ml-1">Vertical </label>
          </div>
          <div class="form-group col-md-3 pr-0">
            <input type="radio" [value]="1" formControlName="optionsView" />
            <label for="custom-row" class="radio-inline ml-1">Horizontal</label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="d-flex justify-content-between">
              <div class="form-group">
                <label for="" class="font-weight-bold"
                  >Answer Options<span class="field-mandatory">*</span></label
                >
              </div>
              <button class="btn p-2 btn-add pull-right" (click)="addOptions()">
                Add
              </button>
            </div>
          </div>

          <div class="table">
            <table class="table">
              <thead>
                <tr>
                  <th>ANSWER CHOICES<span class="field-mandatory">*</span></th>
                  <th>CORRECT ANSWER</th>
                  <th>Image</th>
                  <th>&ensp;</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  formArrayName="options"
                  *ngFor="
                    let field of OptionControl.controls;
                    let index = index
                  "
                >
                  <ng-container [formGroupName]="index">
                    <td class="w-50">
                      <input
                        type="text"
                        class="form-control"
                        formControlName="value"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="m-2"
                        [checked]="field.get('correctAns').value ? true : null"
                        formControlName="correctAns"
                      />
                    </td>

                    <td>
                      <div
                        class="custom-file mt-0 pt-4 pb-4"
                        style="width: 67px"
                        *ngIf="!field.get('imageSrc').value"
                      >
                        <input
                          type="file"
                          style="
                            position: absolute;
                            z-index: 9;
                            visibility: visible;
                            left: 0;
                            top: 0;
                            width: 80%;
                            opacity: 0;
                          "
                          (change)="BannerUpload($event, index)"
                          required
                          id="b"
                        />
                        <label class="custom-file-label" for="b">
                          Upload Image
                        </label>
                      </div>
                      <div *ngIf="field.get('imageSrc').value">
                        <div class="image-with-delete-icon width-180">
                          <i
                            class="fa fa-close"
                            title="Delete"
                            for="4"
                            (click)="onDeleteImage(index)"
                          ></i>
                          <img
                            [src]="field.get('imageSrc').value"
                            width="300px"
                            height="300px"
                            id="4"
                            alt="Image Preview"
                            crossorigin="anonymous"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        *ngIf="OptionControl.controls.length >= 2"
                        class="m-3"
                      >
                        <img
                          (click)="onDeleteOptions(index)"
                          src="./assets/images/cancel.png"
                          width="20px"
                          height="20px"
                          crossorigin="anonymous"
                        />
                      </div>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group pr-0">
              <p class="font-weight-bold" for="exampleInputEmail1">
                Advanced Options
              </p>
              <input type="checkbox" formControlName="editable" /> Post Answer
              Explanation
            </div>
          </div>
          <div class="col-md-4">
            <label class="font-weight-bold">Order</label>
            <select
              class="dropdown-select w-50 form-control"
              formControlName="indexNum"
            >
              <option [value]="null" disabled>Select Order</option>

              <option *ngFor="let cnt of order" [ngValue]="cnt">
                {{ cnt }}
              </option>
            </select>
          </div>
        </div>
        <div class="row" *ngIf="createQuestionForm.get('editable').value">
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold" for="exampleInputEmail1"
                >Question Title</label
              >
              <div [froalaEditor]="options" formControlName="explanation"></div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-4">
            <button class="btn btn-clear" (click)="cancelPopup()">
              Cancel
            </button>
            <button
              class="btn btn-submit"
              [disabled]="isLoading"
              (click)="onCreateQuestion()"
            >
              <i
                class="fa"
                [ngClass]="{ 'fa-spin fa-spinner': isLoading, '': !isLoading }"
              ></i>
              {{ isEdit ? "Update" : "Add" }}
            </button>
          </div>
        </div>
      </form>
    </div>
    <div *ngSwitchCase="4">
      <form [formGroup]="createQuestionForm">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold" for="exampleInputEmail1"
                >Question Title<span class="field-mandatory">*</span></label
              >
              <div [froalaEditor]="options" formControlName="question"></div>
            </div>
          </div>
        </div>
        <div class="row d-flex px-4 pb-4">
          <button class="btn btn-info" (click)="openIconPicker()">
            Choose Icon
          </button>
          <div class="pl-4" *ngIf="selectedIcon">
            <b>Selected Icon:</b>
            <i
              [class]="'fa ' + selectedIcon"
              style="
                width: 40px;
                height: 40px;
                font-size: x-large;
                padding-left: 10px;
              "
            ></i>
            {{ selectedIcon }}
          </div>
          <div *ngIf="selectedIcon">
            <div class="image-with-delete-icon" style="width: 100px; height: 50px">
              <i
                class="fa fa-close"
                title="Delete"
                for="4"
                (click)="onDeleteIcon(index)"
              ></i>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="value.questionslength > 0">
          <div class="col-md-12">
            <div class="form-group pr-0">
              <input
                type="checkbox"
                (change)="getConditionalQuestions()"
                class="checkbox checkbox-danger"
                formControlName="isConditional"
              />
              Make this question conditional, based on another selected question
              and answer
            </div>
          </div>
        </div>
      </form>
      <form [formGroup]="conditionalQuestionForm">
        <div
          class="row mt-2"
          *ngIf="createQuestionForm.get('isConditional').value"
        >
          <div class="col-md-12">
            <label class="font-weight-bold">Question Title</label>
            <ng-select
              (change)="getContionalAnswers($event)"
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="questionId"
            >
              <ng-option
                [value]="item"
                *ngFor="let item of conditionalQuestions"
              >
                <p [innerHTML]="item.question | safeHtml"></p>
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-12" *ngIf="conditionalAnswers.length != 0">
            <label class="font-weight-bold">Answers</label>
            <ng-select
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="optionId"
            >
              <ng-option [value]="item" *ngFor="let item of conditionalAnswers">
                {{ item.value }}
              </ng-option>
            </ng-select>
            <div class="row mt-2">
              <div class="col-md-6">
                <button
                  class="btn btn-add"
                  (click)="
                    addConditionalQuestion(this.conditionalQuestionForm.value)
                  "
                >
                  Add
                </button>
                <button
                  class="btn btn-clear"
                  (click)="onClearConditionalQuestion()"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <form [formGroup]="createQuestionForm">
        <table class="table table-borderless mt-3">
          <tbody>
            <tr
              formArrayName="conditionalQuestions"
              *ngFor="
                let field of conditionalQuestionsControl.controls;
                let index = index
              "
            >
              <div class="questions-container">
                <td>
                  <div>
                    Question -
                    <p
                      class="questions"
                      innerHtml="{{ field.get('question').value }}"
                    ></p>
                  </div>
                  <span
                    class="text-items"
                    style="word-break: break-all"
                    [formGroupName]="index"
                  >
                    <p>Answer - {{ field.get("option").value }}</p>
                  </span>
                </td>
                <td class="cancel-button">
                  <img
                    class="ml-1"
                    alt=""
                    (click)="conditionQuestionDelete(index)"
                    src="./assets/images/cancel.png"
                    width="20px"
                    height="20px"
                    crossorigin="anonymous"
                  />
                </td>
              </div>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <label class="font-weight-bold col-md-3"
            >Option View<span class="field-mandatory">*</span></label
          >

          <div class="form-group col-md-3 pr-0">
            <input type="radio" [value]="0" formControlName="optionsView" />
            <label for="std-row" class="radio-inline ml-1">Vertical </label>
          </div>
          <div class="form-group col-md-3 pr-0">
            <input type="radio" [value]="1" formControlName="optionsView" />
            <label for="custom-row" class="radio-inline ml-1">Horizontal</label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="d-flex justify-content-between">
              <div class="form-group">
                <label for="" class="font-weight-bold"
                  >Answer Options<span class="field-mandatory">*</span></label
                >
              </div>
              <button class="btn p-2 btn-add pull-right" (click)="addOptions()">
                Add
              </button>
            </div>

            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="exampleCheck1"
                [(ngModel)]="isExtendOptions"
                [ngModelOptions]="{ standalone: true }"
                (click)="extendOptions()"
              />
              <label class="form-check-label mt-1 ml-2" for="exampleCheck1"
                >Extended option details</label
              >
            </div>

            <div class="form-check mt-2 mb-2">
              <input
                type="checkbox"
                class="form-check-input"
                id="exampleCheck1"
                (click)="getMoreThanOne()"
              />
              <label class="form-check-label mt-1 ml-2" for="exampleCheck1"
                >Allow user to choose more than 1 option</label
              >
            </div>
          </div>

          <div *ngIf="moreThanOneOption" class="p-3 form-row">
            <div class="col-12">
              <input
                type="number"
                onKeyPress="if(this.value.length == 1) return false;"
                pattern="/^-?\d+\.?\d*$/"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder=""
              />
            </div>
          </div>

          <div class="table">
            <table class="table">
              <thead>
                <tr>
                  <th class="w-50">
                    ANSWER CHOICES<span class="field-mandatory">*</span>
                  </th>
                  <!-- <th *ngIf="!isExtendOptions">CORRECT ANSWER</th> -->
                  <th>Image</th>
                  <th>&ensp;</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="table-border-bottom"
                  formArrayName="options"
                  *ngFor="
                    let field of OptionControl.controls;
                    let index = index
                  "
                >
                  <td colspan="3">
                    <table class="table">
                      <tbody>
                        <tr>
                          <ng-container [formGroupName]="index">
                            <td class="w-50">
                              <input
                                type="text"
                                class="form-control"
                                formControlName="value"
                              />
                            </td>
                            <td>
                              <div
                                class="custom-file mt-0 pt-4 pb-4"
                                style="width: 67px"
                                *ngIf="!field.get('imageSrc').value"
                              >
                                <input
                                  type="file"
                                  style="
                                    position: absolute;
                                    z-index: 9;
                                    visibility: visible;
                                    left: 0;
                                    top: 0;
                                    width: 80%;
                                    opacity: 0;
                                  "
                                  (change)="BannerUpload($event, index, false)"
                                  required
                                  id="b"
                                />
                                <label class="custom-file-label" for="b">
                                  Upload Image
                                </label>
                              </div>
                              <div *ngIf="field.get('imageSrc').value">
                                <div class="image-with-delete-icon width-180">
                                  <i
                                    class="fa fa-close"
                                    title="Delete"
                                    for="4"
                                    (click)="onDeleteImage(index)"
                                  ></i>
                                  <img
                                    [src]="field.get('imageSrc').value"
                                    width="300px"
                                    height="300px"
                                    id="4"
                                    alt="Image Preview"
                                    crossorigin="anonymous"
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                *ngIf="OptionControl.controls.length >= 2"
                                class="m-3"
                              >
                                <img
                                  (click)="onDeleteOptions(index)"
                                  src="./assets/images/cancel.png"
                                  width="20px"
                                  height="20px"
                                  crossorigin="anonymous"
                                />
                              </div>
                            </td>
                          </ng-container>
                        </tr>
                        <tr *ngIf="isExtendOptions">
                          <ng-container [formGroupName]="index">
                            <td class="w-50">
                              <textarea
                                type="text"
                                rows="3"
                                class="form-control"
                                formControlName="pollingDescription"
                              ></textarea>
                            </td>
                            <td>
                              <div
                                class="custom-file mt-0 pt-4 pb-4"
                                style="width: 67px"
                                *ngIf="
                                  !field.get('pollingDescriptionImageSrc').value
                                "
                              >
                                <input
                                  type="file"
                                  accept="image/png, image/jpeg, image/jpg"
                                  style="
                                    position: absolute;
                                    z-index: 9;
                                    visibility: visible;
                                    left: 0;
                                    top: 0;
                                    width: 80%;
                                    opacity: 0;
                                  "
                                  (change)="BannerUpload($event, index, true)"
                                  required
                                  id="b"
                                />
                                <label class="custom-file-label" for="b">
                                  Upload Image
                                  {{
                                    createQuestionForm.value
                                      .pollingDescriptionImageSrc
                                  }}
                                </label>
                              </div>
                              <div
                                *ngIf="
                                  field.get('pollingDescriptionImageSrc').value
                                "
                              >
                                <div class="image-with-delete-icon width-180">
                                  <i
                                    class="fa fa-close"
                                    title="Delete"
                                    for="4"
                                    (click)="
                                      onDeletePollingDescriptionImage(index)
                                    "
                                  ></i>
                                  <img
                                    [src]="
                                      field.get('pollingDescriptionImageSrc')
                                        .value
                                    "
                                    width="300px"
                                    height="300px"
                                    id="4"
                                    alt="Image Preview"
                                    crossorigin="anonymous"
                                  />
                                </div>
                              </div>
                            </td>
                            <td></td>
                          </ng-container>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="table" *ngIf="[3].includes(value.type)">
            <table class="table">
              <thead>
                <tr>
                  <th>ANSWER CHOICES<span class="field-mandatory">*</span></th>
                  <!-- <th>CORRECT ANSWER</th> -->
                  <th>Image</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  formArrayName="options"
                  *ngFor="
                    let field of OptionControl.controls;
                    let index = index
                  "
                >
                  <ng-container [formGroupName]="index">
                    <td class="w-50">
                      <input
                        type="text"
                        class="form-control"
                        formControlName="value"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="m-2"
                        (change)="onClickCorrectAnswer($event, index)"
                        [checked]="field.get('correctAns').value ? true : null"
                        formControlName="correctAns"
                      />
                    </td>

                    <td>
                      <div
                        class="custom-file mt-0 pt-4 pb-4"
                        style="width: 67px"
                        *ngIf="!field.get('imageSrc').value"
                      >
                        <input
                          type="file"
                          style="
                            position: absolute;
                            z-index: 9;
                            visibility: visible;
                            left: 0;
                            top: 0;
                            width: 80%;
                            opacity: 0;
                          "
                          (change)="BannerUpload($event, index)"
                          required
                          id="b"
                        />
                        <label class="custom-file-label" for="b">
                          Upload Image
                        </label>
                      </div>
                      <div *ngIf="field.get('imageSrc').value">
                        <div class="image-with-delete-icon width-180">
                          <i
                            class="fa fa-close"
                            title="Delete"
                            for="4"
                            (click)="onDeleteImage(index)"
                          ></i>
                          <img
                            [src]="field.get('imageSrc').value"
                            width="300px"
                            height="300px"
                            id="4"
                            alt="Image Preview"
                            crossorigin="anonymous"
                          />
                        </div>
                      </div>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group pr-0">
              <p class="font-weight-bold" for="exampleInputEmail1">
                Advanced Options
              </p>
              <input type="checkbox" formControlName="editable" /> Post Answer
              Explanation
            </div>
          </div>
          <div class="col-md-4">
            <label class="font-weight-bold">Order</label>
            <select
              class="dropdown-select w-50 form-control"
              formControlName="indexNum"
            >
              <option [value]="null" disabled>Select Order</option>

              <option *ngFor="let cnt of order" [ngValue]="cnt">
                {{ cnt }}
              </option>
            </select>
          </div>
        </div>
        <div class="row" *ngIf="createQuestionForm.get('editable').value">
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold" for="exampleInputEmail1"
                >Question Title</label
              >

              <div [froalaEditor]="options" formControlName="explanation"></div>
              <!-- <div
                  *ngIf="submitted && f.description.errors"
                  class="invalid-feedback"
                >
                  <small *ngIf="f.description.errors.required">
                    Role Description is required
                  </small>
                </div> -->
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-4">
            <button class="btn btn-clear" (click)="cancelPopup()">
              Cancel
            </button>
            <button
              class="btn btn-submit"
              [disabled]="isLoading"
              (click)="onCreateQuestion()"
            >
              <i
                class="fa"
                [ngClass]="{ 'fa-spin fa-spinner': isLoading, '': !isLoading }"
              ></i>
              {{ isEdit ? "Update" : "Add" }}
            </button>
          </div>
        </div>
      </form>
    </div>

    <div *ngSwitchCase="3">
      <form [formGroup]="createQuestionForm">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold" for="exampleInputEmail1"
                >Question Title<span class="field-mandatory">*</span></label
              >

              <div [froalaEditor]="options" formControlName="question"></div>

              <!-- <div
            *ngIf="submitted && f.description.errors"
            class="invalid-feedback"
          >
            <small *ngIf="f.description.errors.required">
              Role Description is required
            </small>
          </div> -->
            </div>
          </div>
        </div>
        <div class="row d-flex px-4 pb-4">
          <button class="btn btn-info" (click)="openIconPicker()">
            Choose Icon
          </button>
          <div class="pl-4" *ngIf="selectedIcon">
            <b>Selected Icon:</b>
            <i
              [class]="'fa ' + selectedIcon"
              style="
                width: 40px;
                height: 40px;
                font-size: x-large;
                padding-left: 10px;
              "
            ></i>
            {{ selectedIcon }}
          </div>
          <div *ngIf="selectedIcon">
            <div class="image-with-delete-icon" style="width: 100px; height: 50px">
              <i
                class="fa fa-close"
                title="Delete"
                for="4"
                (click)="onDeleteIcon(index)"
              ></i>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="value.questionslength > 0">
          <div class="col-md-12">
            <div class="form-group pr-0">
              <input
                type="checkbox"
                (change)="getConditionalQuestions()"
                class="checkbox checkbox-danger"
                formControlName="isConditional"
              />
              Make this question conditional, based on another selected question
              and answer
            </div>
          </div>
        </div>
      </form>
      <form [formGroup]="conditionalQuestionForm">
        <div
          class="row mt-2"
          *ngIf="createQuestionForm.get('isConditional').value"
        >
          <div class="col-md-12">
            <label class="font-weight-bold">Question Title</label>
            <ng-select
              (change)="getContionalAnswers($event)"
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="questionId"
            >
              <ng-option
                [value]="item"
                *ngFor="let item of conditionalQuestions"
              >
                <p [innerHTML]="item.question | safeHtml"></p>
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-12" *ngIf="conditionalAnswers.length != 0">
            <label class="font-weight-bold">Answers</label>
            <ng-select
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="optionId"
            >
              <ng-option [value]="item" *ngFor="let item of conditionalAnswers">
                {{ item.value }}
              </ng-option>
            </ng-select>
            <div class="row mt-2">
              <div class="col-md-6">
                <button
                  class="btn btn-add"
                  (click)="
                    addConditionalQuestion(this.conditionalQuestionForm.value)
                  "
                >
                  Add
                </button>
                <button
                  class="btn btn-clear"
                  (click)="onClearConditionalQuestion()"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <form [formGroup]="createQuestionForm">
        <table class="table table-borderless mt-3">
          <tbody>
            <tr
              formArrayName="conditionalQuestions"
              *ngFor="
                let field of conditionalQuestionsControl.controls;
                let index = index
              "
            >
              <div class="questions-container">
                <td>
                  <div>
                    Question -
                    <p
                      class="questions"
                      innerHtml="{{ field.get('question').value }}"
                    ></p>
                  </div>
                  <span
                    class="text-items"
                    style="word-break: break-all"
                    [formGroupName]="index"
                  >
                    <p>Answer - {{ field.get("option").value }}</p>
                  </span>
                </td>
                <td class="cancel-button">
                  <img
                    class="ml-1"
                    alt=""
                    (click)="conditionQuestionDelete(index)"
                    src="./assets/images/cancel.png"
                    width="20px"
                    height="20px"
                    crossorigin="anonymous"
                  />
                </td>
              </div>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <label class="font-weight-bold col-md-3"
            >Option View<span class="field-mandatory">*</span></label
          >

          <div class="form-group col-md-3 pr-0">
            <input type="radio" [value]="0" formControlName="optionsView" />
            <label for="std-row" class="radio-inline ml-1">Vertical </label>
          </div>
          <div class="form-group col-md-3 pr-0">
            <input type="radio" [value]="1" formControlName="optionsView" />
            <label for="custom-row" class="radio-inline ml-1">Horizontal</label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="d-flex justify-content-between">
              <div class="form-group">
                <label for="" class="font-weight-bold"
                  >Answer Options<span class="field-mandatory">*</span></label
                >
              </div>
            </div>
          </div>

          <div class="table">
            <table class="table">
              <thead>
                <tr>
                  <th>ANSWER CHOICES<span class="field-mandatory">*</span></th>
                  <th>CORRECT ANSWER</th>
                  <th>Image</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  formArrayName="options"
                  *ngFor="
                    let field of OptionControl.controls;
                    let index = index
                  "
                >
                  <ng-container [formGroupName]="index">
                    <td class="w-50">
                      <input
                        type="text"
                        class="form-control"
                        formControlName="value"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="m-2"
                        (change)="onClickCorrectAnswer($event, index)"
                        [checked]="field.get('correctAns').value ? true : null"
                        formControlName="correctAns"
                      />
                    </td>

                    <td>
                      <div
                        class="custom-file mt-0 pt-4 pb-4"
                        style="width: 67px"
                        *ngIf="!field.get('imageSrc').value"
                      >
                        <input
                          type="file"
                          style="
                            position: absolute;
                            z-index: 9;
                            visibility: visible;
                            left: 0;
                            top: 0;
                            width: 80%;
                            opacity: 0;
                          "
                          (change)="BannerUpload($event, index)"
                          required
                          id="b"
                        />
                        <label class="custom-file-label" for="b">
                          Upload Image
                        </label>
                      </div>
                      <div *ngIf="field.get('imageSrc').value">
                        <div class="image-with-delete-icon width-180">
                          <i
                            class="fa fa-close"
                            title="Delete"
                            for="4"
                            (click)="onDeleteImage(index)"
                          ></i>
                          <img
                            [src]="field.get('imageSrc').value"
                            width="300px"
                            height="300px"
                            id="4"
                            alt="Image Preview"
                            crossorigin="anonymous"
                          />
                        </div>
                      </div>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group pr-0">
              <p class="font-weight-bold" for="exampleInputEmail1">
                Advanced Options
              </p>
              <input type="checkbox" formControlName="editable" /> Post Answer
              Explanation
            </div>
          </div>
          <div class="col-md-4">
            <label class="font-weight-bold">Order</label>
            <select
              class="dropdown-select w-50 form-control"
              formControlName="indexNum"
            >
              <option [value]="null" disabled>Select Order</option>

              <option *ngFor="let cnt of order" [ngValue]="cnt">
                {{ cnt }}
              </option>
            </select>
          </div>
        </div>
        <div class="row" *ngIf="createQuestionForm.get('editable').value">
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold" for="exampleInputEmail1"
                >Question Title</label
              >
              <div [froalaEditor]="options" formControlName="question"></div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-4">
            <button class="btn btn-clear" (click)="cancelPopup()">
              Cancel
            </button>
            <button
              class="btn btn-submit"
              [disabled]="isLoading"
              (click)="onCreateQuestion()"
            >
              <i
                class="fa"
                [ngClass]="{ 'fa-spin fa-spinner': isLoading, '': !isLoading }"
              ></i>
              {{ isEdit ? "Update" : "Add" }}
            </button>
          </div>
        </div>
      </form>
    </div>

    <div *ngSwitchCase="6">
      <form [formGroup]="createQuestionForm">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold" for="exampleInputEmail1"
                >Question Title<span class="field-mandatory">*</span></label
              >

              <div [froalaEditor]="options" formControlName="question"></div>
            </div>
          </div>
        </div>
        <div class="row d-flex px-4 pb-4">
          <button class="btn btn-info" (click)="openIconPicker()">
            Choose Icon
          </button>
          <div class="pl-4" *ngIf="selectedIcon">
            <b>Selected Icon:</b>
            <i
              [class]="'fa ' + selectedIcon"
              style="
                width: 40px;
                height: 40px;
                font-size: x-large;
                padding-left: 10px;
              "
            ></i>
            {{ selectedIcon }}
          </div>
          <div *ngIf="selectedIcon">
            <div class="image-with-delete-icon" style="width: 100px; height: 50px">
              <i
                class="fa fa-close"
                title="Delete"
                for="4"
                (click)="onDeleteIcon(index)"
              ></i>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="value.questionslength > 0">
          <div class="col-md-12">
            <div class="form-group pr-0">
              <input
                type="checkbox"
                (change)="getConditionalQuestions()"
                class="checkbox checkbox-danger"
                formControlName="isConditional"
              />
              Make this question conditional, based on another selected question
              and answer
            </div>
          </div>
        </div>
      </form>
      <form [formGroup]="conditionalQuestionForm">
        <div
          class="row mt-2"
          *ngIf="createQuestionForm.get('isConditional').value"
        >
          <div class="col-md-12">
            <label class="font-weight-bold">Question Title</label>
            <ng-select
              (change)="getContionalAnswers($event)"
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="questionId"
            >
              <ng-option
                [value]="item"
                *ngFor="let item of conditionalQuestions"
              >
                <p [innerHTML]="item.question | safeHtml"></p>
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-12" *ngIf="conditionalAnswers.length != 0">
            <label class="font-weight-bold">Answers</label>
            <ng-select
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="optionId"
            >
              <ng-option [value]="item" *ngFor="let item of conditionalAnswers">
                {{ item.value }}
              </ng-option>
            </ng-select>

            <div class="row mt-2">
              <div class="col-md-6">
                <button
                  class="btn btn-add"
                  (click)="
                    addConditionalQuestion(this.conditionalQuestionForm.value)
                  "
                >
                  Add
                </button>
                <button
                  class="btn btn-clear"
                  (click)="onClearConditionalQuestion()"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <form [formGroup]="createQuestionForm">
        <table class="table table-borderless mt-3">
          <tbody>
            <tr
              formArrayName="conditionalQuestions"
              *ngFor="
                let field of conditionalQuestionsControl.controls;
                let index = index
              "
            >
              <div class="questions-container">
                <td>
                  <div>
                    Question -
                    <p
                      class="questions"
                      innerHtml="{{ field.get('question').value }}"
                    ></p>
                  </div>
                  <span
                    class="text-items"
                    style="word-break: break-all"
                    [formGroupName]="index"
                  >
                    <p>Answer - {{ field.get("option").value }}</p>
                  </span>
                </td>
                <td class="cancel-button">
                  <img
                    class="ml-1"
                    alt=""
                    (click)="conditionQuestionDelete(index)"
                    src="./assets/images/cancel.png"
                    width="20px"
                    height="20px"
                    crossorigin="anonymous"
                  />
                </td>
              </div>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-4">
            <label class="font-weight-bold">Order</label>
            <select
              class="dropdown-select w-50 form-control"
              formControlName="indexNum"
            >
              <option [value]="null" disabled>Select Order</option>

              <option *ngFor="let cnt of order" [ngValue]="cnt">
                {{ cnt }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-4">
            <button class="btn btn-clear" (click)="cancelPopup()">
              Cancel
            </button>
            <button
              class="btn btn-submit"
              [disabled]="isLoading"
              (click)="onCreateQuestion()"
            >
              <i
                class="fa"
                [ngClass]="{ 'fa-spin fa-spinner': isLoading, '': !isLoading }"
              ></i>
              Add
            </button>
          </div>
        </div>
      </form>
    </div>

    <form [formGroup]="createQuestionForm" *ngSwitchCase="7">
      <div class="row">
        <!---- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3" *ngFor="let link of defaultProfileFields; let index = index">
          <div style="padding: 5px">
            <input type="checkbox" />
            {{ link.name }}
          </div>
        </div>-->
        <div
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3"
          *ngFor="let link of defaultProfileFields; let index = index"
        >
          <div style="padding: 5px">
            <input
              type="checkbox"
              [checked]="link.isSelected"
              [value]="link.name"
              (change)="changeQuickLinkStatus(index, $event.target.value)"
            />
            {{ link.name }}
          </div>
        </div>
      </div>
      <div class="row d-flex px-4 pb-4">
        <button class="btn btn-info" (click)="openIconPicker()">
          Choose Icon
        </button>
        <div class="pl-4" *ngIf="selectedIcon">
          <b>Selected Icon:</b>
          <i
            [class]="'fa ' + selectedIcon"
            style="
              width: 40px;
              height: 40px;
              font-size: x-large;
              padding-left: 10px;
            "
          ></i>
          {{ selectedIcon }}
        </div>
        <div *ngIf="selectedIcon">
          <div class="image-with-delete-icon" style="width: 100px; height: 50px">
            <i
              class="fa fa-close"
              title="Delete"
              for="4"
              (click)="onDeleteIcon(index)"
            ></i>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <label class="font-weight-bold">Order</label>
          <select
            class="dropdown-select w-50 form-control"
            formControlName="indexNum"
          >
            <option [value]="null" disabled>Select Order</option>

            <option *ngFor="let cnt of order" [ngValue]="cnt">
              {{ cnt }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-4">
          <button class="btn btn-clear" (click)="cancelPopup()">Cancel</button>
          <button
            class="btn btn-submit"
            [disabled]="isLoading"
            (click)="onCreateQuestion()"
          >
            <i
              class="fa"
              [ngClass]="{ 'fa-spin fa-spinner': isLoading, '': !isLoading }"
            ></i>
            {{ isEdit ? "Update" : "Add" }}
          </button>
        </div>
      </div>
    </form>
    <form [formGroup]="createQuestionForm" *ngSwitchCase="1">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="font-weight-bold" for="exampleInputEmail1"
              >Question Title<span class="field-mandatory">*</span></label
            >
            <div [froalaEditor]="options" formControlName="question"></div>
          </div>
        </div>
      </div>
      <div class="row d-flex px-4 pb-4">
        <button class="btn btn-info" (click)="openIconPicker()">
          Choose Icon
        </button>
        <div class="pl-4" *ngIf="selectedIcon">
          <b>Selected Icon:</b>
          <i
            [class]="'fa ' + selectedIcon"
            style="
              width: 40px;
              height: 40px;
              font-size: x-large;
              padding-left: 10px;
            "
          ></i>
          {{ selectedIcon }}
        </div>
        <div *ngIf="selectedIcon">
          <div class="image-with-delete-icon" style="width: 100px; height: 50px">
            <i
              class="fa fa-close"
              title="Delete"
              for="4"
              (click)="onDeleteIcon(index)"
            ></i>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="value.questionslength > 0">
        <div class="col-md-12">
          <div class="form-group pr-0">
            <input
              type="checkbox"
              (change)="getConditionalQuestions()"
              class="checkbox checkbox-danger"
              formControlName="isConditional"
            />
            Make this question conditional, based on another selected question
            and answer
          </div>
        </div>
      </div>
      <form [formGroup]="conditionalQuestionForm">
        <div
          class="row mt-2"
          *ngIf="createQuestionForm.get('isConditional').value"
        >
          <div class="col-md-12">
            <label class="font-weight-bold">Question Title</label>
            <ng-select
              (change)="getContionalAnswers($event)"
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="questionId"
            >
              <ng-option
                [value]="item"
                *ngFor="let item of conditionalQuestions"
              >
                <p [innerHTML]="item.question | safeHtml"></p>
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-12" *ngIf="conditionalAnswers.length != 0">
            <label class="font-weight-bold">Answers</label>
            <ng-select
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="optionId"
            >
              <ng-option [value]="item" *ngFor="let item of conditionalAnswers">
                {{ item.value }}
              </ng-option>
            </ng-select>
            <div class="row mt-2">
              <div class="col-md-6">
                <button
                  class="btn btn-add"
                  (click)="
                    addConditionalQuestion(this.conditionalQuestionForm.value)
                  "
                >
                  Add
                </button>
                <button
                  class="btn btn-clear"
                  (click)="onClearConditionalQuestion()"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <form [formGroup]="createQuestionForm">
        <table class="table table-borderless mt-3">
          <tbody>
            <tr
              formArrayName="conditionalQuestions"
              *ngFor="
                let field of conditionalQuestionsControl.controls;
                let index = index
              "
            >
              <div class="questions-container">
                <td>
                  <div>
                    Question -
                    <p
                      class="questions"
                      innerHtml="{{ field.get('question').value }}"
                    ></p>
                  </div>
                  <span
                    class="text-items"
                    style="word-break: break-all"
                    [formGroupName]="index"
                  >
                    <p>Answer - {{ field.get("option").value }}</p>
                  </span>
                </td>
                <td class="cancel-button">
                  <img
                    class="ml-1"
                    alt=""
                    (click)="conditionQuestionDelete(index)"
                    src="./assets/images/cancel.png"
                    width="20px"
                    height="20px"
                    crossorigin="anonymous"
                  />
                </td>
              </div>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="d-flex justify-content-between">
              <div class="form-group">
                <label for="" class="font-weight-bold"
                  >Answer Options<span class="field-mandatory">*</span></label
                >
              </div>
              <!-- <button class="btn p-2 m-1 btn-add pull-right" (click)="addOptions()">
                Add
              </button> -->
            </div>
          </div>

          <div class="table">
            <table class="table">
              <thead>
                <tr>
                  <th>ANSWER CHOICES<span class="field-mandatory">*</span></th>
                  <th>CORRECT ANSWER</th>
                  <!-- <th>Image</th>
                  <th>&ensp;</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  formArrayName="options"
                  *ngFor="
                    let field of OptionControl.controls;
                    let index = index
                  "
                >
                  <ng-container [formGroupName]="index">
                    <td class="w-50">
                      <input
                        type="text"
                        class="form-control"
                        formControlName="value"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="m-2 w-50"
                        (change)="onClickCorrectAnswer($event, index)"
                        [checked]="field.get('correctAns').value ? true : ''"
                        formControlName="correctAns"
                      />
                    </td>

                    <td>
                      <div
                        class="custom-file mt-0 pt-4 pb-4"
                        style="width: 67px"
                        *ngIf="!field.get('imageSrc').value"
                      >
                        <input
                          type="file"
                          accept="image/png, image/jpeg, image/jpg"
                          style="
                            position: absolute;
                            z-index: 9;
                            visibility: visible;
                            left: 0;
                            top: 0;
                            text-align: center;
                            width: 80%;
                            opacity: 0;
                          "
                          (change)="BannerUpload($event, index)"
                          required
                          id="b"
                        />
                        <label class="custom-file-label" for="b">
                          Upload Image
                        </label>
                      </div>
                      <div *ngIf="field.get('imageSrc').value">
                        <div class="image-with-delete-icon width-180">
                          <i
                            class="fa fa-close"
                            title="Delete"
                            for="4"
                            (click)="onDeleteImage(index)"
                          ></i>
                          <img
                            [src]="field.get('imageSrc').value"
                            width="300px"
                            height="300px"
                            id="4"
                            alt="Image Preview"
                            crossorigin="anonymous"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        *ngIf="OptionControl.controls.length >= 2"
                        class="m-3"
                      >
                        <img
                          (click)="onDeleteOptions(index)"
                          src="./assets/images/cancel.png"
                          width="20px"
                          height="20px"
                          crossorigin="anonymous"
                        />
                      </div>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
      <div class="form-group d-flex align-items-center mt-2 position-relative">
        <label class="flex-fill" for="">Text Must be between</label>
        <input
          type="number"
          class="form-control"
          oninput="validity.valid || (value='')"
          style="width: 20% !important; margin: 0 1rem"
          min="0"
          value="1"
          disabled
        />
        <span> and </span>
        <input
          type="number"
          formControlName="freeTextRange"
          min="1"
          max="5000"
          oninput="validity.valid || (value='')"
          class="form-control"
          step="1"
          formControlName="maxlength"
          placeholder="Please enter limit here"
          [ngClass]="{
            'is-invalid':
              submittedForm && createQuestionForm.get('maxlength').errors
          }"
          style="width: 21% !important; margin: 0px 1rem"
        /><span class="field-mandatory required font-weight-bold">*</span>
      </div>
      <div class="form-group d-flex align-items-center mt-2 position-relative">
        <label class="flex-fill" for=""></label>
        <span class="text-danger"
          >*Note: Please enter max character limit <br />(max limit is upto 5000
          characters)</span
        >
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group pr-0">
            <p class="font-weight-bold" for="exampleInputEmail1">
              Advanced Options
            </p>
            <input
              type="checkbox"
              class="checkbox checkbox-danger"
              formControlName="editable"
            />
            Post Answer Explanation
          </div>
        </div>
        <div class="col-md-4">
          <label class="font-weight-bold">Order</label>
          <select
            class="dropdown-select w-50 form-control"
            formControlName="indexNum"
          >
            <option [value]="null" disabled>Select Order</option>

            <option *ngFor="let cnt of order" [ngValue]="cnt">
              {{ cnt }}
            </option>
          </select>
        </div>
      </div>
      <div class="row" *ngIf="createQuestionForm.get('editable').value">
        <div class="col-md-12">
          <div class="form-group">
            <label class="font-weight-bold" for="exampleInputEmail1"
              >Question Title</label
            >
            <div [froalaEditor]="options" formControlName="explanation"></div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-4">
          <button class="btn btn-clear" (click)="cancelPopup()">Cancel</button>
          <button
            class="btn btn-submit"
            [disabled]="isLoading"
            (click)="onCreateQuestion()"
          >
            <i
              class="fa"
              [ngClass]="{ 'fa-spin fa-spinner': isLoading, '': !isLoading }"
            ></i>
            {{ isEdit ? "Update" : "Add" }}
          </button>
        </div>
      </div>
    </form>
    <form [formGroup]="createQuestionForm" *ngSwitchCase="8">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="font-weight-bold" for="exampleInputEmail1"
              >Question Title<span class="field-mandatory">*</span></label
            >
            <div [froalaEditor]="options" formControlName="question"></div>
          </div>
        </div>
      </div>
      <div class="row d-flex px-4 pb-4">
        <button class="btn btn-info" (click)="openIconPicker()">
          Choose Icon
        </button>
        <div class="pl-4" *ngIf="selectedIcon">
          <b>Selected Icon:</b>
          <i
            [class]="'fa ' + selectedIcon"
            style="
              width: 40px;
              height: 40px;
              font-size: x-large;
              padding-left: 10px;
            "
          ></i>
          {{ selectedIcon }}
        </div>
        <div *ngIf="selectedIcon">
          <div class="image-with-delete-icon" style="width: 100px; height: 50px">
            <i
              class="fa fa-close"
              title="Delete"
              for="4"
              (click)="onDeleteIcon(index)"
            ></i>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="value.questionslength > 0">
        <div class="col-md-12">
          <div class="form-group pr-0">
            <input
              type="checkbox"
              (change)="getConditionalQuestions()"
              class="checkbox checkbox-danger"
              formControlName="isConditional"
            />
            Make this question conditional, based on another selected question
            and answer
          </div>
        </div>
      </div>
      <form [formGroup]="conditionalQuestionForm">
        <div
          class="row mt-2"
          *ngIf="createQuestionForm.get('isConditional').value"
        >
          <div class="col-md-12">
            <label class="font-weight-bold">Question Title</label>
            <ng-select
              (change)="getContionalAnswers($event)"
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="questionId"
            >
              <ng-option
                [value]="item"
                *ngFor="let item of conditionalQuestions"
              >
                <p [innerHTML]="item.question | safeHtml"></p>
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-12" *ngIf="conditionalAnswers.length != 0">
            <label class="font-weight-bold">Answers</label>
            <ng-select
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="optionId"
            >
              <ng-option [value]="item" *ngFor="let item of conditionalAnswers">
                {{ item.value }}
              </ng-option>
            </ng-select>
            <div class="row mt-2">
              <div class="col-md-6">
                <button
                  class="btn btn-add"
                  (click)="
                    addConditionalQuestion(this.conditionalQuestionForm.value)
                  "
                >
                  Add
                </button>
                <button
                  class="btn btn-clear"
                  (click)="onClearConditionalQuestion()"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <form [formGroup]="createQuestionForm">
        <table class="table table-borderless mt-3">
          <tbody>
            <tr
              formArrayName="conditionalQuestions"
              *ngFor="
                let field of conditionalQuestionsControl.controls;
                let index = index
              "
            >
              <div class="questions-container">
                <td>
                  <div>
                    Question -
                    <p
                      class="questions"
                      innerHtml="{{ field.get('question').value }}"
                    ></p>
                  </div>
                  <span
                    class="text-items"
                    style="word-break: break-all"
                    [formGroupName]="index"
                  >
                    <p>Answer - {{ field.get("option").value }}</p>
                  </span>
                </td>
                <td class="cancel-button">
                  <img
                    class="ml-1"
                    alt=""
                    (click)="conditionQuestionDelete(index)"
                    src="./assets/images/cancel.png"
                    width="20px"
                    height="20px"
                    crossorigin="anonymous"
                  />
                </td>
              </div>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="d-flex justify-content-between">
              <div class="form-group">
                <label for="" class="font-weight-bold"
                  >Answer Options<span class="field-mandatory">*</span></label
                >
              </div>
              <!-- <button class="btn p-2 m-1 btn-add pull-right" (click)="addOptions()">
                Add
              </button> -->
            </div>
          </div>

          <div class="table">
            <table class="table">
              <thead>
                <tr>
                  <th>ANSWER CHOICES<span class="field-mandatory">*</span></th>
                  <th>CORRECT ANSWER</th>
                  <!-- <th>Image</th>
                  <th>&ensp;</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  formArrayName="options"
                  *ngFor="
                    let field of OptionControl.controls;
                    let index = index
                  "
                >
                  <ng-container [formGroupName]="index">
                    <td class="w-50">
                      <input
                        type="text"
                        class="form-control"
                        formControlName="value"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="m-2 w-50"
                        (change)="onClickCorrectAnswer($event, index)"
                        [checked]="field.get('correctAns').value ? true : ''"
                        formControlName="correctAns"
                      />
                    </td>

                    <td>
                      <div
                        class="custom-file mt-0 pt-4 pb-4"
                        style="width: 67px"
                        *ngIf="!field.get('imageSrc').value"
                      >
                        <input
                          type="file"
                          accept="image/png, image/jpeg, image/jpg"
                          style="
                            position: absolute;
                            z-index: 9;
                            visibility: visible;
                            left: 0;
                            top: 0;
                            text-align: center;
                            width: 80%;
                            opacity: 0;
                          "
                          (change)="BannerUpload($event, index)"
                          required
                          id="b"
                        />
                        <label class="custom-file-label" for="b">
                          Upload Image
                        </label>
                      </div>
                      <div *ngIf="field.get('imageSrc').value">
                        <div class="image-with-delete-icon width-180">
                          <i
                            class="fa fa-close"
                            title="Delete"
                            for="4"
                            (click)="onDeleteImage(index)"
                          ></i>
                          <img
                            [src]="field.get('imageSrc').value"
                            width="300px"
                            height="300px"
                            id="4"
                            alt="Image Preview"
                            crossorigin="anonymous"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        *ngIf="OptionControl.controls.length >= 2"
                        class="m-3"
                      >
                        <img
                          (click)="onDeleteOptions(index)"
                          src="./assets/images/cancel.png"
                          width="20px"
                          height="20px"
                          crossorigin="anonymous"
                        />
                      </div>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
      <div class="form-group d-flex align-items-center mt-2 position-relative">
        <label class="flex-fill" for="">Text Must be between</label>
        <input
          type="number"
          ng-model="longTextMin"
          oninput="validity.valid || (value='')"
          id="inputlongTextMin"
          class="form-control"
          style="width: 20% !important; margin: 0 1rem"
          value="1"
          disabled
        />
        <span> and </span>
        <input
          type="number"
          [min]="1"
          [max]="50"
          oninput="validity.valid || (value='')"
          formControlName="maxlength"
          [ngClass]="{
            'is-invalid':
              submittedForm && createQuestionForm.get('maxlength').errors
          }"
          class="form-control"
          step="1"
          placeholder="Please enter limit here"
          style="width: 21% !important; margin: 0px 1rem"
        />
        <span class="field-mandatory required font-weight-bold">*</span>
      </div>
      <div class="form-group d-flex align-items-center mt-2 position-relative">
        <label class="flex-fill" for=""></label>
        <span class="text-danger"
          >*Note: Please enter max character limit <br />(max limit is upto 50
          characters)</span
        >
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group pr-0">
            <input
              type="checkbox"
              class="checkbox checkbox-danger"
              formControlName="isNumeric"
            />
            Is Numeric
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group pr-0">
            <p class="font-weight-bold" for="exampleInputEmail1">
              Advanced Options
            </p>
            <input
              type="checkbox"
              class="checkbox checkbox-danger"
              formControlName="editable"
            />
            Post Answer Explanation
          </div>
        </div>
        <div class="col-md-4">
          <label class="font-weight-bold">Order</label>
          <select
            class="dropdown-select w-50 form-control"
            formControlName="indexNum"
          >
            <option [value]="null" disabled>Select Order</option>

            <option *ngFor="let cnt of order" [ngValue]="cnt">
              {{ cnt }}
            </option>
          </select>
        </div>
      </div>
      <div class="row" *ngIf="createQuestionForm.get('editable').value">
        <div class="col-md-12">
          <div class="form-group">
            <label class="font-weight-bold" for="exampleInputEmail1"
              >Question Title</label
            >
            <div [froalaEditor]="options" formControlName="explanation"></div>
          </div>
        </div>
      </div>
      <div class="row mt-2" *ngIf="createQuestionForm.get('editable').value">
        <div class="col-md-12">
          <div class="form-group">
            <label class="font-weight-bold" for="exampleInputEmail1"
              >Explanation</label
            >
            <div [froalaEditor]="options" formControlName="explanation"></div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-4">
          <button class="btn btn-clear" (click)="cancelPopup()">Cancel</button>
          <button
            class="btn btn-submit"
            [disabled]="isLoading"
            (click)="onCreateQuestion()"
          >
            <i
              class="fa"
              [ngClass]="{ 'fa-spin fa-spinner': isLoading, '': !isLoading }"
            ></i>
            {{ isEdit ? "Update" : "Add" }}
          </button>
        </div>
      </div>
    </form>
    <form [formGroup]="createQuestionForm" *ngSwitchCase="9">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="font-weight-bold" for="exampleInputEmail1"
              >Question Title<span class="field-mandatory">*</span></label
            >
            <div [froalaEditor]="options" formControlName="question"></div>
          </div>
        </div>
      </div>
      <div class="row d-flex px-4 pb-4">
        <button class="btn btn-info" (click)="openIconPicker()">
          Choose Icon
        </button>
        <div class="pl-4" *ngIf="selectedIcon">
          <b>Selected Icon:</b>
          <i
            [class]="'fa ' + selectedIcon"
            style="
              width: 40px;
              height: 40px;
              font-size: x-large;
              padding-left: 10px;
            "
          ></i>
          {{ selectedIcon }}
        </div>
        <div *ngIf="selectedIcon">
          <div class="image-with-delete-icon" style="width: 100px; height: 50px">
            <i
              class="fa fa-close"
              title="Delete"
              for="4"
              (click)="onDeleteIcon(index)"
            ></i>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="value.questionslength > 0">
        <div class="col-md-12">
          <div class="form-group pr-0">
            <input
              type="checkbox"
              (change)="getConditionalQuestions()"
              class="checkbox checkbox-danger"
              formControlName="isConditional"
            />
            Make this question conditional, based on another selected question
            and answer
          </div>
        </div>
      </div>
      <form [formGroup]="conditionalQuestionForm">
        <div
          class="row mt-2"
          *ngIf="createQuestionForm.get('isConditional').value"
        >
          <div class="col-md-12">
            <label class="font-weight-bold">Question Title</label>
            <ng-select
              (change)="getContionalAnswers($event)"
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="questionId"
            >
              <ng-option
                [value]="item"
                *ngFor="let item of conditionalQuestions"
              >
                <p [innerHTML]="item.question | safeHtml"></p>
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-12" *ngIf="conditionalAnswers.length != 0">
            <label class="font-weight-bold">Answers</label>
            <ng-select
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="optionId"
            >
              <ng-option [value]="item" *ngFor="let item of conditionalAnswers">
                {{ item.value }}
              </ng-option>
            </ng-select>
            <div class="row mt-2">
              <div class="col-md-6">
                <button
                  class="btn btn-add"
                  (click)="
                    addConditionalQuestion(this.conditionalQuestionForm.value)
                  "
                >
                  Add
                </button>
                <button
                  class="btn btn-clear"
                  (click)="onClearConditionalQuestion()"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <form [formGroup]="createQuestionForm">
        <table class="table table-borderless mt-3">
          <tbody>
            <tr
              formArrayName="conditionalQuestions"
              *ngFor="
                let field of conditionalQuestionsControl.controls;
                let index = index
              "
            >
              <div class="questions-container">
                <td>
                  <div>
                    Question -
                    <p
                      class="questions"
                      innerHtml="{{ field.get('question').value }}"
                    ></p>
                  </div>
                  <span
                    class="text-items"
                    style="word-break: break-all"
                    [formGroupName]="index"
                  >
                    <p>Answer - {{ field.get("option").value }}</p>
                  </span>
                </td>
                <td class="cancel-button">
                  <img
                    class="ml-1"
                    alt=""
                    (click)="conditionQuestionDelete(index)"
                    src="./assets/images/cancel.png"
                    width="20px"
                    height="20px"
                    crossorigin="anonymous"
                  />
                </td>
              </div>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="d-flex justify-content-between">
              <div class="form-group">
                <label for="" class="font-weight-bold"
                  >Answer Options<span class="field-mandatory">*</span></label
                >
              </div>
              <!-- <button class="btn p-2 m-1 btn-add pull-right" (click)="addOptions()">
                Add
              </button> -->
            </div>
          </div>

          <div class="table">
            <table class="table">
              <thead>
                <tr>
                  <th>ANSWER CHOICES<span class="field-mandatory">*</span></th>
                  <th>CORRECT ANSWER</th>
                  <!-- <th>Image</th>
                  <th>&ensp;</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  formArrayName="options"
                  *ngFor="
                    let field of OptionControl.controls;
                    let index = index
                  "
                >
                  <ng-container [formGroupName]="index">
                    <td class="w-50">
                      <input
                        type="text"
                        class="form-control"
                        formControlName="value"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="m-2 w-50"
                        (change)="onClickCorrectAnswer($event, index)"
                        [checked]="field.get('correctAns').value ? true : ''"
                        formControlName="correctAns"
                      />
                    </td>

                    <td>
                      <div
                        class="custom-file mt-0 pt-4 pb-4"
                        style="width: 67px"
                        *ngIf="!field.get('imageSrc').value"
                      >
                        <input
                          type="file"
                          accept="image/png, image/jpeg, image/jpg"
                          style="
                            position: absolute;
                            z-index: 9;
                            visibility: visible;
                            left: 0;
                            top: 0;
                            text-align: center;
                            width: 80%;
                            opacity: 0;
                          "
                          (change)="BannerUpload($event, index)"
                          required
                          id="b"
                        />
                        <label class="custom-file-label" for="b">
                          Upload Image
                        </label>
                      </div>
                      <div *ngIf="field.get('imageSrc').value">
                        <div class="image-with-delete-icon width-180">
                          <i
                            class="fa fa-close"
                            title="Delete"
                            for="4"
                            (click)="onDeleteImage(index)"
                          ></i>
                          <img
                            [src]="field.get('imageSrc').value"
                            width="300px"
                            height="300px"
                            id="4"
                            alt="Image Preview"
                            crossorigin="anonymous"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        *ngIf="OptionControl.controls.length >= 2"
                        class="m-3"
                      >
                        <img
                          (click)="onDeleteOptions(index)"
                          src="./assets/images/cancel.png"
                          width="20px"
                          height="20px"
                          crossorigin="anonymous"
                        />
                      </div>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group pr-0">
              <p class="font-weight-bold" for="exampleInputEmail1">
                Advanced Options
              </p>
              <input
                type="checkbox"
                class="checkbox checkbox-danger"
                formControlName="editable"
              />
              Post Answer Explanation
            </div>
          </div>
          <div class="col-md-4">
            <label class="font-weight-bold">Order</label>
            <select
              class="dropdown-select w-50 form-control"
              formControlName="indexNum"
            >
              <option [value]="null" disabled>Select Order</option>

              <option *ngFor="let cnt of order" [ngValue]="cnt">
                {{ cnt }}
              </option>
            </select>
          </div>
        </div>
        <div class="row" *ngIf="createQuestionForm.get('editable').value">
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold" for="exampleInputEmail1"
                >Question Title</label
              >

              <div [froalaEditor]="options" formControlName="explanation"></div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-4">
            <button class="btn btn-clear" (click)="cancelPopup()">
              Cancel
            </button>
            <button
              class="btn btn-submit"
              [disabled]="isLoading"
              (click)="onCreateQuestion()"
            >
              <i
                class="fa"
                [ngClass]="{ 'fa-spin fa-spinner': isLoading, '': !isLoading }"
              ></i>
              {{ isEdit ? "Update" : "Add" }}
            </button>
          </div>
        </div>
      </form>
      <div class="row" *ngIf="createQuestionForm.get('editable').value">
        <div class="col-md-12">
          <div class="form-group">
            <label class="font-weight-bold" for="exampleInputEmail1"
              >Question Title</label
            >

            <div [froalaEditor]="options" formControlName="explanation"></div>
          </div>
        </div>
      </div>
    </form>
    <form [formGroup]="createQuestionForm" *ngSwitchCase="10">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="font-weight-bold" for="exampleInputEmail1"
              >Question Title<span class="field-mandatory">*</span></label
            >
            <div [froalaEditor]="options" formControlName="question"></div>
          </div>
        </div>
      </div>
      <div class="row d-flex px-4 pb-4">
        <button class="btn btn-info" (click)="openIconPicker()">
          Choose Icon
        </button>
        <div class="pl-4" *ngIf="selectedIcon">
          <b>Selected Icon:</b>
          <i
            [class]="'fa ' + selectedIcon"
            style="
              width: 40px;
              height: 40px;
              font-size: x-large;
              padding-left: 10px;
            "
          ></i>
          {{ selectedIcon }}
        </div>
        <div *ngIf="selectedIcon">
          <div class="image-with-delete-icon" style="width: 100px; height: 50px">
            <i
              class="fa fa-close"
              title="Delete"
              for="4"
              (click)="onDeleteIcon(index)"
            ></i>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="value.questionslength > 0">
        <div class="col-md-12">
          <div class="form-group pr-0">
            <input
              type="checkbox"
              (change)="getConditionalQuestions()"
              class="checkbox checkbox-danger"
              formControlName="isConditional"
            />
            Make this question conditional, based on another selected question
            and answer
          </div>
        </div>
      </div>
      <form [formGroup]="conditionalQuestionForm">
        <div
          class="row mt-2"
          *ngIf="createQuestionForm.get('isConditional').value"
        >
          <div class="col-md-12">
            <label class="font-weight-bold">Question Title</label>
            <ng-select
              (change)="getContionalAnswers($event)"
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="questionId"
            >
              <ng-option
                [value]="item"
                *ngFor="let item of conditionalQuestions"
              >
                <p [innerHTML]="item.question | safeHtml"></p>
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-12" *ngIf="conditionalAnswers.length != 0">
            <label class="font-weight-bold">Answers</label>
            <ng-select
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="optionId"
            >
              <ng-option [value]="item" *ngFor="let item of conditionalAnswers">
                {{ item.value }}
              </ng-option>
            </ng-select>
            <div class="row mt-2">
              <div class="col-md-6">
                <button
                  class="btn btn-add"
                  (click)="
                    addConditionalQuestion(this.conditionalQuestionForm.value)
                  "
                >
                  Add
                </button>
                <button
                  class="btn btn-clear"
                  (click)="onClearConditionalQuestion()"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <form [formGroup]="createQuestionForm">
        <table class="table table-borderless mt-3">
          <tbody>
            <tr
              formArrayName="conditionalQuestions"
              *ngFor="
                let field of conditionalQuestionsControl.controls;
                let index = index
              "
            >
              <div class="questions-container">
                <td>
                  <div>
                    Question -
                    <p
                      class="questions"
                      innerHtml="{{ field.get('question').value }}"
                    ></p>
                  </div>
                  <span
                    class="text-items"
                    style="word-break: break-all"
                    [formGroupName]="index"
                  >
                    <p>Answer - {{ field.get("option").value }}</p>
                  </span>
                </td>
                <td class="cancel-button">
                  <img
                    class="ml-1"
                    alt=""
                    (click)="conditionQuestionDelete(index)"
                    src="./assets/images/cancel.png"
                    width="20px"
                    height="20px"
                    crossorigin="anonymous"
                  />
                </td>
              </div>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="d-flex">
            <div class="col-8">
              <div class="form-group">
                <p class="font-weight-bold">
                  Answer Options<span class="field-mandatory">*</span>
                </p>
                <div class="col mt-1">
                  <input
                    type="checkbox"
                    [value]="1"
                    name="date"
                    [checked]="dateTimeChecked.date === 1"
                    (change)="setDateTime($event.target.value)"
                  />
                  Date
                </div>
                <div class="col mt-1">
                  <input
                    type="checkbox"
                    [value]="2"
                    name="time"
                    [checked]="dateTimeChecked.time === 2"
                    (change)="setDateTime($event.target.value)"
                  />
                  Time
                </div>
                <div class="col mt-1">
                  <input type="checkbox" formControlName="editable" /> Post
                  Answer Explanation
                </div>
              </div>
            </div>
            <div class="col-4">
              <label class="font-weight-bold">Order</label>
              <select
                class="dropdown-select form-control"
                formControlName="indexNum"
              >
                <option [value]="null" disabled>Select Order</option>

                <option *ngFor="let cnt of order" [ngValue]="cnt">
                  {{ cnt }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="createQuestionForm.get('editable').value">
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold" for="exampleInputEmail1"
                >Question Title</label
              >
              <div [froalaEditor]="options" formControlName="explanation"></div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-4">
            <button class="btn btn-clear" (click)="cancelPopup()">
              Cancel
            </button>
            <button
              class="btn btn-submit"
              [disabled]="isLoading"
              (click)="onCreateQuestion()"
            >
              <i
                class="fa"
                [ngClass]="{ 'fa-spin fa-spinner': isLoading, '': !isLoading }"
              ></i>
              {{ isEdit ? "Update" : "Add" }}
            </button>
          </div>
        </div>
      </form>
    </form>
    <form [formGroup]="createQuestionForm" *ngSwitchCase="11">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="font-weight-bold" for="exampleInputEmail1"
              >Question Title<span class="field-mandatory">*</span></label
            >
            <div [froalaEditor]="options" formControlName="question"></div>
          </div>
        </div>
      </div>
      <div class="row d-flex px-4 pb-4">
        <button class="btn btn-info" (click)="openIconPicker()">
          Choose Icon
        </button>
        <div class="pl-4" *ngIf="selectedIcon">
          <b>Selected Icon:</b>
          <i
            [class]="'fa ' + selectedIcon"
            style="
              width: 40px;
              height: 40px;
              font-size: x-large;
              padding-left: 10px;
            "
          ></i>
          {{ selectedIcon }}
        </div>
        <div *ngIf="selectedIcon">
          <div class="image-with-delete-icon" style="width: 100px; height: 50px">
            <i
              class="fa fa-close"
              title="Delete"
              for="4"
              (click)="onDeleteIcon(index)"
            ></i>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="value.questionslength > 0">
        <div class="col-md-12">
          <div class="form-group pr-0">
            <input
              type="checkbox"
              (change)="getConditionalQuestions()"
              class="checkbox checkbox-danger"
              formControlName="isConditional"
            />
            Make this question conditional, based on another selected question
            and answer
          </div>
        </div>
      </div>
      <div class="row mt-2 mb-4">
        <div class="form-group pr-0 col-md-6">
          <input
            type="checkbox"
            (change)="setIsDynamic($event)"
            class="checkbox checkbox-danger"
            formControlName="isDynamicData"
          />
          Is Dynamic Data Required
        </div>
        <div
          class="col-md-6"
          *ngIf="!!createQuestionForm.get('isDynamicData').value"
        >
          <label class="font-weight-bold">Select Service Module</label>
          <select
            class="dropdown-select w-50 form-control"
            formControlName="serviceApi"
          >
            <option [value]="null" disabled>Select Service</option>
            <option *ngFor="let apicall of serviceCall" [ngValue]="apicall">
              {{ apicall }}
            </option>
          </select>
        </div>
      </div>
      <div *ngIf="!createQuestionForm.get('isDynamicData').value">
        <form [formGroup]="conditionalQuestionForm">
          <div
            class="row mt-2"
            *ngIf="createQuestionForm.get('isConditional').value"
          >
            <div class="col-md-12">
              <label class="font-weight-bold">Question Title</label>
              <ng-select
                (change)="getContionalAnswers($event)"
                class="custom"
                bindLabel="name"
                placeholder="Select Questions"
                appendTo="body"
                [multiple]="false"
                [searchable]="true"
                [clearable]="true"
                formControlName="questionId"
              >
                <ng-option
                  [value]="item"
                  *ngFor="let item of conditionalQuestions"
                >
                  <p [innerHTML]="item.question | safeHtml"></p>
                </ng-option>
              </ng-select>
            </div>
            <div class="col-md-12" *ngIf="conditionalAnswers.length != 0">
              <label class="font-weight-bold">Answers</label>
              <ng-select
                class="custom"
                bindLabel="name"
                placeholder="Select Questions"
                appendTo="body"
                [multiple]="false"
                [searchable]="true"
                [clearable]="true"
                formControlName="optionId"
              >
                <ng-option
                  [value]="item"
                  *ngFor="let item of conditionalAnswers"
                >
                  {{ item.value }}
                </ng-option>
              </ng-select>
              <div class="row mt-2">
                <div class="col-md-6">
                  <button
                    class="btn btn-add"
                    (click)="
                      addConditionalQuestion(this.conditionalQuestionForm.value)
                    "
                  >
                    Add
                  </button>
                  <button
                    class="btn btn-clear"
                    (click)="onClearConditionalQuestion()"
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <form [formGroup]="createQuestionForm">
          <table class="table table-borderless mt-3">
            <tbody>
              <tr
                formArrayName="conditionalQuestions"
                *ngFor="
                  let field of conditionalQuestionsControl.controls;
                  let index = index
                "
              >
                <div class="questions-container">
                  <td>
                    <div>
                      Question -
                      <p
                        class="questions"
                        innerHtml="{{ field.get('question').value }}"
                      ></p>
                    </div>
                    <span
                      class="text-items"
                      style="word-break: break-all"
                      [formGroupName]="index"
                    >
                      <p>Answer - {{ field.get("option").value }}</p>
                    </span>
                  </td>
                  <td class="cancel-button">
                    <img
                      class="ml-1"
                      alt=""
                      (click)="conditionQuestionDelete(index)"
                      src="./assets/images/cancel.png"
                      width="20px"
                      height="20px"
                      crossorigin="anonymous"
                    />
                  </td>
                </div>
              </tr>
            </tbody>
          </table>
        </form>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="d-flex justify-content-between">
              <div class="form-group">
                <label for="" class="font-weight-bold"
                  >Answer Options<span class="field-mandatory">*</span></label
                >
              </div>
              <button class="btn p-2 btn-add pull-right" (click)="addOptions()">
                Add
              </button>
            </div>
          </div>

          <div class="table">
            <table class="table">
              <thead>
                <tr>
                  <th>ANSWER CHOICES<span class="field-mandatory">*</span></th>
                  <th>CORRECT ANSWER</th>
                  <th>Image</th>
                  <th>&ensp;</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  formArrayName="options"
                  *ngFor="
                    let field of OptionControl.controls;
                    let index = index
                  "
                >
                  <ng-container [formGroupName]="index">
                    <td class="w-50">
                      <input
                        type="text"
                        class="form-control"
                        formControlName="value"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="m-2"
                        (change)="onClickCorrectAnswer($event, index)"
                        [checked]="field.get('correctAns').value ? true : null"
                        formControlName="correctAns"
                      />
                    </td>

                    <td>
                      <div
                        class="custom-file mt-0 pt-4 pb-4"
                        style="width: 67px"
                        *ngIf="!field.get('imageSrc').value"
                      >
                        <input
                          type="file"
                          style="
                            position: absolute;
                            z-index: 9;
                            visibility: visible;
                            left: 0;
                            top: 0;
                            width: 80%;
                            opacity: 0;
                          "
                          (change)="BannerUpload($event, index)"
                          required
                          id="b"
                        />
                        <label class="custom-file-label" for="b">
                          Upload Image
                        </label>
                      </div>
                      <div *ngIf="field.get('imageSrc').value">
                        <div class="image-with-delete-icon width-180">
                          <i
                            class="fa fa-close"
                            title="Delete"
                            for="4"
                            (click)="onDeleteImage(index)"
                          ></i>
                          <img
                            [src]="field.get('imageSrc').value"
                            width="300px"
                            height="300px"
                            id="4"
                            alt="Image Preview"
                            crossorigin="anonymous"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        *ngIf="OptionControl.controls.length >= 2"
                        class="m-3"
                      >
                        <img
                          (click)="onDeleteOptions(index)"
                          src="./assets/images/cancel.png"
                          width="20px"
                          height="20px"
                          crossorigin="anonymous"
                        />
                      </div>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group pr-0">
            <p class="font-weight-bold" for="exampleInputEmail1">
              Advanced Options
            </p>
            <input type="checkbox" formControlName="editable" /> Post Answer
            Explanation
          </div>
        </div>
        <div class="col-md-4">
          <label class="font-weight-bold">Order</label>
          <select
            class="dropdown-select w-50 form-control"
            formControlName="indexNum"
          >
            <option [value]="null" disabled>Select Order</option>

            <option *ngFor="let cnt of order" [ngValue]="cnt">
              {{ cnt }}
            </option>
          </select>
        </div>
      </div>
      <div class="row" *ngIf="createQuestionForm.get('editable').value">
        <div class="col-md-12">
          <div class="form-group">
            <label class="font-weight-bold" for="exampleInputEmail1"
              >Question Title</label
            >
            <div [froalaEditor]="options" formControlName="explanation"></div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-4">
          <button class="btn btn-clear" (click)="cancelPopup()">Cancel</button>
          <button
            class="btn btn-submit"
            [disabled]="isLoading"
            (click)="onCreateQuestion()"
          >
            <i
              class="fa"
              [ngClass]="{ 'fa-spin fa-spinner': isLoading, '': !isLoading }"
            ></i>
            {{ isEdit ? "Update" : "Add" }}
          </button>
        </div>
      </div>
    </form>
    <form [formGroup]="createQuestionForm" *ngSwitchCase="12">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="font-weight-bold" for="exampleInputEmail1"
              >Question Title<span class="field-mandatory">*</span></label
            >
            <div [froalaEditor]="options" formControlName="question"></div>
          </div>
        </div>
      </div>
      <div class="row d-flex px-4 pb-4">
        <button class="btn btn-info" (click)="openIconPicker()">
          Choose Icon
        </button>
        <div class="pl-4" *ngIf="selectedIcon">
          <b>Selected Icon:</b>
          <i
            [class]="'fa ' + selectedIcon"
            style="
              width: 40px;
              height: 40px;
              font-size: x-large;
              padding-left: 10px;
            "
          ></i>
          {{ selectedIcon }}
        </div>
        <div *ngIf="selectedIcon">
          <div class="image-with-delete-icon" style="width: 100px; height: 50px">
            <i
              class="fa fa-close"
              title="Delete"
              for="4"
              (click)="onDeleteIcon(index)"
            ></i>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3"
          *ngFor="let link of attachMents; let index = index"
        >
          <div style="padding: 5px">
            <input
              type="checkbox"
              [value]="link.value"
              [checked]="link.selected"
              (change)="onAttachments(index, $event.target.value)"
            />
            {{ link.value }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group pr-0">
            <p class="font-weight-bold" for="exampleInputEmail1">
              Advanced Options
            </p>
            <input type="checkbox" formControlName="editable" /> Post Answer
            Explanation
          </div>
        </div>
        <div class="col-md-4">
          <label class="font-weight-bold">Order</label>
          <select
            class="dropdown-select w-50 form-control"
            formControlName="indexNum"
          >
            <option [value]="null" disabled>Select Order</option>

            <option *ngFor="let cnt of order" [ngValue]="cnt">
              {{ cnt }}
            </option>
          </select>
        </div>
      </div>
      <div class="row" *ngIf="createQuestionForm.get('editable').value">
        <div class="col-md-12">
          <div class="form-group">
            <label class="font-weight-bold" for="exampleInputEmail1"
              >Question Title</label
            >
            <div [froalaEditor]="options" formControlName="explanation"></div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-4">
          <button class="btn btn-clear" (click)="cancelPopup()">Cancel</button>
          <button
            class="btn btn-submit"
            [disabled]="isLoading"
            (click)="onCreateQuestion()"
          >
            <i
              class="fa"
              [ngClass]="{ 'fa-spin fa-spinner': isLoading, '': !isLoading }"
            ></i>
            {{ isEdit ? "Update" : "Add" }}
          </button>
        </div>
      </div>
    </form>
    <form [formGroup]="createQuestionForm" *ngSwitchCase="13">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="font-weight-bold" for="exampleInputEmail1"
              >Question Title<span class="field-mandatory">*</span></label
            >
            <div [froalaEditor]="options" formControlName="question"></div>
          </div>
        </div>
      </div>
      <div class="row d-flex px-4 pb-4">
        <button class="btn btn-info" (click)="openIconPicker()">
          Choose Icon
        </button>
        <div class="pl-4" *ngIf="selectedIcon">
          <b>Selected Icon:</b>
          <i
            [class]="'fa ' + selectedIcon"
            style="
              width: 40px;
              height: 40px;
              font-size: x-large;
              padding-left: 10px;
            "
          ></i>
          {{ selectedIcon }}
        </div>
        <div *ngIf="selectedIcon">
          <div class="image-with-delete-icon" style="width: 100px; height: 50px">
            <i
              class="fa fa-close"
              title="Delete"
              for="4"
              (click)="onDeleteIcon(index)"
            ></i>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="value.questionslength > 0">
        <div class="col-md-12">
          <div class="form-group pr-0">
            <input
              type="checkbox"
              (change)="getConditionalQuestions()"
              class="checkbox checkbox-danger"
              formControlName="isConditional"
            />
            Make this question conditional, based on another selected question
            and answer
          </div>
        </div>
      </div>
      <div>
        <form [formGroup]="conditionalQuestionForm">
          <div
            class="row mt-2"
            *ngIf="createQuestionForm.get('isConditional').value"
          >
            <div class="col-md-12">
              <label class="font-weight-bold">Question Title</label>
              <ng-select
                (change)="getContionalAnswers($event)"
                class="custom"
                bindLabel="name"
                placeholder="Select Questions"
                appendTo="body"
                [multiple]="false"
                [searchable]="true"
                [clearable]="true"
                formControlName="questionId"
              >
                <ng-option
                  [value]="item"
                  *ngFor="let item of conditionalQuestions"
                >
                  <p [innerHTML]="item.question | safeHtml"></p>
                </ng-option>
              </ng-select>
            </div>
            <div class="col-md-12" *ngIf="conditionalAnswers.length != 0">
              <label class="font-weight-bold">Answers</label>
              <ng-select
                class="custom"
                bindLabel="name"
                placeholder="Select Questions"
                appendTo="body"
                [multiple]="false"
                [searchable]="true"
                [clearable]="true"
                formControlName="optionId"
              >
                <ng-option
                  [value]="item"
                  *ngFor="let item of conditionalAnswers"
                >
                  {{ item.value }}
                </ng-option>
              </ng-select>
              <div class="row mt-2">
                <div class="col-md-6">
                  <button
                    class="btn btn-add"
                    (click)="
                      addConditionalQuestion(this.conditionalQuestionForm.value)
                    "
                  >
                    Add
                  </button>
                  <button
                    class="btn btn-clear"
                    (click)="onClearConditionalQuestion()"
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <form [formGroup]="createQuestionForm">
          <table class="table table-borderless mt-3">
            <tbody>
              <tr
                formArrayName="conditionalQuestions"
                *ngFor="
                  let field of conditionalQuestionsControl.controls;
                  let index = index
                "
              >
                <div class="questions-container">
                  <td>
                    <div>
                      Question -
                      <p
                        class="questions"
                        innerHtml="{{ field.get('question').value }}"
                      ></p>
                    </div>
                    <span
                      class="text-items"
                      style="word-break: break-all"
                      [formGroupName]="index"
                    >
                      <p>Answer - {{ field.get("option").value }}</p>
                    </span>
                  </td>
                  <td class="cancel-button">
                    <img
                      class="ml-1"
                      alt=""
                      (click)="conditionQuestionDelete(index)"
                      src="./assets/images/cancel.png"
                      width="20px"
                      height="20px"
                      crossorigin="anonymous"
                    />
                  </td>
                </div>
              </tr>
            </tbody>
          </table>
        </form>
        <div class="row">
          <div class="col-md-12">
            <label class="font-weight-bold"
              >No of Stars<span class="field-mandatory">*</span></label
            >
            <select
              class="dropdown-select w-50 form-control"
              formControlName="value"
            >
              <option [value]="null" disabled>Select</option>

              <option *ngFor="let cnt of noOfStars" [ngValue]="cnt">
                {{ cnt }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group pr-0">
              <p class="font-weight-bold" for="exampleInputEmail1">
                Advanced Options
              </p>
              <input type="checkbox" formControlName="editable" /> Post Answer
              Explanation
            </div>
          </div>
          <div class="col-md-4">
            <label class="font-weight-bold">Order</label>
            <select
              class="dropdown-select w-50 form-control"
              formControlName="indexNum"
            >
              <option [value]="null" disabled>Select Order</option>

              <option *ngFor="let cnt of order" [ngValue]="cnt">
                {{ cnt }}
              </option>
            </select>
          </div>
        </div>
        <div class="row" *ngIf="createQuestionForm.get('editable').value">
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold" for="exampleInputEmail1"
                >Question Title</label
              >
              <div [froalaEditor]="options" formControlName="explanation"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-4">
          <button class="btn btn-clear" (click)="cancelPopup()">Cancel</button>
          <button
            class="btn btn-submit"
            [disabled]="isLoading"
            (click)="onCreateQuestion()"
          >
            <i
              class="fa"
              [ngClass]="{ 'fa-spin fa-spinner': isLoading, '': !isLoading }"
            ></i>
            {{ isEdit ? "Update" : "Add" }}
          </button>
        </div>
      </div>
    </form>
    <form [formGroup]="createQuestionForm" *ngSwitchCase="14">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="font-weight-bold" for="exampleInputEmail1"
              >Question Title<span class="field-mandatory">*</span></label
            >
            <div [froalaEditor]="options" formControlName="question"></div>
          </div>
        </div>
      </div>
      <div class="row d-flex px-4 pb-4">
        <button class="btn btn-info" (click)="openIconPicker()">
          Choose Icon
        </button>
        <div class="pl-4" *ngIf="selectedIcon">
          <b>Selected Icon:</b>
          <i
            [class]="'fa ' + selectedIcon"
            style="
              width: 40px;
              height: 40px;
              font-size: x-large;
              padding-left: 10px;
            "
          ></i>
          {{ selectedIcon }}
        </div>
        <div *ngIf="selectedIcon">
          <div class="image-with-delete-icon" style="width: 100px; height: 50px">
            <i
              class="fa fa-close"
              title="Delete"
              for="4"
              (click)="onDeleteIcon(index)"
            ></i>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="value.questionslength > 0">
        <div class="col-md-12">
          <div class="form-group pr-0">
            <input
              type="checkbox"
              (change)="getConditionalQuestions()"
              class="checkbox checkbox-danger"
              formControlName="isConditional"
            />
            Make this question conditional, based on another selected question
            and answer
          </div>
        </div>
      </div>
      <form [formGroup]="conditionalQuestionForm">
        <div
          class="row mt-2"
          *ngIf="createQuestionForm.get('isConditional').value"
        >
          <div class="col-md-12">
            <label class="font-weight-bold">Question Title</label>
            <ng-select
              (change)="getContionalAnswers($event)"
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="questionId"
            >
              <ng-option
                [value]="item"
                *ngFor="let item of conditionalQuestions"
              >
                <p [innerHTML]="item.question | safeHtml"></p>
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-12" *ngIf="conditionalAnswers.length != 0">
            <label class="font-weight-bold">Answers</label>
            <ng-select
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="optionId"
            >
              <ng-option [value]="item" *ngFor="let item of conditionalAnswers">
                {{ item.value }}
              </ng-option>
            </ng-select>
            <div class="row mt-2">
              <div class="col-md-6">
                <button
                  class="btn btn-add"
                  (click)="
                    addConditionalQuestion(this.conditionalQuestionForm.value)
                  "
                >
                  Add
                </button>
                <button
                  class="btn btn-clear"
                  (click)="onClearConditionalQuestion()"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <form [formGroup]="createQuestionForm">
        <table class="table table-borderless mt-3">
          <tbody>
            <tr
              formArrayName="conditionalQuestions"
              *ngFor="
                let field of conditionalQuestionsControl.controls;
                let index = index
              "
            >
              <div class="questions-container">
                <td>
                  <div>
                    Question -
                    <p
                      class="questions"
                      innerHtml="{{ field.get('question').value }}"
                    ></p>
                  </div>
                  <span
                    class="text-items"
                    style="word-break: break-all"
                    [formGroupName]="index"
                  >
                    <p>Answer - {{ field.get("option").value }}</p>
                  </span>
                </td>
                <td class="cancel-button">
                  <img
                    alt=""
                    (click)="conditionQuestionDelete(index)"
                    src="./assets/images/cancel.png"
                    width="20px"
                    height="20px"
                    crossorigin="anonymous"
                  />
                </td>
              </div>
            </tr>
          </tbody>
        </table>
      </form>
      <div>
        <assign-users
          [isAdminView]="false"
          [userDetails]="userDetails"
          (onSubmitAssignUsers)="updatedUserDetails($event)"
        >
        </assign-users>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group pr-0">
            <p class="font-weight-bold" for="exampleInputEmail1">
              Advanced Options
            </p>
            <input type="checkbox" formControlName="editable" /> Post Answer
            Explanation
          </div>
        </div>
        <div class="col-md-4">
          <label class="font-weight-bold">Order</label>
          <select
            class="dropdown-select w-50 form-control"
            formControlName="indexNum"
          >
            <option [value]="null" disabled>Select Order</option>

            <option *ngFor="let cnt of order" [ngValue]="cnt">
              {{ cnt }}
            </option>
          </select>
        </div>
      </div>
      <div class="row" *ngIf="createQuestionForm.get('editable').value">
        <div class="col-md-12">
          <div class="form-group">
            <label class="font-weight-bold" for="exampleInputEmail1"
              >Question Title</label
            >

            <div [froalaEditor]="options" formControlName="explanation"></div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-4">
          <button class="btn btn-clear" (click)="cancelPopup()">Cancel</button>
          <button
            class="btn btn-submit"
            [disabled]="isLoading"
            (click)="onCreateQuestion()"
          >
            <i
              class="fa"
              [ngClass]="{ 'fa-spin fa-spinner': isLoading, '': !isLoading }"
            ></i>
            {{ isEdit ? "Update" : "Add" }}
          </button>
        </div>
      </div>
    </form>
    <form [formGroup]="createQuestionForm" *ngSwitchCase="15">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="font-weight-bold" for="exampleInputEmail1"
              >Question Title<span class="field-mandatory">*</span></label
            >
            <div [froalaEditor]="options" formControlName="question"></div>
          </div>
        </div>
      </div>
      <div class="row d-flex px-4 pb-4">
        <button class="btn btn-info" (click)="openIconPicker()">
          Choose Icon
        </button>
        <div class="pl-4" *ngIf="selectedIcon">
          <b>Selected Icon:</b>
          <i
            [class]="'fa ' + selectedIcon"
            style="
              width: 40px;
              height: 40px;
              font-size: x-large;
              padding-left: 10px;
            "
          ></i>
          {{ selectedIcon }}
        </div>
        <div *ngIf="selectedIcon">
          <div class="image-with-delete-icon" style="width: 100px; height: 50px">
            <i
              class="fa fa-close"
              title="Delete"
              for="4"
              (click)="onDeleteIcon(index)"
            ></i>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="value.questionslength > 0">
        <div class="col-md-12">
          <div class="form-group pr-0">
            <input
              type="checkbox"
              (change)="getConditionalQuestions()"
              class="checkbox checkbox-danger"
              formControlName="isConditional"
            />
            Make this question conditional, based on another selected question
            and answer
          </div>
        </div>
      </div>
      <form [formGroup]="conditionalQuestionForm">
        <div
          class="row mt-2"
          *ngIf="createQuestionForm.get('isConditional').value"
        >
          <div class="col-md-12">
            <label class="font-weight-bold">Question Title</label>
            <ng-select
              (change)="getContionalAnswers($event)"
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="questionId"
            >
              <ng-option
                [value]="item"
                *ngFor="let item of conditionalQuestions"
              >
                <p [innerHTML]="item.question | safeHtml"></p>
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-12" *ngIf="conditionalAnswers.length != 0">
            <label class="font-weight-bold">Answers</label>
            <ng-select
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="optionId"
            >
              <ng-option [value]="item" *ngFor="let item of conditionalAnswers">
                {{ item.value }}
              </ng-option>
            </ng-select>
            <div class="row mt-2">
              <div class="col-md-6">
                <button
                  class="btn btn-add"
                  (click)="
                    addConditionalQuestion(this.conditionalQuestionForm.value)
                  "
                >
                  Add
                </button>
                <button
                  class="btn btn-clear"
                  (click)="onClearConditionalQuestion()"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <form [formGroup]="createQuestionForm">
        <table class="table table-borderless mt-3">
          <tbody>
            <tr
              formArrayName="conditionalQuestions"
              *ngFor="
                let field of conditionalQuestionsControl.controls;
                let index = index
              "
            >
              <div class="questions-container">
                <td>
                  <div>
                    Question -
                    <p
                      class="questions"
                      innerHtml="{{ field.get('question').value }}"
                    ></p>
                  </div>
                  <span
                    class="text-items"
                    style="word-break: break-all"
                    [formGroupName]="index"
                  >
                    <p>Answer - {{ field.get("option").value }}</p>
                  </span>
                </td>
                <td class="cancel-button">
                  <img
                    class="ml-1"
                    alt=""
                    (click)="conditionQuestionDelete(index)"
                    src="../../../../../../assets/images/cancel.png"
                    width="20px"
                    height="20px"
                  />
                </td>
              </div>
            </tr>
          </tbody>
        </table>
      </form>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="d-flex justify-content-between">
            <div class="form-group">
              <label for="" class="font-weight-bold"
                >Answer Options<span class="field-mandatory">*</span></label
              >
            </div>
            <button
              class="btn p-2 m-1 btn-add pull-right"
              (click)="addOptions()"
            >
              Add
            </button>
          </div>
          <p class="bold-text">Please fill options in correct sequence</p>
        </div>

        <div class="table">
          <table class="table">
            <thead>
              <tr>
                <th>ANSWER CHOICES<span class="field-mandatory">*</span></th>
                <th>Image</th>
                <th>&ensp;</th>
              </tr>
            </thead>
            <tbody>
              <tr
                formArrayName="options"
                *ngFor="let field of OptionControl.controls; let index = index"
              >
                <ng-container [formGroupName]="index">
                  <td class="w-50">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="value"
                    />
                  </td>

                  <td>
                    <div
                      class="custom-file mt-0 pt-4 pb-4"
                      style="width: 67px"
                      *ngIf="!field.get('imageSrc').value"
                    >
                      <input
                        type="file"
                        style="
                          position: absolute;
                          z-index: 9;
                          visibility: visible;
                          left: 0;
                          top: 0;
                          width: 80%;
                          opacity: 0;
                        "
                        (change)="BannerUpload($event, index)"
                        required
                        id="b"
                      />
                      <label class="custom-file-label" for="b">
                        Upload Image
                      </label>
                    </div>
                    <div *ngIf="field.get('imageSrc').value">
                      <div
                        class="image-with-delete-icon width-180 image-width-165"
                      >
                        <i
                          class="fa fa-close"
                          for="4"
                          (click)="onDeleteImage(index)"
                          title="Remove or Change Image"
                          placement="bottom"
                        ></i>
                        <img
                          [src]="field.get('imageSrc').value"
                          width="100%"
                          height="120px"
                          id="4"
                          alt="Image Preview"
                          crossorigin="anonymous"
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      *ngIf="OptionControl.controls.length >= 2"
                      title="Delete Row"
                      placement="bottom"
                    >
                      <i
                        class="fa fa-trash delete-trash-size"
                        (click)="onDeleteOptions(index)"
                      ></i>
                    </div>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group pr-0">
            <p class="font-weight-bold" for="exampleInputEmail1">
              Advanced Options
            </p>
            <input type="checkbox" formControlName="editable" /> Post Answer
            Explanation
          </div>
        </div>
        <div class="col-md-4">
          <label class="font-weight-bold">Order</label>
          <select
            class="dropdown-select w-50 form-control"
            formControlName="indexNum"
          >
            <option [value]="null" disabled>Select Order</option>

            <option *ngFor="let cnt of order" [ngValue]="cnt">
              {{ cnt }}
            </option>
          </select>
        </div>
      </div>
      <div class="row" *ngIf="createQuestionForm.get('editable').value">
        <div class="col-md-12">
          <div class="form-group">
            <label class="font-weight-bold" for="exampleInputEmail1"
              >Question Title</label
            >
            <div [froalaEditor]="options" formControlName="explanation"></div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-4">
          <button class="btn btn-clear" (click)="cancelPopup()">Cancel</button>
          <button
            class="btn btn-submit"
            [disabled]="isLoading"
            (click)="onCreateQuestion()"
          >
            <i
              class="fa"
              [ngClass]="{ 'fa-spin fa-spinner': isLoading, '': !isLoading }"
            ></i>
            {{ isEdit ? "Update" : "Add" }}
          </button>
        </div>
      </div>
    </form>

    <div *ngSwitchCase="16">
      <form [formGroup]="createQuestionForm">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold" for="exampleInputEmail1"
                >Question/Title <span class="field-mandatory">*</span></label
              >
              <div [froalaEditor]="options" formControlName="question"></div>
            </div>
          </div>
        </div>
        <div class="row d-flex px-4 pb-4">
          <button class="btn btn-info" (click)="openIconPicker()">
            Choose Icon
          </button>
          <div class="pl-4" *ngIf="selectedIcon">
            <b>Selected Icon:</b>
            <i
              [class]="'fa ' + selectedIcon"
              style="
                width: 40px;
                height: 40px;
                font-size: x-large;
                padding-left: 10px;
              "
            ></i>
            {{ selectedIcon }}
          </div>
          <div *ngIf="selectedIcon">
            <div class="image-with-delete-icon" style="width: 100px; height: 50px">
              <i
                class="fa fa-close"
                title="Delete"
                for="4"
                (click)="onDeleteIcon(index)"
              ></i>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="value.questionslength > 0">
          <div class="col-md-12">
            <div class="form-group pr-0">
              <input
                type="checkbox"
                (change)="getConditionalQuestions()"
                class="checkbox checkbox-danger"
                formControlName="isConditional"
              />
              Make this question conditional, based on another selected question
              and answer
            </div>
          </div>
        </div>
      </form>
      <form [formGroup]="conditionalQuestionForm">
        <div
          class="row mt-2"
          *ngIf="createQuestionForm.get('isConditional').value"
        >
          <div class="col-md-12">
            <label class="font-weight-bold">Question Title</label>
            <ng-select
              (change)="getContionalAnswers($event)"
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="questionId"
            >
              <ng-option
                [value]="item"
                *ngFor="let item of conditionalQuestions"
              >
                <p [innerHTML]="item.question | safeHtml"></p>
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-12 mt-2" *ngIf="conditionalAnswers.length != 0">
            <label class="font-weight-bold">Answers</label>
            <ng-select
              class="custom"
              bindLabel="name"
              placeholder="Select Questions"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              formControlName="optionId"
            >
              <ng-option [value]="item" *ngFor="let item of conditionalAnswers">
                {{ item.value }}
              </ng-option>
            </ng-select>
            <div class="row mt-2">
              <div class="col-md-6">
                <button
                  class="btn btn-add"
                  (click)="
                    addConditionalQuestion(this.conditionalQuestionForm.value)
                  "
                >
                  {{ isEdit ? "Update" : "Add" }}
                </button>
                <button
                  class="btn btn-clear"
                  (click)="onClearConditionalQuestion()"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <form [formGroup]="createQuestionForm">
        <table class="table table-borderless mt-3">
          <tbody>
            <tr
              formArrayName="conditionalQuestions"
              *ngFor="
                let field of conditionalQuestionsControl.controls;
                let index = index
              "
            >
              <div class="questions-container mt-2">
                <td>
                  <div>
                    Question -
                    <p
                      class="questions"
                      innerHtml="{{ field.get('question').value }}"
                    ></p>
                  </div>
                  <span
                    class="text-items"
                    style="word-break: break-all"
                    [formGroupName]="index"
                  >
                    <p>Answer - {{ field.get("option").value }}</p>
                  </span>
                </td>
                <td class="cancel-button">
                  <img
                    class="ml-1"
                    alt=""
                    (click)="conditionQuestionDelete(index)"
                    src="./assets/images/cancel.png"
                    width="20px"
                    height="20px"
                    crossorigin="anonymous"
                  />
                </td>
              </div>
            </tr>
          </tbody>
        </table>
      </form>

      <hr class="col-xs-12 col-sm-12 col-md-12 col-lg-12" />
      <form [formGroup]="photopickForm">
        <div
          class="custom-file mt-0 pt-4 pb-4"
          *ngIf="!photopickForm.value.imgSrc"
        >
          <input type="file" (change)="photoUpload($event)" id="b" />
          <label class="btn btn-clear" for="b"> Upload Image </label>
        </div>
        <div *ngIf="photopickForm.value.imgSrc">
          <p>Please click on image to select a spot</p>
          <div [ngStyle]="{ position: isPopupOpen ? 'sticky' : 'relative' }">
            <div
              class="full-icon"
              (click)="openPopup()"
              ngbTooltip="Expand"
              placement="bottom"
            >
              <i class="fa fa-expand" aria-hidden="true"></i>
            </div>
            <img
              #myImage
              [src]="photopickForm.value.imgSrc"
              width="100%"
              alt="Image Preview"
              (click)="onImageClick($event)"
              crossorigin="anonymous"
            />
            <div
              *ngFor="let item of imgDescControls?.controls; let i = index"
              [style.left.%]="item?.value?.x"
              [style.top.%]="item?.value?.y"
              class="circle"
            >
              <span class="text">{{ i + 1 }}</span>
            </div>
          </div>
        </div>
        <div
          class="custom-file mt-0 pt-4 pb-4"
          *ngIf="photopickForm.value.imgSrc"
        >
          <input type="file" (change)="photoUpload($event)" id="img" />
          <label class="btn btn-clear" for="img"> Change Image </label>
        </div>

        <div class="row imgSrc">
          <strong class="pb-3">Spot descriptions</strong>
          <div class="table">
            <table class="table">
              <thead>
                <tr>
                  <th class="width-10">SNO</th>
                  <th>DESCRIPTIONS</th>
                  <th>&ensp;</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  formArrayName="imgDescription"
                  *ngFor="
                    let field of imgDescControls.controls;
                    let index = index
                  "
                >
                  <ng-container [formGroupName]="index">
                    <td class="srNo">
                      {{ index + 1 }}
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="value"
                      />
                    </td>
                    <td>
                      <div
                        *ngIf="imgDescControls.controls.length >= 1"
                        class="m-3"
                      >
                        <img
                          (click)="onDeleteDescription(index)"
                          src="./assets/images/cancel.png"
                          width="20px"
                          height="20px"
                          crossorigin="anonymous"
                        />
                      </div>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>

      <div class="row imgSrc" [formGroup]="createQuestionForm">
        <div class="col-md-6">
          <div class="form-group pr-0">
            <p class="font-weight-bold" for="exampleInputEmail1">
              Advanced Options
            </p>
            <input
              type="checkbox"
              class="checkbox checkbox-danger"
              formControlName="editable"
            />
            Post Answer Explanation
          </div>
        </div>
        <div class="col-md-4">
          <label class="font-weight-bold">Order</label>
          <select
            class="dropdown-select w-50 form-control"
            formControlName="indexNum"
          >
            <option [value]="null" disabled>Select Order</option>

            <option *ngFor="let cnt of order" [ngValue]="cnt">
              {{ cnt }}
            </option>
          </select>
        </div>
        <div class="row" *ngIf="createQuestionForm.get('editable').value">
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold" for="exampleInputEmail1"
                >Post Answer Explanation</label
              >
              <div [froalaEditor]="options" formControlName="explanation"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-4">
          <button class="btn btn-clear" (click)="cancelPopup()">Cancel</button>
          <button
            class="btn btn-submit"
            [disabled]="isLoading"
            (click)="onCreateQuestion()"
          >
            <i
              class="fa"
              [ngClass]="{ 'fa-spin fa-spinner': isLoading, '': !isLoading }"
            ></i>
            {{ isEdit ? "Update" : "Add" }}
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="21">
      <form [formGroup]="createQuestionForm">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold" for="exampleInputEmail1"
                >Question Title<span class="field-mandatory">*</span></label
              >
              <div [froalaEditor]="options" formControlName="question"></div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="createQuestionForm.get('editable').value">
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold" for="exampleInputEmail1"
                >Question Title</label
              >
              <div [froalaEditor]="options" formControlName="explanation"></div>
            </div>
          </div>
        </div>
        <div>
          <div class="row d-flex px-4 pb-4">
            <button class="btn btn-info" (click)="openIconPicker()">
              Choose Icon
            </button>
            <div class="pl-4" *ngIf="selectedIcon">
              <b>Selected Icon:</b>
              <i
                [class]="'fa ' + selectedIcon"
                style="
                  width: 40px;
                  height: 40px;
                  font-size: x-large;
                  padding-left: 10px;
                "
              ></i>
              {{ selectedIcon }}
            </div>
            <div *ngIf="selectedIcon">
              <div class="image-with-delete-icon" style="width: 100px; height: 50px">
                <i
                  class="fa fa-close"
                  title="Delete"
                  for="4"
                  (click)="onDeleteIcon(index)"
                ></i>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="value.questionslength > 0">
            <div class="col-md-12">
              <div class="form-group pr-0">
                <input
                  type="checkbox"
                  (change)="getConditionalQuestions()"
                  class="checkbox checkbox-danger"
                  formControlName="isConditional"
                />
                Make this question conditional, based on another selected
                question and answer
              </div>
            </div>
          </div>
          <form [formGroup]="conditionalQuestionForm">
            <div
              class="row mt-2"
              *ngIf="createQuestionForm.get('isConditional').value"
            >
              <div class="col-md-12">
                <label class="font-weight-bold">Question Title</label>
                <ng-select
                  (change)="getContionalAnswers($event)"
                  class="custom"
                  bindLabel="name"
                  placeholder="Select Questions"
                  appendTo="body"
                  [multiple]="false"
                  [searchable]="true"
                  [clearable]="true"
                  formControlName="questionId"
                >
                  <ng-option
                    [value]="item"
                    *ngFor="let item of conditionalQuestions"
                  >
                    <p [innerHTML]="item.question | safeHtml"></p>
                  </ng-option>
                </ng-select>
              </div>
              <div class="col-md-12" *ngIf="conditionalAnswers.length != 0">
                <label class="font-weight-bold">Answers</label>
                <ng-select
                  class="custom"
                  bindLabel="name"
                  placeholder="Select Questions"
                  appendTo="body"
                  [multiple]="false"
                  [searchable]="true"
                  [clearable]="true"
                  formControlName="optionId"
                >
                  <ng-option
                    [value]="item"
                    *ngFor="let item of conditionalAnswers"
                  >
                    {{ item.value }}
                  </ng-option>
                </ng-select>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <button
                      class="btn btn-add"
                      (click)="
                        addConditionalQuestion(
                          this.conditionalQuestionForm.value
                        )
                      "
                    >
                      Add
                    </button>
                    <button
                      class="btn btn-clear"
                      (click)="onClearConditionalQuestion()"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <!-- Newly added changes  -->
        <div class="row mt-3">
          <div class="col-md-6">
            <label class="font-weight-bold">Lookup Table<span class="field-mandatory">*</span></label>
            <ng-select
            (change)="getLookupFields($event)"
            class="custom"
            bindLabel="name"
            placeholder="Select Lookup Value"
            appendTo="body"
            [multiple]="false"
            [searchable]="true"
            [clearable]="true"
            formControlName="lookupTable"
          >
            <ng-option
              [value]="item?._id"
              *ngFor="let item of lookupData"
            >
              <p [innerHTML]="item.name | safeHtml"></p>
            </ng-option>
          </ng-select>
          </div>
          <div class="col-md-6">
            <label class="font-weight-bold">Display Field<span class="field-mandatory">*</span></label>
            <select class="form-control" formControlName="displayField" style="height: 36px;">
              <option [value]="null" disabled>Select</option>
              <option *ngFor="let field of lookupFields" [ngValue]="field">
                {{ field }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <div class="col-md-12 d-flex justify-content-between align-items-center">
              <h5 class="font-weight-bold">Auto Populate Data Field List</h5>
              <button type="button" class="btn btn-primary" (click)="addDynamicRow()">Add Row</button>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th>Source Questions<span class="field-mandatory">*</span></th>
                  <th>Lookup Field<span class="field-mandatory">*</span></th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody formArrayName="autoPopulateData">
                <tr *ngFor="let row of autoPopulateData.controls; let i = index" [formGroupName]="i">
                  <td>
                    <ng-select
                    (change)="getContionalAnswersforSourceQuestions($event)"
                    class="custom"
                    bindLabel="question"
                    placeholder="Select Questions"
                    appendTo="body"
                    [multiple]="false"
                    [searchable]="true"
                    [clearable]="true"
                    formControlName="sourceQuestion"
                  >
                    <ng-option
                      [value]="item._id"
                      *ngFor="let item of conditionalQuestions"
                    >
                      <p [innerHTML]="item.question | safeHtml"></p>
                    </ng-option>
                  </ng-select>
                  </td>
                  <td>
                    <select class="form-control" formControlName="lookupField" style="height: 36px;">
                      <option [value]="null" disabled>Select</option>
                      <option *ngFor="let field of lookupFields" [ngValue]="field">
                        {{ field }}
                      </option>
                    </select>
                  </td>
                  <td>
                    <button type="button" class="btn btn-danger"  *ngIf="i > 0" (click)="removeDynamicRow(i)">Remove</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        
        <div class="row mt-3">
          <div class="col-md-4">
            <button class="btn btn-clear" (click)="cancelPopup()">
              Cancel
            </button>
            <button
              class="btn btn-submit"
              [disabled]="isLoading"
              (click)="onCreateQuestion()"
            >
              <i
                class="fa"
                [ngClass]="{ 'fa-spin fa-spinner': isLoading, '': !isLoading }"
              ></i>
              {{ isEdit ? "Update" : "Add" }}
            </button>
          </div>
        </div>
      </form>
    </div>
    <div *ngSwitchCase="22">
      <form [formGroup]="createQuestionForm">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold" for="exampleInputEmail1"
                >Question Title<span class="field-mandatory">*</span></label
              >
              <div [froalaEditor]="options" formControlName="question"></div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="createQuestionForm.get('editable').value">
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold" for="exampleInputEmail1"
                >Question Title</label
              >
              <div [froalaEditor]="options" formControlName="explanation"></div>
            </div>
          </div>
        </div>
        <div>
          <div class="row d-flex px-4 pb-4 pb-4">
            <button class="btn btn-info" (click)="openIconPicker()">
              Choose Icon
            </button>
            <div class="pl-4" *ngIf="selectedIcon">
              <b>Selected Icon:</b>
              <i
                [class]="'fa ' + selectedIcon"
                style="
                  width: 40px;
                  height: 40px;
                  font-size: x-large;
                  padding-left: 10px;
                "
              ></i>
              {{ selectedIcon }}
            </div>
            <div *ngIf="selectedIcon">
              <div class="image-with-delete-icon" style="width: 100px; height: 50px">
                <i
                  class="fa fa-close"
                  title="Delete"
                  for="4"
                  (click)="onDeleteIcon(index)"
                ></i>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="value.questionslength > 0">
            <div class="col-md-12">
              <div class="form-group pr-0">
                <input
                  type="checkbox"
                  (change)="getConditionalQuestions()"
                  class="checkbox checkbox-danger"
                  formControlName="isConditional"
                />
                Make this question conditional, based on another selected
                question and answer
              </div>
            </div>
          </div>
          <form [formGroup]="conditionalQuestionForm">
            <div
              class="row mt-2"
              *ngIf="createQuestionForm.get('isConditional').value"
            >
              <div class="col-md-12">
                <label class="font-weight-bold">Question Title</label>
                <ng-select
                  (change)="getContionalAnswers($event)"
                  class="custom"
                  bindLabel="name"
                  placeholder="Select Questions"
                  appendTo="body"
                  [multiple]="false"
                  [searchable]="true"
                  [clearable]="true"
                  formControlName="questionId"
                >
                  <ng-option
                    [value]="item"
                    *ngFor="let item of conditionalQuestions"
                  >
                    <p [innerHTML]="item.question | safeHtml"></p>
                  </ng-option>
                </ng-select>
              </div>
              <div class="col-md-12" *ngIf="conditionalAnswers.length != 0">
                <label class="font-weight-bold">Answers</label>
                <ng-select
                  class="custom"
                  bindLabel="name"
                  placeholder="Select Questions"
                  appendTo="body"
                  [multiple]="false"
                  [searchable]="true"
                  [clearable]="true"
                  formControlName="optionId"
                >
                  <ng-option
                    [value]="item"
                    *ngFor="let item of conditionalAnswers"
                  >
                    {{ item.value }}
                  </ng-option>
                </ng-select>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <button
                      class="btn btn-add"
                      (click)="
                        addConditionalQuestion(
                          this.conditionalQuestionForm.value
                        )
                      "
                    >
                      Add
                    </button>
                    <button
                      class="btn btn-clear"
                      (click)="onClearConditionalQuestion()"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="row mt-3">
          <div class="col-md-6">
            <label class="font-weight-bold"
              >Display Value<span class="field-mandatory">*</span></label
            >
            <select
              class="dropdown-select w-50 form-control"
              formControlName="buttonValue"
            >
              <option [value]="null" disabled>Select</option>

              <option *ngFor="let cnt of buttonsValues" [ngValue]="cnt">
                {{ cnt }}
              </option>
            </select>
          </div>
          <div class="col-md-6">
            <label class="font-weight-bold">Order</label>
            <select
              class="dropdown-select w-50 form-control"
              formControlName="indexNum"
            >
              <option [value]="null" disabled>Select Order</option>

              <option *ngFor="let cnt of order" [ngValue]="cnt">
                {{ cnt }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-4">
            <button class="btn btn-clear" (click)="cancelPopup()">
              Cancel
            </button>
            <button
              class="btn btn-submit"
              [disabled]="isLoading"
              (click)="onCreateQuestion()"
            >
              <i
                class="fa"
                [ngClass]="{ 'fa-spin fa-spinner': isLoading, '': !isLoading }"
              ></i>
              {{ isEdit ? "Update" : "Add" }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-container *ngIf="isPopupOpen">
  <div class="popup-overlay">
    <div class="popup">
      <div>
        <div
          class="full-icon"
          (click)="closePopup()"
          ngbTooltip="Exit fullscreen"
          placement="bottom"
        >
          <i class="fa fa-compress" aria-hidden="true"></i>
        </div>
        <img
          #myImage
          [src]="photopickForm.value.imgSrc"
          width="100%"
          alt="Image Preview"
          (click)="onImageClick($event)"
          crossorigin="anonymous"
        />
        <div
          *ngFor="let item of imgDescControls?.controls; let i = index"
          [style.left.%]="item?.value?.x"
          [style.top.%]="item?.value?.y"
          class="circlePopup"
        >
          <span class="text">{{ i + 1 }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div *ngIf="showIconPicker" class="child-modal">
  <div class="child-modal-content">
    <span class="close" (click)="closeIconPicker()">&times;</span>
    <div class="icon-grid">
      <div
        *ngFor="let icon of icons"
        class="icon-item"
        (click)="selectIcon(icon)"
      >
        <i [class]="'fa ' + icon"></i>
        <span>{{ icon }}</span>
      </div>
    </div>
  </div>
</div>
