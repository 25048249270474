import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditResponseComponent } from '../edit-response/edit-response';
import Swal from 'sweetalert2';
import { CustomFormService } from 'src/app/users/service/custom-form/custom-form.service';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { FileDownloadService } from 'src/app/users/service/common/file-download.service';
import { environment } from 'src/environments/environment';

interface SearchQuery {
  value: any;
  questionId: string | null;
  header: string;
}
@Component({
  selector: 'dynamic-table-form',
  templateUrl: './dynamic-table-form.html',
  styleUrls: ['./dynamic-table-form.scss'],
})
export class DynamicTableFormComponent implements OnInit {
  @ViewChild('updateSettings', { static: true })
  updateSettings: TemplateRef<any>;

  @ViewChild('updateStatusSettings', { static: true })
  updateStatusSettings: TemplateRef<any>;


  @Input() formSubmissionListData: any[] = [];
  @Input() columns: any[] = [];
  @Input() customFormId: string = null;
  @Input() componentData: any = null;
  @Input() hasWorkflow: boolean = null;
  @Input() total: number = 0;
  loader: boolean = false;
  tableHeaders: string[] = [];
  tableData: any[] = [];
  columnVisibility: { [key: string]: boolean } = {};
  columnVisibility_Key: string = null;
  isPanelOpen: boolean = false;
  pendingColumnVisibility: { [key: string]: boolean } = {}; // Pending changes
  searchQueries: { [key: string]: SearchQuery } = {}; // Store search queries
  searchValues: { [key: string]: string } = {};

  @Output() searchCall = new EventEmitter<any>();

  private debounceTimer: any;

  // Pagination properties
  currentPage: number = 1;
  itemsPerPage: number = 15;
  privilegeFlags;
  selectedWorkFlowData
  modelRef: any

  constructor(
    public modelService: NgbModal,
    private customFromService: CustomFormService,
    public toastService: ToastrService,
    private fileDownloadService: FileDownloadService,
  ) {}

  ngOnInit(): void {
    console.log('ccc', this.componentData);
    const data = localStorage.getItem('privilegeFlags');
    this.privilegeFlags = JSON.parse(data);
    this.columnVisibility_Key = `columnVisibility_${this.customFormId}`;
    console.log(
      'this.columnVisibility_Key ngOnInit',
      this.columnVisibility_Key
    );
    this.prepareTableData();
  }

  pageChanged(event) {
    this.currentPage = event;
    this.searchCall.emit({
      ...this.searchQueries,
      currentPage: this.currentPage,
    });
  }

  generateHeaders() {
    const columnVisibility = localStorage.getItem(this.columnVisibility_Key);
    if (columnVisibility) {
      this.columnVisibility = JSON.parse(columnVisibility);
      this.pendingColumnVisibility = JSON.parse(columnVisibility);
    } else {
      this.initializeColumnVisibility();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formSubmissionListData) {
      this.prepareTableData();
    }

    if (changes.customFormId?.firstChange) {
      this.columnVisibility_Key = `columnVisibility_${changes.customFormId.currentValue}`;
      this.customFormId = changes.customFormId.currentValue;
    }
  }

  prepareTableData(): void {
    if (this.formSubmissionListData.length > 0) {
      // Get unique headers (keys) from all objects
      const headersSet = new Set<string>();

      this.formSubmissionListData.forEach((item) => {
        if(this.componentData.formSubTypeId){
          headersSet.add('Staff Name');
        }else{
          headersSet.add('Submitted At');
        }

        headersSet.add('Staff Id');
        // headersSet.add('_id');
        for (let key in item) {
          if (
            item[key]?.hasOwnProperty('value') &&
            key !== 'responseId' &&
            key !== '_id' &&
            key !== 'Submitted At' &&
            key !== 'Staff Name' &&
            key !== 'Staff Id'
          ) {
            headersSet.add(key);
          }
        }
      });

      this.tableHeaders = Array.from(headersSet);
      this.generateHeaders();
      // Prepare table rows data
      this.tableData = this.formSubmissionListData.map((item) => {
        const row = {};
        row['allData'] = item;
        [...this.tableHeaders, '_id'].forEach((header) => {
          row[header] = item[header]?.hasOwnProperty('value')
            ? item[header].value
              ? item[header].value.toString().trim()
              : ''
            : item[header];
        });
        return row;
      });
    } else {
      this.tableHeaders = this.columns.map((column) => column.question);
      this.generateHeaders();
      this.tableData = [];
    }
  }

  initializeColumnVisibility(): void {
    // Initialize all columns as visible and sync pending changes
    this.tableHeaders.forEach((header) => {
      this.columnVisibility[header] = true;
      this.pendingColumnVisibility[header] = true;
    });
    if (
      this.columnVisibility_Key &&
      Object.keys(this.columnVisibility).length !== 0
    ) {
      localStorage.setItem(
        this.columnVisibility_Key,
        JSON.stringify(this.columnVisibility)
      );
    }
  }

  isColumnVisible(header: string): boolean {
    // console.log('header',header)
    // if(header === '_id'){
    //   return true
    // }
    return this.columnVisibility[header];
  }
  togglePanel(): void {
    this.modelService.open(this.updateSettings, {
      windowClass: 'modal-ui-fix right-align',
    });
  }
  onSubmitColumnForm() {
    this.tableHeaders.forEach((header) => {
      this.columnVisibility[header] = this.pendingColumnVisibility[header];
    });
    localStorage.setItem(
      this.columnVisibility_Key,
      JSON.stringify(this.columnVisibility)
    );
    this.modelService.dismissAll();
  }

  onCloseColumnForm() {
    this.pendingColumnVisibility = JSON.parse(
      JSON.stringify(this.columnVisibility)
    );
    this.modelService.dismissAll();
  }

  onSearch(event: any, header: string, firstRow: {} | null = null): void {
    const query = event.target.value;
    if (query) {
      if (!this.columns?.length) {
        const questionId = firstRow
          ? firstRow[header]?.questionId || null
          : null;
        this.searchQueries[header] = { value: query, questionId, header };
      } else {
        const qId =
          this.columns.find((item) => item.question === header)?.questionId ||
          null;
        this.searchQueries[header] = { value: query, header, questionId: qId };
      }
    } else {
      delete this.searchQueries[header];
      this.searchValues[header] = '';
    }
    // Clear the previous debounce timer
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    // Set a new debounce timer
    this.debounceTimer = setTimeout(() => {
      this.currentPage = 1;
      this.searchCall.emit({
        ...this.searchQueries,
        currentPage: this.currentPage,
      }); // Emit the data to the parent
      // call API here
    }, 500);
  }

  onClearCall() {
    this.searchValues = {};
  }

  onEdit(row: any): void {
    console.log('ff', row['allData']);
    const modalRef = this.modelService.open(EditResponseComponent, {
      size: 'lg',
    });
    const formatData = Object.keys(row).reduce((acc, key) => {
      if (key !== 'allData') {
        acc[key] = {
          value: row[key],
          questionId: row['allData'][key]?.questionId,
          isUsedForAutoPopulate: row['allData'][key]?.isUsedForAutoPopulate,
        };
      }
      return acc;
    }, {});
    const metaData = {
      firstQuestionId: row['allData'].firstQuestionId,
      formSubTypeId: this.componentData.formSubTypeId,
    };
    console.log('formatData', formatData);
    const responseId = row['allData'].responseId;
    modalRef.componentInstance.rowData = formatData;
    modalRef.componentInstance.tableHeaders = this.tableHeaders;
    modalRef.componentInstance.metaData = metaData;
    modalRef.componentInstance.responseId = responseId;

    modalRef.componentInstance.onSave.subscribe((updatedData: any) => {
      // this.searchCall.emit({}); // Emit the data to the parent
      this.searchCall.emit({
        ...this.searchQueries,
        currentPage: this.currentPage,
      });
    });
  }

  onCancel(row: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to cancel this booking?',
      footer: '',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          responseId: row?._id,
        };
        this.loader = true;
        this.customFromService.cancelSubmission(payload).subscribe(
          (res) => {
            if (res?.success) {
              this.toastService.success(res.message, 'Success');
              // this.searchCall.emit({}); // Emit the data to the parent
              this.searchCall.emit({
                ...this.searchQueries,
                currentPage: this.currentPage,
              });
            } else {
              this.toastService.error(res.message, 'Failed');
            }
            this.loader = false;
          },
          (err) => {
            console.log(err);
            this.toastService.error(err?.error?.error?.message);
          }
        );
      }
    });
  }

  workFlowCall(item){
    this.selectedWorkFlowData = item;
    this.modelRef = this.modelService.open(this.updateStatusSettings, {
      windowClass: 'modal-ui-fix right-align',
    });
  }

  handleCloseModal(flag: boolean): void {
    // Perform actions based on the flag value
    if (flag) {
      this.debounceTimer = setTimeout(() => {
        this.currentPage = 1;
        this.searchCall.emit({
          ...this.searchQueries,
          currentPage: this.currentPage,
        }); // Emit the data to the parent
        // call API here
      }, 500);
    }
    this.modelRef.close()
  }

   getFormattedDateTime() {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, '0'); // Ensure two digits
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = String(now.getFullYear()).slice(-2); // Get last two digits of the year
    const hours = String(now.getHours()).padStart(2, '0'); // Ensure two digits
    const minutes = String(now.getMinutes()).padStart(2, '0'); // Ensure two digits

    return `${day}_${month}_${year}_${hours}:${minutes}`;
  }

  onPrintWorkFlowPdf(id: string) {
    const data = {
      timeZone: new Date().getTimezoneOffset(),
      _id: id
    };
    const subscribe = this.customFromService
      .exportWorkFlowPdf(data)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          if (response?.data?.csvLink) {
            this.downloadAttachments(response?.data?.csvLink, `${this.componentData.title}_${this.getFormattedDateTime()}.pdf`);
          }
        },
        (error) => {
          this.toastService.error('Something went wrong. Please try again later.');
        }
      );
  }

  downloadAttachments(url, fileName) {
     url = `${environment.baseUrl}${url}?print=yes`;
     const printWindow = window.open(url, '_blank');
  if (printWindow) {
    printWindow.onload = () => {
      printWindow.focus(); // Ensure the window is focused
      printWindow.print();
    };
  } else {
    console.error('Unable to open the PDF for printing.');
  }

  }
}
