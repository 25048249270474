<div class="modal-header">
  <div class="card-header flexi-header pt-4 w-100">
    <div>
      <h3>EDIT RECORD</h3>
      <button
        type="button"
        class="btn btn-large close-icon"
        aria-label="Close"
        (click)="cancelPopup()"
      >
        <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</div>
<div class="modal-body">
  <form [formGroup]="editForm">
    <div class="card mb-4">
      <div class="card-body">
        <div class="row d-flex">
          <div
            class="form-group col-sm-6"
            *ngFor="let header of tableHeaders | slice:0:2"
          >
            <label><strong>{{ header }}</strong></label>
            <input
              type="text"
              class="form-control"
              [formControlName]="header"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
<div class="row d-flex">
  <div
    class="box col-sm-6"
    *ngFor="let item of viewQuestions; let i = index"
  >
    <div class="topHead d-flex">
      <strong class="mr-1">{{ i + 1 }} &nbsp;
        <span *ngIf="item.required" class="required">*</span>
      </strong>
      <div class="box-heading" style="word-break: break-word">
        &emsp;
        <p [innerHTML]="item?.question | safeHtml"></p>
      </div>
    </div>
    <div class="box-body" *ngIf="item.type === 1">
      <textarea
        matInput
        rows="2"
        [(ngModel)]="item.answer"
        [disabled]="item.isDisabled"
        style="width: 100%"
      ></textarea>
      <br />
    </div>
    <div class="box-body" *ngIf="item.type === 2">
      <div class="box" *ngFor="let option of item.options">
        <!-- <div> -->
        <div class="card-body" *ngIf="!!option.imageSrc">
          <img
            [src]="option?.imageSrc"
            class="image-size"
            crossorigin="anonymous"
            id="4"
            alt="Image Preview"
          />
        </div>
        <!-- <form [formGroup]="answerQuestionForm"> -->
        <input
          type="radio"
          id="{{option._id}}"
          name="{{item._id}}"
          [value]="option?.value"
          [(ngModel)]="item.answer"
          (change)="setChild(item, option)"
          class="card-header-flexi"
        />
        <label for="consolidated">{{ option.value }}</label>
      </div>
      <br />
    </div>

    <div class="box-body" *ngIf="item.type === 3">
      <div class="boxx" *ngFor="let option of item.options">
        <div class="card-body" *ngIf="!!option.imageSrc">
          <img
            [src]="option?.imageSrc"
            crossorigin="anonymous"
            width="250px"
            height="320px"
            alt="Image Preview"
          />
        </div>
        <input
          type="radio"
          id="{{option._id}}"
          name="{{item._id}}"
          [value]="option?.value"
          [(ngModel)]="item.answer"
          (change)="setChild(item, option)"
          class="card-header-flexi"
        />
        <label for="{{option._id}}">{{ option.value }}</label>
      </div>
      <br />
    </div>

    <div class="card-body" *ngIf="item.type === 4">
      <div class="d-flex" style="overflow: auto">
        <div
          class="question-radio-inline"
          *ngFor="let option of item.options;let i=index"
        >
          <label
            for="{{option._id}}"
            class="card"
            style="border-radius: 10px; width: min-content"
          >
            <img
              *ngIf="!!option.imageSrc"
              crossorigin="anonymous"
              src="{{option.imageSrc}}"
              alt=""
              style="
                width: 270px;
                height: 201px;
                border-radius: 10px 10px 0px 0px;
              "
              (click)="item.selected=i"
            />
            <div
              class="card-container"
              [ngStyle]="{'width': option.imageSrc ? '270px' : '150px' }"
              style="
                background-color: #6eb96e;
                display: flex;
                justify-content: space-between;
                border-radius: 0px 0px 10px 10px;
                padding: 5px;
              "
              (click)="selectPolling(item._id, option)"
            >
              <h4><b>Select</b></h4>
              <img
                crossorigin="anonymous"
                style="width: 12%; min-width: 30px; height: 1%; margin: 3px"
                src="{{'./assets/images/'+(option.answer?'like1':'like0')+'.png'}}"
                alt=""
                height="10px"
              />
            </div>
          </label>
          <h4
            style="text-align: center"
            [ngClass]="{'pollingSelect':item.selected===i}"
            (click)="item.selected=i"
          >
            <b>{{option.value}}</b>
          </h4>
          <span class="text-success" *ngIf="!!option.percentage"
            >{{option.percentage}} %</span
          >
        </div>
      </div>
      <br />
    </div>

    <div class="box-body" *ngIf="item.type === 5">
      <div class="form-group d-flex flex-column answers-options">
        <div class="box" *ngFor="let option of item.options; let index= index">
          <div *ngIf="option.imageSrc">
            <div class="card-body">
              <img
                [src]="option.imageSrc"
                class="image-size"
                id="4"
                alt="Image Preview"
              />
            </div>
          </div>
          <input
            type="checkbox"
            name="{{option._id}}"
            id="{{option._id}}"
            (change)="setChild(item,option,i);setCheckboxAnswer($event,i,option,item['answer'])"
            [checked]="getCheckedValue(option, item['answer'])"
          />
          <label for="consolidated">&nbsp;{{ option.value }}</label>
        </div>
      </div>
      <br />
    </div>

    <div class="box-body" *ngIf="item.type === 6">
      <div>
        <div *ngIf="item.answer" class="signature-div">
          <img [src]="item.answer" class="signature-img" />
        </div>
        <mat-icon
          aria-hidden="false"
          aria-label="Example home icon"
          fontIcon="home"
        ></mat-icon>
      </div>
      <br />
    </div>
    <div class="box-body" *ngIf="item.type === 8">
      <div class="d-flex flex-wrap answers-options">
        <div
          class="box form-group flex-fill"
          style="margin: 1rem 1rem;"
        >
          <textarea
            class="form-control"
            rows="5"
            [(ngModel)]="item.answer"
            maxlength="{{!!item?.maxlength?item?.maxlength:30}}"
            (keydown)="onInputChangeShortText($event, item.isNumeric)"
            (paste)="onPaste($event, item.isNumeric, item.maxlength)"
          ></textarea>
        </div>
      </div>
      <br />
    </div>

    <div class="box-body" *ngIf="item.type === 9">
      <div class="d-flex flex-wrap answers-options">
        <div
          class="box form-group flex-fill"
          style="margin: 1rem 1rem; width: 44%"
        >
          <input
            type="number"
            class="form-control"
            [(ngModel)]="item.answer"
          />
        </div>
      </div>
      <br />
    </div>

    <div class="box-body" *ngIf="item.type === 10">
      <div *ngFor="let option of item.dateTime">
        <div *ngIf="item.dateTime?.length === 2 && option === 2" class="box">
          <div class="d-flex flex-wrap answers-options">
            <div class="form-group flex-fill" style="margin: 1rem">
              <label for="my-input">Date</label>
              <input
                id="my-input"
                class="form-control"
                type="date"
                #myDate
                [(ngModel)]="item.answer['date']"
              />
            </div>
          </div>
          <div style="margin-left: 10px" class="box">
            <label>Time</label>
            <ngb-timepicker
              [(ngModel)]="item.answer['time']"
              [meridian]="true"
              [hourStep]="hourStep"
              [minuteStep]="minuteStep"
            ></ngb-timepicker>
          </div>
        </div>
        <div
          class="box form-group"
          *ngIf="item.dateTime?.length === 1 && option === 1"
        >
          <div class="d-flex flex-wrap answers-options" *ngIf="option === 1">
            <div class="form-group flex-fill" style="margin: 1rem">
              <label for="my-input">Date</label>
              <input
                id="my-input"
                class="form-control"
                type="date"
                #myDate
                [(ngModel)]="item.answer['date']"
              />
            </div>
          </div>
        </div>
        <div
          style="margin-left: 10px"
          class="box"
          *ngIf="option === 2 && item.dateTime?.length === 1"
        >
          <div style="margin-left: 10px" class="box">
            <label>Time</label>
            <ngb-timepicker
              [(ngModel)]="item.answer['time']"
              [meridian]="true"
              [hourStep]="hourStep"
              [minuteStep]="minuteStep"
            ></ngb-timepicker>
          </div>
        </div>
      </div>
      <br />
    </div>

    <div class="box-body" *ngIf="item.type === 11">
      <div class="box form-group ng-select-img-container">
        <ng-select
          class="custom"
          placeholder="Select Options"
          bindLabel="name"
          appendTo="body"
          [multiple]="false"
          [searchable]="true"
          [clearable]="false"
          [(ngModel)]="item.answer"
          [disabled]="item.isDisabled"
        >
        <!-- (change)="setChild(item, item.answer,i)" -->
          <ng-option [value]="choice.value" *ngFor="let choice of item.options">
            <img
              src="{{choice.imageSrc}}"
              crossorigin="anonymous"
              class="dropdown-img"
              *ngIf="choice.imageSrc"
            />
            &nbsp;{{ choice.value }}
          </ng-option>
        </ng-select>
      </div>
      <br />
    </div>

    <div class="box-body" *ngIf="item.type === 12">
      <div class="box form-group">
        <input
          id="my-input"
          class="form-control-file"
          type="file"
          (change)="fileUpload($event,item)"
        />
      </div>
      <a
        [href]="safeUrl(item?.answer?.url)"
        class="btn btn-link"
        download="{{item?.answer?.fileName ? item?.answer?.fileName : item?.answer?.name}}"
        >{{item?.answer?.fileName ? item?.answer?.fileName :
        item?.answer?.name}}
      </a>
      <br />
      Preffered formats are -
      <span *ngFor="let fileType of item?.options;let index=index" class="mt-1">
        {{ fileType.value }}<span *ngIf="item.options?.length != index+1"
          >,
        </span>
      </span>
      <br />
    </div>

    <div class="box-body" *ngIf="item.type === 13">
      <div class="d-flex">
        <ngb-rating [max]="item.value" [(rate)]="item.answer"></ngb-rating>
      </div>
      <br />
    </div>

    <div class="box-body" *ngIf="item.type === 14">
      <div class="row">
        <div class="col-md-6">
          <ng-select
            class="custom"
            bindLabel="name"
            (change)="multiSelect($event,item)"
            placeholder="Select Users"
            [multiple]="true"
            [searchable]="true"
            [(ngModel)]="user"
            [clearable]="true"
          >
            <ng-option
              [value]="choice._id"
              *ngFor="let choice of typeFourteenQuestion"
            >
              {{ choice.name }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div style="display: flex; flex-direction: row; flex-wrap: wrap">
            <div *ngFor="let obj of item?.answer;let index=index">
              <span class="text-items list-ui pr-1">
                {{obj?.name}}
                <img
                  class="ml-3"
                  (click)="deleteDropDownValue(item?.answer,index)"
                  src="./assets/images/cancel.png"
                  width="20px"
                  height="20px"
                  crossorigin="anonymous"
              /></span>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>

    <div class="box-body" *ngIf="item.type === 15">
      <div cdkDropList (cdkDropListDropped)="drop($event,item)">
        <div
          *ngFor="let imagObj of item.images"
          cdkDrag
          [cdkDragDisabled]="isFormSubmitted"
        >
          <div class="img-thumbnail">
            <div *ngIf="imagObj.src">
              <img
                [src]="imagObj.src"
                height="150px"
                width="150px"
                id="4"
                alt="Image Preview"
              />
            </div>
            <div class="caption">
              <p>{{imagObj.value}}</p>
            </div>
          </div>

          <br />
        </div>
      </div>
      <br />
    </div>

    <div class="box-body-16" *ngIf="item.type === 16">
      <div [ngStyle]="{'position': isPopupOpen ? 'sticky' : 'relative' }">
        <div
          class="full-icon"
          (click)="openPopup(item.imageSrc,i)"
          ngbTooltip="Expand"
          placement="bottom"
        >
          <i class="fa fa-expand" aria-hidden="true"></i>
        </div>
        <img
          #myImage
          [src]="item.imageSrc"
          class="qus-image-size"
          alt="Image Preview"
          (click)="onImageClick($event, i)"
        />
        <div
          *ngFor="let value of item?.answer; let j = index"
          [style.left.%]="value?.coordinates?.x"
          [style.top.%]="value?.coordinates?.y"
          class="circle"
        >
          <span class="text">{{ j + 1 }}</span>
        </div>
        -
      </div>

      <br />
      <div *ngIf="isFormSubmitted && item?.explanation">
        <span>Answer Explanation:</span>
        <p [innerHTML]="item?.explanation | safeHtml"></p>
      </div>
    </div>
    <div
      class="row imgSrc"
      *ngIf="item.type === 16 && item?.answer?.length > 0"
    >
      <strong class="pb-3">Spot descriptions</strong>
      <div class="table">
        <table class="table">
          <thead>
            <tr>
              <th class="width-5">SNO</th>
              <th>DESCRIPTIONS</th>
              <th>&ensp;</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let field of item.answer; let index = index">
              <td class="srNo">{{index + 1}}</td>
              <td>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="field.value"
                  [disabled]="isFormSubmitted"
                />
              </td>
              <td *ngIf="item?.answer?.length >= 1" class="cancel-button">
                <img
                  class="image"
                  alt=""
                  (click)="conditionQuestionDelete(index)"
                  src="./assets/images/cancel.png"
                  height="20px"
                  crossorigin="anonymous"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="box-body pb-4" *ngIf="item.type === 21">
      <input
        type="text"
        class="form-control"
        [(ngModel)]="item.answer"
        [disabled]="isFormSubmitted"
      />
    </div>
    <div class="box-body pb-4" *ngIf="item.type === 22">
      <app-date-time-picker
      [maxDate]="maxDate"
      [minDate]="minDate"
      [minuteStep]="1"
      [(ngModel)]="item.answer"
      ></app-date-time-picker>
    </div>
    <button
      *ngIf="item.isUsedForAutoPopulate && (item.answer != item.originalAnswer)"
      type="button"
      class="btn btn-primary"
      (click)='generateAutoPopulateData(item)'
    >
      AutoPopulate Data
    </button>
  </div>
</div>
  <div *ngIf="viewQuestions.length == 0" style="text-align: center">
    No questions to show
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="close()">Close</button>
  <button type="button" class="btn btn-success" (click)="submit()">
    Save changes
  </button>
</div>
