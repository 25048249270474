import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  template: `
    <div *ngIf="isLoading" class="loader">
      <div class="pageLoading">
        <div class="innerDiv">
          <i class="fa fa-spinner fa-spin"></i> {{ message }}
        </div>
      </div>
    </div>
  `,
})
export class LoadingComponent {
  @Input() isLoading: boolean = false;
  @Input() message: string = 'Loading data, please wait...';
}
