<div
  #sidemenu
  id="sidemenu"
  class="p-3 d-none d-sm-block pt-5 sidemenu"
  *ngIf="!isView"
>
  <aside>
    <div class="form-group">
      <label class="d-block text-center">SERVICE TYPE</label>
      <ng-select
        [items]="serviceTypeList"
        bindLabel="serviceTypeName"
        class="custom"
        placeholder="Select/Search Service type"
        appendTo="body"
        [searchable]="true"
        [clearable]="true"
        [closeOnSelect]="true"
        [virtualScroll]="true"
        [loading]="serviceTypeLoader"
        [loadingText]="'Loading more...'"
        [(ngModel)]="serviceType"
        (scrollToEnd)="loadMoreServiceTypes($event)"
      >
      </ng-select>
    </div>

    <div class="row">
      <div class="col pr-md-3">
        <button class="btn btn-danger btn-block" (click)="cancelSelection()">
          Cancel
        </button>
      </div>
      <div class="col pl-md-0">
        <button
          class="btn btn-info btn-block"
          (click)="viewClicked(serviceType)"
        >
          View
        </button>
      </div>
    </div>
  </aside>
</div>

<div class="d-flex flex-column" *ngIf="isView">
  <ul
    ngbNav
    #companyNav="ngbNav"
    [(activeId)]="activeId"
    class="nav-tabs rounded mb-3"
  >
    <li [ngbNavItem]="1">
      <a ngbNavLink>Create Service</a>
      <ng-template ngbNavContent>
        <app-create-service
          (tabChange)="activeId = 2"
          [serviceType]="serviceType"
        ></app-create-service>
      </ng-template>
    </li>
    <li [ngbNavItem]="2" class="not-allowed">
      <a ngbNavLink class="no-click">Logs</a>
      <ng-template ngbNavContent>
        <app-plan-service-logs
          [serviceType]="serviceType"
        ></app-plan-service-logs>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="companyNav"></div>
</div>
