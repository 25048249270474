<form [formGroup]="serviceForm">
  <div class="card mb-3">
    <div class="card-header align-items-center bg-theme">
      <h5 class="m-0">CREATE SERVICE</h5>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col col-sm-6">
          <div class="form-group">
            <label class="font-weight-500"
              >Select Service Name<span class="field-mandatory mandatory_Star"
                >*</span
              ></label
            ><br />
            <ng-select
              class="custom"
              [items]="serviceList"
              bindLabel="serviceName"
              formControlName="serviceName"
              appendTo="body"
              [multiple]="false"
              [searchable]="true"
              [clearable]="true"
              [closeOnSelect]="true"
              [loading]="servicesLoder"
              (scrollToEnd)="loadMoreServiceNames()"
              (change)="onChangeServiceName($event)"
              placeholder="Select Service Name"
            >
            </ng-select>
          </div>
        </div>
        <div class="col col-sm-6 mt-3">
          <div class="form-group">
            <label class="font-weight-500">Select Service Template</label><br />
            <ng-select
              class="custom"
              [items]="templatesList"
              bindLabel="name"
              formControlName="serviceTemplate"
              placeholder="Select Service Template"
              [multiple]="false"
              [searchable]="true"
              [loading]="templatesLoder"
              [clearable]="true"
              [closeOnSelect]="true"
              (change)="prefillServiceTemplate($event)"
              (scrollToEnd)="loadMoreServiceTemplates()"
            >
            </ng-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group border rounded p-3">
            <label class="font-weight-500"
              >Select Service Day<span class="field-mandatory mandatory_Star"
                >*</span
              ></label
            >
            <div class="mb-2 mt-1">
              <button
                class="dayButton m-0 mr-2"
                *ngFor="let day of weekDays"
                [ngClass]="{ selected: selectedDays.includes(day.full) }"
                [disabled]="disableDayArr.includes(day.full)"
                (click)="toggleDay(day.full)"
              >
                {{ day.short[0] }}
              </button>
            </div>
          </div>
        </div>

        <div class="col"></div>
      </div>

      <div class="row">
        <div class="col">
          <div class="border rounded p-3">
            <div class="form-group row mb-2 mt-3 align-items-center">
              <label class="col-sm-5 font-weight-500 text-right"
                >Service Start Time<span class="field-mandatory mandatory_Star"
                  >*</span
                ></label
              >
              <div class="col-sm-5">
                <ng-select
                  [items]="time"
                  class="custom"
                  bindLabel="name"
                  formControlName="serviceStartTime"
                  appendTo="body"
                  [multiple]="false"
                  [searchable]="true"
                  [clearable]="true"
                  [closeOnSelect]="true"
                  placeholder="Start Time"
                >
                </ng-select>
              </div>
            </div>
            <div class="form-group row mb-2 mt-3 align-items-center">
              <label class="col-sm-5 font-weight-500 text-right"
                >Service End Time<span class="field-mandatory mandatory_Star"
                  >*</span
                ></label
              >
              <div class="col-sm-5">
                <ng-select
                  [items]="time"
                  class="custom"
                  bindLabel="name"
                  formControlName="serviceEndTime"
                  appendTo="body"
                  [multiple]="false"
                  [searchable]="true"
                  [clearable]="true"
                  [closeOnSelect]="true"
                  placeholder="End Time"
                >
                </ng-select>
              </div>
            </div>
            <div class="form-group row mb-2 mt-3 align-items-center">
              <label class="col-sm-5 font-weight-500 text-right"
                >Confirm Staff Count<span class="field-mandatory mandatory_Star"
                  >*</span
                ></label
              >
              <div class="col-sm-5">
                <input
                  class="form-control"
                  type="number"
                  formControlName="confirmSlot"
                  placeholder="Confirm Slot"
                />
              </div>
            </div>
            <div
              *ngIf="selectedServiceSetup?.standBySlot"
              class="form-group row mb-2 mt-3 align-items-center"
            >
              <label class="col-sm-5 font-weight-500 text-right"
                >StandBy Staff Count</label
              >
              <div class="col-sm-5">
                <input
                  class="form-control"
                  type="number"
                  formControlName="standBySlot"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="col"></div>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-center">
      <button
        class="btn btn-success"
        (click)="addServiceTemplate()"
        [disabled]="!serviceForm.valid || dayDate.length === 0"
      >
        Add Service As Draft
      </button>
      <button class="btn btn-danger ml-1" (click)="resetForm()">Clear</button>
    </div>
  </div>

  <div class="card">
    <h5 class="card-header bg-theme">PLAN SERVICE</h5>
    <div class="card-body" *ngIf="isShowDraft">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col" sortable="Tier 1">Timing/Slot</th>
              <th scope="col" *ngFor="let day of weekDays">{{ day.short }}</th>
              <th scope="col">Action</th>
            </tr>
          </thead>

          <tbody>
            <ng-container>
              <tr *ngFor="let i of draftServiceList; let index = index">
                <td>
                  <span class="m-0"
                    >Time: {{ i.serviceStartTime }} To
                    {{ i.serviceEndTime }}</span
                  >
                  <p class="m-0">
                    Slot:
                    <span class="text-success">{{ i.confirmSlot }}</span>
                    <span
                      *ngIf="selectedServiceSetup?.standBySlot"
                      class="text-danger"
                      >/{{ i.standBySlot }}</span
                    >
                  </p>
                </td>
                <td>
                  <i
                    class="material-icons"
                    *ngIf="i.selectedDays.includes('monday')"
                  >
                    check_box
                  </i>
                </td>
                <td>
                  <i
                    class="material-icons"
                    *ngIf="i.selectedDays.includes('tuesday')"
                  >
                    check_box
                  </i>
                </td>
                <td>
                  <i
                    class="material-icons"
                    *ngIf="i.selectedDays.includes('wednesday')"
                  >
                    check_box
                  </i>
                </td>
                <td>
                  <i
                    class="material-icons"
                    *ngIf="i.selectedDays.includes('thursday')"
                  >
                    check_box
                  </i>
                </td>
                <td>
                  <i
                    class="material-icons"
                    *ngIf="i.selectedDays.includes('friday')"
                  >
                    check_box
                  </i>
                </td>
                <td>
                  <i
                    class="material-icons"
                    *ngIf="i.selectedDays.includes('saturday')"
                  >
                    check_box
                  </i>
                </td>
                <td>
                  <i
                    class="material-icons"
                    *ngIf="i.selectedDays.includes('sunday')"
                  >
                    check_box
                  </i>
                </td>
                <td>
                  <button
                    *ngIf="!isTemplateSelected"
                    class="btn btn-warning ml-1"
                    (click)="removeDraftTemplate(index)"
                  >
                    Remove
                  </button>
                  <button
                    *ngIf="isTemplateSelected"
                    class="btn btn-danger ml-1"
                    (click)="
                      openConfirmationDialog(
                        'Are you sure you want to delete this service from this template?',
                        'SERVICE',
                        index
                      )
                    "
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="card mt-2">
    <h5 class="card-header bg-theme">Auto Scheduler</h5>
    <div class="card-body">
      <div class="auto-schedule mt-3">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="font-weight-500"
                >Do you want to add auto schedule ?<span
                  class="field-mandatory mandatory_Star"
                  >*</span
                ></label
              >
              <div class="form-check form-check-inline ml-2">
                <input
                  class="form-check-input"
                  type="radio"
                  [value]="true"
                  id="yesCheckin"
                  formControlName="isAutoScheduleEnabled"
                  name="isAutoScheduleEnabled"
                />
                <label class="form-check-label">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  [value]="false"
                  id="noCheckin"
                  formControlName="isAutoScheduleEnabled"
                  name="isAutoScheduleEnabled"
                />
                <label class="form-check-label">No</label>
              </div>
            </div>
          </div>
        </div>

        <div formGroupName="autoScheduleConfig">
          <ng-container *ngIf="serviceForm.get('isAutoScheduleEnabled').value">
            <div class="row">
              <div class="col-3">
                <div class="form-group">
                  <label class="font-weight-500">
                    Auto Schedular Start Date
                    <span class="field-mandatory mandatory_Star">*</span>
                  </label>
                  <div class="input-group">
                    <input
                      class="form-control"
                      formControlName="autoSchedularStartDate"
                      placeholder="dd-mm-yyyy"
                      style="max-width: 240px"
                      name="dpStart"
                      ngbDatepicker
                      #startDate="ngbDatepicker"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="startDate.toggle()"
                        type="button"
                      >
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div class="form-group">
                  <label class="font-weight-500">
                    Auto Schedular End Date
                    <span class="field-mandatory mandatory_Star">*</span>
                  </label>
                  <div class="input-group">
                    <input
                      class="form-control"
                      formControlName="autoSchedularEndDate"
                      placeholder="dd-mm-yyyy"
                      style="max-width: 240px"
                      name="dpEnd"
                      ngbDatepicker
                      #endDate="ngbDatepicker"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="endDate.toggle()"
                        type="button"
                      >
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label class="font-weight-500"
                  >Day<span class="field-mandatory mandatory_Star"
                    >*</span
                  ></label
                >
                <div class="form-group">
                  <ng-select
                    [items]="weekDays"
                    bindLabel="full"
                    bindValue="full"
                    class="custom text-capitalize"
                    formControlName="day"
                    appendTo="body"
                    [multiple]="false"
                    [searchable]="true"
                    [clearable]="true"
                    [closeOnSelect]="true"
                    placeholder="Select Day"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="col-3">
                <label class="font-weight-500"
                  >Time<span class="field-mandatory mandatory_Star"
                    >*</span
                  ></label
                >
                <div class="form-group">
                  <ng-select
                    [items]="time"
                    class="custom"
                    bindLabel="name"
                    placeholder="Select Time"
                    formControlName="autoSchedularTime"
                    appendTo="body"
                    [multiple]="false"
                    [searchable]="true"
                    [clearable]="true"
                    [closeOnSelect]="true"
                  >
                  </ng-select>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-center">
        <button class="btn btn-danger ml-1" (click)="clearServices()">
          Clear Plan
        </button>
        <button
          *ngIf="isTemplateSelected"
          class="btn btn-warning ml-1"
          (click)="updateTemplate()"
          [disabled]="!canSaveTemplate()"
        >
          Update Template
        </button>
        <button
          *ngIf="!isTemplateSelected"
          class="btn btn-info ml-1"
          (click)="savePlanTemplate()"
          [disabled]="!canSaveTemplate()"
        >
          Create Template/Auto-Schedule Service
        </button>
        <button
          *ngIf="isTemplateSelected"
          class="btn btn-danger ml-1"
          (click)="
            openConfirmationDialog(
              'Are you sure you want to delete this template?',
              'TEMPLATE',
              ''
            )
          "
        >
          Delete Template
        </button>
        <button
          class="btn btn-success ml-1"
          *ngIf="
            draftServiceList.length !== 0 &&
            !serviceForm.get('isAutoScheduleEnabled').value
          "
          (click)="openWeekPicker()"
        >
          Publish Service Manually
        </button>
      </div>
    </div>

    <div
      class="modal fade"
      [class.show]="deleteServiceTemplate"
      style="display: block"
      tabindex="-1"
      aria-labelledby="confirmationModalLabel"
      aria-hidden="true"
      *ngIf="deleteServiceTemplate"
    >
      <div class="modal-dialog modal-dialog-centered" style="max-width: 400px">
        <div class="modal-content">
          <div class="modal-body text-center">
            <h4
              class="modal-title"
              id="confirmationModalLabel"
              style="font-size: 18px; color: #333"
            >
              {{ confirmationMessage }}
            </h4>
          </div>
          <div class="modal-footer justify-content-center">
            <button
              class="btn btn-primary ml-1"
              style="width: 6rem"
              (click)="deleteTemplate()"
            >
              Yes
            </button>
            <button
              class="btn btn-danger ml-1"
              style="width: 6rem"
              (click)="deleteServiceTemplate = false"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      [class.show]="showWeekPicker"
      style="display: block"
      tabindex="-1"
      aria-labelledby="confirmationModalLabel"
      aria-hidden="true"
      *ngIf="showWeekPicker"
    >
      <div class="modal-dialog modal-dialog-centered" style="max-width: 500px">
        <div class="modal-content">
          <div
            class="modal-body text-center"
            style="padding: 20px; border-radius: 10px"
          >
            <h4
              class="modal-title"
              id="confirmationModalLabel"
              style="font-size: 18px; color: #333"
            >
              Please Select Week
            </h4>
            <div class="form-group mt-3">
              <ng-select
                [items]="weekSelectionList"
                [loading]="weekLoader"
                loadingText="Please wait data is loading..."
                bindLabel="label"
                class="custom"
                placeholder="Select Week"
                appendTo="body"
                [searchable]="true"
                [clearable]="true"
                [closeOnSelect]="true"
                formControlName="serviceWeek"
              >
                <ng-container *ngFor="let item of weekSelectionList">
                  <ng-option [value]="item.label">{{ item.label }}</ng-option>
                </ng-container>
              </ng-select>
            </div>
            <div class="modal-footer justify-content-center">
              <button
                class="btn btn-primary ml-1"
                style="width: 6rem"
                (click)="publishService()"
              >
                Submit
              </button>
              <button
                class="btn btn-danger ml-1"
                style="width: 6rem"
                (click)="showWeekPicker = false"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
