<div *ngIf='!showCreateIndent && !showLogs'>
  <div *ngIf="isLoader"
    style="position: fixed;top: 0;right: 0;bottom: 0;left: 0;z-index: 1050;background-color: rgba(0, 0, 0, 0.5);overflow: auto;">
    <div class="pageLoading" style="text-align: center;color: white;font-size: 700%;">
      <div class="innerDiv" style=" position: absolute; top: 50%; left: 50%;">
        <i class="fa fa-spinner fa-spin" style="float: none;"></i>
      </div>
    </div>
  </div>
  <div class="card-header flexi-header d-flex justify-content-between">
    <h3>{{overViewData?.title || "SATS"}}</h3>
    <div>
      <button *ngIf='(privilegeFlags.manageForm || privilegeFlags.formSupervisor) && editFormData && editFormData.formSubTypeId' class="btn btn-warning mt-1 mx-2" (click)='checkLogs()'>Logs</button>
      <button *ngIf='isCreateIndent && (privilegeFlags.manageForm || privilegeFlags.formSupervisor)' class="btn btn-primary" (click)='createIndent()'>Create Indent</button>
      <button  class="btn btn-info mt-1 mx-2" (click)='refresh()'>Refresh</button>

    </div>
  </div>
  <stats-display [workflow]="editFormData?.workflow" [totalSubmissions]="totalSubmissions" [errorCount]="errorCount" [statuses]="statusesStats">
  </stats-display>
  <filter-display [firstRow]='firstRow' [statusOptions]='statuses' [componentData]="childComponentData"
    (exportCall)="exportCall($event)" (filterCall)="handleFilterCall($event)"
    [customFormId]="customFormId"></filter-display>
  <div>

    <dynamic-table-form [columns]='columns' [hasWorkflow]="hasWorkflow" [componentData]="childComponentData" [total]='totalSubmissions'
      (searchCall)="handleSearchCall($event)" [formSubmissionListData]="formSubmissionListData"
      [customFormId]="customFormId"></dynamic-table-form>
  </div>
</div>
<div *ngIf='showCreateIndent && !showLogs'>
  <admin-create-indent (openManage)='openManage(data)' [formData]='formData'></admin-create-indent>
</div>

<div *ngIf='showLogs && !showCreateIndent'>
  <admin-form-logs (openManage)='openManage(data)' [customFormId]="customFormId" [formName]='overViewData?.title'></admin-form-logs>
  </div>
