import { element } from 'protractor';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CustomFormService } from 'src/app/users/service/custom-form/custom-form.service';
import { debounceTime, finalize, switchMap, filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { CentralBuilderService } from 'src/app/users/service/central-builder/central-builder.service';
import { FormControl } from '@angular/forms';
import * as Papa from 'papaparse';
import * as XLSX from 'xlsx';


@Component({
  selector: 'admin-create-indent',
  templateUrl: './admin-create-indent.html',
  styleUrls: ['./admin-create-indent.scss'],
})
export class AdminCreateIndentComponent implements OnInit {
  @Input() formData: any = null; //{title, customFormId}

  serviceName: string = null;
  staffId: string = null;
  customFormId: string = null;
  moduleId: string = null;
  questionData: any = [];
  htmlData = [];
  isFormSubmitted;
  typeFourteenQuestion;
  questionIDFourteen;
  viewQuestions: any = [];
  public isModuleView: boolean = false;
  selectedModule;
  sectionList;
  firstSection: string;
  questions: any = [];
  submissionList: any = [];
  isLoader: boolean = false;
  filteredStaffIds: any = [];
  isDataSubmitted = false;

  fileToUpload: File | null = null;
  jsonData: any[] = [];

  staffIdControl: FormControl;
  @Output() openManage = new EventEmitter<any>();

  constructor(
    private customFormService: CustomFormService,
    private toastService: ToastrService,
    private centralBuilderService: CentralBuilderService
  ) {
    this.staffIdControl = new FormControl();
  }

  onStaffIdChange(event: Event): void {
    const input = (event.target as HTMLInputElement).value;
    if (!input) {
      this.filteredStaffIds = [];
    }
  }

  fetchStaffIds(query: string) {
    return this.customFormService.searchUserByText(query);
  }

  setChild(question, answer, index: number) {
    if (question.type == 11 && this.questionData[index]) {
      this.questionData[index].answer = answer;
      this.questionData[index].isVisibleList =
        !this.questionData[index].isVisibleList;
    }
    this.checkQuestions(question, answer._id);
  }

  checkQuestions(question, a) {
    // let q = question._id;
    // const filteredData = this.questions
    //   ?.filter(
    //     v =>
    //       !this.hasParent(v) &&
    //       v?.conditionalQuestions?.some(
    //         c => c.questionId?._id === q && c.optionId?._id === a
    //       )
    //   );
    // const filteredQuestions =  filteredData?.map(c => {
    //     c["cQuestion"] = q;
    //     c["cOption"] = a;
    //     return JSON.parse(JSON.stringify(c));
    //   });
    // if (question.type == 5) {
    //   setTimeout(() => {
    //     if (!question.options.filter(option => option._id === a)[0].answer) {
    //       let allQuestionsadded = filteredQuestions.map(v => v._id);
    //       let questionsNeedToDelete = this.viewQuestions?.reduce((prev, curr, i) => {
    //         return allQuestionsadded.includes(curr._id) ? prev.concat(i) : prev;
    //       }, []);
    //       questionsNeedToDelete.sort((a, b) => b - a);
    //       questionsNeedToDelete.forEach(v => {
    //         this.viewQuestions.splice(v, 1);
    //       })
    //     } else {
    //       const index = this.viewQuestions?.findIndex(ques => ques._id == q) + 1;
    //       this.viewQuestions.splice(index, 0, ...filteredQuestions);
    //     }
    //     this.viewQuestions = this.viewQuestions?.reduce((prev, curr, i) => {
    //       const index = prev.findIndex(v => v._id === curr._id);
    //       return -1 === index ? prev.concat(curr) : prev;
    //     }, []);
    //   }, 100);
    // } else {
    //   setTimeout(() => {
    //     this.viewQuestions = this.viewQuestions?.filter(ques =>
    //       "cQuestion" in ques ? ques.cQuestion !== q : true
    //     );
    //     const index = this.viewQuestions.findIndex(ques => ques._id == q) + 1;
    //     this.viewQuestions.splice(index, 0, ...filteredQuestions);
    //     this.viewQuestions = this.viewQuestions.reduce((prev, curr, i) => {
    //       const index = prev.findIndex(v => v._id === curr._id);
    //       return -1 === index ? prev.concat(curr) : prev;
    //     }, []);
    //   })
    // }
  }

  multiSelect(value, question) {
    question.answer = value?.map((x) => {
      if (this.typeFourteenQuestion?.find((qus) => qus._id == x)) {
        return this.typeFourteenQuestion?.find((qus) => qus._id == x);
      }
    });
  }

  getNominateUserType(questionId) {
    let finalData = {
      questionId: questionId,
    };
    this.centralBuilderService.getNominateUser(finalData).subscribe((data) => {
      this.setDropdown(data.data, questionId);
    });
  }

  setDropdown(data, questionId) {
    this.typeFourteenQuestion = data?.items || [];
    this.questionIDFourteen = questionId;
  }

  fileUpload(event, question) {
    let typeList =
      question.options?.map((data) => {
        return data.value.toLowerCase();
      }) || [];
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
      let fileType = file.name.split('.');
      let fileExe = fileType?.length
        ? fileType[fileType.length - 1].toLowerCase()
        : null;
      if (fileExe && typeList?.includes(fileExe)) {
        var useraddData = new FormData();
        useraddData.append('file', file);
        const fileUpload = this.customFormService
          .uploadFiles(useraddData)
          .pipe(
            finalize(() => {
              fileUpload.unsubscribe();
            })
          )
          .subscribe(
            (data: any) => {
              question.answer = {
                url: data?.filePath || null,
                fileName: file.name,
              };
            },
            (error: HttpErrorResponse) => {
              this.toastService.error(error?.message || 'File upload error');
            }
          );
      } else {
        this.toastService.error(
          'Only this format are accecpted',
          typeList?.toString()
        );
      }
      // }
    } else {
      this.toastService.error(
        'Only this format are accecpted',
        typeList?.toString()
      );
    }
  }

  ngOnInit(): void {
    this.staffIdControl.valueChanges
      .pipe(
        debounceTime(300),
        filter((value) => value !== null && value.trim() !== ''), // Debounce input to avoid excessive API calls
        switchMap((value) => this.fetchStaffIds(value)) // Fetch staff IDs from the API
      )
      .subscribe(
        ({ data }) => {
          this.filteredStaffIds = data;
        },
        (error) => {
          console.error('Error fetching staff IDs', error);
        }
      );
  }

  onOptionSelected(event: any) {
    const selectedStaffId = event.option.value;
    this.staffId = selectedStaffId;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formData) {
      this.formData = changes.formData.currentValue;
      this.serviceName = this.formData.title;
      this.customFormId = this.formData.customFormId;
      this.moduleId = this.formData.moduleId;
      this.getQuestion();
    }
  }

  getQuestion() {
    this.isLoader = true;
    this.customFormService
      .getQuestionList(this.customFormId, this.moduleId, 'submit')
      .subscribe(
        ({ data }) => {
          // pending
          this.questionData = data.sections;
          this.isModuleView = true;
          this.selectedModule = data || null;
          this.sectionList = this.selectedModule.sections || [];
          let sectionList = this.selectedModule.sections;
          this.firstSection = sectionList[0]?.sectionName;
          for (let i = 0; i < sectionList.length; i++) {
            const section = sectionList[0];
            sectionList[0].questions =
              section.questions.filter((v) => 7 !== v.type) || [];
            this.viewQuestions =
              sectionList[0].questions.filter(
                (x) => 0 === x.conditionalQuestions.length
              ) || [];
          }
          let typeFourteen = null;
          this.viewQuestions?.map((item) => {
            item.answer = null;
            if (item.type === 10) {
              item.answer = {};
              item.answer['date'] = null;
              item.answer['time'] = null;
            }
            if (item.type === 14) {
              typeFourteen = item._id;
            }
            if (item.type === 15) {
              item?.options.map((final: any, index) => {
                item['images'][index] = {
                  src: final.imageSrc,
                  value: final.value,
                  option: final._id,
                };
              });
            }
          });
          typeFourteen && this.getNominateUserType(typeFourteen);
          this.selectedModule.sections = sectionList;
          this.questions = sectionList;
          this.isLoader = false;
        },
        (error) => {
          this.isLoader = false;
        }
      );
  }

  openDialog(question: any): void {
    // Handle the logic for opening a signature dialog or similar
  }

  saveDetails() {
    this.isLoader = true;

    let isError: any = false;

    if (!this.staffId) {
      this.toastService.error('Select name of staff to continue');
      this.isLoader = false;
      return;
    }

    this.viewQuestions.forEach((question) => {
      if (question.isNumeric && question.type === 8) {
        isError =
          question.answer?.length !== question.maxlength
            ? 'Please enter valid postal code'
            : false;
      }
    });

    if (isError) {
      this.toastService.error(isError);
      this.isLoader = false;
      return;
    }

    const answer = this.viewQuestions.map((item) => {
      return {
        sectionId: item.sectionId,
        questionId: item._id,
        option: item.answer?._id || item.answer?.option,
        value: item.answer?.value || item.answer,
        type: item.type,
        required: item.required,
      };
    });

    if (answer.some((v) => !v.value && v.required)) {
      this.toastService.error('Answer all Mandatory Question');
      this.isLoader = false;
      return;
    }
    let submitList = [];
    this.submissionList.forEach((data) => {
      const element = data?.finalAnswers;
      if (
        element[1].value == this.staffId ||
        (element[1].value == this.staffId &&
          answer[0]?.value === element[0].value)
      )
        submitList.push('Test');
      // });
    });

    if (submitList.length > 0) {
      this.toastService.error('Duplicate submission not allowed!');
      this.isLoader = false;
      return;
    }

    const dataObj = {
      customFormId: this.customFormId,
      staffId: this.staffId,
      answers: answer,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    this.customFormService.indentDraft(dataObj).subscribe(
      ({ data }) => {
        this.submissionList = [...this.submissionList, data];
        this.toastService.success('Answer submitted successfully');
        this.isLoader = false;
        this.viewQuestions.forEach((item) => {
          item.answer = '';
        });
        this.staffIdControl.reset();
        this.staffId = null;
        this.filteredStaffIds = [];
        this.staffIdControl.setValue('');
      },
      (error) => {
        this.toastService.error(
          error?.error?.error?.message || 'Something went wrong, Try again'
        );
        this.isLoader = false;
      }
    );
  }

  onInputChangeShortText(event: any, isNumeric: boolean) {
    if (isNumeric) {
      // Allow only numeric keys, backspace, tab, arrow keys, and delete
      const allowedKeys = [
        'Backspace',
        'Tab',
        'ArrowLeft',
        'ArrowRight',
        'Delete',
      ];

      // Check if the key pressed is a number (0-9) or allowed control keys
      if (
        !allowedKeys.includes(event.key) &&
        !(event.key >= '0' && event.key <= '9')
      ) {
        // Allow Ctrl+V or Cmd+V for paste operation
        if (
          !(event.ctrlKey && event.key === 'v') &&
          !(event.metaKey && event.key === 'v')
        ) {
          event.preventDefault();
        }
      }
    }
  }

  onPaste(event: ClipboardEvent, isNumeric: boolean, len: number, item) {
    if (isNumeric) {
        let pastedData: string = event.clipboardData?.getData('text') || '';
        pastedData = pastedData.trim();
        pastedData = pastedData.replace(/^'|"|'$/g, ''); // Remove extra quotes

        // Allow only numeric pasted data
        if (!/^\d+$/.test(pastedData)) {
            event.preventDefault(); // Prevent non-numeric characters
        } else {
          item.answer = pastedData
        }
    }
}

  onSave(event) {
    this.isFormSubmitted = event.isDataSubmitted;
    this.staffIdControl.disable();
  }

  goToManage() {
    this.openManage.emit(true);
  }

  importFile() {}
  // Store the selected file
  importBookingFile(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  // Upload and process the CSV file

async importBookingData(fileInput: HTMLInputElement): Promise<void> {
  if (!this.fileToUpload) {
    this.toastService.error('Please select a file first!');
    return;
  }

  const fileExtension = this.fileToUpload.name.split('.').pop()?.toLowerCase();

  // Validate the file format
  if (!['xlsx', 'xls'].includes(fileExtension || '')) {
    this.toastService.error('Invalid file format. Please upload an Excel file.');
    return;
  }

  // Read the file as an array buffer
  const reader = new FileReader();
  reader.onload = (event: any) => {
    const data = new Uint8Array(event.target.result);
    const workbook = XLSX.read(data, { type: 'array' });

    // Process the first sheet
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];

    // Convert sheet data to JSON
    const excelData = XLSX.utils.sheet_to_json(worksheet, { defval: '' });

    // Further process the data as needed
    this.processImportedData(excelData, fileInput);
  };

  reader.readAsArrayBuffer(this.fileToUpload);
}


  async processImportedData(csvtoJSON: any [], fileInput: any) {

    const emptyValueRow = this.checkForEmptyOrMissingValues(csvtoJSON);

    if (emptyValueRow.length) {
      this.toastService.error(
        'Invalid records in the import file. Please check and retry.'
      );
      fileInput.value = '';
      return;
    }

    const hasDuplicates = csvtoJSON.reduce(
      (acc, item) => {
        if (acc.seen.has(item['staff Id'])) {
          acc.duplicateFound = true;
        } else {
          acc.seen.add(item['staff Id']);
        }
        return acc;
      },
      { seen: new Set(), duplicateFound: false }
    ).duplicateFound;

    if (hasDuplicates) {
      this.toastService.error(
        'Duplicate staff IDs found. Please check and try again.'
      );
      fileInput.value = '';
      return;
    }

    const staffIdsArr1 = this.submissionList
      .map((obj) => {
        const staffAnswer = obj.finalAnswers.find(
          (answer) => answer.title === 'Staff Id'
        );
        return staffAnswer?.value || null;
      })
      .filter(Boolean); // Remove null/undefined values
    if(staffIdsArr1?.length){
    // Extract staff Ids from arr2
    const staffIdsArr2 = csvtoJSON.map((obj) => obj['staff Id']);

    // Use a Set to check for common elements
    const staffIdsSet = new Set(staffIdsArr1);

    const hasCommonStaffId = staffIdsArr2.some((id) => staffIdsSet.has(id));

    if(hasCommonStaffId){
      this.toastService.error('Some staff IDs already exist in the draft list. Please remove them from the draft or the CSV file.')
      fileInput.value = '';
      return
    }
    }

    const payload = {
      customFormId: this.customFormId,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      userInfo: this.generatePayload(csvtoJSON, this.viewQuestions),
    };

    const hasError = payload.userInfo.some((item) => item.isError);
    if (hasError) {
      this.toastService.error(
        'Incorrect data found in the file. Please review and retry.'
      );
      fileInput.value = '';
      return;
    }

    let isError: any = false;
    payload.userInfo.forEach((item) => {
      item.answers.forEach((question) => {
        if (question.type === 8 && question.isNumeric && !isError) {
          isError =
            !this.isNumeric(question.value) ||
            question.value?.toString().length !== question.maxlength
              ? 'Please enter valid postal code'
              : false;
        }
        delete question.isNumeric;
      });
    });
    if (isError) {
      this.toastService.error(isError);
      return;
    }

    this.customFormService.indentDraftMultiple(payload).subscribe(
      ({ data }) => {
        this.submissionList = [...this.submissionList, ...data];
        this.toastService.success('Imported data successfully');
        this.isLoader = false;
        this.viewQuestions.forEach((item) => {
          item.answer = '';
        });
        this.staffIdControl.reset();
        this.staffId = null;
        fileInput.value = '';
      },
      (error) => {
        this.toastService.error(error?.error?.error?.message);
        this.isLoader = false;
        fileInput.value = '';
      }
    );
  }

  isNumeric(str) {
    return /^[0-9]+$/.test(str);
  }

  generatePayload(arr1, arr2) {
    let isError = false;
    const result = arr1.map((staff) => {
      const answers = Object.keys(staff).reduce((acc, key) => {
        const questionObj = arr2.find((q) => q.question.includes(key));
        if (questionObj) {
          let option = null;
          if (questionObj.type === 11) {
            const matchingOption = questionObj.options.find(
              (opt) => opt.value === staff[key].trim()
            );
            option = matchingOption ? matchingOption._id : null;
            if (!option) {
              isError = true;
            }
          }

          acc.push({
            sectionId: questionObj.sectionId,
            questionId: questionObj._id,
            option: option,
            value: staff[key],
            type: questionObj.type,
            required: questionObj.required,
            isNumeric: questionObj.isNumeric,
            maxlength: questionObj.maxlength,
          });
        }
        return acc;
      }, []);

      return {
        staffId: staff['staff Id'],
        answers,
        isError,
      };
    });
    return result;
  }

  checkForEmptyOrMissingValues(data: any[]): any[] {
    const invalidRows = data.filter((row, index) => {
      // Check if any field is empty or missing
      return Object.values(row).some(
        (value) => value === null || value === '' || value === undefined
      );
    });
    return invalidRows; // Return rows with invalid fields
  }

  // Convert CSV to JSON using PapaParse
  importDataFromCSV(csvText: string) {
    const parsedData = Papa.parse(csvText, {
      header: true, // Treats the first row as header
      skipEmptyLines: true, // Skips empty rows
    });
    return parsedData.data;
  }

  exportFileFormat() {
    const newColumn = this.viewQuestions.reduce((acc, item) => {
      const key = item.question.replace(/<[^>]+>/g, '').trim(); // Create a clean key by removing HTML tags
      acc[key] = item.options[0] ? item.options[0]?.value : '123456'; // Set the key with the appropriate value
      return acc; // Return the accumulator for the next iteration
    }, {});
    const columns = [{ 'staff Id': 'test200', ...newColumn }];
    this.downloadFile(columns, `format-file-${this.serviceName}`);
  }

  downloadFile(data: any[], filename: string = 'format-file') {
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Set all cells globally to text format
    for (const cellAddress in worksheet) {
      if (cellAddress[0] === '!') continue; // Skip meta properties like !ref, !cols, etc.

      const cell = worksheet[cellAddress];
      if (cell) {
        cell.t = 's'; // Set cell type to string
        cell.z = '@'; // Set cell format to text
      }
    }

    // Set column widths (optional)
    const headers = Object.keys(data[0] || {});
    const columnWidth = 20;
    worksheet['!cols'] = headers.map(() => ({ wch: columnWidth }));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Write the Excel file
    const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelData], { type: 'application/octet-stream' });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', `${filename}.xlsx`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }


  private convertToCsv(objArray: any): string {
    const delimiter = ',';
    const headers = Object.keys(objArray[0]); // Get headers from the first object
    const csvRows = objArray.map((obj) => {
      return headers
        .map((header) => {
          const value = obj[header] ? obj[header] : ''; // Handle undefined or null values
          return `"${value}"`; // Wrap values in quotes
        })
        .join(delimiter); // Join columns with delimiter
    });

    return `${headers.join(delimiter)}\n${csvRows.join('\n')}`; // Join rows with new lines
  }
}
