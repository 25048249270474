import {
  Component,
  EventEmitter,
  OnInit,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  takeUntil,
} from 'rxjs/operators';
import { NgbdSortableHeader } from 'src/app/core/directive/sortable.directive';
import { SortEvent } from 'src/app/shared/directive/sortable.directive';
import { SetupService } from 'src/app/users/service/operations/setup.service';

@Component({
  selector: 'app-service-setup-overview',
  templateUrl: './service-setup-overview.component.html',
})
export class ServiceSetupOverviewComponent implements OnInit, OnDestroy {
  @Output() changeActiveEdit = new EventEmitter<any>();
  @Output() changeActiveView = new EventEmitter<any>();
  serviceTable: any = {
    sortBy: 'desc',
    sortWith: 'createdBy',
    data: [],
    page: 1,
    limit: 10,
    search: '',
  };
  serviceTableErr = false;
  loader = false;
  paginatedData: any[] = [];
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  private destroy$ = new Subject<void>();

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  public serviceSetUpSearchInput = new Subject<KeyboardEvent>();

  constructor(
    private setupService: SetupService,
    private toastService: ToastrService
  ) {
    this.serviceSetUpSearchInput
      .pipe(
        map((event: KeyboardEvent) => (event.target as HTMLInputElement).value),
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.serviceTable.page = 1;
        this.getServiceSetUp();
      });
  }

  ngOnInit(): void {
    this.getServiceSetUp();
  }

  onLimitChangeServiceSetUp(): void {
    this.getServiceSetUp();
  }

  getServiceSetUp(): void {
    this.loader = true;
    const { search, page, limit, sortWith, sortBy } = this.serviceTable;

    this.setupService
      .getServiceSetupDetails({ search, page, limit, sortWith, sortBy })
      .pipe(
        finalize(() => {
          this.loader = false;
        })
      )
      .subscribe(
        (response: any) => {
          const { data, totalRecords } = response.data;
          this.serviceTable.data = data;
          this.serviceTable.total = totalRecords;
          this.paginatedData = this.serviceTable.data;
        },
        () => {
          this.serviceTable.data = [];
          this.serviceTable.total = 0;
          this.toastService.error('No list found');
          this.serviceTableErr = true;
        }
      );
  }

  onSortServiceSetup({ column, direction }: SortEvent): void {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.serviceTable.sortWith = column;
    this.serviceTable.sortBy = direction;
    this.getServiceSetUp();
  }

  onPageChangeServiceSetUp(page: number): void {
    this.serviceTable.page = page;
    this.getServiceSetUp();
  }

  onEditServiceSetup(value: any): void {
    this.changeActiveEdit.emit(value);
  }

  onViewServiceSetup(value: any): void {
    this.changeActiveView.emit(value);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getStatus(status: number): string {
    switch (status) {
      case 1:
        return 'Active';
      case 2:
        return 'Inactive';
      default:
        return 'Unknown';
    }
  }
}
