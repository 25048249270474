<div class="card mb-3" *ngIf="!isModuleView">
  <div class="card-header flexi-header p-4">
    <h3>FORMS</h3>
  </div>
  <div class="card-body">
    <div class="card table-card">
      <div class="card-body flexi-body">
        <div class="flex-column">
          <div class="d-flex justify-content-between mt-2 mb-3">
            <select class="custom-select" style="width: auto" name="pageSize" (change)="onLimitChangeUsers()"
              [(ngModel)]="usersPTable.limit">
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="30">30</option>
              <option [ngValue]="40">40</option>
              <option [ngValue]="50">50</option>
            </select>
            <div class="form-group form-inline mb-0">
              <span *ngIf="usersPTable.isLoading">Please wait...</span>
              <input class="form-control ml-2" type="search" name="searchTerm" (search)="userssearchInput.next($event)"
                (keyup)="userssearchInput.next($event)" placeholder="Search here" [(ngModel)]="usersPTable.search" />
            </div>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" sortable="_id" (sort)="onSortUsers($event)">
                    Date
                  </th>
                  <th scope="col" sortable="title" (sort)="onSortUsers($event)">
                    FORM TITLE
                  </th>
                  <th scope="col">
                    DEPLOYED
                  </th>

                  <th scope="col" sortable="authorName" (sort)="onSortUsers($event)">
                    AUTHOR
                  </th>
                  <th scope="col" sortable="status" (sort)="onSortUsers($event)">
                    STATUS
                  </th>

                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody *ngIf="!isTableLoading">

                <tr *ngFor="let item of usersPTable.data">
                  <td>
                    {{ item.createdAt |date:"medium" }}
                  </td>

                  <td>{{ item.title }}</td>

                  <td>{{ item.isDeployed ===1 ? 'Mobile and Webapp ': 'External' }}</td>
                  <td>
                    {{ item.authorName }}
                  </td>
                  <td>
                    <span class="badge" [ngClass]="
                        item.status == 1
                          ? 'badge-success'
                          : 'badge-danger'
                      ">
                      {{
                        item.status === 1 ? "Published" : "In Active"
                      }}</span>
                  </td>
                  <td>
                    <div class="d-flex justify-content-between">
                      <button *ngIf='privilegeFlags.setUpForm' class="btn btn-submit p-2 m-1 btn-block" (click)="getSingleFormData(item, 1)">View</button>
                      <button *ngIf='privilegeFlags.setUpForm' class="btn btn-add p-2 m-1  btn-block" (click)="getSingleFormData(item, 2)">Edit</button>
                      <button *ngIf='privilegeFlags.manageForm && item.hasWorkflow' class="btn btn-primary p-2 m-1  btn-block" (click)="onExportForm(item)">Export</button>
                      <button *ngIf='privilegeFlags.manageForm || privilegeFlags.formSupervisor || privilegeFlags.exportManageFormOnly' class="btn btn-warning p-2 m-1  btn-block" (click)="onManage(item)">Manage</button>
                      <div *ngIf="item.formUrl" class="url" (click)="onExternalURL(item)"><i class="fa fa-link" aria-hidden="true"></i></div>
                      <div *ngIf="item.formUrl" aria-hidden="true" placement="left" [ngbPopover]="customPopoverContent"
                        popoverTitle="Scan QR Code" class="qr-code ml-2">
                        <i class="fa fa-qrcode"></i>
                      </div>
                      <ng-template #customPopoverContent>
                        <div>
                          <ngx-qrcode [elementType]="'url'" [value]="qrCodeUrl(item.formUrl)" cssClass="aclass" errorCorrectionLevel="M">
                          </ngx-qrcode>
                        </div>
                      </ng-template>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <app-skliton-loading-table [numberOfCols]="6" [isTableLoading]="isTableLoading"></app-skliton-loading-table>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <p>Total records {{ usersPTable.total }}</p>
          <ngb-pagination [collectionSize]="usersPTable.total" [(page)]="usersPTable.page" [maxSize]="5"
            (pageChange)="onPageChangeUsers($event)" [pageSize]="usersPTable.limit">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card" *ngIf="isModuleView">
  <div class="card-header flexi-header pt-4">
    <h3 style="display: inline">
      {{ selectedModule?.moduleName | uppercase }}
    </h3>
    <button class="btn pull-right" style="color: ivory" (click)="isModuleView = false">
      Back
    </button>
  </div>

  <div class="card-body" style="word-break: break-word; padding: 10px">
    <div class="box d-flex flex-column" *ngFor="let item of selectedModule?.questions; let i = index">
      <div class="d-flex">
        <strong class="mr-1">{{ i + 1 }} </strong>
        <div class="box-heading" style="word-break: break-word">
          &emsp; <p [innerHTML]="item?.question | safeHtml"></p>
        </div>
      </div>
      <div class="box-body" *ngIf="item.type === 1">
        <div class="d-flex flex-column answers-options">
          <div class="radio radio-danger radio-inline" *ngFor="let option of item.options">
            <input type="radio" class="disabled" />
            <label for="consolidated">{{ option.value }}</label>
          </div>
        </div>
      </div>

      <div class="box-body" *ngIf="item.type === 2">
        <div class="d-flex flex-column answers-options">
          <div class="checkbox checkbox-danger checkbox-inline" *ngFor="let option of item.options">
            <input type="checkbox" class="disabled" />
            <label for="consolidated">{{ option.value }}</label>
          </div>
        </div>
      </div>

      <div class="box-body" *ngIf="item.type === 3">
        <div class="d-flex flex-column answers-options">
          <div class="radio radio-danger radio-inline" *ngFor="let item of item.options">
            <input type="radio" class="disabled" />
            <label for="consolidated"> {{ item.value }}</label>
          </div>
        </div>
      </div>
      <div class="box-body" *ngIf="item.type === 4">
        <div class="d-flex flex-column answers-options">
          <div class="radio radio-danger radio-inline">
            <input type="radio" class="disabled" />
            <label for="consolidated"> True</label>
          </div>
          <div class="radio radio-danger radio-inline">
            <input type="radio" class="disabled" />
            <label for="consolidated"> False</label>
          </div>
        </div>
      </div>

      <div class="box-body" *ngIf="item.type === 5">
        <div class="d-flex flex-row answers-options">
          <textarea cols="30" class="disabled form-control flex-fill" rows="5"></textarea>
          <div class="flex-fill" style="width: 100%"></div>
        </div>
      </div>

      <div class="box-body" *ngIf="item.type === 6">
        <div class="d-flex flex-wrap answers-options">
          <div class="form-group flex-fill" style="margin: 1rem 1rem; width: 44%" *ngFor="let item of item.profile">
            <label for="my-input">{{ item.questionName }}</label>
            <input id="my-input" class="form-control" type="text" />
          </div>
        </div>
      </div>
      <div class="box-body" *ngIf="item.type === 7">
        <div class="d-flex flex-wrap answers-options">
          <div class="form-group flex-fill" style="margin: 1rem 1rem; width: 44%">
            <textarea class="form-control" rows="3"></textarea>
          </div>
        </div>
      </div>
      <div class="box-body" *ngIf="item.type === 8">
        <div class="d-flex flex-wrap answers-options">
          <div class="form-group flex-fill" style="margin: 1rem 1rem; width: 44%">
            <textarea class="form-control" rows="5"></textarea>
          </div>
        </div>
      </div>
      <div class="box-body" *ngIf="item.type === 9">
        <div class="d-flex flex-wrap answers-options">
          <div class="form-group flex-fill" style="margin: 1rem 1rem; width: 44%">
            <input type="number" class="form-control" />
          </div>
        </div>
      </div>
      <div class="box-body" *ngIf="item.type === 10">
        <div class="form-group">
          <div class="d-flex flex-wrap answers-options">
            <div class="form-group flex-fill" style="margin: 1rem">
              <label for="my-input">Date</label>
              <input id="my-input" class="form-control" type="date" />
            </div>
            <div class="form-group flex-fill" style="margin: 1rem">
              <label for="my-input">Hours</label>
              <select name="" id="" class="form-control"></select>
            </div>
            <div class="form-group flex-fill" style="margin: 1rem">
              <label for="my-input">Minutes</label>
              <select name="" id="" class="form-control"></select>
            </div>
            <div class="form-group flex-fill" style="margin: 1rem">
              <label for="my-input">Seconds</label>
              <select name="" id="" class="form-control"></select>
            </div>
          </div>
        </div>
      </div>
      <div class="box-body" *ngIf="item.type === 11">
        <select class="form-control" [attr.disabled]="true"></select>
      </div>
      <div class="box-body" *ngIf="item.type === 12">
        <div class="form-group">
          <input id="my-input" class="form-control-file" type="file" />
        </div>
        Preffered formats are -
        <span *ngFor="let item of item?.options">
          {{ item.value }}<span *ngIf="item.length != 0"> </span>
        </span>
      </div>
      <div class="box-body" *ngIf="item.type === 13">
        <div class="d-flex">
          <ngb-rating [max]="item.value" [rate]="0"></ngb-rating>
        </div>
      </div>
      <div class="box-body" *ngIf="item.type === 14">
        <select class="form-control" [attr.disabled]="true"></select>
      </div>
      <div class="box-body" *ngIf="item.type === 15">


        <div class="row" ng-model="item.options">
          <div *ngFor="let option of item.options">
            <div class="thumbnail">
              <img ng-if="option.imageSrc" [src]="option.imageSrc" style="height: 150px" crossorigin="anonymous" />
              <div class="caption">
                <p>{{ option.value }}</p>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div *ngIf="selectedModule?.questions.length == 0" style="text-align: center">
        No questions to show
      </div>
    </div>
  </div>
</div>


<ng-template #exportForm>
  <div>
    <div class="modal-content">
      <div class="modal-body" style="padding: 10px; min-height: 100vh">
        <div>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3>EXPORT FORM</h3>
              <button type="button" class="btn" aria-label="Close" (click)="modalClose()">
                <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i>
              </button>
            </div>
            <div class="card-body">
              <form [formGroup]="FormExport">
                <div class="row">
                  <div class="col-md-6">
                      <div class="form-group">
                        <label for="startdate">Select date From</label>
                        <div class="input-group">
                          <input class="form-control" placeholder="yyyy-mm-dd" style="max-width: 240px;" name="dp"
                           formControlName="fromDate" ngbDatepicker #d="ngbDatepicker">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                              <i class="fa fa-calendar" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="form-group">
                        <label for="startdate">Select date To</label>
                        <div class="input-group">
                          <input class="form-control" placeholder="yyyy-mm-dd" style="max-width: 240px;" name="dp"
                          formControlName="toDate" ngbDatepicker #c="ngbDatepicker">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="c.toggle()" type="button">
                              <i class="fa fa-calendar" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                <div class="row mt-5">
                  <ng-container>
                    <div class="col-md-6" *ngFor="let item of workFlow">
                      <label class="font-weight-bold">{{item.title |uppercase}}</label>
                      <ng-select (change)="addWorkFlowStatus($event)" class="custom" bindLabel="name"
                        [placeholder]="item.title" appendTo="body" [multiple]="false" [searchable]="true"
                        [clearable]="true">
                        <pre>{{item |json}}</pre>
                        <ng-option [value]="item" *ngFor="let item of  item.workflowStatus">
                          {{ item.field }}
                        </ng-option>
                      </ng-select>
                    </div>
                  </ng-container>

                </div>
              </form>

              <div class="row mt-5">
                <div class="col-md-12  d-flex justify-content-center">
                  <button class="btn btn-submit d-block" (click)="onSubmit()">
                    <b>Export</b>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
