export const tableParam = {
  draw: 0,
  columns: [],
  order: [{
    column: 0,
    dir: 'desc'
  }],
  start: 0,
  length: 10,
  search: {
    value: '',
    regex: false
  }

};
export const tableParamLog = {
  draw: 0,
  columns: [],
  order: [{
    column: 0,
    dir: 'desc'
  }],
  start: 0,
  length: 10,
  search: {
    value: '',
    regex: false
  }

};

export const transportTableParam = {
  page: 1,
  sortBy: 'asc',
  limit: 10,
  sortWith: '_id',
  search: ""
};
