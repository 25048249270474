<div class="card">
  <div class="card-header align-items-center bg-theme">
    <h5 class="m-0">VIEW SERVICES</h5>
    <p class="m-0">Selected Service: {{ selectedServiceName.serviceName }}</p>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-5">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <label for="" class="font-weight-500">Select Day</label><br />
              <button
                *ngFor="let day of daysOfWeek"
                class="dayButton"
                [class.selected]="day === selectedDay"
                (click)="onDaySelect(day)"
              >
                {{ day.name }}
              </button>
            </div>

            <ng-container *ngIf="dayServices?.length; else noItemsFound">
              <div
                *ngFor="let service of dayServices"
                class="serviceTab my-2"
                [class.selectedService]="service === selectedService"
                (click)="onServiceSelect(service)"
              >
                <div class="d-flex justify-content-between">
                  <p>{{ selectedServiceName.serviceName }}</p>
                  <div class="text-secondary">
                    <span class="m-0">
                      {{ service.serviceStartDateTime | date : "HH:mm" }}
                    </span>
                    <span
                      *ngIf="selectedServiceName.showServiceEndTime"
                      class="m-0"
                    >
                      To {{ service.serviceEndDateTime | date : "HH:mm" }}
                    </span>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex">
                    <div
                      *ngIf="service.status === 2"
                      class="badge badge-danger badge-pill me-2"
                    >
                      Cancelled
                    </div>
                  </div>
                  <div class="badge badge-pill bg-theme text-white ms-auto">
                    {{ service.confirmSlot }}/{{ service.standBySlot }}
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #noItemsFound>
              <h5 class="text-muted">No items found</h5>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="col-md-6" *ngIf="dayServices?.length">
        <div class="card">
          <div class="card-header flexi-header">
            <h3>CREATE SERVICE</h3>
          </div>
          <div class="card-body">
            <form [formGroup]="serviceDetailsForm" *ngIf="!isCreatingForm">
              <div class="mb-3 col-md-12">
                <div class="form-input mb-2">Service Name</div>
                <input
                  class="form-control"
                  formControlName="serviceName"
                  readonly
                />
              </div>
              <div class="mb-3 row mr-0 ml-0">
                <div class="col">
                  <label class="form-input mb-2">Date</label>
                  <input class="form-control" formControlName="date" readonly />
                </div>
                <div class="col">
                  <label class="form-input mb-2">Start Time</label>
                  <input
                    class="form-control"
                    formControlName="startTime"
                    readonly
                  />
                </div>
                <div *ngIf="selectedServiceName.showServiceEndTime" class="col">
                  <label class="form-input mb-2">End Time</label>
                  <input
                    class="form-control"
                    formControlName="endTime"
                    readonly
                  />
                </div>
              </div>
              <div class="mb-3 col-md-12">
                <div class="form-input mb-2">Created By</div>
                <input
                  class="form-control"
                  formControlName="createdBy"
                  readonly
                />
              </div>
              <div class="mb-3 row ml-0 mr-0">
                <div class="col">
                  <label class="form-input mb-2">Confirm Slot</label>
                  <input
                    class="form-control"
                    formControlName="confirmSlot"
                    readonly
                  />
                </div>
                <div *ngIf="selectedServiceName.standBySlot" class="col">
                  <label class="form-input mb-2">StandBy Slot</label>
                  <input
                    class="form-control"
                    formControlName="standBySlot"
                    readonly
                  />
                </div>
                <div class="col mb-3">
                  <label class="form-input mb-2">Booked Count</label>
                  <input
                    class="form-control"
                    formControlName="bookedCount"
                    readonly
                  />
                </div>
              </div>
              <div
                *ngIf="
                  hasManageAccess &&
                  !isServiceStarted(selectedService.serviceStartDateTime) &&
                  selectedService.status !== 2
                "
                class="mb-3 row mx-0"
              >
                <div class="col">
                  <button
                    class="btn btn-block bg-theme w-100 d-flex justify-content-center align-items-center p-0"
                    (click)="adjustServicebtn = !adjustServicebtn"
                  >
                    Adjust Service
                    <i class="material-icons text-white">
                      {{ adjustServicebtn ? "expand_less" : "expand_more" }}
                    </i>
                  </button>
                </div>
                <div *ngIf="selectedServiceName.cancelService" class="col">
                  <button
                    class="btn btn-block bg-theme w-100"
                    (click)="
                      openConfirmationDialog(
                        'Are you sure you want to cancel this service?',
                        'SERVICE',
                        selectedServiceName._id
                      )
                    "
                  >
                    Cancel Service
                  </button>
                </div>
              </div>

              <div
                id="adjustService"
                *ngIf="adjustServicebtn"
                class="mt-3 border border-dark p-3"
              >
                <div class="row">
                  <div class="col-md-6">
                    <label>Confirm Slot</label>
                    <input
                      class="form-control"
                      formControlName="confirmSlot"
                      readonly
                    />
                  </div>
                  <div class="col-md-6">
                    <label>Adjust to</label>
                    <input
                      type="number"
                      class="form-control"
                      formControlName="adjust"
                      #adjustInput
                    />
                  </div>
                  <div class="col-12 d-flex justify-content-center mt-3">
                    <button
                      class="btn btn-success mr-3"
                      (click)="adjustService(adjustInput.value)"
                      [disabled]="!adjustInput.value"
                    >
                      Submit
                    </button>
                    <button
                      class="btn btn-danger"
                      (click)="adjustServicebtn = !adjustServicebtn"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>

              <div class="card mt-2">
                <div class="card-header flexi-header">
                  <h3>Booked Users</h3>
                </div>
                <div class="card-body">
                  <ng-container
                    *ngIf="
                      serviceDetailsForm.get('bookedUsers').value?.length;
                      else noUsersFound
                    "
                  >
                    <div
                      *ngFor="
                        let user of serviceDetailsForm.get('bookedUsers').value
                      "
                      class="booked-user d-flex justify-content-between align-items-center mb-2 border border-success p-3"
                    >
                      <div>{{ user.staffId }}</div>

                      <ng-container [ngSwitch]="user.status">
                        <div
                          *ngSwitchCase="4"
                          class="badge badge-danger badge-pill"
                          style="font-size: smaller"
                        >
                          Cancelled By Admin
                        </div>
                        <div
                          *ngSwitchCase="3"
                          class="badge badge-danger badge-pill"
                          style="font-size: smaller"
                        >
                          Cancelled By User
                        </div>
                        <ng-container *ngSwitchDefault>
                          <button
                            *ngIf="
                              hasManageAccess &&
                              !isServiceStarted(
                                selectedService.serviceStartDateTime
                              ) &&
                              selectedService.status !== 2
                            "
                            class="btn bg-theme"
                            (click)="
                              openConfirmationDialog(
                                'Are you sure you want to cancel this booking?',
                                'BOOKING',
                                user
                              )
                            "
                          >
                            Cancel Booking
                          </button>
                        </ng-container>
                      </ng-container>
                    </div>
                  </ng-container>
                  <ng-template #noUsersFound>
                    <h5 class="text-muted">No users booked</h5>
                  </ng-template>
                </div>
              </div>

              <div
                class="modal fade"
                [class.show]="displayConfirmation"
                style="display: block"
                tabindex="-1"
                aria-labelledby="confirmationModalLabel"
                aria-hidden="true"
                *ngIf="displayConfirmation"
              >
                <div
                  class="modal-dialog modal-dialog-centered"
                  style="max-width: 400px"
                >
                  <div class="modal-content">
                    <div class="modal-body text-center">
                      <h3
                        class="modal-title"
                        id="confirmationModalLabel"
                        style="font-size: 18px; color: #333"
                      >
                        {{ confirmationMessage }}
                      </h3>
                    </div>
                    <div class="modal-footer justify-content-center">
                      <button
                        class="btn btn-primary ml-1"
                        style="width: 6rem"
                        (click)="cancelRequest()"
                      >
                        Yes
                      </button>
                      <button
                        class="btn btn-danger ml-1"
                        style="width: 6rem"
                        (click)="displayConfirmation = false"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-loading
  [isLoading]="isLoading"
  [message]="'Loading data, please wait...'"
></app-loading>
