import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomFormService } from 'src/app/users/service/custom-form/custom-form.service';
import { ToastrService } from 'ngx-toastr';
import { DynamicTableFormComponent } from './dynamic-table-form/dynamic-table-form';

@Component({
  selector: 'app-form-manage',
  templateUrl: './form-manage.component.html',
  styleUrls: ['./form-manage.component.scss'],
})
export class FormManageComponent implements OnInit {
  manageFormLogTable;
  @Input() manageId: string = null;
  @Input() editFormData: any = null;
  @Input() overViewData: any = null;

  formSubmissionListData = [];
  totalSubmissions: number = 0;
  errorCount: number = 0;
  statuses: any = [];
  statusesStats: any= [];
  customFormId: string = null;
  childComponentData: any = null;
  serviceId: string = null;
  buId: string = null;
  status: string = null;
  searchObj: any = null;
  isCreateIndent: boolean = false;
  hasWorkflow: boolean = false;
  firstRow: any = null;
  showCreateIndent: boolean = false;
  showLogs: boolean = false;
  formData: any = null;
  isLoader: boolean = false;
  currentPage = 1;
  privilegeFlags;
  columns = []
  @ViewChild(DynamicTableFormComponent) dynamicTableFormComponent: DynamicTableFormComponent;
  constructor(
    public toastService: ToastrService,
    public formBuilder: FormBuilder,
    public modelService: NgbModal,
    private customFromService: CustomFormService
  ) {}

  async ngOnInit(): Promise<void> {
    this.childComponentData = this.editFormData;
    this.customFormId = this.manageId;
    this.isCreateIndent = this.editFormData?.isCreateIndent;
    this.hasWorkflow = this.editFormData?.hasWorkflow;
    const data =  localStorage.getItem("privilegeFlags");
    this.privilegeFlags = JSON.parse(data);
  }

  getRecords() {
    this.isLoader = true;
    this.customFromService
      .formSubmissionList(this.customFormId, {
        serviceId: this.serviceId,
        transportBuId: this.editFormData.formSubTypeId ? this.buId : null,
        parentBuId: !this.editFormData.formSubTypeId ? this.buId : null,
        status: this.status,
        search: this.searchObj,
        hasWorkflow: this.hasWorkflow
      }, this.currentPage)
      .subscribe((data) => {
        this.formSubmissionListData = data?.data;
        this.columns = data?.columns
        this.firstRow = data?.data[0];
        this.totalSubmissions = data?.totalRecords;

        this.statusesStats = data?.countsByLabel;
        this.statuses = JSON.parse(JSON.stringify(data?.countsByLabel));
        this.errorCount = data?.errorCount;
        this.isLoader = false;
        if(!this.hasWorkflow){
        this.statusesStats = [{_id: 'Submission', count: this.totalSubmissions }, ...this.statuses]

        this.statusesStats.push({_id: 'Error', count: this.errorCount})
        }else {
          this.statuses = this.editFormData.workflow.map(item => item.workflowStatus).flat();
        }
      }, (error) => {
        this.isLoader = false;
      });
  }

  refresh(){
    this.getRecords();
  }

  getStats() {
    this.customFromService.getStats(this.customFormId).subscribe((data) => {
      this.totalSubmissions = data?.data.total;
      this.statuses = data?.data.statuses;
      this.errorCount = data?.data.errorCount;
    });
  }

  handleFilterCall(event) {
    this.serviceId = event.serviceId;
    this.buId = event.buId;
    this.status = event.status;
    this.currentPage = event.currentPage
    if(event.isClear){
    this.searchObj = null
    this.dynamicTableFormComponent.onClearCall()
    }
    this.getRecords();
  }

  exportCall(event) {
    this.isLoader = true;
    const type = event.type;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    this.customFromService
      .exportData(this.customFormId, {
        serviceId: this.serviceId,
        transportBuId: this.editFormData.formSubTypeId ? this.buId : null,
        parentBuId: this.editFormData.formSubTypeId ? this.buId : null,
        status: this.status,
        search: this.searchObj,
        type,
        groupBy: event.groupBy,
        timeZone
      })
      .subscribe((data) => {
        this.downloadFile(data.fileURL, data.fileName)

        this.toastService.success("Data exported successfully", 'Success');
        this.isLoader = false;
      }, (error) => {
        console.log(error, 'error')
        error.error.message
        const message = error?.error?.error?.message || 'Something went wrong'
        this.toastService.error(message, 'Error');
        this.isLoader = false;
      });
  }

  downloadFile(url: string, fileName: string): void {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        // Create a new URL for the blob
        const downloadUrl = window.URL.createObjectURL(blob);

        // Create a new anchor element
        const anchor = document.createElement('a');

        // Set the href attribute to the blob URL
        anchor.href = downloadUrl;

        // Set the download attribute to suggest a filename for the downloaded file
        anchor.download = fileName;

        // Append the anchor element to the document body
        document.body.appendChild(anchor);

        // Programmatically click the anchor to trigger the download
        anchor.click();

        // Clean up by revoking the object URL and removing the anchor
        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(anchor);
      })
      .catch(error => console.error('Error downloading the file:', error));
  }

  checkLogs() {
    this.showLogs = true;
  }


  ngOnDestroy(): void {}

  handleSearchCall(event) {
    const keys = Object.keys(event);
    this.currentPage = event.currentPage
    delete event.currentPage
    this.searchObj = event;
    this.getRecords();
  }

  createIndent() {
    this.formData = {
      customFormId: this.overViewData?._id,
      title: this.overViewData?.title,
      moduleId: this.overViewData?.moduleId,
    }
    this.showCreateIndent = true;
  }
  openManage() {
    this.showCreateIndent = false
    this.showLogs = false;
  }
}
