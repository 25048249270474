<div class="card mb-3">
  <div class="card-header flexi-header pt-4">
    <h5 style="display: inline" style="color: #fff;">FORMS</h5>
    <button
      class="btn btn-warning pull-right"
      style="color: ivory"
      (click)="back()"
    >
      Back
    </button>
  </div>
  <div class="card-body">
    <div class="card table-card">
      <div class="card-body flexi-body">
        <div class="flex-column">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" sortable="Tier 1">DATE</th>
                  <th scope="col" sortable="Tier 2">FORM TITLE</th>
                  <th scope="col" sortable="Tier 3">SERVICE DATE & TIME</th>
                  <th scope="col" sortable="Tier 3">ATTENDANCE</th>
                  <th scope="col" sortable="Tier 5">
                    ACTION
                    <!-- {{followUP | uppercase}} -->
                  </th>
                </tr>
              </thead>
              <tbody>
                <div *ngIf="usersPTable?.length === 0">
                  <h5 style="text-align: center; margin-left: 200px">
                    No Records Found
                  </h5>
                </div>
                <tr *ngFor="let item of usersPTable">
                  <td>
                    {{ item.createdAt | date : "mediumDate" }}
                  </td>
                  <td>
                    {{ item.formTitle }}
                  </td>
                  <td>
                    {{ item?.serviceDateTime }}
                  </td>
                  <td>
                    {{ item?.attendanceLabel }}
                  </td>
                  <!-- <td   *ngIf="item?.showFormStatus">
                        <ng-container *ngFor="let workflow of item.showFormStatus">
                          {{ workflow.status}}
                        </ng-container>
                      </td> -->
                  <td>
                    <button
                      class="btn btn-warning"
                      (click)="openPopupForSubmit(item)"
                    >
                      Take Action
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #submitSection let-c="close" let-d="dismiss">
  <div>
    <div class="modal-content">
      <div class="modal-body" style="padding: 10px; min-height: 100vh">
        <div>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3>({{ mainTitle }})</h3>
              <button
                type="button"
                class="btn close-button"
                aria-label="Close"
                (click)="c('Cross click')"
              >
                <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i>
              </button>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div
                    class="card-body"
                    style="word-break: break-word; padding: 5px"
                  >
                    <div
                      class="box d-flex flex-column"
                      *ngFor="let section of sectionList; let i = index"
                    >
                      <div
                        class="d-flex card-header flexi-header"
                        style="color: #fff"
                      >
                        <strong class="mr-1">{{ i + 1 }} </strong>
                        <div
                          class="box-heading ml-2 d-flex"
                          style="word-break: break-word"
                        >
                          &emsp;
                          <p>{{ section?.sectionName }}</p>
                        </div>
                      </div>
                      <div
                        *ngFor="let item of section.questions; let i = index"
                      >
                        <div
                          class="box d-flex flex-column"
                          style="padding: 16px"
                          *ngIf="item.type !== 22"
                        >
                          <div class="d-flex" style="height: 20px">
                            <strong class="mr-1">{{ i + 1 }} </strong>
                            <div
                              class="box-heading ml-2"
                              style="word-break: break-word"
                            >
                              &emsp;
                              <p [innerHTML]="item.question | safeHtml"></p>
                            </div>
                          </div>
                          <div class="d-flex flex-wrap answers-options">
                            <div class="form-group flex-fill">
                              <label for="my-input">{{
                                item.questionName
                              }}</label>
                              <div>
                                <input
                                  id="my-input"
                                  class="form-control"
                                  disabled
                                  type="text"
                                  value="{{ item.value }}"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="d-flex justify-content-between w-50"
                          style="margin: 0 auto"
                          *ngIf="item.type === 22"
                        >
                          <button
                            *ngIf="item.question.includes('Arrived' || 'Alighted')"
                            [disabled]="!!item?.value"
                            (click)="markAsAttendance(item.questionId)"
                            class="btn btn w-100 m-2"
                            [ngClass]="{ 'bg-grey': !item?.value }"
                            style="background-color: green; color: #fff"
                          >
                            <p [innerHTML]="item.question | safeHtml"></p>
                            {{ item?.value }}
                          </button>
                          <button
                            *ngIf="item.question.includes('Boarded')"
                            [disabled]="!!item?.value"
                            (click)="markAsAttendance(item.questionId)"
                            class="btn btn w-100 m-2"
                            [ngClass]="{ 'bg-grey': !item?.value }"
                            style="background-color: green; color: #fff"
                          >
                            <p [innerHTML]="item.question | safeHtml"></p>
                            {{ item?.value }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
