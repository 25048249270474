import { FacialIdComponent } from "./pages/admin/users/facial-id/facial-id.component";
import { OpsGroupComponent } from "./pages/admin/users/ops-group/ops-group.component";
import { BuShiftSetupComponent } from "./pages/operations/setup/bu-shift-setup/bu-shift-setup.component";
import { UserShiftSetupComponent } from "./pages/operations/setup/user-shift-setup/user-shift-setup.component";
import { ShiftSchemeComponent } from "./pages/operations/setup/shift-scheme/shift-scheme.component";
import { PlanShiftComponent } from "./pages/operations/shift-scheduler/plan-shift/plan-shift.component";
import { ManageShiftComponent } from "./pages/operations/shift-scheduler/manage-shift/manage-shift.component";
import { ViewShiftComponent } from "./pages/operations/shift-scheduler/view-shift/view-shift.component";
import { ViewBookingComponent } from "./pages/operations/shift-scheduler/view-booking/view-booking.component";
import { TimesheetComponent } from "./pages/operations/shift-scheduler/timesheet/timesheet.component";
import { ListShiftComponent } from "./pages/operations/report/list-shift/list-shift.component";
import { ListBookingComponent } from "./pages/operations/report/list-booking/list-booking.component";
import { ListCancellationComponent } from "./pages/operations/report/list-cancellation/list-cancellation.component";
import { ListUserComponent } from "./pages/operations/report/list-user/list-user.component";
import { LeaveSchemeComponent } from "./pages/human-resource/leave-scheme/leave-scheme.component";
import { LeaveBallotingComponent } from "./pages/human-resource/leave-balloting/leave-balloting.component";
import { LeavePlannerComponent } from "./pages/human-resource/leave-planner/leave-planner.component";
import { CompanySetupComponent } from "./pages/system-settings/company-setup/company-setup.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AutoLoginGuard } from "./guards/auto-login.guard";
import { AuthGuard } from "./guards/auth.guard";
import { DesignComponent } from "./layout/design/design.component";
import { BussinessUnitComponent } from "./pages/system-settings/bussiness-unit/bussiness-unit.component";
import { HomeComponent } from "./pages/home/home.component";

import { VerificationGuard } from "./guards/user-verification.guard";
import { ForgetPasswordComponent } from "./pages/auth/forget-password/forget-password.component";
import { VerifyUserComponent } from "./pages/auth/verify-user/verify-user.component";
import { CustomFieldsComponent } from "./pages/system-settings/custom-fields/custom-fields.component";
import { UserRolesComponent } from "./pages/system-settings/user-roles/user-roles.component";

import { UserDetailsComponent } from "./pages/users/view-users/user-details/user-details.component";
import { ViewUsersComponent } from "./pages/users/view-users/view-users.component";
import { LoginComponent } from "./pages/auth/login/login.component";
import { CreateUsersComponent } from "./pages/users/create-users/create-users.component";
import { UserProfileComponent } from "./pages/user-profile/user-profile.component";
import { ChannelSetupComponent } from "./pages/communication/channel-setup/channel-setup.component";
import { QuestionBuilderComponent } from "./pages/central-builder/question-builder/question-builder.component";
import { CreateEditModuleComponent } from "./pages/central-builder/question-builder/create-edit-module/create-edit-module.component";
import { FormsComponent } from "./pages/central-builder/forms/forms.component";
import { MyFormsComponent } from "./pages/central-builder/my-forms/my-forms.component";
import { MyNotificationComponent } from "./pages/communication/notification/my-notification/my-notification.component";
import { ManageNotificationComponent } from "./pages/communication/notification/manage-notification/manage-notification.component";
import { BoardSetupComponent } from "./pages/communication/board-setup/board-setup.component";
import { ManageNewsComponent } from "./pages/communication/manage-news/manage-news.component";
import { ManageEventsComponent } from "./pages/communication/manage-events/manage-events.component";
import { ChallengesComponent } from "./pages/gamification/challenges/challenges.component";
import { RedemptionListComponent } from "./pages/gamification/Redemption-List/Redemption-List.component";
import { MyNewsAndEventsComponent } from "./pages/communication/my-news-and-events/my-news-and-events.component";

import { LockedAccountComponent } from "./pages/users/locked-account/locked-account.component";
import { ResetPasswordComponent } from "./pages/users/reset-password/reset-password.component";
import { IntegrationLogsComponent } from "./pages/system-settings/integration-logs/integration-logs.component";
import { TermsAndConditionsComponent } from "./pages/auth/terms-and-conditions/terms-and-conditions.component";
import { SupportComponent } from "./pages/auth/support/support.component";

import { NotificationComponent } from './pages/notification/notification.component';
import { PasswordExpiredComponent } from "./pages/auth/password-expired/password-expired.component";
import { WelcomepageComponent } from "./pages/auth/welcomepage/welcomepage.component";
import { ServiceSetupComponent } from "./pages/operations/setup/service-setup/service-setup.component";
import { ManageServiceComponent } from "./pages/operations/shift-scheduler/manage-service/manage-service.component";
import { PlanServiceComponent } from "./pages/operations/shift-scheduler/plan-service/plan-service.component";
import { ManageTransportComponent } from "./pages/admin/users/manage-transport/manage-transport.component";

const routes: Routes = [
    {
        path: "login/:name",
        component: LoginComponent,
        canActivate: [AutoLoginGuard],
    },
    {
        path: "terms-conditions/:name",
        component: TermsAndConditionsComponent,
    },
    {
        path: "forget-password/:name",
        component: ForgetPasswordComponent,
    },
    {
        path: "password-expired/:name",
        component: PasswordExpiredComponent,
    },
    {
        path: "WelcomeScreen/:name",
        component: WelcomepageComponent,
    },
    {
        path: "support/:name",
        component: SupportComponent,
    },
    {
        path: "user-verify",
        component: VerifyUserComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "",
        pathMatch: "full",
        redirectTo: "login/:name",
    },
    {
        path: "",
        component: DesignComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: "home",
                component: HomeComponent,
            },
            {
                path: "profile",
                component: UserProfileComponent,
            },
            {
                path: "bu-unit",
                component: BussinessUnitComponent,
            },
            {
                path: "user-roles",
                component: UserRolesComponent,
            },
            {
                path: "company-setup",
                component: CompanySetupComponent,
            },
            {
                path: "custom-fields",
                component: CustomFieldsComponent,
            },
            {
                path: "view-users",
                component: ViewUsersComponent,
            },
            {
                path: "view-users/details",
                component: UserDetailsComponent,
            },
            {
                path: "create-users",
                component: CreateUsersComponent,
            },
            {
                path: "lock-account",
                component: LockedAccountComponent,
            },
            {
                path: "Challenges",
                component: ChallengesComponent,
            },
            {
                path: "rest-password",
                component: ResetPasswordComponent,
            },
            {
                path: "facial-id",
                component: FacialIdComponent,
            },
            {
                path: "manage-transport",
                component: ManageTransportComponent,
            },
            {
                path: "ops-group",
                component: OpsGroupComponent,
            },
            {
                path: "channel-setup",
                component: ChannelSetupComponent,
            },
            {
                path: "question-builder",
                component: QuestionBuilderComponent,
            },
            {
                path: "forms",
                component: FormsComponent,
            },
            {
                path: "my-forms",
                component: MyFormsComponent,
            },
            {
                path: "my-notifications",
                component: MyNotificationComponent,
            },
            {
                path: "manage-notification",
                component: ManageNotificationComponent,
            },
            {
                path: "board-setup",
                component: BoardSetupComponent,
            },
            {
                path: "manage-news",
                component: ManageNewsComponent,
            },
            {
                path: "manage-events",
                component: ManageEventsComponent,
            },
            {
                path: "my-news-and-events",
                component: MyNewsAndEventsComponent,
            },
            {
                path: "bu-shift-setup",
                component: BuShiftSetupComponent,
            },
            {
                path: "user-shift-setup",
                component: UserShiftSetupComponent,
            },
            {
                path: "shift-scheme",
                component: ShiftSchemeComponent,
            },
            {
                path: "plan-shift",
                component: PlanShiftComponent,
            },
            {
                path: "manage-shift",
                component: ManageShiftComponent,
            },
            {
                path: "view-shift",
                component: ViewShiftComponent,
            },
            {
                path: "view-booking",
                component: ViewBookingComponent,
            },
            {
                path: "timesheet",
                component: TimesheetComponent,
            },
            {
                path: "list-of-shift-released",
                component: ListShiftComponent,
            },
            {
                path: "list-of-booking",
                component: ListBookingComponent,
            },
            {
                path: "list-of-cancellation",
                component: ListCancellationComponent,
            },
            {
                path: "list-of-users",
                component: ListUserComponent,
            },
            {
                path: "leave-scheme",
                component: LeaveSchemeComponent,
            },
            {
                path: "leave-balloting",
                component: LeaveBallotingComponent,
            },
            {
                path: "leave-planner",
                component: LeavePlannerComponent,
            },
            {
                path: "manage-events",
                component: ManageEventsComponent,
            },
            {
                path: "bu-shift-setup",
                component: BuShiftSetupComponent,
            },
            {
                path: "user-shift-setup",
                component: UserShiftSetupComponent,
            },
            {
                path: "shift-scheme",
                component: ShiftSchemeComponent,
            },
            {
                path: "plan-shift",
                component: PlanShiftComponent,
            },
            {
                path: "manage-shift",
                component: ManageShiftComponent,
            },
            {
                path: "view-shift",
                component: ViewShiftComponent,
            },
            {
                path: "view-booking",
                component: ViewBookingComponent,
            },
            {
                path: "timesheet",
                component: TimesheetComponent,
            },
            {
                path: "list-of-shift-released",
                component: ListShiftComponent,
            },
            {
                path: "list-of-booking",
                component: ListBookingComponent,
            },
            {
                path: "list-of-cancellation",
                component: ListCancellationComponent,
            },
            {
                path: "list-of-users",
                component: ListUserComponent,
            },
            {
                path: "leave-scheme",
                component: LeaveSchemeComponent,
            },
            {
                path: "leave-balloting",
                component: LeaveBallotingComponent,
            },
            {
                path: "leave-planner",
                component: LeavePlannerComponent,
            },
            {
                path: "integration-logs",
                component: IntegrationLogsComponent,
            },
            {
                path: "redemption-list",
                component: RedemptionListComponent,
            },
            {
              path: 'custom-notification',
              component: NotificationComponent,
            },
            {
                path: 'service-setup',
                component: ServiceSetupComponent,
              },
              {
                path: 'plan-service',
                component: PlanServiceComponent,
              },
              {
                path: 'manage-service',
                component: ManageServiceComponent,
              }

        ],
        //path: 'home', component: HomeComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class UserRoutingModule {}
