import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { formatGMTOffset, getGmtOffSet } from 'src/app/core/utils/helper';
import { SetupService } from 'src/app/users/service/operations/setup.service';

@Component({
  selector: 'app-create-service',
  templateUrl: './create-service.component.html',
  styleUrls: ['./create-service.component.scss'],
})
export class CreateServiceComponent implements OnInit {
  dateModel: NgbDateStruct;
  @Input() serviceType: any;
  @Output() tabChange = new EventEmitter<void>();
  serviceList = [];
  templatesList = [];
  draftServiceList: any = [];
  deleteServiceTemplate: boolean = false;
  showWeekPicker: boolean = false;
  weeksLoader: boolean = false;
  selectedDays: string[] = [];
  dayDate = [];
  disableDayArr = [];
  serviceForm: FormGroup;
  selectServiceTemp: [];
  time: string[] = [];
  gmtOffset: any;
  scheduleTimeZone: any;
  isShowDraft = false;
  weekDays = [
    { full: 'monday', short: 'Mon' },
    { full: 'tuesday', short: 'Tue' },
    { full: 'wednesday', short: 'Wed' },
    { full: 'thursday', short: 'Thu' },
    { full: 'friday', short: 'Fri' },
    { full: 'saturday', short: 'Sat' },
    { full: 'sunday', short: 'Sun' },
  ];
  selectedServiceSetup: any;
  isTemplateSelected: boolean = false;
  selectedTemplateId: string;
  servicesLoder: boolean = false;
  templatesLoder: boolean = false;
  wantToDeleteItem: number = null;
  confirmationMessage: string = '';
  confirmationBoxType: string = '';
  pagination = {
    page: 1,
    limit: 10,
    sort: '{"createdAt": -1}',
  };
  servicesPagination = { ...this.pagination };
  templatesPagination = { ...this.pagination };
  weekSelectionList = [];

  constructor(
    private setupService: SetupService,
    private formBuilder: FormBuilder,
    private toastService: ToastrService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getServiceNamesAndWeeks();
    this.gmtOffset = formatGMTOffset(getGmtOffSet());
    this.scheduleTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.populateTimeSlots();
  }

  private initForm() {
    this.serviceForm = this.formBuilder.group({
      serviceName: [null, Validators.required],
      serviceTemplate: [null],
      serviceWeek: [null],
      confirmSlot: [null, Validators.required],
      standBySlot: [0],
      serviceStartTime: [null, Validators.required],
      serviceEndTime: [null, Validators.required],
      isAutoScheduleEnabled: [true, Validators.required],
      autoScheduleConfig: this.formBuilder.group({
        autoSchedularStartDate: [null],
        autoSchedularEndDate: [null],
        day: [null],
        autoSchedularTime: [null],
      }),
    });
  }

  private getServiceNamesAndWeeks() {
    this.servicesLoder = true;
    this.setupService
      .getServicesAndWeeks(
        this.servicesPagination,
        this.serviceType?.serviceTypeName
      )
      .pipe(finalize(() => (this.servicesLoder = false)))
      .subscribe(
        (response: any) => {
          this.serviceList = [...this.serviceList, ...response.data];
        },
        () => (this.serviceList = [])
      );
  }

  loadMoreServiceNames(): void {
    this.servicesPagination.page++;
    this.getServiceNamesAndWeeks();
  }

  onChangeServiceName(selectedServiceSetup: any): void {
    if (!selectedServiceSetup) return;
    this.selectedServiceSetup = selectedServiceSetup;
    this.templatesList = [];
    this.templatesPagination = { ...this.pagination };
    this.getServiceTemplates();
  }

  private getServiceTemplates() {
    this.templatesLoder = true;
    this.setupService
      .getServiceTemplates(
        this.templatesPagination,
        this.selectedServiceSetup._id
      )
      .pipe(finalize(() => (this.templatesLoder = false)))
      .subscribe(
        (response: any) => {
          this.templatesList = [...this.templatesList, ...response.data];
        },
        () => (this.templatesList = [])
      );
  }

  loadMoreServiceTemplates() {
    this.templatesPagination.page++;
    this.getServiceTemplates();
  }

  toggleDay(day: string) {
    const isSelected = this.selectedDays.includes(day);
    this.selectedDays = isSelected
      ? this.selectedDays.filter((d) => d !== day)
      : [...this.selectedDays, day];
    this.updateDayDate(day, !isSelected);
  }

  private updateDayDate(day: string, isAdding: boolean) {
    this.dayDate = isAdding
      ? [...this.dayDate, { day }]
      : this.dayDate.filter((d) => d.day !== day);
  }

  private populateTimeSlots() {
    this.time = Array.from({ length: 96 }, (_, i) =>
      moment({ hour: Math.floor(i / 4), minute: (i % 4) * 15 }).format('HH:mm')
    );
  }

  addServiceTemplate() {
    const daysArray = this.dayDate.map((item) => item.day);
    const draftValueToPush = {
      serviceStartTime: this.serviceForm.get('serviceStartTime').value,
      serviceEndTime: this.serviceForm.get('serviceEndTime').value,
      confirmSlot: this.serviceForm.get('confirmSlot').value,
      standBySlot: this.serviceForm.get('standBySlot').value,
      days: this.dayDate,
      selectedDays: Array.from(new Set(daysArray)),
    };

    this.draftServiceList.push(draftValueToPush);
    this.isShowDraft = true;
    this.dayDate = [];
    this.resetForm();
    this.serviceForm.get('serviceName').disable();
  }

  savePlanTemplate() {
    const payload = this.generateTemplatePayload();
    this.setupService
      .createTemplate(payload)
      .pipe(finalize(() => {}))
      .subscribe(
        () => {
          this.toastService.success('Service Template saved successfully!');
          this.clearServices();
          this.tabChange.emit();
        },
        (err) => {
          const errorMessage =
            err?.error?.error?.message || 'Failed to save service template';
          this.toastService.error(errorMessage);
        }
      );
  }

  updateTemplate() {
    const payload = this.generateTemplatePayload(this.selectedTemplateId);
    this.setupService
      .updateTemplate(payload)
      .pipe(finalize(() => {}))
      .subscribe(
        () => {
          this.toastService.success('Service Template updated successfully!');
          this.clearServices();
        },
        (err) => {
          const errorMessage =
            err?.error?.error?.message || 'Failed to update service template';
          this.toastService.error(errorMessage);
        }
      );
  }

  private generateTemplatePayload(templateId?: string): any {
    const { isAutoScheduleEnabled, autoScheduleConfig } =
      this.serviceForm.value;
    const {
      autoSchedularStartDate,
      autoSchedularEndDate,
      day,
      autoSchedularTime,
    } = autoScheduleConfig;

    const payload: any = {
      serviceSetupId: this.selectedServiceSetup._id,
      serviceType: this.serviceType.serviceTypeName,
      services: this.draftServiceList.map(({ selectedDays, ...rest }) => rest),
      isScheduled: isAutoScheduleEnabled,
      scheduleDay: day || '',
      scheduleStartDate: isAutoScheduleEnabled
        ? this.dateFormat(autoSchedularStartDate)
        : '',
      scheduleEndDate: isAutoScheduleEnabled
        ? this.dateFormat(autoSchedularEndDate, true)
        : '',
      scheduleTime: autoSchedularTime || '',
      scheduleTimeZone: isAutoScheduleEnabled ? this.scheduleTimeZone : '',
      timezone: this.gmtOffset,
    };

    if (templateId) {
      payload.templateId = templateId;
    }

    return payload;
  }

  prefillServiceTemplate(selectedItem: any): void {
    const isAutoScheduleEnabled = selectedItem.isScheduled;
    this.selectedTemplateId = selectedItem._id;
    const autoSchedularStartDate = isAutoScheduleEnabled
      ? this.parseDate(selectedItem.scheduleStartDate)
      : '';
    const autoSchedularEndDate = isAutoScheduleEnabled
      ? this.parseDate(selectedItem.scheduleEndDate)
      : '';
    const autoSchedularTime = selectedItem.scheduleTime;

    this.serviceForm.patchValue({
      isAutoScheduleEnabled: isAutoScheduleEnabled,
      autoScheduleConfig: {
        autoSchedularStartDate,
        autoSchedularEndDate,
        day: selectedItem.scheduleDay || '',
        autoSchedularTime,
      },
    });

    this.draftServiceList = selectedItem.services.map((service) => ({
      serviceStartTime: service.serviceStartTime,
      serviceEndTime: service.serviceEndTime,
      confirmSlot: service.confirmSlot,
      standBySlot: service.standBySlot,
      days: service.days,
      selectedDays: service.days.map((d) => d.day.toLowerCase()),
    }));

    this.selectedServiceSetup = selectedItem.serviceSetup;

    const serviceNameControl = this.serviceForm.get('serviceName');
    serviceNameControl.setValue(this.selectedServiceSetup);
    serviceNameControl.disable();
    this.isTemplateSelected = true;
    this.isShowDraft = true;
  }

  canSaveTemplate(): boolean {
    const autoScheduleConfig = this.serviceForm.get('autoScheduleConfig');
    const isAutoScheduleEnabled = this.serviceForm.get(
      'isAutoScheduleEnabled'
    ).value;
    if (this.draftServiceList.length === 0) return false;

    if (isAutoScheduleEnabled) {
      return (
        autoScheduleConfig.get('autoSchedularStartDate').value &&
        autoScheduleConfig.get('autoSchedularEndDate').value &&
        autoScheduleConfig.get('day').value &&
        autoScheduleConfig.get('autoSchedularTime').value
      );
    }
    return true;
  }

  clearServices() {
    this.selectedServiceSetup = {};
    this.serviceForm.reset({ isAutoScheduleEnabled: true, standBySlot: 0 });
    this.draftServiceList = [];
    this.templatesList = [];
    this.isShowDraft = false;
    this.isTemplateSelected = false;
    this.serviceForm.enable();
  }

  resetForm() {
    if (this.isTemplateSelected) {
      this.serviceForm.patchValue({
        confirmSlot: null,
        standBySlot: 0,
        serviceStartTime: null,
        serviceEndTime: null,
      });
    } else {
      this.serviceForm.reset({
        standBySlot: 0,
        isAutoScheduleEnabled: true,
        serviceName: this.selectedServiceSetup,
      });
    }
    this.dayDate = [];
    this.selectedDays = [];
  }

  removeDraftTemplate(index: number) {
    this.draftServiceList.splice(index, 1);
  }

  deleteTemplate(): void {
    if (
      this.confirmationBoxType === 'SERVICE' &&
      this.wantToDeleteItem !== null
    ) {
      this.draftServiceList.splice(this.wantToDeleteItem, 1);
      this.wantToDeleteItem = null;
    } else if (
      this.confirmationBoxType === 'TEMPLATE' &&
      this.selectedTemplateId
    ) {
      this.setupService.deleteTemplate(this.selectedTemplateId).subscribe(
        () => {
          this.toastService.success('Service Template deleted successfully!');
          this.clearServices();
        },
        (err) => {
          const errorMessage =
            err?.error?.error?.message || 'Failed to delete service template';
          this.toastService.error(errorMessage);
        }
      );
    }
    this.deleteServiceTemplate = false;
  }

  publishService() {
    const serviceWeek = this.serviceForm.get('serviceWeek').value;
    if (!serviceWeek) {
      this.toastService.error('Please Select Service Week');
      return;
    }
    const weekRangeStartsAt = this.formatDate(serviceWeek.label, 0);
    const weekRangeEndsAt = this.formatDate(serviceWeek.label, 1);

    const payload: any = {
      serviceSetupId: this.selectedServiceSetup._id,
      serviceType: this.serviceType.serviceTypeName,
      services: this.draftServiceList.map(({ selectedDays, ...rest }) => rest),
      weekRangeStartsAt,
      weekRangeEndsAt,
      isScheduled: false,
      timezone: this.gmtOffset,
    };

    if (this.serviceForm.get('serviceTemplate').value) {
      payload.templateId = this.selectedTemplateId;
    }

    this.setupService
      .createServices(payload)
      .pipe(finalize(() => {}))
      .subscribe(
        (response: any) => {
          this.toastService.success(
            response?.message || 'Service published successfully!'
          );
          this.tabChange.emit();
        },
        (err) => {
          const errorMessage =
            err?.error?.error?.message ||
            'Failed to publish the service template.';
          this.toastService.error(errorMessage);
        }
      );

    this.showWeekPicker = false;
  }

  openConfirmationDialog(message: string, type: string, index?: number): void {
    this.confirmationBoxType = type;
    this.confirmationMessage = message;
    if (type === 'SERVICE') this.wantToDeleteItem = index;
    this.deleteServiceTemplate = true;
  }

  openWeekPicker() {
    this.weeksLoader = true;
    const count = this.selectedServiceSetup.serviceViewWeeks || 1;
    const startDate = moment().startOf('isoWeek');
    this.weekSelectionList = [];

    for (let i = 0; i <= count; i++) {
      const weekData = this.getWeekStartEndDate(startDate, 7 * i);
      this.weekSelectionList.push(weekData);
    }

    this.serviceForm.get('serviceWeek').setValue(this.weekSelectionList[0]);
    this.weeksLoader = false;
    this.showWeekPicker = true;
  }

  private getWeekStartEndDate(date: moment.Moment, offset: number) {
    const startOfWeek = date.clone().add(offset, 'days');
    const endOfWeek = startOfWeek.clone().add(6, 'days');

    return {
      label: `${startOfWeek.format('MMM DD')} to ${endOfWeek.format('MMM DD')}`,
      value: startOfWeek.format('MM-DD-YYYY'),
      key: offset,
    };
  }

  private dateFormat(date: NgbDateStruct, isEndOfDay: boolean = false): string {
    const { day, month, year } = date;
    const formattedMonth = month.toString().padStart(2, '0');
    const formattedDay = day.toString().padStart(2, '0');
    const timeOfDay = isEndOfDay ? '23:59:59' : '00:00:00';
    const gmtOffset = this.gmtOffset.replace('GMT', '');

    return `${year}-${formattedMonth}-${formattedDay}T${timeOfDay}${gmtOffset}`;
  }

  private formatDate(weekLabel: string, offset: number): string {
    const date = this.getDateFromLabel(weekLabel, offset);
    return moment(date).format('MM-DD-YYYY [00:00:00 GMT]ZZ');
  }

  private getDateFromLabel(label: string, offset: number): Date {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const day = parseInt(label.slice(4, 6));
    const month = months.indexOf(label.slice(0, 3));
    const year = new Date().getFullYear();
    const baseDate = new Date(year, month, day);

    if (offset === 1) {
      const endDate = new Date(baseDate);
      endDate.setDate(endDate.getDate() + 6);
      return endDate;
    }
    return baseDate;
  }

  private parseDate(dateString: string): NgbDateStruct {
    const date = new Date(dateString);
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }
}
