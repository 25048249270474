<div class="stats-container">

  <div *ngIf='workflow && workflow.length===0' class="stat-item statuses">
    <ng-container *ngFor="let status of statuses; let i = index; let last = last">
      <div class="status-item">
        <div class="stat-label">{{ status._id }}</div>
        <div class="stat-value">{{ status.count }}</div>
      </div>
      <div class="separator" *ngIf="!last"></div>
      <ng-template #lastItem></ng-template>
    </ng-container>
  </div>

  <div  *ngIf='workflow && workflow.length>0' class="card mb-3 w-100">
    <div class="card-header flexi-header">
      <h3>WORKFLOW STATUS</h3>
    </div>
    <div class="card-body">
      <div class="d-flex forms-manage " style="padding-right: 1rem">
        <div class="box-item d-flex flex-column mr-2" *ngFor="let item of workflow; let i = index">
          <h5>{{item?.title}}</h5>
          <div class="d-flex box-item-status">
            <div class="d-flex flex-column flex-fill align-items-center status" *ngFor="let data of workflow[i]?.workflowStatus">
              <h6>{{data?.field}}</h6>
              <!-- <h3>0</h3> -->
              <h3> {{getWorkflowStatusCount(data?._id)}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
