import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import { ShiftSchedulerService } from 'src/app/users/service/operations/shift-scheduler.service';

@Component({
  selector: 'app-manage-service',
  templateUrl: './manage-service.component.html',
  styleUrls: ['./manage-service.component.scss'],
})
export class ManageServiceComponent implements OnInit {
  @ViewChild('sidemenu') sidemenu!: ElementRef;

  serviceTypeList: any[] = [];
  serviceNameList: any[] = [];
  weeksArr: any[] = [];

  serviceTypeLoader = false;
  servicesNameLoader = false;
  weekLoader = false;

  serviceType: any;
  serviceName: any;
  selectedWeek: any;

  serviceTypePagination = { page: 1, limit: 10, sort: '{"_id": -1}' };
  servicesPagination = { ...this.serviceTypePagination };

  serviceRequest: any = null;

  isView = false;
  activeId = 1;
  routeSub: any;
  buOption: any;
  manageService: any;
  receivedPlanShift: any;
  buId: any;
  weeksLoader = false;
  headerData: any;
  startDate: any;
  topData: any;
  managePageData: any = null;
  isManageTabActive = false;
  refreshStatus = false;
  manageServiceTypeId: any = null;

  eventsSubject: Subject<void> = new Subject<void>();
  startDateLocal!: Date;
  refreshNum = 0;
  isLoader = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private setupService: SetupService,
    private toastrService: ToastrService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.routeSub = this.route.data.subscribe(
      (val) => (this.isView = val.isView)
    );
  }

  ngOnInit(): void {
    this.getServiceType();
  }

  getServiceType(): void {
    this.serviceTypeLoader = true;
    this.setupService
      .getServiceType(this.serviceTypePagination)
      .pipe(finalize(() => (this.serviceTypeLoader = false)))
      .subscribe(
        (response: any) => {
          const { data, totalRecords } = response.data;
          this.serviceTypeList = [...this.serviceTypeList, ...data];
        },
        (error) =>
          this.toastrService.error(
            error?.error?.error?.message || 'Failed to load service types'
          )
      );
  }

  loadMoreServiceTypes(): void {
    this.serviceTypePagination.page++;
    this.getServiceType();
  }

  onSelectServiceType(selectedServiceType: any): void {
    if (!selectedServiceType) return;
    this.serviceNameList = [];
    this.clearDropdown(2);
    this.serviceType = selectedServiceType;
    this.getServiceNamesAndWeeks();
  }

  private getServiceNamesAndWeeks(): void {
    this.servicesNameLoader = true;
    this.setupService
      .getServicesAndWeeks(
        this.servicesPagination,
        this.serviceType?.serviceTypeName
      )
      .pipe(finalize(() => (this.servicesNameLoader = false)))
      .subscribe(
        (response: any) => {
          const { data } = response;
          this.serviceNameList = [...this.serviceNameList, ...data];
        },
        () => (this.serviceNameList = [])
      );
  }

  loadMoreServiceNames(): void {
    this.servicesPagination.page++;
    this.getServiceNamesAndWeeks();
  }

  onSelectServiceName(selectedServiceName: any): void {
    if (!selectedServiceName) return;
    this.serviceName = selectedServiceName;
    this.weeksLoader = true;
    const count = selectedServiceName.serviceViewWeeks || 1;
    const startDate = moment().startOf('isoWeek');
    this.weeksArr = [];

    for (let i = -count; i <= count; i++) {
      const weekData = this.getWeekStartEndDate(startDate, 7 * i);
      this.weeksArr.push(weekData);
    }

    this.selectedWeek = this.weeksArr[count]?.label;
    this.weeksLoader = false;
  }

  private getWeekStartEndDate(date: moment.Moment, offset: number): any {
    const startOfWeek = date.clone().add(offset, 'days');
    const endOfWeek = startOfWeek.clone().add(6, 'days');

    return {
      label: `${startOfWeek.format('MMM DD')} to ${endOfWeek.format('MMM DD')}`,
      value: startOfWeek.format('MM-DD-YYYY'),
      key: offset,
    };
  }

  onSelectWeek(selectedWeek: any): void {
    if (!selectedWeek) return;
    this.selectedWeek = selectedWeek.label;
  }

  ngAfterViewInit(): void {
    if (!this.isView) {
      setTimeout(() => {
        this.renderer.addClass(this.sidemenu.nativeElement, 'animate');
      }, 50);
    }
  }

  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
  }

  refreshPage(id: number): void {
    this.activeId = id;
    this.refreshNum++;
  }

  headerHrsStaff(e: any): void {
    const [start, end] = this.selectedWeek.split(' to ');
    const weekStart = moment(start, 'MMM DD');
    const weekEnd = moment(end, 'MMM DD');
    const weekdays: string[] = [];
    moment.updateLocale('en', { week: { dow: 1 } });

    for (
      let day = weekStart.clone();
      day.isSameOrBefore(weekEnd);
      day.add(1, 'day')
    ) {
      weekdays.push(day.format('DD-MM-YYYY'));
    }
    this.topData = this.managePageData?.graphDataWeb[weekdays[e.count]];
  }

  changeActiveTab($event: any): void {
    this.activeId = $event;
  }

  switchToManage(): void {
    if (!this.serviceType || !this.serviceName || !this.selectedWeek) {
      this.toastrService.error('All fields are required');
      return;
    }

    const weekRangeStartAt = this.formatDate(this.selectedWeek, 0);
    const weekRangeEndAt = this.formatDate(this.selectedWeek, 1);
    const serviceSetupId = this.serviceName._id;

    this.serviceRequest = { weekRangeStartAt, weekRangeEndAt, serviceSetupId };
    this.isView = true;
    this.isManageTabActive = true;
    this.headerHrsStaff({ count: 0, day: 'M' });
  }

  formatDate(weekLabel: string, offset: number): string {
    const date = this.getDateFromLabel(weekLabel, offset);
    return offset === 0
      ? moment(date).format('YYYY-MM-DDT00:00:00.000[Z]')
      : moment(date).format('YYYY-MM-DDT23:59:59.999[Z]');
  }

  getDateFromLabel(label: string, offset: number): Date {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const day = parseInt(label.slice(4, 6), 10);
    const month = months.indexOf(label.slice(0, 3));
    const year = new Date().getFullYear();
    const baseDate = new Date(year, month, day);

    if (offset === 1) {
      const endDate = new Date(baseDate);
      endDate.setDate(endDate.getDate() + 6);
      return endDate;
    }
    return baseDate;
  }

  clearDropdown(level: number): void {
    switch (level) {
      case 1:
        this.serviceType = null;
        this.serviceName = null;
        this.selectedWeek = null;
        break;
      case 2:
        this.serviceName = null;
        this.selectedWeek = null;
        break;
      case 3:
        this.selectedWeek = null;
        break;
      default:
        this.serviceType = null;
        this.serviceName = null;
        this.selectedWeek = null;
        break;
    }
  }
}
