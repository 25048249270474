import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Subject, Subscription, of } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, mergeMap, delay, finalize } from 'rxjs/operators';
import { SortableHeader, SortEvent } from 'src/app/shared/directive/sortable.directive';

import { CustomFormService } from 'src/app/users/service/custom-form/custom-form.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'admin-form-logs',
  templateUrl: './admin-form-logs.html',
  styleUrls: ['./admin-form-logs.scss'],
})
export class AdminFormLogsComponent implements OnInit {
  @Input() customFormId: string = null;
  @Input() formName: any = null;
  isTableLoading: boolean = false;
  @Output() openManage = new EventEmitter<any>();
  usersPTable
  isLoader = false
  public userssearchInput = new Subject<KeyboardEvent>();
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  onSortUsers({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {

      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.usersPTable.sortBy = direction || 'desc';
    this.usersPTable.sortWith = column;
    this.usersPTable.page = 1;
    this.loadFormLog();
  }

  onPageChangeUsers(page: number) {
    this.usersPTable.page = page;
    this.loadFormLog();
  }

  downloadLogFile(logItem) {
    this.isLoader = true
    this.customformService
      .downloadLogError({logId: logItem._id, customFormId: this.customFormId})
      .subscribe((data) => {
        this.downloadFile(data.fileURL, `${this.formName}_${new Date().getUTCSeconds()}.csv`)
        this.isLoader = false
        this.toastService.success('File downloaded successfully');
      }, (error) => {
        this.isLoader = false
        this.toastService.error('There is some error in file download. Try again');
      });
  }

  downloadFile(url: string, fileName: string): void {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        // Create a new URL for the blob
        const downloadUrl = window.URL.createObjectURL(blob);

        // Create a new anchor element
        const anchor = document.createElement('a');

        // Set the href attribute to the blob URL
        anchor.href = downloadUrl;

        // Set the download attribute to suggest a filename for the downloaded file
        anchor.download = fileName;

        // Append the anchor element to the document body
        document.body.appendChild(anchor);

        // Programmatically click the anchor to trigger the download
        anchor.click();

        // Clean up by revoking the object URL and removing the anchor
        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(anchor);
      })
      .catch(error => console.error('Error downloading the file:', error));
  }

  onLimitChangeUsers() {
    this.usersPTable.page = 1;
    this.loadFormLog();
  }

  private usersSearchSubscription: Subscription;

  constructor(
    private customformService: CustomFormService,
    public toastService: ToastrService,
  ) {
    this.usersPTable = {
      sortBy: 'desc',
      sortWith: 'createdAt',

      data: [],
      page: 1,
      limit: 10,
      search: '',

    };
    // this.companyName = this.storageService.getByKey('companyId');

    this.usersSearchSubscription = this.userssearchInput
      .pipe(
        map((event) => {
          const target = event.target as HTMLTextAreaElement;
          return target.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(500)))
      )
      .subscribe((input) => {
        this.usersPTable.page = 1;
        this.usersPTable.search = input;
        this.loadFormLog();
      });
  }

  ngOnInit(): void {
    console.log('customFormId', this.customFormId, this.formName)
    this.loadFormLog();
  }

  loadFormLog() {
    this.isLoader = true;
    const query = {
      page: this.usersPTable.page,
       limit: this.usersPTable.limit,
       search: this.usersPTable.search,
       sortBy: this.usersPTable.sortBy,
       sortWith: this.usersPTable.sortWith,
    }
    console.log(this.usersPTable, query)
    const subscribe = this.customformService
      .getIndentLogs(query, this.customFormId)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
        })
      )
      .subscribe(
        (response: any) => {
          console.log('response.data', response.data)

          this.usersPTable.data = response.data?.data;
          this.usersPTable.total = response.data?.total;
          this.isLoader = false;
        },
        (error) => {
          this.usersPTable.data = [];
          this.usersPTable.total = 0;
          this.toastService.error('No list found');
          this.isLoader = false;
        }
      );
  }

  ngOnDestroy(): void {
    this.usersSearchSubscription && this.usersSearchSubscription.unsubscribe();
  }

  goToManage(){
    this.openManage.emit(true);
  }
}



