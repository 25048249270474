<div class="card" *ngIf="isOverview">
  <label class="font-weight-500 m-3" for="">Select Week</label>
  <div class="d-flex ml-3 mb-3">
    <div
      class="weekbtn d-flex justify-content-center align-items-center rounded-left"
      (click)="changeWeek('Prev')"
      title="Previous Week"
    >
      <i class="material-icons"> chevron_left </i>
    </div>

    <div class="weekInput d-flex align-items-center">
      {{ formattedWeekRange }}
    </div>

    <div
      class="weekbtn d-flex justify-content-center align-items-center rounded-right"
      (click)="changeWeek('Nxt')"
      title="Next Week"
    >
      <i class="material-icons"> chevron_right </i>
    </div>
  </div>
  <div class="card-header align-items-center bg-theme">
    <h5 class="m-0">SERVICE LOGS</h5>
    <p class="m-0">Selected Service Type : {{ serviceType.serviceTypeName }}</p>
  </div>
  <div class="card-body table-responsive p-0">
    <ngx-skeleton-loader
      *ngIf="logsLoader"
      count="6"
      [theme]="skeletonLoaderTheme"
    ></ngx-skeleton-loader>
    <table class="table m-0" *ngIf="!logsLoader">
      <thead>
        <tr>
          <th scope="col" sortable="Tier 1">DATE</th>
          <!-- (sort)="onSortBuShiftSetup($event) -->
          <th scope="col" sortable="Tier 2">TIME</th>
          <th scope="col" sortable="Tier 3">DESCRIPTION</th>

          <th scope="col" sortable="Tier 4">USER</th>
          <th scope="col" sortable="Status">STATUS</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let log of logsData">
          <td>{{ log.createdAt | date : "dd/MM/yyyy" }}</td>
          <td>{{ log.createdAt | date : "HH:mm" }}</td>
          <td>{{ log.description }}</td>
          <td>{{ log.staffId }}</td>
          <td>
            <button
              class="btn btn-success"
              (click)="changeOverview(false, log)"
            >
              View
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="bg-white text-center p-3"
      *ngIf="!logsLoader && logsData?.length === 0"
    >
      No Data Available
    </div>
  </div>
</div>

<div class="card" *ngIf="!isOverview">
  <div
    class="card-header d-flex align-items-center justify-content-between bg-theme"
  >
    <h5 class="m-0">LOG DETAILS</h5>
    <button class="btn btn-outline-light btn-sm" (click)="changeOverview(true)">
      Back
    </button>
  </div>
  <div class="card-body">
    <form>
      <div class="card mb-3">
        <div class="card-header">
          <h5 class="m-0">General</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="">Date</label>
                <input
                  #date
                  class="form-control"
                  [disabled]="true"
                  [value]="this.logDetail?.createdAt | date : 'dd/MM/yyyy'"
                  placeholder="date"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="">Time</label>
                <input
                  class="form-control"
                  [disabled]="true"
                  [value]="this.logDetail?.createdAt | date : 'HH:mm'"
                  placeholder="time"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="">User</label>
                <input
                  class="form-control"
                  [disabled]="true"
                  [value]="this.logDetail?.staffId"
                  placeholder="user"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Description</label>
                <input
                  class="form-control"
                  [disabled]="true"
                  [value]="this.logDetail?.description"
                  placeholder="Description"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <h5 class="m-0">Service Listing</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div *ngFor="let j of logDetail?.services">
                <div
                  class="shiftTab d-flex align-items-center mb-2"
                  [ngClass]="{ selected: selectedService === day }"
                  (click)="
                    changeShiftDetailsView(true, j, day); selectedService = day
                  "
                  *ngFor="let day of j?.days"
                >
                  <div class="d-flex justify-content-between w-100">
                    <div>{{ logDetail?.serviceName }}</div>
                    <div>
                      {{ j.serviceStartTime }}-{{ j.serviceEndTime }}
                      <div class="d-flex justify-content-end mt-1">
                        <div class="badge badge-pill bg-theme">
                          {{ j?.confirmSlot }}/{{ j?.standBySlot }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6" *ngIf="showServiceDetails">
              <div class="card">
                <div class="card-header">
                  <h6 class="m-0">Details of Service</h6>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Service Name</label>
                        <input
                          class="form-control"
                          placeholder="Service Name"
                          [disabled]="true"
                          value="{{ logDetail?.serviceName }}"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Day</label>
                        <input
                          class="form-control text-capitalize"
                          [disabled]="true"
                          value="{{ serviceDetail?.day }}"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group row">
                        <div class="col-md-6">
                          <label for="">Start Time</label>
                          <input
                            class="form-control"
                            placeholder="Timing"
                            [disabled]="true"
                            value="{{ serviceDetail.serviceStartTime }}"
                          />
                        </div>
                        <div class="col-md-6">
                          <label for="">End Time</label>
                          <input
                            class="form-control"
                            placeholder="Timing"
                            [disabled]="true"
                            value="{{ serviceDetail.serviceEndTime }}"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Publish Message</label>
                        <input
                          class="form-control"
                          [disabled]="true"
                          value="Service Created Successfully"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
