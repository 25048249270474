<div class="card">
  <h5 class="card-header bg-theme">
    {{ userId ? "UPDATE" : "ADD" }}
  </h5>

  <div class="card-body">
    <form [formGroup]="createUserForm">
      <div class="row" *ngIf="userId">
        <label class="font-weight-bold col-md-3"> STATUS</label>

        <div class="form-group col-md-2 pr-0">
          <input type="radio" [value]="1" formControlName="status" />
          <label for="std-row" class="radio-inline ml-2">Active</label>
        </div>
        <div class="form-group col-md-2 pr-0">
          <input type="radio" [value]="2" formControlName="status" />
          <label for="custom-row" class="radio-inline ml-2">Inactive</label>
        </div>
      </div>

      <div class="row mb-2">
        <label class="font-weight-bold col-md-3">ALL BU TOKEN</label>

        <div class="form-group col-md-1 pr-0">
          <input type="checkbox" id="active" formControlName="allBUAccess" />
          <label for="active" class="radio-inline ml-2">ACTIVE</label>
        </div>
      </div>
      <div class="row mb-2">
        <label class="font-weight-bold col-md-3">BUSINESS UNIT</label>
      </div>
      <div class="row mb-2">
        <div class="col-md-3 mt-2" formGroupName="parentBussinessUnitId">
          <label>Business unit (Parent)<span class="field-mandatory">*</span></label>
          <ng-select
            (change)="addParentBussinentUnit($event)"
            class="custom"
            bindLabel="name"
            placeholder="Parent Business unit"
            appendTo="body"
            [multiple]="false"
            [searchable]="true"
            [clearable]="true"
            [closeOnSelect]="true"
            [loading]="buUnitDropDownLoading"
          [(ngModel)]="parentBuUnit"
          [ngModelOptions]="{ standalone: true }"


          >
            <ng-option [value]="item" *ngFor="let item of parentBuUnitDropDown">
              {{ item.orgName }}
            </ng-option>
          </ng-select>
          <div
            *ngIf="createUserForm.get('parentBussinessUnitId.string').value"
            class="row red-container mt-3 ml-0"
          >
            <div class="col-10 selected-text">
              {{ createUserForm.get("parentBussinessUnitId.string").value }}
            </div>
            <div class="col-2 cancel-btn-container">
              <img
                (click)="deleteparentBussinesUnit()"
                src="./assets/images/cancel.png"
                width="20px"
                height="20px"
              />
            </div>
          </div>
        </div>
        <div class="col-md-3 mt-2 angular2-multiselect-dropdown">
          <label> Business unit (Plan) </label>
          <div *ngIf="buUnitDropDownLoading" class="loader-multiselect-icon"><span></span></div>
          <angular2-multiselect [data]="planBuUnitDropDown" [(ngModel)]="planBuUnit" [settings]="dropdownSettingsBplan"
            (onSelect)="addBussinesUnitPlan($event)" (onDeSelect)="deselectBussinesUnitPlan($event)"
            (onSelectAll)="addAllBussinesUnitPlan($event)" [ngModelOptions]="{ standalone: true }"
            (onDeSelectAll)="deselectAllBussinesUnitPlan($event)">
            <c-badge>
              <ng-template let-item="item">
                {{planBuUnit?.length}} selected
              </ng-template>
            </c-badge>
          </angular2-multiselect>
          <div
            formArrayName="planBussinessUnitId"
            *ngFor="
              let field of PlanBuUnitFieldsControl.controls;
              let index = index
            "
            class="row red-container mt-3 ml-0"
          >
            <div class="col-10 selected-text">
              {{ field.get("string").value }}
            </div>
            <div class="col-2 cancel-btn-container">
              <img
                (click)="deletePlanBuUnitFields(index,field.get('_id').value)"
                src="./assets/images/cancel.png"
                width="20px"
                height="20px"
              />
            </div>
          </div>
        </div>
        <div class="col-md-3 mt-2  angular2-multiselect-dropdown">
          <label> Business unit (view) </label>
         <div *ngIf="buUnitDropDownLoading" class="loader-multiselect-icon"><span></span></div>
          <angular2-multiselect [data]="planBuUnitDropDown" [(ngModel)]="viewBuUnit" [settings]="dropdownSettingsBview"
            (onSelect)="addBussinesUnitView($event)" (onDeSelect)="deselectBussinesUnitView($event)"
            (onSelectAll)="addAllBussinesUnitView($event)" [ngModelOptions]="{ standalone: true }"
            (onDeSelectAll)="deselectAllBussinesUnitView($event)">
            <c-badge>
              <ng-template let-item="item">
                {{viewBuUnit?.length}} selected
              </ng-template>
            </c-badge>
          </angular2-multiselect>

          <div
            formArrayName="viewBussinessUnitId"
            *ngFor="let field of ViewBuUnitControl.controls; let index = index"
            class="row red-container mt-3 ml-0"
          >
            <div class="col-10 selected-text">
              {{ field.get("string").value }}
            </div>
            <div class="col-2 cancel-btn-container">
              <img
                (click)="deleteViewBuUnitFields(index,field.get('_id').value )"
                src="./assets/images/cancel.png"
                width="20px"
                height="20px"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-2 mt-3">
        <label class="font-weight-bold col-md-3">USER INFORMATION</label>
      </div>
      <div class="row mb-2">
        <div class="col-md-3 mt-1">
          <div class="form-group">
            <label class="pb-2 form-bg" for="exampleInputEmail1">Name<span class="field-mandatory">*</span></label>

            <input
              type="text"
              class="form-control"
              placeholder=" Name"
              required
              formControlName="name"
              maxlength="40"
            />
          </div>
        </div>
        <div class="col-md-3 mt-1">
          <div class="form-group">
            <label class="pb-2 form-bg" for="exampleInputEmail1"
              >Staff ID<span class="field-mandatory">*</span></label
            >

            <input
              type="text"
              class="form-control"
              placeholder="Staff ID"
              required
              formControlName="staffId"
              maxlength="40"
            />
          </div>
        </div>
        <div class="col-md-3">
          <label>
            Appoinment
            <button class="btn p-1 ml-1" (click)="addApoinment()">
              <i class="fa fa-plus-circle" aria-hidden="true"></i></button
          ><span class="field-mandatory">*</span></label>
          <ng-select
            [items]="appoinmentDropDown"
            bindLabel="name"
            bindValue="_id"
            [searchable]="true"
            [closeOnSelect]="true"
            [clearable]="true"
            [multiple]="false"
            placeholder="Select appoinment"
            formControlName="appointmentId"
            [loading]="apppoinmentDropDownLoading"
            (search)="onSeachAppoinment($event)"
            (scrollToEnd)="loadMoreAppoinmentItems($event)"
            (clear)="onClearAppoinmentDropdown($event)"
          >
          </ng-select>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-md-3">
          <div class="form-group">
            <label class="form-bg" for="exampleInputEmail1"
              >Contact number</label
            >
            <input
              type="text"
              placeholder="Contact Number"
              class="form-control"
              maxlength="13"
              formControlName="contactNumber"
              pattern="[+\d]*"
              oninput="this.value = this.value.replace(/[^+\d]/g, '')"
            />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="form-bg" for="exampleInputEmail1">Email<span class="field-mandatory">*</span></label>
            <input
              type="text"
              class="form-control"
              maxlength="40"
              placeholder="Email"
              formControlName="email"
            />
            <ng-container *ngIf="createUserForm.controls.email.touched && createUserForm.controls?.email?.errors?.pattern">
              <label style="color: red;margin-top: 4px;"> Enter a valid email.</label>
            </ng-container>
          </div>
        </div>
      </div>
    </form>
    <div class="row mb-2">
      <label class="font-weight-bold col-md-3">CUSTOM FIELD</label>
    </div>

    <form [formGroup]="form" *ngIf="fields">
      <dynamic-form-builder
        [gridView]="'col-xs-12 col-sm-12 col-md-3 col-lg-3 '"
        [formGroup]="form"
        [fields]="getFields()"
      ></dynamic-form-builder>
    </form>

    <form [formGroup]="createUserForm">
      <div class="row mb-2">
        <label class="font-weight-bold col-md-3"
          >ADDITIONAL USER INFORMATION</label
        >
      </div>
      <div class="row mb-2">
        <div class="col-md-3 form-group" formGroupName="role">
          <label>Roles<span class="field-mandatory">*</span></label>
          <ng-select
            (change)="addRole($event)"
            class="custom"
            bindLabel="name"
            placeholder="Select role"
            appendTo="body"
            [multiple]="false"
            [searchable]="true"
            [clearable]="true"
            [loading]="roleDropDownLoading"
            [(ngModel)]="selectedRole"
            [ngModelOptions]="{ standalone: true }"
          >
          <!-- (search)="onSeachRole($event)"
          (scrollToEnd)="loadMoreRoleItems($event)"
          (clear)="onClearRoleDropdown($event)" -->
            <ng-option [value]="item" *ngFor="let item of roleDropDown">
              {{ item.name }}
            </ng-option>
          </ng-select>
        </div>

        <div class="col-md-6 user-role">
          <ul *ngIf="createUserForm.get('role.string').value">
            <li>
              <span class="text-items list-ui"
                >{{ createUserForm.get("role.string").value }}
                <img
                  class="ml-3"
                  (click)="deleteroleFields()"
                  *ngIf="createUserForm.get('role.string').value"
                  src="./assets/images/cancel.png"
                  width="20px"
                  height="20px"
              /></span>
            </li>
          </ul>
        </div>
      </div>

      <div class="row my-2">
        <div class="col-md-3 form-group" formGroupName="leaveGroupId">
          <label>Assign Leave Grouping</label>
          <ng-select
            (change)="addAssignLeave($event)"
            class="custom"
            bindLabel="name"
            placeholder="Select role"
            appendTo="body"
            [multiple]="false"
            [searchable]="true"
            [clearable]="true"
            [loading]="roleDropDownLoading"
            [(ngModel)]="selectedLeaveGroup"
            [ngModelOptions]="{ standalone: true }"
          >
          <!-- (search)="onSeachRole($event)"
          (scrollToEnd)="loadMoreRoleItems($event)"
          (clear)="onClearRoleDropdown($event)" -->
            <ng-option [value]="item" *ngFor="let item of assignData">
              {{ item.name }}
            </ng-option>
          </ng-select>
        </div>

        <div class="col-md-6 user-role">
          <ul *ngIf="createUserForm.get('leaveGroupId.string').value">
            <li>
              <span class="text-items list-ui"
                >{{ createUserForm.get("leaveGroupId.string").value }}
                <img
                  class="ml-3"
                  (click)="deleteAssignFields()"
                  *ngIf="createUserForm.get('leaveGroupId.string').value"
                  src="./assets/images/cancel.png"
                  width="20px"
                  height="20px"
              /></span>
            </li>
          </ul>
        </div>
      </div>

      <div class="row my-2">
        <div class="col-md-3 form-group" formGroupName="transportBuId">
          <label>Transport BU</label>
          <ng-select
            (change)="addTransportBU($event)"
            class="custom"
            bindLabel="name"
            placeholder="Select Transport Bu"
            appendTo="body"
            [multiple]="false"
            [searchable]="true"
            [clearable]="true"
            [loading]="roleDropDownLoading"
            [(ngModel)]="selectedTransportBuId"
            [ngModelOptions]="{ standalone: true }"
          >
            <ng-option [value]="item" *ngFor="let item of transportBuIdData">
              {{ item.name }}
            </ng-option>
          </ng-select>
        </div>

        <div class="col-md-6 user-role">
          <ul *ngIf="createUserForm.get('transportBuId.string').value">
            <li>
              <span class="text-items list-ui"
                >{{ createUserForm.get("transportBuId.string").value }}
                <img
                  class="ml-3"
                  (click)="deleteAssignFields()"
                  *ngIf="createUserForm.get('transportBuId.string').value"
                  src="./assets/images/cancel.png"
                  width="20px"
                  height="20px"
              /></span>
            </li>
          </ul>
        </div>
      </div>
    </form>
  </div>
  <div class="card-footer">
    <div class="d-flex">
      <button
        (click)="overview()"
        class="align-items-center btn btn-outline-dark d-flex"
      >
        <span class="material-icons mr-1"> arrow_back </span>Back
      </button>
      <button
        class="align-items-center btn-success btn d-flex ml-1"
        [disabled]="isUpdateLoading"
        (click)="onCreateUser()"
      >
        {{ userId ? "Update User" : "Add User" }}
        <span
          *ngIf="isUpdateLoading"
          class="spinner-border spinner-border-sm ml-1"
          role="status"
          aria-hidden="true"
        ></span>
        <span class="material-icons ml-1" *ngIf="!isUpdateLoading">
          arrow_upward
        </span>
      </button>
    </div>
  </div>
</div>
<div class="loading-class" *ngIf="isUserEdited">
  <img src="./assets/images/loading.gif" />
  <div class="text-center">Please wait, data is loading...</div>
</div>
<ng-template #AddSubSection let-c="close" let-d="dismiss">
  <div>
    <div class="modal-content">
      <div class="modal-body" style="padding: 10px; min-height: 100vh">
        <div>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3>ADD APPOINMENT</h3>
              <button
                type="button"
                class="btn"
                aria-label="Close"
                (click)="c('Cross click')"
              >
                <i class="fa fa-times-circle fa-lg" aria-hidden="true"></i>
              </button>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <form [formGroup]="createApoinmentForm">
                    <div class="form-group">
                      <label for="exampleInputEmail1">APPOINMENT</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="name"
                        placeholder="Appoinment"
                      />
                    </div>
                  </form>
                </div>
              </div>
              <button
                class="btn btn-add pull-right"
                [disabled]="!createApoinmentForm.valid"
                (click)="appoinmentCreate()"
              >
                {{ isAppoinmentEdited ? "Update" : "Add" }}
              </button>
            </div>
          </div>
          <div class="card my-3 w-100">
            <div class="card-header flexi-header">
              <h3>APPOINMENT LIST</h3>
            </div>
            <div class="card-body">
              <div class="card table-card">
                <div class="card-body flexi-body">
                  <div class="flex-column">
                    <div class="d-flex justify-content-between mt-2 mb-3">
                      <select
                        class="custom-select"
                        style="width: auto"
                        name="pageSize"
                        (change)="onLimitChangeApoinment()"
                        [(ngModel)]="appoinmentPTable.limit"
                      >
                        <option [ngValue]="10">10</option>
                        <option [ngValue]="20">20</option>
                        <option [ngValue]="30">30</option>
                        <option [ngValue]="40">40</option>
                        <option [ngValue]="50">50</option>
                      </select>
                      <div class="form-group form-inline mb-0">
                        <span *ngIf="appoinmentPTable.isLoading"
                          >Please wait...</span
                        >
                        <input
                          class="form-control ml-2"
                          type="search"
                          name="searchTerm"
                          (keyup)="apoinmentSearchInput.next($event)"
                          placeholder="Search here"
                          [(ngModel)]="appoinmentPTable.search"
                        />
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>S.NO</th>
                            <th
                              scope="col"
                              sortable="name"
                              (sort)="onSortApoinment($event)"
                            >
                              APPOINMENT
                            </th>

                            <th scope="col">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of appoinmentPTable.data;
                              let index = index
                            "
                          >
                            <td>{{ index + 1 }}</td>

                            <td>{{ item.name }}</td>

                            <td>
                              <button
                                class="btn btn-add p-2 m-1"
                                (click)="onEditAppoinment(item)"
                              >
                                Edit
                              </button>
                              <button
                                class="btn btn-clear p-2 m-1"
                                (click)="onDeleteAppoinment(item._id)"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="d-flex justify-content-between">
                    <p>Total records {{ appoinmentPTable.total }}</p>
                    <ngb-pagination
                      [collectionSize]="appoinmentPTable.total"
                      [(page)]="appoinmentPTable.page"
                      [maxSize]="5"
                      (pageChange)="onPageChangeApoinment($event)"
                      [pageSize]="appoinmentPTable.limit"
                    >
                    </ngb-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
