<div class="card mb-3" *ngIf="!isModuleView">
  <h5 class="card-header bg-theme">FORMS</h5>
  <div class="card-body">
    <div class="card table-card">
      <div class="card-body flexi-body">
        <div class="flex-column">
          <div class="d-flex justify-content-between mt-2 mb-3">
            <select
              class="custom-select"
              style="width: auto"
              name="pageSize"
              (change)="onLimitChangeUsers()"
              [(ngModel)]="usersPTable.limit"
            >
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="30">30</option>
              <option [ngValue]="40">40</option>
              <option [ngValue]="50">50</option>
            </select>
            <div class="form-group form-inline mb-0">
              <!-- <span *ngIf="usersPTable.isLoading">Please wait...</span> -->
              <input
                class="form-control ml-2"
                type="search"
                name="searchTerm"
                (keyup)="userssearchInput.next($event)"
                placeholder="Search here"
                [(ngModel)]="usersPTable.search"
              />
            </div>
          </div>
          <div class="table-responsive">
            <table class="table" *ngIf="!isTableLoading">
              <thead>
                <tr>
                  <th
                    scope="col"
                    sortable="_id"
                    (sort)="onSortUsers($event)"
                  >
                    CREATED AT
                  </th>
                  <th
                    scope="col"
                    sortable="title"
                    (sort)="onSortUsers($event)"
                  >
                    FORMS TITLE
                  </th>
                  <th>
                    DEPLOYED
                  </th>

                  <th
                    scope="col"
                    sortable="status"
                    (sort)="onSortUsers($event)"
                  >
                    STATUS
                  </th>

                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of usersPTable.data | slice: (usersPTable.page-1) * usersPTable.limit : usersPTable.page * usersPTable.limit;">
                  <td>
                    {{ item.createdAt | date: "medium" }}
                  </td>

                  <td>{{ item.title }}</td>

                  <td>
                    {{
                      item.isDeployed === 1 ? "Mobile and Webapp	" : "External"
                    }}
                  </td>
                  <td>
                    <span
                      class="badge"
                      [ngClass]="
                        item.status == 1
                          ? 'badge-success'
                          : 'badge-danger'
                      "
                    >
                      {{
                        item.status === 1 ? "Published" : "In Active"
                      }}</span
                    >
                  </td>

                  <td>
                    <button
                      class="btn btn-submit p-2 m-1"
                      (click)="getQuestions(item.moduleId,item._id)"
                    >
                      Take Questions
                    </button>
                    <button class="btn btn btn-white p-2 m-1" (click)="viewQuestions(item._id,item.title)">View</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <app-skliton-loading-table
            [numberOfCols]="5"
            [isTableLoading]="isTableLoading"
          ></app-skliton-loading-table>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <p>Total Records {{ usersPTable.total }}</p>
          <ngb-pagination
            [collectionSize]="usersPTable.total"
            [(page)]="usersPTable.page"
            [maxSize]="5"
            (pageChange)="onPageChangeUsers($event)"
            [pageSize]="usersPTable.limit"
          >
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

  <div class="card" *ngIf="isModuleView">
    <div class="card-header flexi-header pt-4">
      <h3 style="display: inline">
        {{ selectedModule?.moduleName | uppercase }}
      </h3>
     <button class="btn pull-right" style="color: ivory" (click)="back()">
        Back
      </button>
    </div>


    <div class="card-header">
      <div class="card-body centered-image">
        <img [src]="selectedModule.welComeAttachement" width="300px" height="300px" class="centered-image" id="4" alt="Image Preview" crossorigin="anonymous" />
      </div>
      <h3 style="text-align: center">
        {{ selectedModule?.welComeMessage }}
      </h3>
    </div>
  <!--<div style="margin-left: 400px; margin-top:20px">
    <h4>
      {{ selectedModule?.welComeMessage }}
    </h4>
  </div>-->
    <div class="card-body" style="word-break: break-word; padding: 10px">
      <form [formGroup]="answerForm" (ngSubmit) ="submit()">
        <div class="box d-flex flex-column" *ngFor="let session of selectedModule?.sessions; let i = index">
       <div class="box d-flex flex-column" *ngFor="let item of session?.questions; let i = index">
        <div class="d-flex">
          <strong class="mr-1">{{ i + 1 }} </strong>
          <div class="box-heading" style="word-break: break-word">
            &emsp; <p [innerHTML]="item?.question | safeHtml"></p>
          </div>
        </div>
        <div class="box-body" *ngIf="item.type == 1">
          <div class=" form-group d-flex flex-column answers-options">
            <div class="box" *ngFor="let option of item.options">
              <div *ngIf="option.imageSrc">
                <div class="card-body">
                  <img [src]="option.imageSrc" width="320px" height="350px" class="centered-image" id="4" alt="Image Preview" crossorigin="anonymous" />
                </div>
              </div>
              <input type="radio" name="typeOne" formControlName ="typeOne"[value]="option.value" />
              <label for="consolidated">{{ option.value }}</label>
            </div>
          </div>
          <br/>
        </div>

        <div  class="box-body" *ngIf="item.type == 2">
          <div class=" form-group d-flex flex-column answers-options ">
            <div class="box" *ngFor="let option of item.options">
             <div *ngIf="option?.imageSrc">
            <div class="card-body">
              <img [src]="option.imageSrc" width="320px" height="350px" class="centered-image" id="4" alt="Image Preview" crossorigin="anonymous" />
            </div>
          </div>
              <input type="checkbox"  [value]="option.value" (change)="onCheckChangeTypeTwo($event)" class="card-header-flexi"/>
              <label for="consolidated">{{ option.value }}</label>
            </div>
          </div>
          <br/>
        </div>


        <div class="box-body" *ngIf="item.type === 3">
          <div class=" form-group d-flex flex-column answers-options">
            <div class="box" *ngFor="let item of item.options">
              <div *ngIf="item.imageSrc">
                <div class="card-body">
                  <img [src]="item.imageSrc" width="320px" height="350px" class="centered-image" id="4" alt="Image Preview" crossorigin="anonymous" />
                </div>
              </div>
              <input type="radio"  name="typeThree" formControlName ="typeThree"[value]="item.value" />
              <label for="consolidated"> {{ item.value }}</label>
            </div>
          </div>
          <br/>
        </div>


        <div class="card-body" *ngIf="item.type === 4">
          <div class=" form-group d-flex flex-column answers-options">
            <div class="parent">
            <div class="flex-child" *ngFor="let item of item.options">
                  <div class="box"  *ngIf="item.imageSrc">
                    <img [src]="item.imageSrc" width="250px" height="300px"  id="4" alt="Image Preview" crossorigin="anonymous" />
                  </div>
                <input type="radio"  name="typeFour" formControlName ="typeFour"[value]="item.value" />
                  <label for="consolidated"> {{ item.value }}</label>
              </div>
            </div>
            </div>
            <br/>
          </div>


        <div class="box-body" *ngIf="item.type === 5">
          <div class=" form-group d-flex flex-column answers-options">
            <div class="box" *ngFor="let option of item.options">
              <div *ngIf="option.imageSrc">
                <div class="card-body">
                  <img [src]="option.imageSrc" width="320px" height="350px" class="centered-image" id="4"  crossorigin="anonymous" alt="Image Preview" />
                </div>
              </div>
              <input type="checkbox"  [value]="option.value" (change)="onCheckChangeTypeFive($event)"/>
                <label for="consolidated">{{ option.value }}</label>
              </div>
            </div>
            <br/>
          </div>


        <div class="box-body" *ngIf="item.type === 6">
          <div class="d-flex flex-wrap answers-options">
            <div class="form-group flex-fill" style="margin: 1rem 1rem; width: 44%" *ngFor="let item of item.profile">
              <label for="my-input">{{ item.questionName }}</label>
              <input id="my-input" class="form-control" type="text" name="typeSix" formControlName="typeSix" />
            </div>
          </div>
          <br/>
        </div>


        <div class="box-body" *ngIf="item.type === 7">
          <div class="d-flex flex-wrap answers-options">
            <div class="form-group flex-fill" style="margin: 1rem 1rem; width: 44%">
              <textarea class="form-control" rows="3"></textarea>
            </div>
          </div>
          <br/>
        </div>

        <div class="box-body" *ngIf="item.type === 8">
          <div class="d-flex flex-wrap answers-options">
            <div class="form-group flex-fill" style="margin: 1rem 1rem; width: 44%">
              <textarea class="form-control" rows="5"></textarea>
            </div>
          </div>
          <br/>
        </div>


        <div class="box-body" *ngIf="item.type === 9">
          <div class="d-flex flex-wrap answers-options">
            <div class="form-group flex-fill" style="margin: 1rem 1rem; width: 44%">
              <input type="number" class="form-control" />
            </div>
          </div>
          <br/>
        </div>


        <div class="box-body" *ngIf="item.type === 10">
          <div class="form-group">
            <div class="d-flex flex-wrap answers-options">
              <div class="form-group flex-fill" style="margin: 1rem">
                <label for="my-input">Date</label>
                <input id="my-input" class="form-control" type="date" />
              </div>
              <div class="form-group flex-fill" style="margin: 1rem">
                <label for="my-input">Hours</label>
                <select name="" id="" class="form-control"></select>
              </div>
              <div class="form-group flex-fill" style="margin: 1rem">
                <label for="my-input">Minutes</label>
                <select name="" id="" class="form-control"></select>
              </div>
              <div class="form-group flex-fill" style="margin: 1rem">
                <label for="my-input">Seconds</label>
                <select name="" id="" class="form-control"></select>
              </div>
            </div>
          </div>
          <br/>
        </div>


        <div class="box-body" *ngIf="item.type === 11">
          <select class="form-control" [attr.disabled]="true"></select>
          <br/>
        </div>


        <div class="box-body" *ngIf="item.type === 12">
          <div class="form-group">
            <input id="my-input" class="form-control-file" type="file" />
          </div>
          Preffered formats are -
          <span *ngFor="let item of item?.options">
            {{ item.value }}<span *ngIf="item.length != 0"> </span>
          </span>
          <br/>
        </div>

        <div class="box-body" *ngIf="item.type === 13">
          <div class="d-flex">
            <ngb-rating [max]="item.value" [rate]="0"></ngb-rating>
          </div>
          <br/>
        </div>


        <div class="box-body" *ngIf="item.type === 14">
          <select class="form-control" [attr.disabled]="true"></select>
          <br/>
        </div>


        <div class="box-body" *ngIf="item.type === 15">


          <div class="row" ng-model="item.options">
            <div *ngFor="let option of item.options">
              <div class="thumbnail">
                <img ng-if="option.imageSrc" [src]="option.imageSrc" style="height: 150px" crossorigin="anonymous" />
                <div class="caption">
                  <p>{{ option.value }}</p>
                </div>
              </div>
            </div>
          </div>
          <br/>
        </div>


        <div *ngIf="selectedModule?.questions.length == 0" style="text-align: center">
          No questions to show
        </div>
       </div>
      </div>
      <div style="margin-left: 400px; margin-top:20px">
        <h4>
          {{ selectedModule?.Message }}
        </h4>
      </div>
      <button type="submit" class="btn-submit">submit</button>
    </form>
    </div>
  </div>
