<div class="card">
  <div class="card-header bg-theme">
    <h5 class="m-0">SERVICE SETUP</h5>
  </div>
  <div class="card-body table-responsive p-0">
    <div class="d-flex justify-content-between m-2">
      <select (change)="onLimitChangeServiceSetUp()" [(ngModel)]="serviceTable.limit" class="custom-select" name="pageSize"
        style="width: auto">
        <option *ngFor="let option of pageSizeOptions" [ngValue]="option">{{ option }}</option>
      </select>
      <div class="form-group form-inline mb-0">
        <input
          (keyup)="serviceSetUpSearchInput.next($event)"
          [(ngModel)]="serviceTable.search"
          class="form-control ml-2"
          name="searchTerm"
          placeholder="Search here"
          type="search"
        />
      </div>
    </div>

    <div *ngIf="serviceTableErr; then NoData; else dataTable"></div>
    <ng-template #NoData>
      <div class="text-md text-black-50 p-4">Not Found!</div>
    </ng-template>
    <ng-template #dataTable>
      <table class="table table-striped">
        <thead>
          <tr>
            <th
              (sort)="onSortServiceSetup($event)"
              scope="col"
              sortable="createdAt"
              >Date</th
            >
            <th
              (sort)="onSortServiceSetup($event)"
              scope="col"
              sortable="serviceName"
              >Service Name</th
            >
            <th
              (sort)="onSortServiceSetup($event)"
              scope="col"
              sortable="createdBy"
              >Author</th
            >
            <th
              (sort)="onSortServiceSetup($event)"
              scope="col"
              sortable="status"
              >Status</th
            >
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of paginatedData">
            <td>{{ item.createdAt | date }}</td>
            <td>{{ item.serviceName }}</td>
            <td>{{ item.createdBy.name }} ({{ item.createdBy.staffId }})</td>
            <td>{{ getStatus(item.status) }}</td>
            <td>
              <div class="d-flex">
                <button
                  (click)="onViewServiceSetup(item)"
                  class="btn btn-info btn-sm"
                  >View</button
                >
                <button
                  (click)="onEditServiceSetup(item)"
                  class="btn btn-success btn-sm ml-1"
                  >Edit</button
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-template>
    <ngx-skeleton-loader
      *ngIf="loader"
      count="6"
      [theme]="{
        width: 'calc(100% - 20px)',
        'border-radius': '6px',
        height: '44px',
        margin: '10px',
        display: 'block'
      }"
    ></ngx-skeleton-loader>
  </div>
  <div class="card-footer">
    <div class="d-flex justify-content-between">
      <p class="align-self-center m-0 text-muted">
        Total Records <b class="text-dark">{{ serviceTable.total }}</b>
      </p>
      <ngb-pagination
        (pageChange)="onPageChangeServiceSetUp($event)"
        [(page)]="serviceTable.page"
        [collectionSize]="serviceTable.total"
        [maxSize]="5"
        [pageSize]="serviceTable.limit"
      ></ngb-pagination>
    </div>
  </div>
</div>
