import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { SetupService } from 'src/app/users/service/operations/setup.service';

@Component({
  selector: 'app-manage-service-logs',
  templateUrl: './manage-service-logs.component.html',
  styleUrls: ['./manage-service-logs.component.scss'],
})
export class ManageServiceLogsComponent implements OnInit {
  @Input() selectedServiceName: any;
  @Input() serviceRequest: any;
  @Input() refreshNum: any;
  logsLoader = false;
  logsData: any[] = [];
  logDetail: any;
  serviceDetail: any;
  isOverview = true;
  showServiceDetails: boolean = false;

  constructor(
    private setupService: SetupService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.getLogs();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.refreshNum && !changes.refreshNum.firstChange) {
      this.handleRefresh();
    }
  }

  private getLogs() {
    this.logsLoader = true;
    const payload = {
      weekRangeStartAt: this.serviceRequest.weekRangeStartAt,
      weekRangeEndAt: this.serviceRequest.weekRangeEndAt,
      status: [3, 4, 5, 6],
    };

    this.setupService
      .getServiceLogs(payload)
      .pipe(
        finalize(() => {
          this.logsLoader = false;
        })
      )
      .subscribe(
        (response: any) => {
          this.logsData = response.data?.data || [];
        },
        (error) => {
          this.toastrService.error(
            error?.error?.error?.message || 'Failed to load service logs'
          );
        }
      );
  }

  private handleRefresh() {
    this.logsData = [];
    this.logDetail = null;
    this.serviceDetail = null;
    this.isOverview = true;
    this.showServiceDetails = false;
    this.getLogs();
  }

  changeOverview(value: boolean, item: any) {
    this.showServiceDetails = false;
    this.serviceDetail = null;
    this.logDetail = item;
    this.isOverview = value;
  }

  changeShiftDetailsView(value: boolean, item: any) {
    this.serviceDetail = item;
    this.showServiceDetails = value;
  }
}
